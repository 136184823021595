import React from "react";

import { Card, CardContent, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";

import { SERVICE_TYPES } from "../../../config/constants";
import { PlasticBagconfigurationViewUtils, ServiceActiveFlagViewUtils } from "../CountrySettingView";
import { ADVANCE_DAYS_DDN_OPTIONS } from "../CountrySettingsUtils";

import RenderAdvanceSlotDays from "./RenderAdvanceSlotDays";

interface RenderStandardServiceTypeDetailsInterface {
  classes: any;
  standardDetails: any;
  handleStandardDetailsChange: any;
  handleBagChargeChange: any;
  handleAdvanceDaysClick: any;
  handleServiceTypeActiveChange: any;
  serviceTypeName: any;
}

const RenderStandardServiceTypeDetails = (props: RenderStandardServiceTypeDetailsInterface) => {
  const { classes, standardDetails, serviceTypeName, handleStandardDetailsChange, handleBagChargeChange, handleAdvanceDaysClick, handleServiceTypeActiveChange } = props;

  return (
    <Card className={classes.serviceTypeCard}>
      <CardContent>
        <Grid container justifyContent="flex-start" spacing={2}>
          <ServiceActiveFlagViewUtils classes={classes} serviceTypeName={serviceTypeName} serviceActiveFlag={standardDetails.serviceActive} isDisabled={false} handleServiceTypeActiveChange={handleServiceTypeActiveChange} />
          <Grid item xs={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.serviceTypeContentTitle}>
                Next Day Slot Visibility
              </FormLabel>
              <RadioGroup name="standardNextDaySlotVisibility" value={standardDetails.nextDaySlotVisibility} onChange={handleStandardDetailsChange}>
                <FormControlLabel value={true} control={<Radio color="primary" />} label="On" classes={{ label: classes.formControlLabel }} />
                <FormControlLabel value={false} control={<Radio color="primary" />} label="Off" classes={{ label: classes.formControlLabel }} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {" "}
          </Grid>
          <Grid item xs={3}>
            <PlasticBagconfigurationViewUtils classes={classes} bagChargeEnabled={standardDetails.bagChargeEnabled} handleBagChargeChange={handleBagChargeChange} name={SERVICE_TYPES.STANDARD} />
          </Grid>
        </Grid>
        <RenderAdvanceSlotDays
          classes={classes}
          advanceSlotDays={standardDetails.advanceSlotDays}
          showAdvanceSlotDaysInput={standardDetails.showAdvanceSlotDaysInput}
          menuItems={ADVANCE_DAYS_DDN_OPTIONS}
          name={"standardAdvanceSlotDays"}
          value={standardDetails.editSlotDays || standardDetails.advanceSlotDays}
          handleChange={handleStandardDetailsChange}
          handleAdvanceDaysClick={handleAdvanceDaysClick}
          serviceTypeName={serviceTypeName}
        />
      </CardContent>
    </Card>
  );
};

export default React.memo(RenderStandardServiceTypeDetails);
