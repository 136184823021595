import moment, { Moment } from "moment";
import { isNullOrEmpty } from "./DataUtils";

export const HH_MM_SS = "HH:mm:ss";
export const HH_MM = "HH:mm";

export function getDateFromData(dateData: any) {
  if (dateData) {
    return moment(dateData).format("DD MMM YYYY");
  }
  return dateData;
}

export function getTimeFormatData(timeData: any) {
  if (timeData) {
    return moment(timeData, HH_MM_SS).format("HH:mm");
  }
  return timeData;
}

export function getDateFromPicker(dateData: any) {
  return moment(dateData).format("YYYY-MM-DD");
}

export function convertDateFromCurrentFormatToNewFormat(dateData: any, currentFormat: any, newFormat: any) {
  let returnDate = moment(dateData, currentFormat).format(newFormat);
  return returnDate;
}

interface GetTimeOptions {
  use24HourFormat?: boolean;
  includeSeconds?: boolean;
}

export const getTimeInHourAndMinutes = (minTime: string | number, options: GetTimeOptions = {}): string => {
  const { use24HourFormat = false, includeSeconds = false } = options;

  const totalMinutes = !isNaN(Number(minTime)) ? Math.floor(Number(minTime) / 60) : 0;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = includeSeconds ? (totalMinutes * 60) % 60 : 0;

  const formattedTime = use24HourFormat
    ? `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}${includeSeconds ? `:${String(seconds).padStart(2, "0")}` : ""}`
    : `${hours} hour${hours !== 1 ? "s" : ""} ${minutes} min${minutes !== 1 ? "s" : ""}${includeSeconds ? ` ${seconds} sec${seconds !== 1 ? "s" : ""}` : ""}`;
  return formattedTime;
};

export type TimeValue = string | number | Moment;
export enum DateFormat {
  YYYY_MM_DD = "YYYY-MM-DD",
  YYYY_MM_DDTHH_mm_ss = "YYYY-MM-DDTHH:mm:ss",
  HH_MM_SS = "HH:mm:ss",
  HH_MM = "HH:mm",
}

export const getMomentDateObj = (date: TimeValue, currentFormat: DateFormat) => {
  if (!isNullOrEmpty(date) && moment(date, currentFormat).isValid()) {
    return moment(date, currentFormat);
  }
  return date;
};

export const isDateValid = (date: TimeValue, currentFormat?: DateFormat) => {
  if (!isNullOrEmpty(currentFormat)) {
    return moment(date, currentFormat).isValid();
  }
  return moment(date).isValid();
};

export const getMomentDateBasedOnFormat = (date: TimeValue, currentFormat: DateFormat) => {
  if (!isNullOrEmpty(date) && moment(date).isValid()) {
    return moment(date).format(currentFormat);
  }
  return moment(date);
};

export const getMomentDateWithCurrentFormatBasedOnFormat = (date: TimeValue, currentFormat: DateFormat, returnFormat: DateFormat) => {
  if (!isNullOrEmpty(date) && moment(date).isValid()) {
    return moment(date, currentFormat).format(returnFormat);
  }
  return moment(date);
};

export function getTimeValueAsNumber(timeValue: TimeValue): number {
  if (typeof timeValue === "number") {
    return timeValue; // Already a number
  } else if (typeof timeValue === "string") {
    // Attempt to parse the string as a date/time
    const momentTime = moment(timeValue);
    if (momentTime.isValid()) {
      return momentTime.valueOf();
    } else {
      // Handle invalid string format (e.g., throw an error or return a default value)
      console.error(`Invalid time string: ${timeValue}`);
      return 0; // Or throw an error
    }
  } else if (moment.isMoment(timeValue)) {
    return timeValue.valueOf(); // Moment object
  } else {
    // Handle unexpected type (e.g., throw an error)
    console.error(`Unexpected time value type: ${timeValue}`);
    return 0; // Or throw an error
  }
}
