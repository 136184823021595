import React, { useEffect, useState } from "react";
import produce from "immer";

import { Accordion, AccordionDetails, AccordionSummary, Card, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ModalContainer from "../../base/ModalContainer";
import LinkButton from "../common/LinkButton/LinkButton";
import PrimaryButton from "../common/PrimaryButton/PrimaryButton";

import DlvFeeDateConfiguration from "./UtilsNew/DlvFeeDateConfiguration";

import { userStyles } from "./Styles/DeliveryFeeDateTimeStyles";
import CapacityUtilisationConfiguration from "./UtilsNew/CapacityUtilisationConfiguration";
import {
  handle_add_days_calculate_days_on_click,
  handle_add_days_on_click,
  handle_add_time__threshold_object,
  handle_display_days_on_change,
  handle_start_time_validation_minimum_time,
  handle_time_validation,
  validate_request_payload,
  _design_capacity_utilisation_demo_list,
  _update_capacity_utilisation_list_record,
} from "./UtilsNew/DeliveryFeeDateTimeUtils";
import { FEE_REG_EXP_VALUE } from "../../config/constants";
interface DeliveryFeeDateTimeInterface {
  open: any;
  handleClose: any;
  serviceTypeDetailsObj: any;
  _handle_on_save_configuration_dialog: any;
  state: any;
}

const DeliveryFeeDateTime = (props: DeliveryFeeDateTimeInterface) => {
  const { open, handleClose, serviceTypeDetailsObj, _handle_on_save_configuration_dialog, state } = props;
  const classes = userStyles();

  const [configurationState, setConfigurationState] = useState<any>({
    isLoading: false,
    openAddConfiguration: true,
    openCapacityUtilisation: false,
    configuration: [],
    capacity_utilisation_configuration: [],
    isCapacityUtilisationConfigurationEnabled: false,
  });

  const handleSave = (e: any) => {
    e.preventDefault();
    let configurationStateData = validate_request_payload(configurationState, "Save");
    setConfigurationState({ ...configurationStateData });
    if (configurationStateData.allOk && configurationStateData.capacity_all_ok) {
      _handle_on_save_configuration_dialog(configurationState.configuration, serviceTypeDetailsObj.serviceTypeName, serviceTypeDetailsObj.isMultiShipmentLevel, configurationState.capacity_utilisation_configuration);
    }
  };

  const handleAddDateButton = () => {
    const prev_configuration: any = produce(configurationState.configuration, (draft: any) => {
      draft.push({
        startDate: new Date(),
        endDate: new Date(),
      });
    });
    setConfigurationState({ ...configurationState, configuration: prev_configuration });
  };

  const handleDateChange = (dateObj: any, inputName: any, indexValue: any) => {
    const prev_configuration: any = produce(configurationState.configuration, (draft: any) => {
      if (indexValue >= 0) {
        draft[indexValue][inputName] = dateObj;
      }
    });
    setConfigurationState({ ...configurationState, configuration: prev_configuration });
  };

  const removeDateRecord = (indexValue: any) => {
    const prev_configuration: any = produce(configurationState.configuration, (draft: any) => {
      if (indexValue >= 0) {
        draft.splice(indexValue, 1);
      }
    });
    setConfigurationState({ ...configurationState, configuration: prev_configuration });
  };

  const handleAddDaysButton = (indexValue: any) => {
    if (indexValue >= 0) {
      let prev_configuration = configurationState.configuration;
      if (prev_configuration[indexValue] !== undefined && prev_configuration[indexValue].allDays && Array.isArray(prev_configuration[indexValue].allDays) && prev_configuration[indexValue].allDays.length > 0) {
        setConfigurationState({ ...configurationState, configuration: handle_add_days_on_click(indexValue, configurationState) });
      } else {
        setConfigurationState({ ...configurationState, configuration: handle_add_days_calculate_days_on_click(indexValue, configurationState) });
      }
    }
  };

  const handleSelectedDaysChange = (configIndex: any, event: any, daysIndex: any) => {
    let prev_configuration = configurationState.configuration;
    if (configIndex >= 0) {
      prev_configuration = handle_display_days_on_change(configIndex, prev_configuration, daysIndex, event, false);
    }
    setConfigurationState({ ...configurationState, configuration: prev_configuration });
  };

  const removeDayButton = (configIndex: any, daysIndex: any) => {
    let prev_configuration = configurationState.configuration;
    if (configIndex >= 0) {
      prev_configuration = handle_display_days_on_change(configIndex, prev_configuration, daysIndex, [], true);
    }
    setConfigurationState({ ...configurationState, configuration: prev_configuration });
  };

  const handleAddTimeButton = (dayObjectIndex: any, configIndex: any) => {
    setConfigurationState({ ...configurationState, configuration: handle_add_time__threshold_object(dayObjectIndex, configIndex, configurationState) });
  };

  const handleConfigTimeChange = (date: any, inputName: any, itemconfigIndex: any, dayObjectIndex: any, configIndex: any) => {
    let prev_configuration: any = produce(configurationState.configuration, (draft: any) => {
      draft[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration[itemconfigIndex][inputName] = date;
    });

    let errorMessage = "";
    if (inputName === "endTime") {
      errorMessage = handle_time_validation(itemconfigIndex, dayObjectIndex, configIndex, prev_configuration);
    } else if (inputName === "startTime") {
      errorMessage = handle_start_time_validation_minimum_time(itemconfigIndex, dayObjectIndex, configIndex, prev_configuration);
    }
    prev_configuration = produce(prev_configuration, (draft: any) => {
      draft[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration[itemconfigIndex].errorMessage = errorMessage;
    });

    setConfigurationState({ ...configurationState, configuration: prev_configuration });
  };

  const handleThresholdChange = (e: any, itemconfigIndex: any, dayObjectIndex: any, configIndex: any) => {
    const { name, value } = e.target;
    const decimalRegex = /^(\d+([.]{1}\d*)?)$/;
    if (value !== "" && !decimalRegex.test(value)) {
      e.target.value = "";
      return;
    } else {
      const prev_configuration: any = produce(configurationState.configuration, (draft: any) => {
        draft[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration[itemconfigIndex][name] = value;
      });
      setConfigurationState({ ...configurationState, configuration: prev_configuration });
    }
  };

  const removeTimeSlot = (itemconfigIndex: any, dayObjectIndex: any, configIndex: any) => {
    const prev_configuration: any = produce(configurationState.configuration, (draft: any) => {
      draft[configIndex].daysConfiguration[dayObjectIndex].timeZoneConfiguration.splice(itemconfigIndex, 1);
    });
    setConfigurationState({ ...configurationState, configuration: prev_configuration });
  };

  //
  //
  // New Changes - Capacity Utilisation - 20 Oct 2022
  //
  const _handle_accordion_open_state = (accordian_name: any) => {
    if (accordian_name === "openCapacityUtilisation") {
      setConfigurationState({
        ...configurationState,
        capacity_utilisation_configuration: !configurationState[accordian_name] === false ? [] : _design_capacity_utilisation_demo_list(),
        [accordian_name]: !configurationState[accordian_name],
        configuration: [],
      });
    } else {
      setConfigurationState({ ...configurationState, [accordian_name]: !configurationState[accordian_name], capacity_utilisation_configuration: [], configuration: [] });
    }
  };

  const handleCapacityRangeChange = (event: any, index_value: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { name, value } = event.target;
      if (name === "capacityLow" && value !== "" && (isNaN(Number(value)) || !FEE_REG_EXP_VALUE.test(value) || parseInt(value) < 0)) {
        return;
      } else if ((value !== "" && (isNaN(Number(value)) || !FEE_REG_EXP_VALUE.test(value))) || parseInt(value) > 100) {
        return;
      }
      const prev_configuration: any = produce(configurationState.capacity_utilisation_configuration, (draft: any) => {
        draft[index_value][name] = value;
      });
      setConfigurationState({ ...configurationState, capacity_utilisation_configuration: prev_configuration });
    }
  };

  const handleCapacityFeesChange = (event: any, index_value: any) => {
    const { name, value } = event.target;
    const decimalRegex = /^(\d+([.]{1}\d*)?)$/;
    if (value !== "" && !decimalRegex.test(value)) {
      event.target.value = "";
      return;
    } else {
      const prev_configuration: any = produce(configurationState.capacity_utilisation_configuration, (draft: any) => {
        draft[index_value][name] = value;
      });
      setConfigurationState({ ...configurationState, capacity_utilisation_configuration: prev_configuration });
    }
  };

  const _handle_click_add_capacity_range = (event: any) => {
    let capacity_configuration_state_data = validate_request_payload(configurationState, "Validating");
    setConfigurationState({ ...capacity_configuration_state_data });
  };

  const _handle_click_remove_capacity_range = (state_index: any) => {
    const prev_configuration: any = produce(configurationState.capacity_utilisation_configuration, (draft: any) => {
      if (draft !== undefined && Array.isArray(draft) && draft.length > 0) {
        draft.splice(state_index, 1);
      }
    });
    setConfigurationState({ ...configurationState, capacity_utilisation_configuration: prev_configuration });
  };

  useEffect(() => {
    if (
      !serviceTypeDetailsObj.isMultiShipmentLevel &&
      state &&
      state.shipmentLevel &&
      state.shipmentLevel[serviceTypeDetailsObj.serviceTypeName] &&
      state.shipmentLevel[serviceTypeDetailsObj.serviceTypeName].additionalDeliveryFeeConfigDTOList &&
      state.shipmentLevel[serviceTypeDetailsObj.serviceTypeName].deliveryFeeSlotCapacityConfigDTOList
    ) {
      setConfigurationState({
        ...configurationState,
        configuration: [...state.shipmentLevel[serviceTypeDetailsObj.serviceTypeName].additionalDeliveryFeeConfigDTOList],
        capacity_utilisation_configuration: [...state.shipmentLevel[serviceTypeDetailsObj.serviceTypeName].deliveryFeeSlotCapacityConfigDTOList],
        openAddConfiguration:
          Array.isArray(state.shipmentLevel[serviceTypeDetailsObj.serviceTypeName].additionalDeliveryFeeConfigDTOList) && state.shipmentLevel[serviceTypeDetailsObj.serviceTypeName].additionalDeliveryFeeConfigDTOList.length > 0 ? true : false,
        openCapacityUtilisation:
          Array.isArray(state.shipmentLevel[serviceTypeDetailsObj.serviceTypeName].deliveryFeeSlotCapacityConfigDTOList) && state.shipmentLevel[serviceTypeDetailsObj.serviceTypeName].deliveryFeeSlotCapacityConfigDTOList.length > 0 ? true : false,
      });
    }
  }, [state]);

  return (
    <ModalContainer open={open} className={classes.mainContainer} onClose={handleClose} disableBackdropClick>
      <Card className={classes.cardContainer}>
        <Grid container className={classes.gridContainerMain}>
          <Grid item xs={12} className={classes.gridContainerTitleDivStyle}>
            <div className={classes.headerStyleNormalDiv}>{`Time Based Configurations Customization - ${
              serviceTypeDetailsObj && serviceTypeDetailsObj.serviceTypeName && serviceTypeDetailsObj.serviceTypeName !== "" ? serviceTypeDetailsObj.serviceTypeName : "service type"
            }`}</div>
          </Grid>
        </Grid>
        <Grid container className={classes.gridContainerContent}>
          <Grid item xs={12}>
            <Grid container className={classes.gridContentContainerMain}>
              <Grid item xs={8}>
                {""}
              </Grid>
              <Grid item xs={4}>
                <div className={classes.buttonStyle}>
                  <LinkButton buttonLabel={"Add Dates"} name="dateButton" onClick={(e) => handleAddDateButton()} />
                </div>
              </Grid>
            </Grid>

            <Grid container className={classes.gridContentContainerMain}>
              {configurationState && configurationState.configuration && Array.isArray(configurationState.configuration) && configurationState.configuration.length > 0 ? (
                configurationState.configuration.map((config: any, configIndex: any) => (
                  <DlvFeeDateConfiguration
                    key={`${configIndex}-configurationState-configuration`}
                    state={config}
                    configIndex={configIndex}
                    classes={classes}
                    handleDateChange={handleDateChange}
                    removeDateRecord={removeDateRecord}
                    handleAddDaysButton={handleAddDaysButton}
                    handleSelectedDaysChange={handleSelectedDaysChange}
                    removeDayButton={removeDayButton}
                    handleAddTimeButton={handleAddTimeButton}
                    handleConfigTimeChange={handleConfigTimeChange}
                    handleThresholdChange={handleThresholdChange}
                    removeTimeSlot={removeTimeSlot}
                  />
                ))
              ) : (
                <Grid container className={classes.noDateSelectedDiv}>
                  <Typography className={classes.noDateSelectedTextStyle}>No Date range selected </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* Footer */}
        <Grid container className={classes.gridContainerFooter}>
          <PrimaryButton className={classes.cancelButtonStyle} buttonLabel="Cancel" onClick={() => handleClose()} />
          <PrimaryButton disabled={false} className={classes.saveButtonStyle} buttonLabel="Proceed" onClick={(e: any) => handleSave(e)} />
        </Grid>
      </Card>
    </ModalContainer>
  );
};

export default React.memo(DeliveryFeeDateTime);
