import Fetch from "./Fetch";

import { API_HOST_URL, API_HOST_URL_V2 } from "../config/constants";
import { handleError, handleResponse } from "./ApiUtils";

export const fetchPOSAndServiceTypesCount = (data) => {
  return Fetch.get(`${API_HOST_URL_V2}/pos/count`, data).then(handleResponse).catch(handleError);
};

export const createCountrySettings = (countryId, data) => {
  return Fetch.create(`${API_HOST_URL}/country/${countryId}`, data).then(handleResponse).catch(handleError);
};

export const fetchCountrySettings = (countryId) => {
  return Fetch.get(`${API_HOST_URL}/country/${countryId}`).then(handleResponse).catch(handleError);
};

export const updateCountrySettings = (countryId, data) => {
  return Fetch.update(`${API_HOST_URL}/country/${countryId}`, data).then(handleResponse).catch(handleError);
};
