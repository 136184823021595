import Fetch from "./Fetch";

import { API_HOST_DUMMY_URL, API_HOST_URL } from "../config/constants";
import { handleError, handleResponse } from "./ApiUtils";

export const getCollectionPointsTypes = () => {
  return Fetch.get(`${API_HOST_URL}/collectionpoints`).then(handleResponse).catch(handleError);
};

export const fetchServicePropositionRecord = () => {
  return Fetch.get(`${API_HOST_DUMMY_URL}/api/v2/services`).then(handleResponse).catch(handleError);
};
