import { Grid, Tooltip, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import settingsIcon from "../../assets/svg/Settings.svg";

import { APP_ROUTES } from "config/constants";
import { userStyles } from "../Styles/UserManagementStyle";

export const getUserListColumns = () => {
  const classes = userStyles();

  const renderColumnCell = (value: string | number) => <Typography className={classes.recordSpanStyle}>{value}</Typography>;
  const renderToolTip = (value: string | number) => <Typography className={classes.toolTipRoot}>{value}</Typography>;

  return [
    {
      sortable: false,
      field: "id",
      headerName: "User ID",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => renderColumnCell(params?.row?.id || "-"),
    },
    { sortable: false, flex: 1, field: "firstName", headerName: "First Name", headerClassName: classes.headerStyle, renderCell: (params: any) => renderColumnCell(params?.row?.firstName || "-") },
    { sortable: false, flex: 1, field: "lastName", headerName: "Last Name", headerClassName: classes.headerStyle, renderCell: (params: any) => renderColumnCell(params?.row?.lastName || "-") },
    // {
    //   sortable: false,
    //   flex: 1,
    //   field: "accessToDeliveryEngine",
    //   headerName: "Access to Delivery Engine",
    //   headerClassName: classes.headerStyle,
    //   renderCell: (params: any) => {
    //     return <span> {params.row.accessToDeliveryEngine ? "Yes" : "No"}</span>;
    //   },
    // },
    {
      sortable: false,
      flex: 1,
      field: "userProfile.role",
      headerName: "Role",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => renderColumnCell(params?.row?.userProfile?.role || "-"),
    },
    {
      sortable: false,
      flex: 2,
      field: "serviceTypeName",
      headerName: "Service Type",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => (
        <Tooltip title={renderToolTip(params?.row?.serviceTypeName || "-")} placement="bottom-start">
          {renderColumnCell(params?.row?.serviceTypeName || "-")}
        </Tooltip>
      ),
    },
    {
      sortable: false,
      flex: 2,
      field: "countryDisplayName",
      headerName: "Country",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => (
        <Tooltip title={renderToolTip(params?.row?.countryDisplayName || "-")} placement="bottom-start">
          {renderColumnCell(params?.row?.countryDisplayName || "-")}
        </Tooltip>
      ),
    },
    {
      sortable: false,
      field: "active",
      headerName: "Status",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => {
        return (
          <Grid container spacing={2} className={classes.statusTypeContainer}>
            <div className={params.row.active ? `${classes.activeStatus} ` : `${classes.inactiveStatus}`}>
              <div className={`${classes.status}`}>{params.row.active ? "ACTIVE" : "INACTIVE"}</div>
            </div>
          </Grid>
        );
      },
    },
    {
      sortable: false,
      field: "actions",
      headerName: "Actions",
      headerClassName: classes.headerStyle,
      renderCell: (params: any) => {
        const {
          row: { id },
        } = params;
        return (
          <Link to={{ pathname: `${APP_ROUTES.USER_MANAGEMENT}`, search: `?userDetailId=${id}` }}>
            <div className={classes.editIconStyle}>
              <img src={settingsIcon} alt="Edit User" />
            </div>
          </Link>
        );
      },
    },
  ];
};
