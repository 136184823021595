import produce from "immer";
import moment from "moment";

import { ALERT_TYPES, USER_TYPES } from "../../../config/constants";
import { isArrayValid, isEmptyArray, isEmptyOrNull, isNonEmptyArray, isNonEmptyObject, isUndefined } from "../../../utils/DataUtils";
import CustomAlert from "../../common/CustomAlert/CustomAlert";
import { ALL_SERVICE_TYPE, DELIVERY_TYPES_ALLOWED_FOR_POLYGON, POLYGON_CONSTANT_TEXT, POLYGON_ID_LOCAL_STORAGE, POLYGON_MAP_COLORS_SCHEME, SERVICE_TYPE_RECORD_LIST, polygon_count_obj } from "./PlolygonServicesConstants";
import {
  AppStateObjInterface,
  MAP_MARKER_INTERFACE,
  MappingListItem,
  MapPolygonInterface,
  MapPolygonOptionInterface,
  PayloadObjectInterface,
  PlolygonServicesInitialCountStateInteface,
  PlolygonServicesInitialStateInteface,
  PolygonDetailsSideDrawerInterface,
  PolygonFilterPopUpStateInterface,
  ServiceTypeMapping,
  ServiceTypeMappings,
  ServiceTypeRecord,
} from "./PlolygonServicesInterface";
import { COLORS } from "../../../config/theme/theme";
import PolygonMapPosIcon from "../../assets/svg/PolygonMapPosIcon.svg";
import { getServicePropositionObject, isObjectEmpty } from "../../../utils/helperFunctions";

export const allowPolygonServiceEdit = (role: any) => {
  if (role !== undefined && role !== "") {
    let is_polygon_services_editable = false;
    switch (role) {
      case USER_TYPES.MAF_ADMIN:
      case USER_TYPES.SUPER_ADMIN:
        is_polygon_services_editable = true;
        break;
      default: {
        is_polygon_services_editable = false;
        break;
      }
    }
    return is_polygon_services_editable;
  }
  return false;
};

export const isLatitudeValid = (num: number | string) => isFinite(Number(num)) && Math.abs(Number(num)) <= 90;
export const isLongitudeValid = (num: number | string) => isFinite(Number(num)) && Math.abs(Number(num)) <= 180;

export const handle_make_row_editable_utils = (row_index: any, appStateObj: any) => {
  let newAppStateObj: any = produce(appStateObj, (draft: any) => {
    if (draft !== undefined && draft.polygonServiceListData !== undefined && Array.isArray(draft.polygonServiceListData) && draft.polygonServiceListData.length > 0 && draft.polygonServiceListData[row_index] !== undefined) {
      localStorage.removeItem(POLYGON_ID_LOCAL_STORAGE);
      draft.polygonServiceListData.forEach((listRecord: any, index: any) => {
        if (index === row_index) {
          draft.polygonServiceListData[index].isEditable = !draft.polygonServiceListData[index].isEditable;
          if (draft.polygonServiceListData[index].isEditable) {
            localStorage.setItem(POLYGON_ID_LOCAL_STORAGE, JSON.stringify(draft.polygonServiceListData[index].polygonId));
          }
        } else {
          draft.polygonServiceListData[index].isEditable = false;
        }
      });
    }
  });
  return newAppStateObj;
};

export const handle_reset_pos_zone_mappind_details_utils = (appStateObj: any, loading: boolean, serviceTypeName: string, serviceRowIndex: number | string, mappingListRowIndex: number | string) => {
  let newAppStateObj: any = produce(appStateObj, (draft: any) => {
    draft.loading = loading;
    draft.posZoneSearchText = "";
    draft.posZoneServiceRecord = [];
    draft.posZoneServiceFilteredRecord = [];
    draft.showPosZoneMappingPopUp = false;
    draft.serviceRowIndex = serviceRowIndex;
    draft.mappingListRowIndex = mappingListRowIndex;
    draft.isNonServiceable = false;
    //
    draft.serviceTypeName = serviceTypeName;
    draft.selectedPosZoneIndex = "";
    draft.selectedPosZoneObj = {};
  });
  return newAppStateObj;
};

export const _handle_Pos_Zone_Service_List_Record = (appStateObj: AppStateObjInterface, listData: any) => {
  let newAppStateObj: AppStateObjInterface = produce(appStateObj, (draft: AppStateObjInterface) => {
    draft.loading = false;
    draft.posZoneSearchText = "";
    draft.posZoneServiceRecord = listData;
    draft.posZoneServiceFilteredRecord = listData;
    draft.showPosZoneMappingPopUp = true;
    //
    draft.selectedPosZoneIndex = "";
    draft.selectedPosZoneObj = {};
  });
  return newAppStateObj;
};

export const _handle_empty_pos_zone_mapping_data_and_loading_off = (appStateObj: AppStateObjInterface) => {
  let newAppStateObj: AppStateObjInterface = produce(appStateObj, (draft: AppStateObjInterface) => {
    draft.loading = false;
    draft.serviceRowIndex = "";
    draft.mappingListRowIndex = "";
  });
  return newAppStateObj;
};

export const generateServiceNameValueForPolygon = (servicePropositionList: any): { id: string; name: string; displayName?: string }[] => {
  const serviceArray: { id: string; name: string; displayName: string; rank: number; mappings: any[] }[] = generatePolygonServiceListRecord(servicePropositionList);
  if (!isUndefined(serviceArray) && isArrayValid(serviceArray)) {
    return serviceArray.map((obj) => ({ id: obj.name, name: obj.name, displayName: obj?.displayName || "" }));
  }
  return [...SERVICE_TYPE_RECORD_LIST];
};

export const generateServiceNameForPolygon = (servicePropositionList: any): string[] => {
  const serviceArray: { id: string; name: string; displayName: string; rank: number; mappings: any[] }[] = generatePolygonServiceListRecord(servicePropositionList);
  if (!isUndefined(serviceArray) && isArrayValid(serviceArray)) {
    return serviceArray.map((obj) => obj.name);
  }
  return [...ALL_SERVICE_TYPE];
};

export const generatePolygonServiceListRecord = (servicePropositionList: any): { id: string; name: string; displayName: string; rank: number; mappings: any[] }[] => {
  const serviceArray: { id: string; name: string; displayName: string; rank: number; mappings: any[] }[] = [];
  if (!isUndefined(servicePropositionList) && isArrayValid(servicePropositionList)) {
    servicePropositionList.forEach((serviceObj: any) => {
      if (
        !isUndefined(serviceObj?.deliveryType) &&
        !isUndefined(serviceObj.rank) &&
        !isUndefined(serviceObj.name) &&
        DELIVERY_TYPES_ALLOWED_FOR_POLYGON.includes(serviceObj?.deliveryType) &&
        serviceObj.name.toUpperCase() !== POLYGON_CONSTANT_TEXT.FBC
      ) {
        serviceArray.push({ id: serviceObj.name, name: serviceObj.name, displayName: serviceObj?.displayName || "", rank: serviceObj.rank, mappings: [] });
      }
    });
    serviceArray.sort((a, b) => {
      const rankA = servicePropositionList.find((service: any) => service.name === a.name)?.rank || 0;
      const rankB = servicePropositionList.find((service: any) => service.name === b.name)?.rank || 0;
      return rankA - rankB;
    });
    return serviceArray;
  }
  return [];
};

export const design_polygon_service_list_record = (appStateObj: PlolygonServicesInitialStateInteface, record_data: any, servicePropositionList: any) => {
  let newAppStateObj: PlolygonServicesInitialStateInteface = produce(appStateObj, (draft: PlolygonServicesInitialStateInteface) => {
    const serviceArray: { id: string; name: string; displayName: string; rank: number; mappings: any[] }[] = generatePolygonServiceListRecord(servicePropositionList);
    let polygonServiceListData = [] as any;
    if (record_data !== undefined && record_data.content !== undefined && Array.isArray(record_data.content) && record_data.content.length > 0) {
      record_data.content.forEach((polygon: any) => {
        let polygon_service_obj = { id: "", polygonId: "", lastModifiedAt: "", serviceTypeMappings: JSON.parse(JSON.stringify(serviceArray)) } as any;
        if (polygon !== undefined && polygon.polygonId !== undefined && polygon.polygonId !== "") {
          const { polygonId, serviceTypeMappings } = polygon;
          polygon_service_obj.id = polygonId;
          polygon_service_obj.polygonId = polygonId;

          if (!isEmptyOrNull(serviceTypeMappings) && isArrayValid(serviceTypeMappings)) {
            serviceTypeMappings.forEach((service: any) => {
              if (!isUndefined(service) && !isUndefined(service.storeDayWiseMappings) && !isUndefined(service.serviceType) && isArrayValid(service.storeDayWiseMappings)) {
                service.storeDayWiseMappings.forEach((storeMappings: any) => {
                  if (!isUndefined(storeMappings?.mappings) && isArrayValid(storeMappings.mappings)) {
                    storeMappings.mappings.forEach((mappingObj: any) => {
                      const { posNo, posName, zoneName, lastModifiedAt } = mappingObj;
                      if (!isUndefined(polygon_service_obj.serviceTypeMappings) && isArrayValid(polygon_service_obj.serviceTypeMappings)) {
                        const serviceIndex: number = polygon_service_obj.serviceTypeMappings.findIndex((item: any) => item.name === service.serviceType.toUpperCase());
                        if (serviceIndex !== -1) {
                          polygon_service_obj.serviceTypeMappings[serviceIndex].mappings.push({ ...mappingObj, address: `${posNo || ""} ${posName || ""} - ${zoneName || "-"}` });
                        }
                      }

                      if (!isEmptyOrNull(lastModifiedAt) && moment(lastModifiedAt).isValid()) {
                        if (polygon_service_obj.lastModifiedAt === "") {
                          polygon_service_obj.lastModifiedAt = lastModifiedAt;
                        } else {
                          if (moment(lastModifiedAt).isAfter(polygon_service_obj.lastModifiedAt)) {
                            polygon_service_obj.lastModifiedAt = lastModifiedAt;
                          }
                        }
                      }
                    });
                  }
                });
              }
            });
          }
        }
        polygonServiceListData.push(polygon_service_obj);
      });
    }
    draft.loading = false;

    draft.page = record_data.currentPage;
    draft.size = record_data.pageSize;
    draft.maxRecordPresent = record_data.totalElements;
    draft.polygonServiceListData = [...polygonServiceListData];
    draft.polygonServiceListHeader = JSON.parse(JSON.stringify(serviceArray));

    draft.posZoneSearchText = "";
    draft.posZoneServiceRecord = [];
    draft.posZoneServiceFilteredRecord = [];
    draft.totalRowsCountData = 0;
    draft.showPosZoneMappingPopUp = false;
    draft.editableRowIndex = "";
    draft.isNonServiceable = false;
    draft.serviceTypeName = "";
    draft.selectedPosZoneIndex = "";
    draft.selectedPosZoneObj = {};
  });

  return newAppStateObj;
};

export const design_custom_pos_zone_mapping_filtered_list_based_on_search = (appStateObj: AppStateObjInterface, searchText: string) => {
  let newAppStateObj: AppStateObjInterface = produce(appStateObj, (draft: AppStateObjInterface) => {
    draft.posZoneSearchText = searchText;
    if (draft.posZoneServiceRecord && Array.isArray(draft.posZoneServiceRecord) && draft.posZoneServiceRecord.length > 0) {
      let selected_list = [] as any;
      let result_list = [];
      if (draft.posZoneSearchText !== undefined && draft.posZoneSearchText !== "") {
        let list_data = draft.posZoneServiceRecord.filter((list: any) => (list.posNo && list.posNo.toLowerCase().includes(searchText.toLowerCase())) || (list.posName && list.posName.toLowerCase().includes(searchText.toLowerCase())));
        result_list = selected_list.concat(list_data);
      } else {
        result_list = selected_list.concat(draft.posZoneServiceRecord);
      }

      // remove duplicate
      let map = new Map();
      result_list.forEach((item: any) => {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      });
      draft.posZoneServiceFilteredRecord = Array.from(map.values());
    }
  });
  return newAppStateObj;
};

export const _handle_action_pos_zone_mapping_open_index_utils = (appStateObj: AppStateObjInterface, pos_list_id: any, pos_list_index: any) => {
  let newAppStateObj: AppStateObjInterface = produce(appStateObj, (draft: AppStateObjInterface) => {
    if (
      draft.posZoneServiceFilteredRecord !== undefined &&
      Array.isArray(draft.posZoneServiceFilteredRecord) &&
      draft.posZoneServiceFilteredRecord.length > 0 &&
      draft.posZoneServiceFilteredRecord[pos_list_index] !== undefined &&
      draft.posZoneServiceFilteredRecord[pos_list_index].id !== undefined &&
      draft.posZoneServiceFilteredRecord[pos_list_index].id !== "" &&
      draft.posZoneServiceFilteredRecord[pos_list_index].id === pos_list_id
    ) {
      draft.posZoneServiceFilteredRecord[pos_list_index].isOpened = !draft.posZoneServiceFilteredRecord[pos_list_index].isOpened;
    }
  });
  return newAppStateObj;
};

export const _handle_action_pos_zone_selected_mapping_obj_utils = (appStateObj: AppStateObjInterface, pos_list_id: any, pos_list_index: any, pos_zone_id: any, pos_zone_Index: any) => {
  let newAppStateObj: AppStateObjInterface = produce(appStateObj, (draft: AppStateObjInterface) => {
    if (
      draft.posZoneServiceFilteredRecord !== undefined &&
      Array.isArray(draft.posZoneServiceFilteredRecord) &&
      draft.posZoneServiceFilteredRecord.length > 0 &&
      draft.posZoneServiceFilteredRecord[pos_list_index] !== undefined &&
      draft.posZoneServiceFilteredRecord[pos_list_index].id !== undefined &&
      draft.posZoneServiceFilteredRecord[pos_list_index].id !== "" &&
      draft.posZoneServiceFilteredRecord[pos_list_index].id === pos_list_id &&
      draft.posZoneServiceFilteredRecord[pos_list_index].zoneServicetypeList !== undefined &&
      Array.isArray(draft.posZoneServiceFilteredRecord[pos_list_index].zoneServicetypeList) &&
      draft.posZoneServiceFilteredRecord[pos_list_index].zoneServicetypeList.length > 0 &&
      draft.posZoneServiceFilteredRecord[pos_list_index].zoneServicetypeList[pos_zone_Index] !== undefined &&
      draft.posZoneServiceFilteredRecord[pos_list_index].zoneServicetypeList[pos_zone_Index].zoneId !== undefined &&
      draft.posZoneServiceFilteredRecord[pos_list_index].zoneServicetypeList[pos_zone_Index].zoneId !== "" &&
      draft.posZoneServiceFilteredRecord[pos_list_index].zoneServicetypeList[pos_zone_Index].zoneId === pos_zone_id
    ) {
      draft.selectedPosZoneIndex = pos_zone_id;
      draft.selectedPosZoneObj = draft.posZoneServiceFilteredRecord[pos_list_index].zoneServicetypeList[pos_zone_Index];
    }
  });
  return newAppStateObj;
};

export const design_polygon_submit_pauload_object_utils = (appStateObj: any, polygon_record: any, serviceTypeName: any, isNonServiceable: any, selectedPosZoneObj: any, servicePropositionList: any) => {
  const payload_object = {
    polygonId: "",
    polygonName: "",
    serviceType: "",
    serviceTypeId: "",
    isServiceable: "",
  } as any;
  let issue_found = 0;

  if (!isEmptyOrNull(polygon_record.polygonId)) {
    payload_object.polygonId = polygon_record.polygonId;
  } else {
    issue_found++;
    CustomAlert(ALERT_TYPES.ERROR, "Polygon id not found");
  }

  /**
   * TODO
   * Once Polygon Name is editable, then only check this value
   *
   */
  // if (!isEmptyOrNull(polygon_record.polygonName)) {
  //   payload_object.polygonName = polygon_record.polygonName;
  // } else {
  //   issue_found++;
  //   CustomAlert(ALERT_TYPES.ERROR, "Polygon name not found");
  // }

  if (!isEmptyOrNull(serviceTypeName)) {
    payload_object.serviceType = serviceTypeName;
  } else {
    issue_found++;
    CustomAlert(ALERT_TYPES.ERROR, "Service name not found");
  }

  if (isNonServiceable !== undefined && isNonServiceable !== "" && isNonServiceable === true) {
    payload_object.isServiceable = false;

    if (
      appStateObj !== undefined &&
      appStateObj.posZoneServiceFilteredRecord !== undefined &&
      Array.isArray(appStateObj.posZoneServiceFilteredRecord) &&
      appStateObj.posZoneServiceFilteredRecord.length > 0 &&
      appStateObj.posZoneServiceFilteredRecord[0] !== undefined &&
      appStateObj.posZoneServiceFilteredRecord[0].serviceTypeId !== undefined &&
      appStateObj.posZoneServiceFilteredRecord[0].serviceTypeId !== ""
    ) {
      payload_object.serviceTypeId = appStateObj.posZoneServiceFilteredRecord[0].serviceTypeId;
    } else {
      const serviceId = getServiceTypeIdByName(servicePropositionList, serviceTypeName);
      if (serviceId !== null) {
        payload_object.serviceTypeId = serviceId;
      } else {
        issue_found++;
        CustomAlert(ALERT_TYPES.ERROR, "Service id not found");
      }
    }
  } else {
    payload_object.isServiceable = true;
    const { serviceTypeId, status, posNo, posName, zoneId, zoneName } = selectedPosZoneObj;

    if (!isEmptyOrNull(serviceTypeId)) {
      payload_object.serviceTypeId = serviceTypeId;
    } else {
      const serviceId = getServiceTypeIdByName(servicePropositionList, serviceTypeName);
      if (serviceId !== null) {
        payload_object.serviceTypeId = serviceId;
      } else {
        issue_found++;
        CustomAlert(ALERT_TYPES.ERROR, "Service id not found");
      }
    }

    if (!isEmptyOrNull(status)) {
      payload_object.status = status;
    } else {
      issue_found++;
      CustomAlert(ALERT_TYPES.ERROR, "Status not found");
    }

    if (!isEmptyOrNull(posNo)) {
      payload_object.posNo = posNo;
    } else {
      issue_found++;
      CustomAlert(ALERT_TYPES.ERROR, "POS no not found");
    }

    if (!isEmptyOrNull(posName)) {
      payload_object.posName = posName;
    } else {
      issue_found++;
      CustomAlert(ALERT_TYPES.ERROR, "POS name not found");
    }

    if (!isEmptyOrNull(zoneId)) {
      payload_object.zoneId = zoneId;
    } else {
      issue_found++;
      CustomAlert(ALERT_TYPES.ERROR, "Zone id not found");
    }

    if (!isEmptyOrNull(zoneName)) {
      payload_object.zoneName = zoneName;
    } else {
      issue_found++;
      CustomAlert(ALERT_TYPES.ERROR, "Zone name not found");
    }
  }

  if (issue_found > 0) {
    return {};
  }

  return payload_object;
};

export const getSelectedTileNameByIndex = (index: any) => {
  if (index !== undefined && index >= 0) {
    switch (index) {
      case 0:
        return "ALL";
      case 1:
        return "MAPPED";
      case 2:
        return "NON_SERVICEABLE";
      case 3:
        return "UNMAPPED";
    }
  }
  return "ALL"; // ALL / MAPPED / UNMAPPED / NON_SERVICEABLE
};

export const getServiceTypeIdByName = (servicePropositionList: any, serviceName: any) => {
  if (!isUndefined(serviceName) && !isEmptyOrNull(serviceName)) {
    let indexValue = servicePropositionList.findIndex((item: any) => item?.name?.toUpperCase() === serviceName.toUpperCase());
    if (indexValue !== -1) {
      return servicePropositionList[indexValue]?.id || 0;
    }
  }
  return null;
};

export const handle_reset_polygon_service_count_utils = (appCountStateObj: any, loading: any) => {
  let newAppCountStateObj: any = produce(appCountStateObj, (draft: any) => {
    draft.loading = loading;
    draft.countHeader = [...polygon_count_obj];
  });
  return newAppCountStateObj;
};

export const design_polygon_service_count_record_utils = (appCountStateObj: PlolygonServicesInitialCountStateInteface, data: any, servicePropositionList: any) => {
  let newAppCountStateObj: PlolygonServicesInitialCountStateInteface = produce(appCountStateObj, (draft: PlolygonServicesInitialCountStateInteface) => {
    draft.loading = false;
    if (data !== undefined && typeof data === "object" && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key_name: any) => {
        const index = draft.countHeader.findIndex((countObj: any) => countObj.id === key_name);
        if (index !== undefined && index !== -1) {
          draft.countHeader[index].value = data[key_name];
        }
      });
    }
    draft.serviceTypeList = generateServiceNameValueForPolygon(servicePropositionList);
    draft.selectedServiceTypeFilter = generateServiceNameForPolygon(servicePropositionList);
  });
  return newAppCountStateObj;
};

/**
 *
 * Filter Polygon listing
 * https://mafretail.atlassian.net/browse/DOS-2214
 *
 */

export const handle_open_filter_pop_up_utils = (event: any, service_type: any, appFilterPopUpObj: PolygonFilterPopUpStateInterface, open: any) => {
  let newAppFilterPopUpObj: PolygonFilterPopUpStateInterface = produce(appFilterPopUpObj, (draft: any) => {
    draft.loading = open;
    draft.openPopUp = open;
    draft.anchorEl = null;
    if (event !== undefined && event.currentTarget !== undefined && event.currentTarget !== null) {
      draft.anchorEl = event.currentTarget;
    }
    draft.posZoneSearchText = "";
    draft.posZoneServiceRecord = [];
    draft.posZoneServiceFilteredRecord = [];
    draft.serviceType = service_type;
  });
  return newAppFilterPopUpObj;
};

export const _handle_mapped_pos_zone_list_by_service_utils = (appFilterPopUpObj: any, listData: any) => {
  let newAppFilterPopUpObj: any = produce(appFilterPopUpObj, (draft: any) => {
    listData = listData.map((value: any) => ({ ...value, isChecked: false, hide_row: false }));
    draft.loading = false;
    draft.posZoneSearchText = "";
    if (!isUndefined(draft?.filterList) && isNonEmptyObject(draft.filterList) && !isUndefined(draft.serviceType) && !isUndefined(draft.filterList[draft.serviceType]) && isArrayValid(draft.filterList[draft.serviceType])) {
      listData = listData.map((value: any) => (!isUndefined(value?.id) && draft.filterList[draft.serviceType].findIndex((item: any) => item.id === value.id) > -1 ? { ...value, isChecked: true } : value));
    }
    draft.posZoneServiceRecord = listData;
    draft.posZoneServiceFilteredRecord = listData;
  });
  return newAppFilterPopUpObj;
};

export const design_pos_zone_based_on_search = (data_list: any, searchText: any, current_list: any) => {
  let result_list = [] as any;
  let selected_list = current_list.filter((list: any) => list.isChecked === true);
  //
  if (data_list !== undefined && Array.isArray(data_list) && data_list.length > 0) {
    if (searchText !== undefined && searchText !== "") {
      let filtered_list_data = data_list.filter((list: any) => (list.posNo && list.posNo.toLowerCase().includes(searchText.toLowerCase())) || (list.posName && list.posName.toLowerCase().includes(searchText.toLowerCase())));
      result_list = [...filtered_list_data];
    } else {
      result_list = [...data_list];
    }
  }

  let filtered_list = JSON.parse(JSON.stringify(result_list));
  if (selected_list !== undefined && Array.isArray(selected_list) && selected_list.length > 0) {
    selected_list.forEach((selected: any) => {
      if (selected !== undefined && selected.id !== undefined && filtered_list.findIndex((item: any) => item.id === selected.id) === -1) {
        let selected_copy = JSON.parse(JSON.stringify(selected));
        selected_copy.hide_row = true;
        filtered_list.push(selected_copy);
      } else {
        let index = filtered_list.findIndex((item: any) => item.id === selected.id);
        filtered_list[index].isChecked = true;
      }
    });
  }
  const returnObject = { listData: filtered_list };
  return returnObject;
};

export const handle_reset_pos_zone_filter_pop_up_utils = (appFilterPopUpObj: any, serviceType: any) => {
  let newAppFilterPopUpObj: any = produce(appFilterPopUpObj, (draft: any) => {
    let listData = appFilterPopUpObj.posZoneServiceRecord.map((value: any) => ({ ...value, isChecked: false }));
    draft.posZoneServiceRecord = listData;
    draft.posZoneServiceFilteredRecord = listData;
    if (!isUndefined(draft?.filterList) && !isEmptyOrNull(draft.filterList) && !isUndefined(draft?.filterList[serviceType])) {
      draft.filterList[serviceType] = [];
    }
  });
  return newAppFilterPopUpObj;
};

export const handle_check_box_click_utils = (appFilterPopUpObj: PolygonFilterPopUpStateInterface, checked: any, listIndex: any) => {
  let newAppFilterPopUpObj: PolygonFilterPopUpStateInterface = produce(appFilterPopUpObj, (draft: PolygonFilterPopUpStateInterface) => {
    draft.posZoneServiceFilteredRecord[listIndex].isChecked = checked;
    if (!isUndefined(draft?.serviceType) && !isEmptyOrNull(draft.serviceType)) {
      if (checked) {
        if (isUndefined(draft.filterList[draft.serviceType])) {
          draft.filterList[draft.serviceType] = [];
        }
        draft.filterList[draft.serviceType] = [...draft.filterList[draft.serviceType], draft.posZoneServiceFilteredRecord[listIndex]];
      } else {
        if (isUndefined(draft.filterList[draft.serviceType])) {
          draft.filterList[draft.serviceType] = draft.filterList[draft.serviceType].filter((obj: any) => obj.id !== draft.posZoneServiceFilteredRecord[listIndex].id);
        }
      }
    }
  });
  return newAppFilterPopUpObj;
};

export const design_service_type_filter_from_header = (appFilterPopUpObj: PolygonFilterPopUpStateInterface) => {
  const servive_type_filter_object = [] as any;
  if (!isUndefined(appFilterPopUpObj) && !isUndefined(appFilterPopUpObj.filterList) && isNonEmptyObject(appFilterPopUpObj.filterList)) {
    Object.keys(appFilterPopUpObj.filterList).forEach((serviceType: string) => {
      if (!isUndefined(serviceType) && !isUndefined(appFilterPopUpObj.filterList[serviceType]) && isArrayValid(appFilterPopUpObj.filterList[serviceType])) {
        let posZones = [] as any;
        appFilterPopUpObj.filterList[serviceType].forEach((arr: any) => {
          let obj = { posNo: arr?.posNo, zone: arr?.zone };
          posZones.push(obj);
        });
        servive_type_filter_object.push({ serviceType: serviceType, posZones });
      }
    });
  }
  return servive_type_filter_object;
};

export const handle_clear_all_pos_zone_filter_pop_up_utils = (appFilterPopUpObj: any) => {
  let newAppFilterPopUpObj: any = produce(appFilterPopUpObj, (draft: any) => {
    draft.loading = false;
    draft.serviceType = "";
    draft.openPopUp = false;
    draft.anchorEl = null;
    draft.posZoneSearchText = "";
    draft.posZoneServiceRecord = [];
    draft.posZoneServiceFilteredRecord = [];
    draft.filterList = {};
  });
  return newAppFilterPopUpObj;
};

/**
 * https://mafretail.atlassian.net/browse/DOS-1914
 * */
export const reset_polygon_details_history_drawer_obj = (polygonDetailsSideDrawerObj: PolygonDetailsSideDrawerInterface) => {
  let newpPlygonDetailsSideDrawerObj: PolygonDetailsSideDrawerInterface = produce(polygonDetailsSideDrawerObj, (draft: PolygonDetailsSideDrawerInterface) => {
    draft.openDrawer = true;
    draft.loading = true;
    draft.serviceType = "STANDARD";
    draft.polygonId = "";
    draft.polygonName = "";
    draft.polygonDetailsHistory = [];
  });
  return newpPlygonDetailsSideDrawerObj;
};

export const close_polygon_details_history_drawer_obj = (polygonDetailsSideDrawerObj: PolygonDetailsSideDrawerInterface) => {
  let newpPlygonDetailsSideDrawerObj: PolygonDetailsSideDrawerInterface = produce(polygonDetailsSideDrawerObj, (draft: PolygonDetailsSideDrawerInterface) => {
    draft.openDrawer = false;
    draft.loading = false;
    draft.serviceType = "STANDARD";
    draft.polygonId = "";
    draft.polygonName = "";
    draft.polygonDetailsHistory = [];
  });
  return newpPlygonDetailsSideDrawerObj;
};

const get_heading_based_on_status = (status: any) => {
  if (status !== undefined && status !== "") {
    switch (status.toUpperCase()) {
      case "CREATED":
        return "Created";
      case "UPDATED":
        return "Updated";
      case "REMOVED":
        return "Removed";
      case "DELETED":
        return "Deleted";
    }
  }
};

const updateDateTimeText = (modifiedAt: any) => {
  return modifiedAt.replaceAll(" ", " on ");
};

export const design_polygon_history_record_utils = (polygonId: any, polygonName: any, serviceType: any, data: any, newPolygonDetailsSideDrawerObj: PolygonDetailsSideDrawerInterface) => {
  let updatedPolygonDetailsSideDrawerObj: PolygonDetailsSideDrawerInterface = produce(newPolygonDetailsSideDrawerObj, (draft: PolygonDetailsSideDrawerInterface) => {
    draft.loading = false;
    draft.polygonId = polygonId;
    draft.polygonName = polygonName;
    draft.serviceType = serviceType;
    //
    let polygonDetailsHistory = [] as any;
    if (data !== undefined && data.operations !== undefined && Array.isArray(data.operations) && data.operations.length > 0) {
      data.operations.forEach((history: any) => {
        if (history !== undefined && typeof history === "object" && Object.keys(history).length > 0) {
          let actionPerformed = "";
          let actionPerformedByAt = "";

          let history_obj = {
            ...history,
            actionPerformed: "",
            actionPerformedByAt: "",
          } as any;

          if (history.reference !== undefined && history.reference !== "") {
            if (history.reference === "polygon") {
              if (history.action === "CREATED") {
                actionPerformed = `Polygon created`;
              } else if (history.action === "UPDATED") {
                if (history.polygonName !== undefined && history.polygonName !== "") {
                  actionPerformed = `${get_heading_based_on_status(history.action)} name to ${history.polygonName}`;
                }
              }
            } else if (history.reference === "polygon-mapping") {
              if (history.posNo !== undefined && history.posName !== undefined && history.zone !== undefined) {
                if (history.action === "CREATED") {
                  actionPerformed = `${get_heading_based_on_status(history.action)} mapping to ${history.posNo || "-"} ${history.posName || "-"} ${history.zone || "-"}`;
                } else if (history.action === "UPDATED") {
                  if (history.isServiceable !== undefined && history.isServiceable) {
                    actionPerformed = `${get_heading_based_on_status(history.action)} mapping to ${history.posNo || "-"} ${history.posName || "-"} ${history.zone || "-"}`;
                  } else {
                    actionPerformed = `${get_heading_based_on_status(history.action)} mapping to non serviceable`;
                  }
                } else if (history.action === "REMOVED") {
                  actionPerformed = `${get_heading_based_on_status(history.action)} mapping from ${history.posNo || "-"} ${history.posName || "-"} ${history.zone || "-"}`;
                }
              }
            }
          }

          //
          if (history.modifiedBy !== undefined && history.modifiedBy !== "") {
            actionPerformedByAt = `By ${history.modifiedBy}`;
          }
          if (history.modifiedAt !== undefined && history.modifiedAt !== "") {
            actionPerformedByAt = `${actionPerformedByAt} at ${updateDateTimeText(history.modifiedAt)}`;
          }

          history_obj.actionPerformed = actionPerformed;
          history_obj.actionPerformedByAt = actionPerformedByAt;
          polygonDetailsHistory.push(history_obj);
        }
      });
      draft.polygonDetailsHistory = polygonDetailsHistory;
    }
  });
  return updatedPolygonDetailsSideDrawerObj;
};

const designMapIcon = () => {
  return { url: PolygonMapPosIcon, scale: 2, labelOrigin: { x: 40, y: 40 } };
};

const designMapLabel = (posNo: number) => {
  return { text: posNo, fontSize: "12px", fontWeight: "700", color: COLORS.WHITE, lineHeight: "0px" };
};

const latLngMarkerRecord = (latSearchValue: number | string, lngSearchValue: number | string) => {
  return {
    key: `${latSearchValue}-${lngSearchValue}`,
    name: "",
    location: { lat: Number(latSearchValue), lng: Number(lngSearchValue) },
    address: {},
    serviceTypes: {},
    selectedServiceDetails: {},
    openWindow: false,
    posNo: "",
    posName: "",
    visible: true,
  };
};

export const design_polygon_map_marker_record_utils = (appStateObj: PlolygonServicesInitialStateInteface, markerSliceRecord: any, servicePropositionList: any[], serviceType?: string) => {
  const markersRecord = [] as any;
  if (markerSliceRecord && isArrayValid(markerSliceRecord)) {
    markerSliceRecord.forEach((record: any) => {
      if (record && record.pos && record.pos.posNo) {
        let markerObj: MAP_MARKER_INTERFACE = {
          key: `${record.pos.posNo}`,
          name: `${record.pos.posNo}`,
          location: { lat: 0, lng: 0 },
          icon: designMapIcon(),
          label: designMapLabel(record.pos.posNo),
          address: {},
          serviceTypes: record.serviceTypes.map((service: any) => ({ ...service, isSelected: false })),
          selectedServiceDetails: {},
          openWindow: false,
          posNo: `${record.pos.posNo}`,
          posName: `${record.pos.posNo} - ${record.pos.name}`,
          visible: true,
        };
        if (!isUndefined(markerObj.serviceTypes) && isArrayValid(markerObj.serviceTypes)) {
          const updatedServiceTypeList = markerObj.serviceTypes.map((service: any) => {
            if (!isUndefined(service?.name) && !isEmptyOrNull(service.name)) {
              const details = getServicePropositionObject(servicePropositionList, service.name);
              if (!isObjectEmpty(details)) {
                return { ...service, displayName: details.displayName || "", rank: details.rank || 999 };
              }
            }
            return service;
          });
          markerObj.serviceTypes = [...updatedServiceTypeList];
        }
        if (record && record.pos && record.pos.address && record.pos.address.latitude && record.pos.address.longitude) {
          markerObj.address = { ...record.pos.address };
          markerObj.location.lat = Number(record.pos.address.latitude);
          markerObj.location.lng = Number(record.pos.address.longitude);
        }
        if (!isUndefined(serviceType) && !isEmptyOrNull(serviceType)) {
          markerObj.visible = false;
          if (!isUndefined(record.serviceTypes) && isNonEmptyObject(record.serviceTypes) && Object.values(record.serviceTypes).find((val: any) => val?.name?.toLowerCase() === serviceType?.toLowerCase())) {
            markerObj.visible = true;
          }
        }
        markersRecord.push(markerObj);
      }
    });
  }
  if (!isUndefined(appStateObj) && !isEmptyOrNull(appStateObj?.latSearchValue) && !isEmptyOrNull(appStateObj?.lngSearchValue)) {
    const isLatitude = isLatitudeValid(appStateObj.latSearchValue);
    const isLongitude = isLongitudeValid(appStateObj.lngSearchValue);
    if (isLatitude && isLongitude) {
      markersRecord.push(latLngMarkerRecord(appStateObj.latSearchValue, appStateObj.lngSearchValue));
    }
  }
  return markersRecord;
};

const getColorKeyNameBasedOnServiceType = (serviceBasedColorRecord: any[], serviceType: string, colorKey: string) => {
  if (!isUndefined(serviceBasedColorRecord) && isArrayValid(serviceBasedColorRecord)) {
    const serviceIndex = serviceBasedColorRecord.findIndex((item: any) => item.name === serviceType.toUpperCase());
    if (serviceIndex !== -1) {
      return serviceBasedColorRecord[serviceIndex][colorKey];
    }
  }
  return POLYGON_MAP_COLORS_SCHEME.DEFAULT_COLOR_SCHEME[colorKey];
};

export const unmappedPolygonOptionObj: MapPolygonOptionInterface = {
  fillColor: POLYGON_MAP_COLORS_SCHEME.UNMAPPED_COLOR_SCHEME.fillColor,
  fillColorCopy: POLYGON_MAP_COLORS_SCHEME.UNMAPPED_COLOR_SCHEME.fillColor,
  fillOpacity: 0.6,
  strokeColor: POLYGON_MAP_COLORS_SCHEME.UNMAPPED_COLOR_SCHEME.strokeColor,
  strokeColorCopy: POLYGON_MAP_COLORS_SCHEME.UNMAPPED_COLOR_SCHEME.strokeColor,
  strokeOpacity: 1,
  strokeWeight: 2,
  zIndex: 1,
};

export const design_polygon_map_view_record_utils = (mapRecord: any, serviceBasedColorRecord: any[], serviceType?: string, appStateObj?: PlolygonServicesInitialStateInteface) => {
  const polygonRecord = [] as Array<MapPolygonInterface>;
  if (mapRecord && isArrayValid(mapRecord)) {
    mapRecord.forEach((record: any, recordIndex: number) => {
      let polygonObj: MapPolygonInterface = {
        path: [],
        options: { ...unmappedPolygonOptionObj },
        open: false,
        polygonId: record.polygonId,
        polygonKey: record.polygonId,
        polygonName: record.polygonName,
        serviceTypeMapping: { ...record.serviceTypeMapping },
        posNo: [],
      };

      if (record && record.coordinates && isArrayValid(record.coordinates)) {
        polygonObj.path = record.coordinates.map((coordinate: Array<number | string>) => ({ lat: coordinate[0] || 0, lng: coordinate[1] || 0 }));
      }

      if (record && record.serviceTypeMapping && isNonEmptyObject(record.serviceTypeMapping)) {
        polygonObj.polygonKey = `${polygonObj.polygonId}-${new Date().getTime()}`;
        Object.values(polygonObj.serviceTypeMapping).forEach((service: any) => {
          if (service && service.posNo && polygonObj.posNo && polygonObj.posNo.indexOf(service.posNo) === -1) {
            polygonObj.posNo.push(service.posNo);
          }
        });
        // Polygon is mapped to a service
        polygonObj.options.fillColor = POLYGON_MAP_COLORS_SCHEME.MULTIPLE_SERVICES_COLOR_SCHEME.fillColor;
        polygonObj.options.fillColorCopy = POLYGON_MAP_COLORS_SCHEME.MULTIPLE_SERVICES_COLOR_SCHEME.fillColor;
        polygonObj.options.strokeColor = POLYGON_MAP_COLORS_SCHEME.MULTIPLE_SERVICES_COLOR_SCHEME.strokeColor;
        polygonObj.options.strokeColorCopy = POLYGON_MAP_COLORS_SCHEME.MULTIPLE_SERVICES_COLOR_SCHEME.strokeColor;
        if (!isUndefined(serviceType) && !isEmptyOrNull(serviceType)) {
          // Is header level service filter applied
          polygonObj.options.fillColor = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.fillColor;
          polygonObj.options.fillColorCopy = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.fillColor;
          polygonObj.options.strokeColor = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.strokeColor;
          polygonObj.options.strokeColorCopy = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.strokeColor;
          let isServiceTypePresent = serviceType && Object.keys(record.serviceTypeMapping).indexOf(serviceType) > -1;
          if (isServiceTypePresent && serviceType && record.serviceTypeMapping[serviceType] && record.serviceTypeMapping[serviceType].isServiceable) {
            const fillColor = getColorKeyNameBasedOnServiceType(serviceBasedColorRecord, serviceType, "fillColor");
            polygonObj.options.fillColor = fillColor;
            polygonObj.options.fillColorCopy = fillColor;
            const strokeColor = getColorKeyNameBasedOnServiceType(serviceBasedColorRecord, serviceType, "strokeColor");
            polygonObj.options.strokeColor = strokeColor;
            polygonObj.options.strokeColorCopy = strokeColor;
          }
        } else {
          let serviceable = 0 as number;
          let serviceable_service_type = [] as string[];
          Object.keys(record.serviceTypeMapping).forEach((serviceType: string) => {
            if (record.serviceTypeMapping[serviceType].isServiceable) {
              serviceable_service_type.push(serviceType);
              serviceable++;
            }
          });
          if (serviceable === 0) {
            polygonObj.options.fillColor = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.fillColor;
            polygonObj.options.fillColorCopy = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.fillColor;
            polygonObj.options.strokeColor = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.strokeColor;
            polygonObj.options.strokeColorCopy = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.strokeColor;
          } else if (serviceable === 1) {
            const fillColor = getColorKeyNameBasedOnServiceType(serviceBasedColorRecord, serviceable_service_type[0], "fillColor");
            polygonObj.options.fillColor = fillColor;
            polygonObj.options.fillColorCopy = fillColor;
            const strokeColor = getColorKeyNameBasedOnServiceType(serviceBasedColorRecord, serviceable_service_type[0], "strokeColor");
            polygonObj.options.strokeColor = strokeColor;
            polygonObj.options.strokeColorCopy = strokeColor;
          }
        }

        // Is marker level filter applied
        if (
          !isUndefined(appStateObj) &&
          !isUndefined(appStateObj?.posLevelFilterData) &&
          !isUndefined(appStateObj?.posLevelFilterData?.posNo) &&
          !isUndefined(polygonObj.posNo) &&
          isArrayValid(polygonObj.posNo) &&
          polygonObj.posNo.indexOf(String(appStateObj?.posLevelFilterData?.posNo)) !== -1
        ) {
          if (!isUndefined(appStateObj?.posLevelFilterData?.selectedServiceDetails) && isNonEmptyObject(appStateObj?.posLevelFilterData?.selectedServiceDetails)) {
            if (
              polygonObj.serviceTypeMapping[appStateObj?.posLevelFilterData?.selectedServiceDetails?.name] &&
              !isUndefined(polygonObj.serviceTypeMapping[appStateObj?.posLevelFilterData?.selectedServiceDetails?.name].posNo) &&
              !isEmptyOrNull(polygonObj.serviceTypeMapping[appStateObj?.posLevelFilterData?.selectedServiceDetails?.name].posNo) &&
              polygonObj.serviceTypeMapping[appStateObj?.posLevelFilterData?.selectedServiceDetails?.name].posNo.toString() === appStateObj?.posLevelFilterData?.posNo.toString() &&
              polygonObj.serviceTypeMapping[appStateObj?.posLevelFilterData?.selectedServiceDetails?.name].isServiceable
            ) {
              polygonObj.options.fillOpacity = 1;
              polygonObj.options.fillColor = getColorKeyNameBasedOnServiceType(serviceBasedColorRecord, appStateObj?.posLevelFilterData?.selectedServiceDetails?.name, "strokeColor");
              polygonObj.options.strokeColor = getColorKeyNameBasedOnServiceType(serviceBasedColorRecord, appStateObj?.posLevelFilterData?.selectedServiceDetails?.name, "fillColor");
              if (!isUndefined(appStateObj?.posLevelFilterData?.selectedServiceDetails?.zones && isArrayValid(appStateObj?.posLevelFilterData?.selectedServiceDetails?.zones))) {
                const zone_index = appStateObj?.posLevelFilterData?.selectedServiceDetails?.zones.findIndex((zone: any) => zone.isSelected === true);
                if (!isUndefined(zone_index) && zone_index !== -1) {
                  if (
                    polygonObj.serviceTypeMapping[appStateObj?.posLevelFilterData?.selectedServiceDetails?.name].zoneName &&
                    polygonObj.serviceTypeMapping[appStateObj?.posLevelFilterData?.selectedServiceDetails?.name].zoneName === appStateObj?.posLevelFilterData?.selectedServiceDetails.zones[zone_index].name
                  ) {
                    polygonObj.options.strokeColor = COLORS.WHITE;
                    polygonObj.options.strokeWeight = 4;
                  }
                }
              }
            }
          } else {
            polygonObj.options.fillOpacity = 1;
            polygonObj.options.fillColor = polygonObj.options.strokeColorCopy;
            polygonObj.options.strokeColor = polygonObj.options.fillColorCopy;
          }
        }
      }
      polygonRecord.push(polygonObj);
    });
  }
  return polygonRecord;
};

export const open_polygon_pos_zone_info_window_utils = (markerIndex: number, appStateObj: PlolygonServicesInitialStateInteface, appCountStateObj: PlolygonServicesInitialCountStateInteface) => {
  let newAppStateObj: PlolygonServicesInitialStateInteface = produce(appStateObj, (draft: PlolygonServicesInitialStateInteface) => {
    let markerData = {} as any;
    draft.openMapInfoWindow = false;
    draft.posLevelFilterData = null;
    draft.event = null;
    draft.isPosLevelFilteredApplied = false;
    if (!isUndefined(markerIndex) && markerIndex >= 0) {
      if (draft && draft.mapMarkersRecord && isArrayValid(draft.mapMarkersRecord)) {
        const markerRecod = draft.mapMarkersRecord[markerIndex];
        if (
          !isUndefined(markerRecod) &&
          !isUndefined(markerRecod.posNo) &&
          !isUndefined(appStateObj.posLevelFilterData) &&
          !isUndefined(appStateObj?.posLevelFilterData?.posNo) &&
          appStateObj?.posLevelFilterData?.posNo.toString() === markerRecod.posNo.toString()
        ) {
          const previous_selected_marker_index = draft.mapMarkersRecord.findIndex((marker: MAP_MARKER_INTERFACE) => marker.posNo === appStateObj?.posLevelFilterData?.posNo);
          if (!isUndefined(previous_selected_marker_index) && Number(previous_selected_marker_index) >= 0) {
            draft.mapMarkersRecord[previous_selected_marker_index].key = `${draft.mapMarkersRecord[previous_selected_marker_index].name}-${new Date().getTime()}`;
            draft.mapMarkersRecord[previous_selected_marker_index].openWindow = true;
            draft.isPosLevelFilteredApplied = true;
            if (
              !isUndefined(appStateObj?.posLevelFilterData?.selectedServiceDetails) &&
              isNonEmptyObject(appStateObj?.posLevelFilterData?.selectedServiceDetails) &&
              !isUndefined(appStateObj?.posLevelFilterData?.selectedServiceDetails?.name) &&
              !isUndefined(draft.mapMarkersRecord[previous_selected_marker_index].serviceTypes) &&
              !isUndefined(draft.mapMarkersRecord[previous_selected_marker_index].serviceTypes.find((service: any) => service.name === appStateObj?.posLevelFilterData?.selectedServiceDetails?.name))
            ) {
              const service_index = draft.mapMarkersRecord[previous_selected_marker_index].serviceTypes.findIndex((service: any) => service.name === appStateObj?.posLevelFilterData?.selectedServiceDetails?.name);
              if (!isUndefined(service_index) && Number(service_index) !== -1) {
                draft.mapMarkersRecord[previous_selected_marker_index].serviceTypes[service_index] = appStateObj?.posLevelFilterData?.selectedServiceDetails;
              }
            }
            draft.posLevelFilterData = draft.mapMarkersRecord[previous_selected_marker_index];
            markerData = draft.posLevelFilterData;
          }
        } else {
          draft.mapMarkersRecord.forEach((marker: MAP_MARKER_INTERFACE, index: number) => {
            if (!isEmptyOrNull(marker?.name)) {
              draft.mapMarkersRecord[index].key = `${draft.mapMarkersRecord[index].name}-${new Date().getTime()}`;
              draft.mapMarkersRecord[index].openWindow = false;
              draft.mapMarkersRecord[index].selectedServiceDetails = {};
              draft.mapMarkersRecord[index].serviceTypes = marker.serviceTypes.map((service: any) => ({ ...service, isSelected: false }));
              if (markerIndex === index) {
                draft.mapMarkersRecord[index].openWindow = true;
                draft.isPosLevelFilteredApplied = true;
                // is top level filter applied
                if (
                  !isUndefined(appCountStateObj) &&
                  !isUndefined(appCountStateObj.serviceTypeFilter) &&
                  !isEmptyOrNull(appCountStateObj.serviceTypeFilter) &&
                  !isUndefined(draft.mapMarkersRecord[index]) &&
                  !isUndefined(draft.mapMarkersRecord[index].serviceTypes) &&
                  isArrayValid(draft.mapMarkersRecord[index].serviceTypes) &&
                  draft.mapMarkersRecord[index].serviceTypes.find((val: any) => val?.name?.toLowerCase() === appCountStateObj.serviceTypeFilter?.toLowerCase())
                ) {
                  const markerServiceIndex = draft.mapMarkersRecord[index].serviceTypes.findIndex((val: any) => val?.name?.toLowerCase() === appCountStateObj.serviceTypeFilter?.toLowerCase());
                  if (!isUndefined(markerServiceIndex) && markerServiceIndex !== -1) {
                    draft.mapMarkersRecord[index].serviceTypes[markerServiceIndex].isSelected = true;
                    let serviceData = draft.mapMarkersRecord[index].serviceTypes[markerServiceIndex];
                    if (serviceData && serviceData.zones && isArrayValid(serviceData.zones)) {
                      serviceData = { ...serviceData, zones: serviceData.zones.map((zone: any) => ({ ...zone, serviceName: serviceData?.name, isSelected: false })) };
                    }
                    draft.mapMarkersRecord[markerIndex].selectedServiceDetails = serviceData;
                  }
                }
                markerData = draft.mapMarkersRecord[markerIndex];
                draft.posLevelFilterData = markerData;
              }
            }
          });
        }
      }
    }
    if (draft.mapPolygonsRecord && isArrayValid(draft.mapPolygonsRecord)) {
      draft.mapPolygonsRecord.forEach((polygon: MapPolygonInterface, polygonIndex: number) => {
        draft.mapPolygonsRecord[polygonIndex].polygonKey = `${draft.mapPolygonsRecord[polygonIndex].polygonId}-${new Date().getTime()}`;
        draft.mapPolygonsRecord[polygonIndex].options.fillOpacity = 0.6;
        draft.mapPolygonsRecord[polygonIndex].options.strokeWeight = 2;
        draft.mapPolygonsRecord[polygonIndex].options.fillColor = draft.mapPolygonsRecord[polygonIndex].options.fillColorCopy;
        draft.mapPolygonsRecord[polygonIndex].options.strokeColor = draft.mapPolygonsRecord[polygonIndex].options.strokeColorCopy;
        if (markerData && markerData.posNo && polygon && polygon.posNo && polygon.posNo.indexOf(markerData.posNo) !== -1) {
          // Polygon contain pos
          if (!isUndefined(markerData) && !isUndefined(markerData.selectedServiceDetails) && isNonEmptyObject(markerData.selectedServiceDetails)) {
            // A service is already selected for this pos marker
            if (
              polygon.serviceTypeMapping &&
              isNonEmptyObject(polygon.serviceTypeMapping) &&
              polygon.serviceTypeMapping[markerData.selectedServiceDetails?.name] &&
              !isUndefined(polygon.serviceTypeMapping[markerData.selectedServiceDetails?.name].posNo) &&
              !isEmptyOrNull(polygon.serviceTypeMapping[markerData.selectedServiceDetails?.name].posNo) &&
              polygon.serviceTypeMapping[markerData.selectedServiceDetails?.name].posNo.toString() === markerData.posNo.toString() &&
              polygon.serviceTypeMapping[markerData.selectedServiceDetails?.name].isServiceable
            ) {
              draft.mapPolygonsRecord[polygonIndex].options.fillOpacity = 1;
              draft.mapPolygonsRecord[polygonIndex].options.fillColor = getColorKeyNameBasedOnServiceType(draft.serviceBasedColorRecord, markerData.selectedServiceDetails?.name, "strokeColor");
              draft.mapPolygonsRecord[polygonIndex].options.strokeColor = getColorKeyNameBasedOnServiceType(draft.serviceBasedColorRecord, markerData.selectedServiceDetails?.name, "fillColor");
              // TODO also check if zone is also previously selected
              if (!isUndefined(markerData?.selectedServiceDetails?.zones && isArrayValid(markerData?.selectedServiceDetails?.zones))) {
                const zone_index = markerData?.selectedServiceDetails?.zones.findIndex((zone: any) => zone.isSelected === true);
                if (!isUndefined(zone_index) && zone_index !== -1) {
                  if (
                    polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].zoneName &&
                    polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].zoneName === draft.mapMarkersRecord[markerIndex].selectedServiceDetails.zones[zone_index].name
                  ) {
                    draft.mapPolygonsRecord[polygonIndex].options.strokeColor = COLORS.WHITE;
                    draft.mapPolygonsRecord[polygonIndex].options.strokeWeight = 4;
                  }
                }
              }
            }
          } else {
            // Polygon is opening first time so if polygon conatin pos then make it darker
            draft.mapPolygonsRecord[polygonIndex].options.fillOpacity = 1;
            draft.mapPolygonsRecord[polygonIndex].options.fillColor = draft.mapPolygonsRecord[polygonIndex].options.strokeColorCopy;
            draft.mapPolygonsRecord[polygonIndex].options.strokeColor = draft.mapPolygonsRecord[polygonIndex].options.fillColorCopy;
          }
        }
      });
    }
    draft.overlayLoader = false;
  });
  return newAppStateObj;
};

export const close_polygon_pos_zone_info_window_utils = (appStateObj: PlolygonServicesInitialStateInteface) => {
  let newAppStateObj: PlolygonServicesInitialStateInteface = produce(appStateObj, (draft: PlolygonServicesInitialStateInteface) => {
    if (draft && draft.mapMarkersRecord && isArrayValid(draft.mapMarkersRecord)) {
      draft.openMapInfoWindow = false;
      draft.event = null;
      draft.mapMarkersRecord.forEach((marker: MAP_MARKER_INTERFACE, markerIndex: number) => {
        draft.mapMarkersRecord[markerIndex].key = `${draft.mapMarkersRecord[markerIndex].name}`;
        draft.mapMarkersRecord[markerIndex].openWindow = false;
      });
    }
  });
  return newAppStateObj;
};

export const select_service_polygon_pos_zone_info_window_utils = (markerData: any, serviceIndex: number, mapRecord: any, appStateObj: PlolygonServicesInitialStateInteface) => {
  let newAppStateObj: PlolygonServicesInitialStateInteface = produce(appStateObj, (draft: PlolygonServicesInitialStateInteface) => {
    const markerIndex = draft.mapMarkersRecord.findIndex((marker: MAP_MARKER_INTERFACE) => marker.posNo === markerData.posNo);
    if (!isUndefined(markerIndex) && markerIndex !== -1 && draft.mapMarkersRecord && draft.mapMarkersRecord[markerIndex] && draft.mapMarkersRecord[markerIndex].serviceTypes && isArrayValid(draft.mapMarkersRecord[markerIndex].serviceTypes)) {
      draft.mapMarkersRecord[markerIndex].selectedServiceDetails = {};
      draft.mapMarkersRecord[markerIndex].serviceTypes.forEach((service: any, index: number) => {
        draft.mapMarkersRecord[markerIndex].serviceTypes[index].isSelected = false;
        if (index === serviceIndex) {
          let serviceData = service;
          if (serviceData && serviceData.zones && isArrayValid(serviceData.zones)) {
            serviceData = { ...serviceData, zones: serviceData.zones.map((zone: any) => ({ ...zone, serviceName: service?.name, isSelected: false })) };
          }
          draft.mapMarkersRecord[markerIndex].selectedServiceDetails = serviceData;
          draft.mapMarkersRecord[markerIndex].selectedServiceDetails.isSelected = true;
          draft.mapMarkersRecord[markerIndex].serviceTypes[index].isSelected = true;
          draft.posLevelFilterData = draft.mapMarkersRecord[markerIndex];
        }
      });
      if (draft.mapMarkersRecord && draft.mapMarkersRecord[markerIndex] && draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name) {
        if (!isUndefined(draft.mapPolygonsRecord) && isArrayValid(draft.mapPolygonsRecord)) {
          draft.mapPolygonsRecord.forEach((polygon: MapPolygonInterface, polygonIndex: number) => {
            draft.mapPolygonsRecord[polygonIndex].polygonKey = `${draft.mapPolygonsRecord[polygonIndex].polygonId}-${new Date().getTime()}`;
            draft.mapPolygonsRecord[polygonIndex].options.fillOpacity = 0.6;
            draft.mapPolygonsRecord[polygonIndex].options.strokeWeight = 2;
            draft.mapPolygonsRecord[polygonIndex].options.fillColor = draft.mapPolygonsRecord[polygonIndex].options.fillColorCopy;
            draft.mapPolygonsRecord[polygonIndex].options.strokeColor = draft.mapPolygonsRecord[polygonIndex].options.strokeColorCopy;
            if (polygon && polygon.posNo && draft.mapMarkersRecord && draft.mapMarkersRecord[markerIndex] && draft.mapMarkersRecord[markerIndex].name && polygon.posNo.indexOf(draft.mapMarkersRecord[markerIndex].name) !== -1) {
              if (
                polygon.serviceTypeMapping &&
                isNonEmptyObject(polygon.serviceTypeMapping) &&
                polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name] &&
                !isUndefined(polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].posNo) &&
                !isEmptyOrNull(polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].posNo) &&
                polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].posNo.toString() === markerData.posNo.toString() &&
                polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].isServiceable
              ) {
                draft.mapPolygonsRecord[polygonIndex].options.fillOpacity = 1;
                draft.mapPolygonsRecord[polygonIndex].options.fillColor = getColorKeyNameBasedOnServiceType(draft.serviceBasedColorRecord, draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name, "strokeColor");
                draft.mapPolygonsRecord[polygonIndex].options.strokeColor = getColorKeyNameBasedOnServiceType(draft.serviceBasedColorRecord, draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name, "fillColor");
              }
            }
          });
        }
      }
    }
    draft.overlayLoader = false;
  });
  return newAppStateObj;
};

export const select_service_zone_polygon_pos_zone_info_window_utils = (markerData: any, zone: any, zoneIndex: number, mapRecord: any, appStateObj: PlolygonServicesInitialStateInteface) => {
  let newAppStateObj: PlolygonServicesInitialStateInteface = produce(appStateObj, (draft: PlolygonServicesInitialStateInteface) => {
    const markerIndex = draft.mapMarkersRecord.findIndex((marker: any) => marker.posNo === markerData.posNo);
    if (
      !isUndefined(markerIndex) &&
      markerIndex !== -1 &&
      draft.mapMarkersRecord &&
      draft.mapMarkersRecord[markerIndex] &&
      draft.mapMarkersRecord[markerIndex].selectedServiceDetails &&
      draft.mapMarkersRecord[markerIndex].selectedServiceDetails.name &&
      draft.mapMarkersRecord[markerIndex].selectedServiceDetails.zones &&
      isArrayValid(draft.mapMarkersRecord[markerIndex].selectedServiceDetails.zones)
    ) {
      draft.mapMarkersRecord[markerIndex].key = `${draft.mapMarkersRecord[markerIndex].name}-${new Date().getTime()}`;
      draft.mapMarkersRecord[markerIndex].selectedServiceDetails.zones.forEach((zone: any, index: number) => {
        zone.isSelected = false;
        if (index === zoneIndex) {
          zone.isSelected = true;
        }
      });
      draft.posLevelFilterData = draft.mapMarkersRecord[markerIndex];
      if (draft.mapPolygonsRecord && isArrayValid(draft.mapPolygonsRecord)) {
        draft.mapPolygonsRecord.forEach((polygon: MapPolygonInterface, polygonIndex: number) => {
          draft.mapPolygonsRecord[polygonIndex].polygonKey = `${draft.mapPolygonsRecord[polygonIndex].polygonId}-${new Date().getTime()}`;
          if (polygon && polygon.posNo && draft.mapMarkersRecord && draft.mapMarkersRecord[markerIndex] && draft.mapMarkersRecord[markerIndex].name && polygon.posNo.indexOf(draft.mapMarkersRecord[markerIndex].name) !== -1) {
            if (
              polygon.serviceTypeMapping &&
              isNonEmptyObject(polygon.serviceTypeMapping) &&
              polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name] &&
              !isUndefined(polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].posNo) &&
              !isEmptyOrNull(polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].posNo) &&
              polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].posNo.toString() === markerData.posNo.toString() &&
              polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].isServiceable
            ) {
              draft.mapPolygonsRecord[polygonIndex].options.fillOpacity = 1;
              draft.mapPolygonsRecord[polygonIndex].options.strokeWeight = 2;
              draft.mapPolygonsRecord[polygonIndex].options.fillColor = getColorKeyNameBasedOnServiceType(draft.serviceBasedColorRecord, draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name, "strokeColor");
              draft.mapPolygonsRecord[polygonIndex].options.strokeColor = getColorKeyNameBasedOnServiceType(draft.serviceBasedColorRecord, draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name, "fillColor");
              if (
                polygon.serviceTypeMapping &&
                isNonEmptyObject(polygon.serviceTypeMapping) &&
                polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name] &&
                polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].isServiceable &&
                polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].zoneName &&
                polygon.serviceTypeMapping[draft.mapMarkersRecord[markerIndex].selectedServiceDetails?.name].zoneName === draft.mapMarkersRecord[markerIndex].selectedServiceDetails.zones[zoneIndex].name
              ) {
                draft.mapPolygonsRecord[polygonIndex].options.strokeColor = COLORS.WHITE;
                draft.mapPolygonsRecord[polygonIndex].options.strokeWeight = 4;
              }
            }
          }
        });
      }
    }
    draft.overlayLoader = false;
  });
  return newAppStateObj;
};

export const get_searched_polygon__index_utils = (appStateObj: PlolygonServicesInitialStateInteface) => {
  if (appStateObj.mapPolygonsRecord && isArrayValid(appStateObj.mapPolygonsRecord)) {
    const searchedIndex = appStateObj.mapPolygonsRecord.findIndex((polygon: any) => (appStateObj.searchTypeValue === "polygonId" ? polygon.polygonId === appStateObj.searchValue : polygon.polygonName === appStateObj.searchValue));
    if (searchedIndex !== -1) {
      return searchedIndex;
    } else {
      let error_message = "Polygon Name";
      if (appStateObj.searchTypeValue === "polygonId") {
        error_message = "Polygon ID";
      }
      CustomAlert(ALERT_TYPES.ERROR, `${error_message} is not found in the current view.`);
    }
  }
  return -1;
};

export const turn_on_overlay_loader = (appStateObj: PlolygonServicesInitialStateInteface) => {
  let newAppStateObj: PlolygonServicesInitialStateInteface = produce(appStateObj, (draft: PlolygonServicesInitialStateInteface) => {
    draft.overlayLoader = true;
  });
  return newAppStateObj;
};

export const handle_single_service_type_change_utils = (appStateObj: PlolygonServicesInitialStateInteface, value: string) => {
  let newAppStateObj: PlolygonServicesInitialStateInteface = produce(appStateObj, (draft: PlolygonServicesInitialStateInteface) => {
    if (!isUndefined(value) && !isEmptyOrNull(value)) {
      draft.isPosLevelFilteredApplied = false;
      draft.posLevelFilterData = null;
      if (draft.mapPolygonsRecord && isArrayValid(draft.mapPolygonsRecord)) {
        draft.mapPolygonsRecord.forEach((polygon: any, polygonIndex: number) => {
          if (polygon && polygon.serviceTypeMapping && isNonEmptyObject(polygon.serviceTypeMapping)) {
            draft.mapPolygonsRecord[polygonIndex].polygonKey = `${draft.mapPolygonsRecord[polygonIndex].polygonId}-${new Date().getTime()}`;
            draft.mapPolygonsRecord[polygonIndex].options.fillOpacity = 0.6;
            draft.mapPolygonsRecord[polygonIndex].options.strokeWeight = 2;
            draft.mapPolygonsRecord[polygonIndex].options.fillColor = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.fillColor;
            draft.mapPolygonsRecord[polygonIndex].options.fillColorCopy = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.fillColor;
            draft.mapPolygonsRecord[polygonIndex].options.strokeColor = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.strokeColor;
            draft.mapPolygonsRecord[polygonIndex].options.strokeColorCopy = POLYGON_MAP_COLORS_SCHEME.NON_SERVICEABLE_COLOR_SCHEME.strokeColor;
            let isServiceTypePresent = value && Object.keys(polygon.serviceTypeMapping).indexOf(value) > -1;
            if (isServiceTypePresent && value && polygon.serviceTypeMapping[value] && polygon.serviceTypeMapping[value].isServiceable) {
              const fillColor = getColorKeyNameBasedOnServiceType(draft.serviceBasedColorRecord, value, "fillColor");
              draft.mapPolygonsRecord[polygonIndex].options.fillColor = fillColor;
              draft.mapPolygonsRecord[polygonIndex].options.fillColorCopy = fillColor;
              const strokeColor = getColorKeyNameBasedOnServiceType(draft.serviceBasedColorRecord, value, "strokeColor");
              draft.mapPolygonsRecord[polygonIndex].options.strokeColor = strokeColor;
              draft.mapPolygonsRecord[polygonIndex].options.strokeColorCopy = strokeColor;
            }
          }
        });
      }
      if (!isUndefined(draft.mapMarkersRecord) && isArrayValid(draft.mapMarkersRecord)) {
        draft.mapMarkersRecord.forEach((marker: MAP_MARKER_INTERFACE, markerIndex: number) => {
          draft.mapMarkersRecord[markerIndex].key = `${draft.mapMarkersRecord[markerIndex].name}-${new Date().getTime()}`;
          draft.mapMarkersRecord[markerIndex].visible = false;
          draft.mapMarkersRecord[markerIndex].selectedServiceDetails = {};
          if (!isUndefined(marker) && !isUndefined(marker.serviceTypes) && isArrayValid(marker.serviceTypes) && marker.serviceTypes.find((val: any) => val?.name?.toLowerCase() === value?.toLowerCase())) {
            const markerServiceIndex = marker.serviceTypes.findIndex((val: any) => val?.name?.toLowerCase() === value?.toLowerCase());
            draft.mapMarkersRecord[markerIndex].visible = true;
            if (!isUndefined(markerServiceIndex) && markerServiceIndex !== -1) {
              draft.mapMarkersRecord[markerIndex].serviceTypes[markerServiceIndex].isSelected = false;
              let serviceData = draft.mapMarkersRecord[markerIndex].serviceTypes[markerServiceIndex];
              serviceData = { ...serviceData, zones: serviceData.zones.map((zone: any) => ({ ...zone, serviceName: value, isSelected: false })) };
              draft.mapMarkersRecord[markerIndex].selectedServiceDetails = serviceData;
            }
          }
        });
      }
    }
    draft.overlayLoader = false;
  });
  return newAppStateObj;
};

export const refresh_map_marker_data = (appStateObj: PlolygonServicesInitialStateInteface) => {
  let mapMarkersRecord = appStateObj.mapMarkersRecord;
  if (!isUndefined(mapMarkersRecord) && isArrayValid(mapMarkersRecord)) {
    mapMarkersRecord = mapMarkersRecord.map((marker: MAP_MARKER_INTERFACE) => ({ ...marker, key: `${marker.name}-${new Date().getTime()}` }));
  }
  return mapMarkersRecord;
};

export const reset_map_marker_data = (appStateObj: PlolygonServicesInitialStateInteface) => {
  let mapMarkersRecord = appStateObj.mapMarkersRecord;
  if (!isUndefined(mapMarkersRecord) && isArrayValid(mapMarkersRecord)) {
    mapMarkersRecord = mapMarkersRecord.map((marker: MAP_MARKER_INTERFACE) => ({ ...marker, key: `${marker.name}-${new Date().getTime()}`, openWindow: false }));
  }
  return mapMarkersRecord;
};

export const re_enter_map_marker_data = (appStateObj: PlolygonServicesInitialStateInteface) => {
  let mapMarkersRecord = appStateObj.mapMarkersRecord;
  if (!isUndefined(mapMarkersRecord) && isArrayValid(mapMarkersRecord)) {
    mapMarkersRecord = mapMarkersRecord.map((marker: MAP_MARKER_INTERFACE) => ({ ...marker, key: `${marker?.name}-${new Date().getTime()}`, openWindow: false, visible: true }));
    if (!isUndefined(appStateObj) && !isUndefined(appStateObj?.latSearchValue) && !isUndefined(appStateObj?.lngSearchValue)) {
      mapMarkersRecord = mapMarkersRecord.filter((marker: MAP_MARKER_INTERFACE) => marker && marker.icon);
    }
  }
  return mapMarkersRecord;
};

export const move_map_to_cordinates_on_search_utils = (appStateObj: PlolygonServicesInitialStateInteface) => {
  let newAppStateObj: PlolygonServicesInitialStateInteface = produce(appStateObj, (draft: PlolygonServicesInitialStateInteface) => {
    let center = { lat: Number(draft.latSearchValue), lng: Number(draft.lngSearchValue) };
    draft.loading = false;
    draft.overlayLoader = true;
    draft.mapDefaultCenter = center;
    draft.mapCenter = center;
    draft.mapMarkersRecord = draft.mapMarkersRecord.filter((marker: MAP_MARKER_INTERFACE) => marker && marker.icon);
    draft.mapMarkersRecord.push(latLngMarkerRecord(appStateObj.latSearchValue, appStateObj.lngSearchValue));
  });
  return newAppStateObj;
};

// January 2025

export const hanlde_update_service_type_record = (appStateObj: AppStateObjInterface, loading: any, serviceRowIndex: string | number, updatedMappingList: any) => {
  let newAppStateObj: AppStateObjInterface = produce(appStateObj, (draft: AppStateObjInterface) => {
    draft.loading = loading;
    draft.posZoneSearchText = "";
    draft.posZoneServiceRecord = [];
    draft.posZoneServiceFilteredRecord = [];
    draft.showPosZoneMappingPopUp = false;
    draft.serviceRowIndex = "";
    draft.mappingListRowIndex = "";
    draft.isNonServiceable = false;
    draft.serviceTypeName = "";
    draft.selectedPosZoneIndex = "";
    draft.selectedPosZoneObj = {};
    //
    draft.serviceTypeRecordList = [
      ...draft.serviceTypeRecordList.slice(0, Number(serviceRowIndex)),
      { ...draft.serviceTypeRecordList[Number(serviceRowIndex)], mappingList: updatedMappingList },
      ...draft.serviceTypeRecordList.slice(Number(serviceRowIndex) + 1),
    ];
  });
  return newAppStateObj;
};

export const handlePolygonIdSearchTextChangeUtils = (appStateObj: AppStateObjInterface, allPolygonListIdsRecord: string[], polygonIdsSearchTextValue: string) => {
  let newAppStateObj: AppStateObjInterface = produce(appStateObj, (draft: AppStateObjInterface) => {
    draft.polygonIdsSearchTextValue = polygonIdsSearchTextValue;
    if (allPolygonListIdsRecord && Array.isArray(allPolygonListIdsRecord) && allPolygonListIdsRecord.length > 0) {
      let selected_list = [...draft.mappedPolygonIdsRecord] as string[];
      let result_list = [];
      if (draft.polygonIdsSearchTextValue !== undefined && draft.polygonIdsSearchTextValue !== "") {
        let list_data = allPolygonListIdsRecord.filter((list: string) => list && list.toLowerCase().includes(polygonIdsSearchTextValue.toLowerCase()));
        result_list = selected_list.concat(list_data);
      } else {
        result_list = selected_list.concat(allPolygonListIdsRecord);
      }

      // remove duplicate
      let map = new Map();
      result_list.forEach((item: any) => {
        if (!map.has(item)) {
          map.set(item, item);
        }
      });
      draft.allPolygonListIdsRecord = Array.from(map.values());
    }
  });
  return newAppStateObj;
};

export const handleMappedPolygonIdSearchTextChangeUtils = (appStateObj: AppStateObjInterface, mappedPolygonIdsSearchTextValue: string) => {
  let newAppStateObj: AppStateObjInterface = produce(appStateObj, (draft: AppStateObjInterface) => {
    draft.mappedPolygonIdsSearchTextValue = mappedPolygonIdsSearchTextValue;
  });
  return newAppStateObj;
};

export const getMappedPolygonIdsRecordListUtils = (appStateObj: AppStateObjInterface) => {
  if (appStateObj.mappedPolygonIdsRecord && Array.isArray(appStateObj.mappedPolygonIdsRecord) && appStateObj.mappedPolygonIdsRecord.length > 0) {
    let filteredList = [...appStateObj.mappedPolygonIdsRecord];
    if (appStateObj.mappedPolygonIdsSearchTextValue !== undefined && appStateObj.mappedPolygonIdsSearchTextValue !== "") {
      filteredList = filteredList.filter((list: string) => list && list.toLowerCase().includes(appStateObj.mappedPolygonIdsSearchTextValue.toLowerCase()));
    }
    return filteredList;
  }
  return [];
};

export const getPolygonIdsRecordListUtils = (appStateObj: AppStateObjInterface, allPolygonListIdsRecord: string[]) => {
  if (allPolygonListIdsRecord && Array.isArray(allPolygonListIdsRecord) && allPolygonListIdsRecord.length > 0) {
    let filteredList = [...allPolygonListIdsRecord];
    if (appStateObj.polygonIdsSearchTextValue !== undefined && appStateObj.polygonIdsSearchTextValue !== "") {
      filteredList = filteredList.filter((list: string) => list && list.toLowerCase().includes(appStateObj.polygonIdsSearchTextValue.toLowerCase()));
    }
    return filteredList;
  }
  return [];
};

export const headerPolygonIdsRowCheckboxOnchangeUtils = (appStateObj: AppStateObjInterface, checked: boolean) => {
  let newAppStateObj: AppStateObjInterface = produce(appStateObj, (draft: AppStateObjInterface) => {
    if (draft.allPolygonListIdsRecord && Array.isArray(draft.allPolygonListIdsRecord) && draft.allPolygonListIdsRecord.length > 0) {
      if (checked) {
        draft.mappedPolygonIdsRecord = [...new Set([...draft.mappedPolygonIdsRecord, ...draft.allPolygonListIdsRecord])];
      } else {
        draft.mappedPolygonIdsRecord = draft.mappedPolygonIdsRecord.filter((id) => !draft.allPolygonListIdsRecord.includes(id));
      }
    }
  });
  return newAppStateObj;
};

export const tableRowCellCheckboxOnchangeUtils = (appStateObj: AppStateObjInterface, checked: boolean, polygonId: string) => {
  let newAppStateObj: AppStateObjInterface = produce(appStateObj, (draft: AppStateObjInterface) => {
    if (draft.allPolygonListIdsRecord && Array.isArray(draft.allPolygonListIdsRecord) && draft.allPolygonListIdsRecord.length > 0) {
      if (checked) {
        draft.mappedPolygonIdsRecord = [...new Set([...draft.mappedPolygonIdsRecord, polygonId])];
      } else {
        draft.mappedPolygonIdsRecord = draft.mappedPolygonIdsRecord.filter((id) => !polygonId.includes(id));
      }
    }
  });
  return newAppStateObj;
};

export const deleteSelectedMappedPolygonIdUtils = (appStateObj: AppStateObjInterface, polygonId: string) => {
  let newAppStateObj: AppStateObjInterface = produce(appStateObj, (draft: AppStateObjInterface) => {
    draft.mappedPolygonIdsRecord = draft.mappedPolygonIdsRecord.filter((id) => !polygonId.includes(id));
  });
  return newAppStateObj;
};

export const validatePolygonIdConfigurationSaveObject = (appStateObj: AppStateObjInterface, saveType: string, servicePropositionList: any) => {
  const { serviceTypeRecordList, mappedPolygonIdsRecord } = appStateObj;
  const payloadObject: PayloadObjectInterface = { polygonIds: [], changedServiceTypes: [], serviceTypeMappings: [] };
  const responseValue: any = {};
  let emptyCount = 0 as any;

  if (isUndefined(appStateObj)) return {};

  if (saveType === POLYGON_CONSTANT_TEXT.SAVE && isEmptyArray(mappedPolygonIdsRecord)) {
    CustomAlert(ALERT_TYPES.ERROR, POLYGON_CONSTANT_TEXT.POLYGON_ID_REQUIRED);
    return {};
  } else if (saveType === POLYGON_CONSTANT_TEXT.UPDATE) {
    if (appStateObj.polygonId) {
      payloadObject.polygonIds.push(appStateObj.polygonId);
    } else {
      CustomAlert(ALERT_TYPES.ERROR, POLYGON_CONSTANT_TEXT.POLYGON_ID_UPDATE_ERROR_REQUIRED);
      return {};
    }
  } else {
    payloadObject.polygonIds = mappedPolygonIdsRecord;
  }

  if (isUndefined(serviceTypeRecordList)) return {};

  serviceTypeRecordList.forEach((serviceObj: ServiceTypeRecord) => {
    const { name, active, mappingList } = serviceObj;
    if (active) {
      emptyCount++;
      payloadObject.changedServiceTypes.push(name.toUpperCase());

      if (isEmptyArray(mappingList)) {
        responseValue[`mappingList-${name}-empty`] = `${POLYGON_CONSTANT_TEXT.Please_PROVIDE_MAPPING_RECORD} for ${name}`;
        return;
      }

      let serviceTypeMapping: ServiceTypeMappings = { serviceType: name.toUpperCase(), storeDayWiseMappings: [{ mappings: [] }] };

      mappingList.forEach((mapObj: MappingListItem) => {
        if (!mapObj.startTime || !mapObj.endTime || !moment(mapObj.startTime).isValid() || !moment(mapObj.endTime).isValid()) {
          responseValue[`mappingList-${name}-time-error`] = `${POLYGON_CONSTANT_TEXT.PROVIDE_VALID_START_END_TIME} for ${name}`;
          return;
        }

        const { startTime, endTime } = mapObj;
        let serviceMappingObj: ServiceTypeMapping = {
          startTime: moment(startTime).format("HH:mm:ss"),
          endTime: moment(endTime).format("HH:mm:ss"),
          serviceType: name.toUpperCase(),
          serviceTypeId: mapObj?.serviceTypeId ? mapObj.serviceTypeId : getServiceTypeIdByName(servicePropositionList, name.toUpperCase()),
          isServiceable: true,
          ...(mapObj?.posNo && String(mapObj?.posNo) !== ""
            ? {
                posId: mapObj.posId || "",
                posNo: mapObj.posNo,
                posName: mapObj.posName,
                zoneName: mapObj.zoneName,
                zoneId: mapObj.zoneId,
                status: mapObj.status,
              }
            : {}),
        };

        if (endTime && moment(endTime).isValid() && moment(endTime).hours() === 23 && moment(endTime).minutes() === 59 && moment(endTime).seconds() !== 59) {
          moment(endTime).set({ seconds: 59 });
        }

        if (mapObj?.posNo === undefined && String(mapObj?.posNo) === "") {
          responseValue[`mappingList-${name}-is-Non-Serviceable-error`] = `${POLYGON_CONSTANT_TEXT.NO_POS_ZONE_SELECTED_ERROR}`;
          return;
        } else if (mapObj.isNonServiceable !== undefined && mapObj.isNonServiceable) {
          serviceMappingObj.isServiceable = false;
        } else if (mapObj?.posNo === undefined && isEmptyOrNull(mapObj.isNonServiceable)) {
          responseValue[`mappingList-${name}-is-Non-Serviceable-error`] = `${POLYGON_CONSTANT_TEXT.NO_POS_ZONE_SELECTED_ERROR}`;
          return;
        }

        serviceTypeMapping.storeDayWiseMappings[0].mappings.push(serviceMappingObj);
      });
      payloadObject.serviceTypeMappings.push(serviceTypeMapping);
    }
  });

  if (emptyCount === 0) {
    CustomAlert(ALERT_TYPES.ERROR, POLYGON_CONSTANT_TEXT.NO_CONFIGURATION_FOUND);
    return {};
  } else if (responseValue && Object.keys(responseValue).length !== 0) {
    CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
    return {};
  }
  return payloadObject;
};

export const designServiceTypeRecordListUtils = (payload: any, servicePropositionList: any) => {
  const serviceTypeRecordList: ServiceTypeRecord[] = getServiceTypeRecordList(servicePropositionList);
  if (payload && payload.serviceTypeMappings && Array.isArray(payload.serviceTypeMappings)) {
    payload.serviceTypeMappings.forEach((serviceObj: any) => {
      const index = serviceTypeRecordList.findIndex((record: any) => record.name.toUpperCase() === serviceObj?.serviceType?.toUpperCase());
      if (index !== -1) {
        serviceTypeRecordList[index].active = true;
        serviceTypeRecordList[index].openAccordian = true;
        serviceTypeRecordList[index].enableConfigEdit = true;
        serviceTypeRecordList[index].mappingList = [];
        if (serviceObj.storeDayWiseMappings && Array.isArray(serviceObj.storeDayWiseMappings)) {
          serviceObj.storeDayWiseMappings.forEach((storeDayWiseObj: any) => {
            if (storeDayWiseObj && storeDayWiseObj.mappings && Array.isArray(storeDayWiseObj.mappings)) {
              storeDayWiseObj.mappings.forEach((mappingObj: any) => {
                const newMappingObj = {
                  ...mappingObj,
                  startTime: moment(mappingObj.startTime, "HH:mm:ss"),
                  endTime: moment(mappingObj.endTime, "HH:mm:ss"),
                  isNonServiceable: mappingObj?.isServiceable ? false : true,
                };
                serviceTypeRecordList[index].mappingList.push(newMappingObj);
              });
            }
          });
        }
      }
    });
  }
  return serviceTypeRecordList;
};

export const getServiceTypeRecordList = (servicePropositionList: any) => {
  const serviceArray: any = generateServiceNameValueForPolygon(servicePropositionList);
  if (!isUndefined(serviceArray) && isArrayValid(serviceArray)) {
    return serviceArray.map((serviceObj: any) => ({ ...serviceObj, openAccordian: false, active: false, sameAsEarlier: false, enableConfigEdit: false, mappingList: [] }));
  }
  return [];
};

const getColorByIndex = (index: number) => {
  if (isNonEmptyObject(COLORS)) {
    const keys = Object.keys(COLORS);
    if (index >= 0 && index < keys.length) {
      return COLORS[keys[index]];
    } else {
      return undefined; // Or throw an error, or return a default color
    }
  }
  return "";
};

export const getServiceTypeColorList = (servicePropositionList: any) => {
  let serviceArray: any = generateServiceNameValueForPolygon(servicePropositionList);
  if (!isUndefined(serviceArray) && isArrayValid(serviceArray)) {
    let colorIndex: number = 0;
    serviceArray = serviceArray.map((serviceObj: any) => {
      const fillColor = getColorByIndex(colorIndex);
      const strokeColor = getColorByIndex(colorIndex + 1); // Or handle it differently
      colorIndex = colorIndex + 2;
      return { ...serviceObj, fillColor, strokeColor };
    });
    serviceArray.push({ name: "Non serviceable", fillColor: COLORS.CASHMERE, strokeColor: COLORS.PIPER });
    serviceArray.push({ name: "Multiple services", fillColor: COLORS.CLAM_SHELL, strokeColor: COLORS.CLARET });
    serviceArray.push({ name: "Unmapped", fillColor: COLORS.CHICAGO_LIGHT, strokeColor: COLORS.CHICAGO });
    return serviceArray;
  }
  return [];
};
