import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../config/theme/theme";

export const countrySettingsStyles = makeStyles((theme) => ({
  countrySection: {
    marginTop: "80px",
    padding: "24px",
  },
  title: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "36px",
    color: COLORS.TEXT_PRIMARY,
  },
  areaMappingContainer: {
    margin: "16px 0px 0px 0px",
    padding: 0,
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    background: COLORS.DOS_BLUE_SUPER_LIGHT,
  },
  posDetailsPaperStyle: {
    margin: "24px 0px 0px 0px",
    padding: 0,
    borderRadius: "8px 8px 0px 0px",
    background: COLORS.WHITE,
    height: "auto",
  },
  activePOSGridItem: {
    margin: 0,
    padding: "8px",
    height: "100px",
    background: "linear-gradient(180deg, #1B96C3 0%, #41BCEA 100%)",
    borderRadius: "8px 0px 0px 0px",
    flex: 1,
  },
  activePOSGridInnerContainer: {
    margin: "auto",
    padding: 0,
    height: "100%",
  },
  activePOSGridInnerItem: {
    margin: "auto 8px auto 0px",
    padding: 0,
  },
  activePosCount: {
    color: COLORS.WHITE,
    fontWeight: 500,
    fontSize: "14px",
  },
  posCountNo: {
    color: COLORS.WHITE,
    fontWeight: 700,
    fontSize: "18px",
  },
  serviceTab: {
    margin: 0,
    padding: "8px",
    height: "100px",
    background: COLORS.ALABASTER,
    borderRadius: 0,
    border: "1px solid",
    borderColor: COLORS.DOS_BLUE_LIGHT,
    cursor: "pointer",
    flex: 1,
    "&:last-child": {
      borderRadius: "0px 8px 0px 0px",
    },
  },
  serviceTypeDetailsSectionActive: {
    background: COLORS.DOS_BLUE_LIGHT,
    borderColor: COLORS.DOS_BLUE_DARK,
  },
  serviceTypeStyle: {
    color: COLORS.DOS_BLUE_DARK,
    fontWeight: 600,
    fontSize: "14px",
  },
  serviceTypeCountData: {
    color: COLORS.BLACK,
    fontWeight: 700,
    fontSize: "18px",
  },
  serviceTypeCard: {
    margin: 0,
    padding: 0,
    borderRadius: "0px 0px 8px 8px",
    boxShadow: "0px 1px 4px rgba(14, 90, 167, 0.2)",
  },
  serviceTypeCardForEmptyFBCServiceTypeCard: {
    margin: 0,
    padding: 0,
    height: "300px",
    borderRadius: "0px 0px 8px 8px",
    boxShadow: "0px 1px 4px rgba(14, 90, 167, 0.2)",
  },
  serviceTypeContentTitle: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "16px",
    color: COLORS.TEXT_PRIMARY,
  },
  formControlLabel: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: COLORS.TEXT_PRIMARY,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  advanceSlotDaysContainer: {
    margin: "16px 0px 0px 0px",
    padding: 0,
  },
  advanceSlotDaysInput: {
    margin: "8px 0px 0px 0px",
  },
  editDaysButton: {
    margin: 0,
    padding: 0,
  },
  collectionPointGridContainerStyle: {
    margin: 0,
    padding: "16px 0px 0px 0px",
  },
  collectionPointGridItemStyle: {
    margin: "auto 0px auto 16px",
    padding: 0,
  },
  defaultDividerStyle: {
    margin: "12px auto",
    background: COLORS.DOS_BLUE_DARK,
  },
  defaultHolidayGridContainer: {
    margin: "12px 0px 0px 0px",
    padding: 0,
  },
  defaultHolidayGridItem: {
    margin: "8px 0px 0px 0px",
    padding: 0,
  },
  dateSearchBoxOpen: {
    width: "100%",
    height: "40px",
    margin: 0,
    padding: "0 8px",
    border: "1px solid",
    borderColor: COLORS.BLACK_INPUT_BORDER,
    borderRadius: "8px",
    background: "#FFFFFF",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: 500,
  },
  addCalendarDateIconGridItem: {
    margin: "auto",
    padding: 0,
  },
  addCalendarDateIconStyle: {
    margin: 0,
    padding: "0px 0px 0px 8px",
    width: "35px",
    height: "25px",
    color: COLORS.DOS_BLUE_DARK,
    cursor: "pointer",
  },
  deleteCalendarIconStyle: {
    margin: 0,
    padding: "0px 16px 0px 0px",
    width: "35px",
    height: "25px",
    color: COLORS.DOS_BLUE_DARK,
    cursor: "pointer",
  },
  defaultCustomHolidayCalendarGridItem: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  enableServiceTypeGridMain: {
    margin: "0px 0px 16px 0px",
    padding: 0,
    height: "50px",
    display: "flex",
    justifyContent: "flex-end",
    background: COLORS.DOS_BLUE_SUPER_LIGHT,
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
  enableServiceTypeGridMainDisabled: {
    margin: "0px 0px 16px 0px",
    padding: 0,
    height: "50px",
    display: "flex",
    justifyContent: "flex-end",
    background: COLORS.DOS_BLUE_SUPER_LIGHT,
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    opacity: 0.5,
  },
  enableServiceTypeGridConatiner: {
    margin: "auto",
    padding: 0,
  },
  enableServiceTypeGridItem: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "flex-end",
  },
  enableServiceTypeSwitchGridItem: {
    margin: 0,
    padding: "0px 0px 0px 8px",
    display: "flex",
    justifyContent: "flex-start",
  },
  enableServiceTypeGridItemTextStyle: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 700,
    color: COLORS.DOS_BLUE_DARK,
  },
  switchStyle: {},
  disableSwitch: {
    opacity: 0.5,
  },
  applyChangesModalBody: {
    margin: "auto",
    padding: 0,
    top: "80px",
    position: "relative",
    maxWidth: "80vw",
    maxHeight: "90vh",
    border: "none",
    backgroundColor: "white",
    transform: "none",
    textAlign: "center",
    borderRadius: "8px",
    "&:focus": {
      outline: "none",
    },
    overflow: "auto",
    "&:focus": {
      outline: "none",
    },
  },
  modalTitleGridItem: {
    margin: "0px 0px 24px 0px",
    padding: 0,
  },
  modalTitle: {
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: 600,
  },
  modalBody: {
    margin: 0,
    padding: 0,
  },
  serviceTypeGridItem: {
    margin: "0px",
    padding: "8px",
  },
  serviceTypeGridContainer: {
    padding: "8px",
    background: COLORS.DOS_BLUE_SUPER_LIGHT,
    borderRadius: "8px",
  },
  serviceTypeFooterGridItem: {
    margin: 0,
    padding: "16px",
  },
  applyModalBodyTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  serviceTypeHeaderDetailsGridItem: {
    margin: "0px 0px 4px 0px",
    padding: 0,
  },
  serviceTypeDetailsGridItem: {
    margin: "0px 0px 4px 0px",
    padding: 0,
    display: "flex",
    justifyContent: "flex-start",
  },
  applyModalBodyContent: {
    fontSize: "14px",
    fontWeight: 400,
    color: COLORS.BLACK,
  },
  selectedValuesData: {
    fontSize: "14px",
    fontWeight: 600,
    paddingLeft: "16px",
    color: COLORS.BLACK,
  },
  selectedValuesDataTrue: {
    fontSize: "14px",
    fontWeight: 600,
    paddingLeft: "16px",
    color: COLORS.DOS_BLUE_DARK,
  },
  selectedValuesDataFalse: {
    fontSize: "14px",
    fontWeight: 600,
    paddingLeft: "16px",
    color: COLORS.DARK_RED,
  },
  serviceTypeTitle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "45px",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    color: COLORS.TEXT_PRIMARY,
  },
  standardServiceTypeCount: {
    background: "rgba(59, 59, 152, 0.2);",
    width: "66px",
    height: "48px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    color: "#3B3B98",
  },
  expressServiceTypeCount: {
    background: "rgba(227, 135, 15, 0.2)",
    width: "66px",
    height: "48px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    color: "#E3870F",
  },
  cncServiceTypeCount: {
    background: "rgba(132, 61, 119, 0.2)",
    width: "66px",
    height: "48px",
    borderRadius: "100px",
    marginRight: "40px",
    display: "flex",
    justifyContent: "center",
    color: "#843D77",
  },
  defaultServiceTypeCount: {
    background: "rgba(58, 186, 113, 0.2);",
    width: "66px",
    height: "48px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    color: "#3B3B98",
  },
  activeServiceDdnIcon: {
    marginLeft: "8px",
  },
  nextDaySlotVisibility: {
    marginTop: "12px",
  },
  collectionPtCount: {
    background: "#B7D5D2",
    width: "34px",
    height: "34px",
    marginTop: "0px",
    marginLeft: "8px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    color: "#037E73",
    lineHeight: "17px",
    "& >div": {
      fontSize: "14px",
    },
  },
  footerGridContainer: {
    margin: "16px 0px 0px 0px",
  },
  buttonsSection: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  calendarMarginTop: {
    marginTop: "10px !important",
  },
  modalButtons: {
    padding: "24px",
  },
  buttonStyles: {
    minWidth: "100%",
  },
  uploadFileDescription: {
    color: COLORS.DARK_GRAY,
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: 500,
    marginTop: "9px",
  },
  dividerStyle: {
    marginTop: "24px",
  },

  fbcServiceTypeCount: {
    background: "#FFFFFF",
    width: "66px",
    height: "48px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  noServiceItem: {
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  noServiceSpanStyle: {
    margin: "auto 0px",
    fontSize: "14px",
    fontWeight: 600,
    color: COLORS.TUATARA,
  },
}));
