import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as PolygonServiceAPI from "../../../api/PolygonServiceAPI";
import { API_RESPONSE_STATUS } from "../../../config/constants";
import { PayloadObjectInterface } from "../Utils/PlolygonServicesInterface";

export const getPolygonServiceRecordnBasedOnCountryId = createAsyncThunk("plolygonService/fetchPolygonServiceRecordnBasedOnCountryId", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.fetchPolygonServiceRecordnBasedOnCountryId(data);
    if (response !== undefined && response.data !== undefined && typeof response.data === "object") {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchPosZoneServiceTypeListByCountryIdAndServiceType = createAsyncThunk("plolygonService/fetchPosZoneServiceTypeList", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.fetchPosZoneServiceTypeList(data);
    if (response && response.data && response.data.content && Array.isArray(response.data.content)) {
      return response.data.content;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const postPolygonServiceRecordnByPayload = createAsyncThunk("plolygonService/updatePolygonServiceRecordnByPayload", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.updatePolygonServiceRecordnByPayload(data);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPolygonServiceCountBasedOnQuery = createAsyncThunk("plolygonService/fetchPolygonServiceCountBasedOnQuery", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.fetchPolygonServiceCountBasedOnQuery(data);
    if (response && response.data && typeof response.data === "object" && Object.keys(response.data).length > 0) {
      return response.data;
    }
    return {};
  } catch (error) {
    return rejectWithValue(error);
  }
});

/** https://mafretail.atlassian.net/browse/DOS-1895 */
export const getMappedPosZoneListBasedOnServiceTypeAndCountry = createAsyncThunk("plolygonService/fetchMappedPosZoneListBasedOnServiceTypeAndCountry", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.fetchMappedPosZoneListBasedOnServiceTypeAndCountry(data);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

/** https://mafretail.atlassian.net/browse/DOS-1914 */
export const getPolygonDetailsHistoryBasedOnIdServiceTypeAndCountry = createAsyncThunk("plolygonService/fetchPolygonDetailsHistoryBasedOnIdServiceTypeAndCountry", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.fetchPolygonDetailsHistoryBasedOnIdServiceTypeAndCountry(data);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

/** https://mafretail.atlassian.net/browse/DOS-2264 */
export const exportToExcel = createAsyncThunk("plolygonService/exportToExcel", async (data: any, { rejectWithValue }) => {
  try {
    const { response, fileName }: any = await PolygonServiceAPI.fetchExportToExcel(data);
    return { response, fileName };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPolygonServiceMapRecordnBasedOnBoundaries = createAsyncThunk("plolygonService/getPolygonServiceMapRecordnBasedOnBoundaries", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.fetchPolygonServiceMapRecordnBasedOnBoundaries(data);
    if (response !== undefined && response.data !== undefined && Array.isArray(response.data)) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPosZoneRecordPositionDetails = createAsyncThunk("plolygonService/getPosZoneRecordPositionDetails", async (data: any, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.fetchPosZoneRecordPositionDetails(data);
    if (response !== undefined && Array.isArray(response)) {
      return response;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAllPolygonIdsDetails = createAsyncThunk("plolygonService/getAllPolygonIdsDetails", async (_, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.getAllPolygonIdsDetails();
    if (response !== undefined && response.data !== undefined && Array.isArray(response.data) && response.data.length > 0) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createNewPolygonIdConfig = createAsyncThunk("plolygonService/createNewPolygonIdConfig", async (data: PayloadObjectInterface, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.createNewPolygonIdConfig(data);
    if (response !== undefined && response.data !== undefined) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchPolygonIdDetails = createAsyncThunk("plolygonService/fetchPolygonIdDetails", async (polygonId: string, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.fetchPolygonIdDetails(polygonId);
    if (response !== undefined && response.data !== undefined) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePolygonIdConfig = createAsyncThunk("plolygonService/updatePolygonIdConfig", async (data: PayloadObjectInterface, { rejectWithValue }) => {
  try {
    const { response }: any = await PolygonServiceAPI.updatePolygonIdConfig(data);
    if (response !== undefined && response.data !== undefined) {
      return response.data;
    }
    return [];
  } catch (error) {
    return rejectWithValue(error);
  }
});

const commonFetchInitialState = { fetchStatus: API_RESPONSE_STATUS.IDLE, error: "", data: [] as any, loading: false };

const PlolygonServicesSlice = createSlice({
  name: "plolygonService",
  initialState: {
    polygonServiceConfigurationRecord: { ...commonFetchInitialState },
    posZoneListDetailsService: { ...commonFetchInitialState },
    updatePolygonRecordWithPayload: { ...commonFetchInitialState },
    polygonServiceConfigurationCount: { ...commonFetchInitialState },
    mappedPosZoneListBasedOnServiceTypeRecord: { ...commonFetchInitialState },
    polygonDetailsHistoryRecord: { ...commonFetchInitialState },
    polygonExportToExcelRecord: { ...commonFetchInitialState, excelLoading: false },
    polygonServiceMapRecord: { ...commonFetchInitialState },
    posZoneRecordPositionRecord: { ...commonFetchInitialState },
    allPolygonListIdsRecord: { ...commonFetchInitialState },
    specificPolygonIdDetails: { ...commonFetchInitialState },
  },
  reducers: {
    resetPolygonServiceConfigurationServiceState: (state: any): any => {
      state.polygonServiceConfigurationRecord = { ...commonFetchInitialState };
    },
    resetPosZoneListDetailsServiceState: (state: any): any => {
      state.posZoneListDetailsService = { ...commonFetchInitialState };
    },
    resetPolygonServiceCountServiceState: (state: any): any => {
      state.polygonServiceConfigurationCount = { ...commonFetchInitialState };
    },
    resetAllPolygonIdsRecordServiceState: (state: any): any => {
      state.allPolygonListIdsRecord = { ...commonFetchInitialState };
    },
    resetSpecificPolygonIdDetailsState: (state: any): any => {
      state.specificPolygonIdDetails = { ...commonFetchInitialState };
    },
  },
  extraReducers: (builders) => {
    builders
      /* getPolygonServiceRecordnBasedOnCountryId */
      .addCase(getPolygonServiceRecordnBasedOnCountryId.pending, (state: any) => {
        state.polygonServiceConfigurationRecord.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.polygonServiceConfigurationRecord.loading = true;
        state.polygonServiceConfigurationRecord.data = [];
        state.polygonServiceConfigurationRecord.error = "";
      })
      .addCase(getPolygonServiceRecordnBasedOnCountryId.fulfilled, (state: any, action: any) => {
        state.polygonServiceConfigurationRecord.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.polygonServiceConfigurationRecord.loading = false;
        state.polygonServiceConfigurationRecord.data = action.payload;
        state.polygonServiceConfigurationRecord.error = "";
      })
      .addCase(getPolygonServiceRecordnBasedOnCountryId.rejected, (state: any, action: any) => {
        state.polygonServiceConfigurationRecord.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.polygonServiceConfigurationRecord.loading = false;
        state.polygonServiceConfigurationRecord.error = action?.payload?.message || "Error";
      })
      /* fetchPosZoneServiceTypeListByCountryIdAndServiceType */
      .addCase(fetchPosZoneServiceTypeListByCountryIdAndServiceType.pending, (state: any) => {
        state.posZoneListDetailsService.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.posZoneListDetailsService.loading = true;
        state.posZoneListDetailsService.data = [];
        state.posZoneListDetailsService.error = "";
      })
      .addCase(fetchPosZoneServiceTypeListByCountryIdAndServiceType.fulfilled, (state: any, action: any) => {
        state.posZoneListDetailsService.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.posZoneListDetailsService.loading = false;
        state.posZoneListDetailsService.data = action.payload;
      })
      .addCase(fetchPosZoneServiceTypeListByCountryIdAndServiceType.rejected, (state: any, action: any) => {
        state.posZoneListDetailsService.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.posZoneListDetailsService.loading = false;
        state.posZoneListDetailsService.error = action.error.message;
      })
      /* postPolygonServiceRecordnByPayload */
      .addCase(postPolygonServiceRecordnByPayload.pending, (state: any) => {
        state.updatePolygonRecordWithPayload.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.updatePolygonRecordWithPayload.loading = true;
        state.updatePolygonRecordWithPayload.data = [];
        state.updatePolygonRecordWithPayload.error = "";
      })
      .addCase(postPolygonServiceRecordnByPayload.fulfilled, (state: any, action: any) => {
        state.updatePolygonRecordWithPayload.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.updatePolygonRecordWithPayload.loading = false;
        state.updatePolygonRecordWithPayload.data = action.payload;
        state.updatePolygonRecordWithPayload.error = "";
      })
      .addCase(postPolygonServiceRecordnByPayload.rejected, (state: any, action: any) => {
        state.updatePolygonRecordWithPayload.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.updatePolygonRecordWithPayload.loading = false;
        state.updatePolygonRecordWithPayload.error = action?.payload?.message || "Error";
      })
      /* getPolygonServiceCountBasedOnQuery */
      .addCase(getPolygonServiceCountBasedOnQuery.pending, (state: any) => {
        state.polygonServiceConfigurationCount.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.polygonServiceConfigurationCount.loading = true;
        state.polygonServiceConfigurationCount.data = [];
        state.polygonServiceConfigurationCount.error = "";
      })
      .addCase(getPolygonServiceCountBasedOnQuery.fulfilled, (state: any, action: any) => {
        state.polygonServiceConfigurationCount.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.polygonServiceConfigurationCount.loading = false;
        state.polygonServiceConfigurationCount.data = action.payload;
        state.polygonServiceConfigurationCount.error = "";
      })
      .addCase(getPolygonServiceCountBasedOnQuery.rejected, (state: any, action: any) => {
        state.polygonServiceConfigurationCount.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.polygonServiceConfigurationCount.loading = false;
        state.polygonServiceConfigurationCount.error = action?.payload?.message || "Error";
      })
      /* getMappedPosZoneListBasedOnServiceTypeAndCountry */
      .addCase(getMappedPosZoneListBasedOnServiceTypeAndCountry.pending, (state: any) => {
        state.mappedPosZoneListBasedOnServiceTypeRecord.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.mappedPosZoneListBasedOnServiceTypeRecord.loading = true;
        state.mappedPosZoneListBasedOnServiceTypeRecord.data = [];
        state.mappedPosZoneListBasedOnServiceTypeRecord.error = "";
      })
      .addCase(getMappedPosZoneListBasedOnServiceTypeAndCountry.fulfilled, (state: any, action: any) => {
        state.mappedPosZoneListBasedOnServiceTypeRecord.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.mappedPosZoneListBasedOnServiceTypeRecord.loading = false;
        state.mappedPosZoneListBasedOnServiceTypeRecord.data = action.payload;
        state.mappedPosZoneListBasedOnServiceTypeRecord.error = "";
      })
      .addCase(getMappedPosZoneListBasedOnServiceTypeAndCountry.rejected, (state: any, action: any) => {
        state.mappedPosZoneListBasedOnServiceTypeRecord.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.mappedPosZoneListBasedOnServiceTypeRecord.loading = false;
        state.mappedPosZoneListBasedOnServiceTypeRecord.error = action?.payload?.message || "Error";
      })
      /* getPolygonDetailsHistoryBasedOnIdServiceTypeAndCountry */
      .addCase(getPolygonDetailsHistoryBasedOnIdServiceTypeAndCountry.pending, (state: any) => {
        state.polygonDetailsHistoryRecord.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.polygonDetailsHistoryRecord.loading = true;
        state.polygonDetailsHistoryRecord.data = [];
        state.polygonDetailsHistoryRecord.error = "";
      })
      .addCase(getPolygonDetailsHistoryBasedOnIdServiceTypeAndCountry.fulfilled, (state: any, action: any) => {
        state.polygonDetailsHistoryRecord.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.polygonDetailsHistoryRecord.loading = false;
        state.polygonDetailsHistoryRecord.data = action.payload;
        state.polygonDetailsHistoryRecord.error = "";
      })
      .addCase(getPolygonDetailsHistoryBasedOnIdServiceTypeAndCountry.rejected, (state: any, action: any) => {
        state.polygonDetailsHistoryRecord.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.polygonDetailsHistoryRecord.loading = false;
        state.polygonDetailsHistoryRecord.error = action?.payload?.message || "Error";
      })
      /* getPolygonServiceMapRecordnBasedOnBoundaries */
      .addCase(getPolygonServiceMapRecordnBasedOnBoundaries.pending, (state: any) => {
        state.polygonServiceMapRecord.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.polygonServiceMapRecord.loading = true;
        state.polygonServiceMapRecord.data = [];
        state.polygonServiceMapRecord.error = "";
      })
      .addCase(getPolygonServiceMapRecordnBasedOnBoundaries.fulfilled, (state: any, action: any) => {
        state.polygonServiceMapRecord.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.polygonServiceMapRecord.loading = false;
        state.polygonServiceMapRecord.data = action.payload;
        state.polygonServiceMapRecord.error = "";
      })
      .addCase(getPolygonServiceMapRecordnBasedOnBoundaries.rejected, (state: any, action: any) => {
        state.polygonServiceMapRecord.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.polygonServiceMapRecord.loading = false;
        state.polygonServiceMapRecord.error = action?.payload?.message || "Error";
      })
      /**getPosZoneRecordPositionDetails */
      .addCase(getPosZoneRecordPositionDetails.pending, (state: any) => {
        state.posZoneRecordPositionRecord.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.posZoneRecordPositionRecord.loading = true;
        state.posZoneRecordPositionRecord.data = [];
        state.posZoneRecordPositionRecord.error = "";
      })
      .addCase(getPosZoneRecordPositionDetails.fulfilled, (state: any, action: any) => {
        state.posZoneRecordPositionRecord.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.posZoneRecordPositionRecord.loading = false;
        state.posZoneRecordPositionRecord.data = action.payload;
        state.posZoneRecordPositionRecord.error = "";
      })
      .addCase(getPosZoneRecordPositionDetails.rejected, (state: any, action: any) => {
        state.posZoneRecordPositionRecord.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.posZoneRecordPositionRecord.loading = false;
        state.posZoneRecordPositionRecord.error = action?.payload?.message || "Error";
      })
      /**getAllPolygonIdsDetails */
      .addCase(getAllPolygonIdsDetails.pending, (state: any) => {
        state.allPolygonListIdsRecord.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.allPolygonListIdsRecord.loading = true;
        state.allPolygonListIdsRecord.data = [];
        state.allPolygonListIdsRecord.error = "";
      })
      .addCase(getAllPolygonIdsDetails.fulfilled, (state: any, action: any) => {
        state.allPolygonListIdsRecord.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.allPolygonListIdsRecord.loading = false;
        state.allPolygonListIdsRecord.data = action.payload;
        state.allPolygonListIdsRecord.error = "";
      })
      .addCase(getAllPolygonIdsDetails.rejected, (state: any, action: any) => {
        state.allPolygonListIdsRecord.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.allPolygonListIdsRecord.loading = false;
        state.allPolygonListIdsRecord.error = action?.payload?.message || "Error";
      })
      /**fetchPolygonIdDetails */
      .addCase(fetchPolygonIdDetails.pending, (state: any) => {
        state.specificPolygonIdDetails.fetchStatus = API_RESPONSE_STATUS.LOADING;
        state.specificPolygonIdDetails.loading = true;
        state.specificPolygonIdDetails.data = [];
        state.specificPolygonIdDetails.error = "";
      })
      .addCase(fetchPolygonIdDetails.fulfilled, (state: any, action: any) => {
        state.specificPolygonIdDetails.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
        state.specificPolygonIdDetails.loading = false;
        state.specificPolygonIdDetails.data = action.payload;
        state.specificPolygonIdDetails.error = "";
      })
      .addCase(fetchPolygonIdDetails.rejected, (state: any, action: any) => {
        state.specificPolygonIdDetails.fetchStatus = API_RESPONSE_STATUS.FAILED;
        state.specificPolygonIdDetails.loading = false;
        state.specificPolygonIdDetails.error = action?.payload?.message || "Error";
      });
  },
});

export default PlolygonServicesSlice.reducer;

export const { resetPolygonServiceConfigurationServiceState, resetPosZoneListDetailsServiceState, resetPolygonServiceCountServiceState, resetAllPolygonIdsRecordServiceState, resetSpecificPolygonIdDetailsState } = PlolygonServicesSlice.actions;

export const selectPolygonServiceConfigurationRecord = (state: any) => state.plolygonService.polygonServiceConfigurationRecord;
export const selectServiceTypePosZoneMapRecord = (state: any) => state.plolygonService.posZoneListDetailsService;
export const selectPolygonServiceCountData = (state: any) => state.plolygonService.polygonServiceConfigurationCount;
export const selectPolygonExportToExcelRecord = (state: any) => state.plolygonService.polygonExportToExcelRecord;
export const selectAllIdsPolygonRecord = (state: any) => state.plolygonService.allPolygonListIdsRecord;
export const selectSpecificPolygonIdDetails = (state: any) => state.plolygonService.specificPolygonIdDetails;
