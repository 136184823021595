import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, Typography } from "@material-ui/core";

import { ALERT_TYPES, API_RESPONSE_STATUS, CURRENT_COUNTRY, USER_TYPES, APPROVAL_FLOW_ACTIONS, APP_ROUTES, REQUESTER_COMMENT, APPROVER_COMMENT } from "../../config/constants";
import { userStyles } from "./Styles/ApprovalRequestRecordStyle";

import Loader from "../common/Loader/Loader";
import PrimaryButton from "../common/PrimaryButton/PrimaryButton";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import ApprovalRequestSlotDataDetailsPage from "./common/ApprovalRequestSlotDataDetailsPage";
import CustomTextArea from "../common/TextBoxWithLabel/CustomTextArea";

import { fetchOneApprovalRequestRecordBasedOnReuestId, fetchPendingApprovalRequest, fetchSelectedApprovalRequestRecord, generateApprovalRequestService, selectApprovalRecordBasedOnRequestIdServices } from "./Redux/ApprovalRequestSlice";
import { ApprovalCongigurationDetailsHeading, design_approval_request_record_based_on_request_id_util } from "./utils/ApprovalRequestRecordUtils";
import { selectUserDetails } from "../Login/redux/userSlice";
import { ExpandMoreAccordianIcon } from "components/common/App/AppViewUtils";
import { isEmptyOrNull, isNonEmptyObject, isUndefined } from "../../utils/DataUtils";
import { APPROVAL_RECORD_TEXT_CONSTANT } from "./common/ApprovalRequestConstant";
import { serviceSelectPropositionRecord } from "../../config/redux/configurationsSlice";

const ApprovalRequestDetailsPage = () => {
  const classes = userStyles();

  const history = useHistory();
  let location = useLocation<any>();
  const dispatch = useDispatch();

  const approvalRequestRecord = useSelector(selectApprovalRecordBasedOnRequestIdServices);
  const userInfo: any = useSelector(selectUserDetails);
  const selectedApprovalRequestRecord = useSelector(fetchSelectedApprovalRequestRecord);
  const servicePropositionList = useSelector(serviceSelectPropositionRecord);

  const [detailsState, setDetailsState] = useState({
    requestedId: "",
    posNo: "",
    storeName: "",
    zoneName: "",
    serviceType: "",
    approvalStatus: "",
    countryId: "",
    loader: false,
    recordData: {},
    approverComment: "",
  }) as any;

  const design_approval_request_record_based_on_request_id = () => {
    const query: any = new URLSearchParams(location.search);
    let currentCountry: any = localStorage.getItem(CURRENT_COUNTRY);
    let countryId: any = "";
    if (currentCountry) {
      countryId = JSON.parse(currentCountry).countryId;
    }
    let recordData = {} as any;
    if (approvalRequestRecord !== undefined) {
      if (approvalRequestRecord.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED) {
        recordData = design_approval_request_record_based_on_request_id_util(approvalRequestRecord.data);
      }

      if (approvalRequestRecord.fetchStatus === API_RESPONSE_STATUS.FAILED) {
        CustomAlert(ALERT_TYPES.ERROR, APPROVAL_RECORD_TEXT_CONSTANT.ERROR_WHILE_FETCHING_DETAILS);
      }

      setDetailsState({
        ...detailsState,
        recordData: recordData,
        countryId: countryId,
        posNo: query.get("posNo"),
        zoneName: query.get("zoneName"),
        requestedId: query.get("requestedId"),
        serviceType: query.get("serviceType"),
        approvalStatus: query.get("approvalStatus"),
        storeName: query.get("storeName"),
        loader: false,
      });
    }
  };

  const generateApprovalRequestAction = async (action: any) => {
    const payload_Object = { action: action, requestedId: detailsState.requestedId, payload: { approverComment: "" } } as any;
    if (!isUndefined(detailsState?.approverComment) && !isEmptyOrNull(detailsState?.approverComment)) {
      payload_Object.payload.approverComment = detailsState.approverComment;
    }
    const { payload }: any = await dispatch(generateApprovalRequestService(payload_Object));
    if (payload && payload.response !== undefined && payload.response.data === "success") {
      CustomAlert(ALERT_TYPES.SUCCESS, `Requested configuration successfully ${action || "n/a"}`);
      dispatch(fetchPendingApprovalRequest({ countryId: detailsState.countryId, status: ["PENDING"] }));
      history.push(APP_ROUTES.APPROVAL_REQUESTS);
    } else {
      setDetailsState({ ...detailsState, loader: false });
      let errorMessage = APPROVAL_RECORD_TEXT_CONSTANT.ERROR_OCCURRED;
      if (payload.message && payload.message !== APPROVAL_RECORD_TEXT_CONSTANT.NO_MESSAGE_AVAILABLE) {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    }
  };

  const handleCommentInputChange = (event: any) => {
    if (event && event.target) {
      const { value } = event.target;
      if (!isUndefined(value?.length) && value?.length > 200) {
        return;
      } else {
        setDetailsState({ ...detailsState, approverComment: value });
      }
    }
  };

  const rejectApprovalRequest = () => {
    if (!isUndefined(detailsState?.requestedId) && !isEmptyOrNull(detailsState?.requestedId)) {
      setDetailsState({ ...detailsState, loader: true });
      generateApprovalRequestAction(APPROVAL_FLOW_ACTIONS.REJECTED);
    }
  };

  const approveApprovalRequest = () => {
    if (!isUndefined(detailsState?.requestedId) && !isEmptyOrNull(detailsState?.requestedId)) {
      setDetailsState({ ...detailsState, loader: true });
      generateApprovalRequestAction(APPROVAL_FLOW_ACTIONS.APPROVED);
    }
  };

  const getCommentValueFromSliceObj = (commentName: any) => {
    if (!isUndefined(commentName) && !isEmptyOrNull(commentName)) {
      if (!isUndefined(selectedApprovalRequestRecord?.data?.requestId)) {
        switch (commentName) {
          case REQUESTER_COMMENT:
            return `${selectedApprovalRequestRecord.data.requesterComment || "-"}`;
          case APPROVER_COMMENT:
            return `${selectedApprovalRequestRecord.data.approverComment || "-"}`;
        }
      }
    }
    return false;
  };

  const getStatusClass = () => {
    if (detailsState?.approvalStatus === APPROVAL_FLOW_ACTIONS.APPROVED) {
      return classes.approverDetailsStatusSpanStyleApproved;
    } else if (detailsState.approvalStatus === APPROVAL_FLOW_ACTIONS.REJECTED) {
      return classes.approverDetailsStatusSpanStyleRejected;
    }
    return classes.approverDetailsStatusSpanStylePending;
  };

  const isRequestInPendingStatus = () => {
    return !isUndefined(detailsState?.approvalStatus) && !isEmptyOrNull(detailsState?.approvalStatus) && detailsState?.approvalStatus === APPROVAL_FLOW_ACTIONS.PENDING;
  };

  const isUserRoleAllowedToReject = (role: string): boolean => {
    switch (role) {
      case USER_TYPES.ADMIN:
      case USER_TYPES.APPROVER:
      case USER_TYPES.MAF_ADMIN:
      case USER_TYPES.SUPER_ADMIN:
        return true;
    }
    return false;
  };

  const isUserRoleAllowedToApprove = (role: string): boolean => {
    switch (role) {
      case USER_TYPES.APPROVER:
      case USER_TYPES.MAF_ADMIN:
      case USER_TYPES.SUPER_ADMIN:
        return true;
    }
    return false;
  };

  const isUserCanApproveRejectRequest = () => {
    if (isRequestInPendingStatus() && !isUndefined(userInfo?.userProfile?.role) && !isEmptyOrNull(userInfo?.userProfile?.role) && isUserRoleAllowedToApprove(userInfo.userProfile.role)) {
      return true;
    }
    return false;
  };

  const isUserCanApproveRequest = () => {
    if (isRequestInPendingStatus() && !isUndefined(userInfo?.userProfile?.role) && !isEmptyOrNull(userInfo?.userProfile?.role) && isUserRoleAllowedToApprove(userInfo.userProfile.role)) {
      return true;
    }
    return false;
  };

  const isUserCanRejectRequest = () => {
    if (isRequestInPendingStatus() && !isUndefined(userInfo?.userProfile?.role) && !isEmptyOrNull(userInfo?.userProfile?.role) && isUserRoleAllowedToReject(userInfo?.userProfile?.role)) {
      return true;
    }
    return false;
  };

  const isUserAdmin = () => {
    return !isUndefined(userInfo?.userProfile?.role) && !isEmptyOrNull(userInfo?.userProfile?.role) && userInfo?.userProfile?.role === USER_TYPES.ADMIN;
  };

  const renderRejectButton = () => {
    return <PrimaryButton className={classes.rejectButtonConfigStyle} buttonLabel={`${APPROVAL_RECORD_TEXT_CONSTANT.REJECT}`} disableFocusRipple={true} disableRipple={true} onClick={rejectApprovalRequest} />;
  };

  const renderApproveButton = () => {
    return <PrimaryButton className={classes.approveButtonConfigStyle} buttonLabel={`${APPROVAL_RECORD_TEXT_CONSTANT.APPROVE}`} disableFocusRipple={true} disableRipple={true} onClick={approveApprovalRequest} />;
  };

  const renderFooterActionButtons = () => {
    return (
      <>
        {isRequestInPendingStatus() ? (
          <>
            <Grid item className={classes.approveButtonConfigDivStyle}>
              {isUserCanApproveRequest() ? renderApproveButton() : ""}
            </Grid>
            <Grid item className={classes.approveButtonConfigDivStyle}>
              {isUserCanRejectRequest() ? renderRejectButton() : ""}
            </Grid>
          </>
        ) : (
          <Grid item xs={6}>
            {""}
          </Grid>
        )}
      </>
    );
  };

  const renderFooter = () => {
    return (
      <Grid container spacing={0} className={classes.footerButtonMainDiv} justifyContent="space-between">
        <Grid item>
          <PrimaryButton
            className={classes.cancelConfigStyle}
            buttonLabel={`${APPROVAL_RECORD_TEXT_CONSTANT.BACK_TO_LIST}`}
            disableFocusRipple={true}
            disableRipple={true}
            onClick={() => {
              history.push("/approval-flow-request");
            }}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={1} justifyContent="flex-end">
            {renderFooterActionButtons()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    const query: any = new URLSearchParams(location.search);
    let requestedId = query.get(APPROVAL_RECORD_TEXT_CONSTANT.REQUESTED_ID);
    if (!isUndefined(requestedId) && !isEmptyOrNull(requestedId)) {
      let currentCountry: any = localStorage.getItem(CURRENT_COUNTRY);
      let countryId: any = "";
      if (currentCountry) {
        countryId = JSON.parse(currentCountry).countryId;
      }
      setDetailsState({ ...detailsState, loader: true });
      dispatch(fetchOneApprovalRequestRecordBasedOnReuestId({ countryId, requestedId }));
    } else {
      history.push("/approval-flow-request");
    }
  }, [location.search]);

  useEffect(() => {
    design_approval_request_record_based_on_request_id();
  }, [approvalRequestRecord, approvalRequestRecord.fetchStatus]);

  return (
    <div className={classes.container}>
      <Grid container justifyContent="space-between">
        <Typography className={classes.heading}>{`${APPROVAL_RECORD_TEXT_CONSTANT.TITLE}`}</Typography>
      </Grid>

      {detailsState.loader ? (
        <Loader />
      ) : (
        <>
          <Card className={classes.configApproverRequestDetailsMainDiv}>
            <Grid container spacing={1}>
              <ApprovalCongigurationDetailsHeading xs={2} classes={classes} headingName={`${APPROVAL_RECORD_TEXT_CONSTANT.POS_NO}`} headingValue={`${detailsState.posNo || "-"}`} />
              <ApprovalCongigurationDetailsHeading xs={3} classes={classes} headingName={`${APPROVAL_RECORD_TEXT_CONSTANT.POS_NAME}`} headingValue={`${detailsState.storeName || "-"}`} />
              <ApprovalCongigurationDetailsHeading xs={2} classes={classes} headingName={`${APPROVAL_RECORD_TEXT_CONSTANT.ZONE_NAME}`} headingValue={`${detailsState.zoneName || "-"}`} />
              <ApprovalCongigurationDetailsHeading xs={2} classes={classes} headingName={`${APPROVAL_RECORD_TEXT_CONSTANT.SERVICE_TYPE}`} headingValue={`${detailsState.serviceType || "-"}`} />
              <ApprovalCongigurationDetailsHeading xs={3} classes={classes} headingName={`${APPROVAL_RECORD_TEXT_CONSTANT.REQUESTER_NAME}`} headingValue={`${selectedApprovalRequestRecord?.data?.requester || "-"}`} />
              <ApprovalCongigurationDetailsHeading xs={2} classes={classes} headingName={`${APPROVAL_RECORD_TEXT_CONSTANT.REQUESTED_DATE}`} headingValue={`${selectedApprovalRequestRecord?.data?.requestDate || "-"}`} />
              <Grid item className={classes.approverDetailsMainHeadingDivStyle}>
                <Grid container spacing={0}>
                  <Grid item xs={12} className={classes.approverDetailsHeadingDivStyle}>
                    <Typography className={classes.commonApproverDetailsHeadingSpanStyle}>{`${APPROVAL_RECORD_TEXT_CONSTANT.STATUS}`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={getStatusClass()}>{`${detailsState.approvalStatus || "-"}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!isRequestInPendingStatus() && (
                <>
                  <ApprovalCongigurationDetailsHeading xs={3} classes={classes} headingName={`${APPROVAL_RECORD_TEXT_CONSTANT.RESPONDER_NAME}`} headingValue={`${selectedApprovalRequestRecord?.data?.actionTakenBy || "-"}`} />
                  <ApprovalCongigurationDetailsHeading xs={2} classes={classes} headingName={`${APPROVAL_RECORD_TEXT_CONSTANT.RESPONSE_DATE}`} headingValue={`${selectedApprovalRequestRecord?.data?.actionDate || "-"}`} />
                </>
              )}
            </Grid>
            {!isUndefined(detailsState?.recordData) && isNonEmptyObject(detailsState.recordData) ? (
              <>
                <Grid container spacing={0} className={classes.slotBoxGridContainerStyle}>
                  {Object.keys(detailsState.recordData).map((slot_data_object: any, slot_data_index: number) => (
                    <Grid item xs={6} key={`${slot_data_index}-slot_data_index-recordData-detailsState`} className={slot_data_index === 0 ? classes.slotBoxGridItemStyle : ""}>
                      <ApprovalRequestSlotDataDetailsPage
                        classes={classes}
                        slot_data_object_key={slot_data_object}
                        slot_data_object={detailsState.recordData[slot_data_object]}
                        serviceTypeName={detailsState.serviceType}
                        slotBoxClass={isRequestInPendingStatus() ? classes.slotsGridContainerDetailsStyleWithMessage : classes.slotsGridContainerDetailsStyle}
                        servicePropositionList={servicePropositionList}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} className={classes.messageGridItemStyle}>
                    <Accordion defaultExpanded={true} className={classes.accordionStyle}>
                      <AccordionSummary expandIcon={<ExpandMoreAccordianIcon />} className={classes.accordionSummaryStyle}>
                        <Grid container alignItems="center">
                          <Grid>
                            <Typography className={classes.accordionTitle}>{`${APPROVAL_RECORD_TEXT_CONSTANT.ACCORDIAN_TITLE_COMMENT}`}</Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetailsStyle}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <ApprovalCongigurationDetailsHeading xs={12} classes={classes} headingName={REQUESTER_COMMENT} headingValue={`${getCommentValueFromSliceObj(REQUESTER_COMMENT) || "-"}`} />
                          </Grid>
                          <Grid item xs={6}>
                            {isRequestInPendingStatus() ? (
                              isUserCanApproveRejectRequest() ? (
                                <Grid item xs={12} className={classes.approverDetailsMainHeadingDivStyle}>
                                  <Grid container spacing={0}>
                                    <Grid item xs={12} className={classes.approverDetailsHeadingDivStyle}>
                                      <Typography className={classes.approverDetailsHeadingSpanStyle}>{`${APPROVER_COMMENT}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <CustomTextArea
                                        label={""}
                                        name={"requesterComments"}
                                        value={detailsState.approverComment}
                                        placeholderText={`${APPROVAL_RECORD_TEXT_CONSTANT.COMMENT_TEXT_BOX_PLACE_HOLDER}`}
                                        handleChange={handleCommentInputChange}
                                        disabled={false}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : (
                                <></>
                              )
                            ) : (
                              <ApprovalCongigurationDetailsHeading xs={12} classes={classes} headingName={APPROVER_COMMENT} headingValue={`${getCommentValueFromSliceObj(APPROVER_COMMENT) || "-"}`} />
                            )}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container spacing={0} className={classes.slotBoxGridContainerStyle}>
                <Grid item xs={12}>
                  <Typography>{`${APPROVAL_RECORD_TEXT_CONSTANT.NO_DATA_AVAILABLE}`}</Typography>
                </Grid>
              </Grid>
            )}
          </Card>
          {renderFooter()}
        </>
      )}
    </div>
  );
};

export default React.memo(ApprovalRequestDetailsPage);
