import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import moment from "moment";

import { Card, CardContent, Grid, Paper, Typography } from "@material-ui/core";

import previewModalStyles from "components/ServiceAndSlots/PreviewModal/PreviewModalStyles";

import { ALERT_TYPES, AREA_POS_MAPPING_TYPE, SERVICE_TYPES, STATUS_OK, DELIVERY_TYPES } from "../../config/constants";
import { fetchServiceInActiveStatus, selectAllCountries } from "../../config/redux/configurationsSlice";
import { ReactComponent as CloseButton } from "../assets/svg/closeButton.svg";
import { serviceSelectPropositionRecord } from "../../config/redux/configurationsSlice.js";

import ModalContainer from "base/ModalContainer";
import ConfirmModal from "../common/ConfirmModal/ConfirmModal";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import Loader from "../common/Loader/Loader";

import { countrySettingsStyles } from "./CountrySettingsStyles";
import { ClickCollectInitConfig, countrySettingsDetailsPayloadNew, DEFAULT_DAY_BASED_OBJ, DEFAULT_MINUTE_BASED_OBJ, DEFAULT_SLOT_BASED_OBJ, ExpressInitConfig, FBCInitialConfig, getDateFromPicker, getHolidaysListObject } from "./CountrySettingsUtils";
import { PosTabInfo } from "./CountrySettingView";
import { CountryChangesConfirmationModal } from "./modal/CountryChangesConfirmationModal";
import { createCountrySettings, fetchActivePOSAndServiceTypesCount, fetchCountrySettings, updateCountrySettings } from "./redux/countrySettingsSlice";
import { isArrayValid, isUndefined } from "../../utils/DataUtils";

import CountryMappingTypeView from "./common/CountryMappingTypeView";
import CountrySettingsFooter from "./common/CountrySettingsFooter";
import RenderStandardServiceTypeDetails from "./common/RenderStandardServiceTypeDetails";
import RenderExpressServiceTypeDetails from "./common/RenderExpressServiceTypeDetails";
import RenderClickAndCollectServiceTypeDetails from "./common/RenderClickAndCollectServiceTypeDetails";
import RenderDefaultTypeDetails from "./common/RenderDefaultTypeDetails";
import RenderFBCTypeDetails from "./common/RenderFBCTypeDetails";

const CountrySettings = () => {
  const { countryName } = useParams();

  const classes = countrySettingsStyles();
  const dispatch = useDispatch();

  const {
    countrySettings: {
      posCount,
      fetchingPosCount,
      loading,
      countryDetails: { data: savedCountryDetails },
    },
  } = useSelector((state) => state);
  const servicePropositionList = useSelector(serviceSelectPropositionRecord);
  const allCountries = useSelector(selectAllCountries);

  const [configs, setConfigs] = useState({});

  const { countryId } = allCountries.length ? allCountries.find((country) => country.name === countryName) : {};

  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [selectedDeliveryType, setSelectedDeliveryType] = useState("");
  const [expressType, setExpressType] = useState("");

  const [expressDetails, setExpressDetails] = useState(ExpressInitConfig);
  const [clickCollectDetails, setClickCollectDetails] = useState(ClickCollectInitConfig);
  const [fbcDetails, setFBCDetails] = useState(FBCInitialConfig);

  const [showApplyChangesModal, setShowApplyChangesModal] = useState(false);
  const [showExpressTypeChangeConfirmModal, setShowExpressTypeChangeConfirmModal] = useState(false);

  const [showInfoModal, setShowInfoModal] = useState({ show: false, duplicates: [], oldDates: false });
  const [defaultDateState, setDefaultDateState] = useState({ dateFrom: "", dateTo: "", dateLabel: "" });

  const [areaPosMappingType, setAreaPosMappingType] = useState(AREA_POS_MAPPING_TYPE.POLYGON_BASED);

  const combinedResponse = useMemo(() => {
    return servicePropositionList.map((service) => {
      const count = posCount[service.name] || 0;
      return { ...service, count };
    });
  }, [servicePropositionList, posCount]);

  // Sort combinedResponse based on rank
  const sortedCombinedResponse = useMemo(() => {
    return [...combinedResponse].sort((a, b) => a.rank - b.rank);
  }, [combinedResponse]);

  const handleServiceTypeDetails = (service) => {
    const { deliveryType, name } = service;
    setSelectedServiceType(name.toUpperCase());
    setSelectedDeliveryType(deliveryType);
  };

  const handleAdvanceDaysClick = (serviceType) => {
    setConfigs((prevConfigs) => ({ ...prevConfigs, [serviceType]: { ...prevConfigs[serviceType], showAdvanceSlotDaysInput: true } }));
  };

  const handleSlotBasedDetailChanges = (serviceType, event) => {
    const { name, value } = event?.target;
    let newValue = value === "true" || value === true;
    setConfigs((prevConfigs) => {
      const updatedConfig = { ...prevConfigs[serviceType], [name]: newValue };
      if (name === "standardAdvanceSlotDays") {
        updatedConfig.editSlotDays = value;
      }
      if (name === "standardNextDaySlotVisibility") {
        updatedConfig.nextDaySlotVisibility = newValue;
      }
      return { ...prevConfigs, [serviceType]: updatedConfig };
    });
  };

  const handleMinuteBasedChanges = (serviceType, event) => {
    const { name, value } = event?.target;
    let newValue = value === "true";
    setConfigs((prevConfigs) => {
      const updatedConfig = { ...prevConfigs[serviceType], [name]: newValue };
      if (name === "expressNextDaySlotVisibility") {
        updatedConfig.nextDaySlotVisibility = newValue;
      } else if (name === "expressType") {
        updatedConfig.expressType = value;
      } else if (name === "expressAdvanceSlotDays") {
        updatedConfig.editSlotDays = value;
      }
      return { ...prevConfigs, [serviceType]: updatedConfig };
    });
  };

  const handleExpressTypeChangeApprove = () => {
    setExpressDetails({ ...expressDetails, expressType });
    setShowExpressTypeChangeConfirmModal(false);
  };

  const handleClickAndCollectDetailsChange = (event) => {
    const { name, value } = event?.target;
    let newValue = value === "true";
    setClickCollectDetails({ ...clickCollectDetails, [name]: newValue });
    switch (name) {
      case "cncNextDaySlotVisibility":
        setClickCollectDetails({ ...clickCollectDetails, nextDaySlotVisibility: newValue });
        break;
      case "cncAdvanceSlotDays":
        setClickCollectDetails({ ...clickCollectDetails, editSlotDays: value });
        break;
      default:
        return "";
    }
  };

  const handleApplyChangesModalClose = () => {
    setShowApplyChangesModal(false);
  };

  const handleApplyChangesClick = () => {
    setShowApplyChangesModal(true);
  };

  const handleSuccessPut = () => {
    setShowApplyChangesModal(false);
    dispatch(fetchCountrySettings({ countryId }));
  };

  const handleSubmit = async () => {
    let requestPayload = { countryId, countryData: countrySettingsDetailsPayloadNew(countryName, configs, areaPosMappingType) };
    if (!(savedCountryDetails && Object.keys(savedCountryDetails).length)) {
      const { payload } = await dispatch(createCountrySettings(requestPayload));
      if (payload?.status === STATUS_OK) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Country Settings saved successfully");
        dispatch(fetchServiceInActiveStatus(countryId));
        handleSuccessPut();
      } else if (payload?.message) {
        CustomAlert(ALERT_TYPES.ERROR, "Error while creating country settings.");
      }
    } else {
      const { payload } = await dispatch(updateCountrySettings(requestPayload));
      if (payload?.status === STATUS_OK) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Country Settings updated successfully");
        dispatch(fetchServiceInActiveStatus(countryId));
        handleSuccessPut();
      } else if (payload?.message) {
        CustomAlert(ALERT_TYPES.ERROR, "Error while updating country settings.");
      }
    }
  };

  const handleDateChangeEvent = (event, picker) => {
    if (event && event.target) {
      if (event.target.name === "date") {
        const dateStart = getDateFromPicker(picker.startDate);
        const dateEnd = getDateFromPicker(picker.endDate);
        let label = "";
        label = dateStart === dateEnd ? dateStart : dateStart + " - " + dateEnd;
        setDefaultDateState({ dateFrom: dateStart, dateTo: dateEnd, dateLabel: label });
      }
    }
  };

  const handleCalendarHoliday = async (event, type, label, serviceType) => {
    if (!label || label.trim().length === 0) {
      return;
    }
    const dateFrom = new Date(defaultDateState.dateFrom);
    const dateTo = new Date(defaultDateState.dateTo);
    const currentDate = new Date();

    if (type === "ADD") {
      if (dateFrom <= currentDate || dateTo <= currentDate) {
        setShowInfoModal({ show: true, duplicates: [], oldDates: true });
        return;
      }
      let list = await getHolidaysListObject(defaultDateState);
      let arr = list.map((item) => item.objectDate);
      let allDates = [...arr, ...configs[serviceType].holidays.map((holiday) => holiday.offDate || holiday.offDay)];
      let duplicate = allDates.filter((e, i, a) => a.indexOf(e) !== i);
      if (duplicate && duplicate.length > 0) {
        setShowInfoModal({ show: true, duplicates: duplicate, oldDates: false });
        return;
      }

      setConfigs((prevConfigs) => {
        const updatedHolidays = [...prevConfigs[serviceType].holidays, defaultDateState];
        return { ...prevConfigs, [serviceType]: { ...prevConfigs[serviceType], holidays: updatedHolidays } };
      });
      setDefaultDateState({ dateFrom: "", dateTo: "", dateLabel: "" });
    }

    if (type === "REMOVE") {
      setConfigs((prevConfigs) => {
        const updatedHolidays = prevConfigs[serviceType].holidays.filter((item) => {
          return item.dateLabel !== label && item.offDate !== label;
        });
        return { ...prevConfigs, [serviceType]: { ...prevConfigs[serviceType], holidays: updatedHolidays } };
      });
    }
  };

  const handleCancel = (event, picker) => {
    event.preventDefault();
    picker.element.val("");
    setDefaultDateState({ dateFrom: "", dateTo: "", dateLabel: "" });
  };

  const handleBagChargeChange = (event, serviceType) => {
    const { value } = event?.target;
    let newValue = value === "true" || value === true;
    if (serviceType) {
      setConfigs((prevConfigs) => ({ ...prevConfigs, [serviceType]: { ...prevConfigs[serviceType], bagChargeEnabled: newValue } }));
    }
  };

  const handleServiceTypeActiveChange = (event, serviceTypeName) => {
    if (serviceTypeName !== undefined && serviceTypeName !== "") {
      setConfigs((prevConfigs) => ({ ...prevConfigs, [serviceTypeName]: { ...prevConfigs[serviceTypeName], serviceActive: !prevConfigs[serviceTypeName].serviceActive } }));
    }
  };

  const updateCountryDetailsInfo = () => {
    const { areaPosMappingType, serviceTypes: savedServiceTypes } = savedCountryDetails;
    const initialConfigs = {};
    if (Array.isArray(savedServiceTypes) && savedServiceTypes.length > 0) {
      sortedCombinedResponse.forEach((item) => {
        const matchingServiceType = savedServiceTypes.find((serviceType) => serviceType.serviceTypeID === item.id);
        if (matchingServiceType) {
          item.config = matchingServiceType;
          initialConfigs[item.name] = matchingServiceType;
          if (item?.deliveryType && item.deliveryType === DELIVERY_TYPES.DAY_BASED && !isUndefined(matchingServiceType.holidays) && isArrayValid(matchingServiceType.holidays)) {
            const calHolidays = [];
            const weekHolidays = [];
            const holidayDates = [];
            matchingServiceType.holidays.forEach((holiday) => {
              if (holiday.offDay) {
                weekHolidays.push(holiday.offDay);
              } else if (holiday.offDate) {
                const actualDate = moment(holiday.offDate).format("YYYY-MM-DD");
                holidayDates.push(actualDate);
                calHolidays.push({ dateFrom: actualDate, dateTo: actualDate, dateLabel: actualDate });
              }
            });
            initialConfigs[item.name] = { ...matchingServiceType, weeklyHolidays: weekHolidays };
          }
        } else {
          switch (item.deliveryType) {
            case DELIVERY_TYPES.DAY_BASED:
              initialConfigs[item.name] = { ...DEFAULT_DAY_BASED_OBJ, serviceTypeID: item.id };
              break;
            case DELIVERY_TYPES.MINUTE_BASED:
              initialConfigs[item.name] = { ...DEFAULT_MINUTE_BASED_OBJ, serviceTypeID: item.id };
              break;
            case DELIVERY_TYPES.SLOT_BASED:
              initialConfigs[item.name] = { ...DEFAULT_SLOT_BASED_OBJ, serviceTypeID: item.id };
              break;
            default:
              break;
          }
        }
      });
    }
    setConfigs(initialConfigs);
    setAreaPosMappingType(areaPosMappingType);
  };

  const handleDetailsChange = (event, serviceType) => {
    if (event !== undefined) {
      setConfigs((prevConfigs) => ({ ...prevConfigs, [serviceType]: { ...prevConfigs[serviceType], weeklyHolidays: [...event] } }));
    }
  };

  useEffect(() => {
    if (savedCountryDetails !== undefined && typeof savedCountryDetails === "object" && Object.keys(savedCountryDetails).length > 0) {
      updateCountryDetailsInfo();
    }
  }, [savedCountryDetails]);

  useEffect(() => {
    if (allCountries.length) {
      dispatch(fetchCountrySettings({ countryId }));
      // dispatch(fetchAllRegionsOfCountry(countryId));
      // dispatch(fetchAllAreasOfCountry(countryId));
    }
  }, [dispatch, countryId, allCountries.length]);

  useEffect(() => {
    dispatch(fetchActivePOSAndServiceTypesCount({ country: countryName }));
  }, [dispatch, countryName]);

  return (
    <div className={classes.countrySection}>
      <Typography className={classes.title}>{`Country Setting - ${countryName}`}</Typography>

      {fetchingPosCount ? (
        <Loader />
      ) : (
        <>
          <Paper className={classes.posDetailsPaperStyle}>
            <Grid container spacing={0}>
              <Grid item sx={{ flex: 1 }} className={classes.activePOSGridItem}>
                <Grid container spacing={0} className={classes.activePOSGridInnerContainer} justifyContent="space-between">
                  <Grid item className={classes.activePOSGridInnerItem}>
                    <Typography className={classes.activePosCount}>{`Active POS`}</Typography>
                  </Grid>
                  <Grid item className={classes.activePOSGridInnerItem}>
                    <Typography className={classes.posCountNo}>{posCount.ALL}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {sortedCombinedResponse.map((element, index) => (
                <Grid item key={index} onClick={() => handleServiceTypeDetails(element)} className={`${classes.serviceTab} ${selectedServiceType === element.name ? classes.serviceTypeDetailsSectionActive : ""}`} sx={{ flex: 1 }}>
                  <PosTabInfo element={element} classes={classes} />
                </Grid>
              ))}
            </Grid>
          </Paper>

          {selectedDeliveryType === "" && (
            <Card className={classes.serviceTypeCard}>
              <CardContent>
                <Grid className={classes.noServiceItem}>
                  <Typography className={classes.noServiceSpanStyle}>{`Please select a Service Type`}</Typography>
                </Grid>
              </CardContent>
            </Card>
          )}

          {selectedDeliveryType === DELIVERY_TYPES.SLOT_BASED && (
            <RenderStandardServiceTypeDetails
              classes={classes}
              standardDetails={configs[selectedServiceType]}
              handleStandardDetailsChange={(event) => handleSlotBasedDetailChanges(selectedServiceType, event)}
              handleBagChargeChange={(event) => handleBagChargeChange(event, selectedServiceType)}
              handleAdvanceDaysClick={() => handleAdvanceDaysClick(selectedServiceType)}
              handleServiceTypeActiveChange={(event) => handleServiceTypeActiveChange(event, selectedServiceType)}
              serviceTypeName={selectedServiceType}
            />
          )}

          {selectedDeliveryType === DELIVERY_TYPES.MINUTE_BASED && (
            <RenderExpressServiceTypeDetails
              classes={classes}
              expressDetails={configs[selectedServiceType]}
              handleExpressDetailsChange={(event) => handleMinuteBasedChanges(selectedServiceType, event)}
              handleBagChargeChange={(event) => handleBagChargeChange(event, selectedServiceType)}
              handleAdvanceDaysClick={handleAdvanceDaysClick}
              handleServiceTypeActiveChange={handleServiceTypeActiveChange}
              serviceTypeName={selectedServiceType}
            />
          )}

          {selectedDeliveryType === DELIVERY_TYPES.DAY_BASED && (
            <RenderDefaultTypeDetails
              classes={classes}
              defaultDetails={configs[selectedServiceType]}
              configs={configs}
              handleBagChargeChange={(event) => handleBagChargeChange(event, selectedServiceType)}
              weeklyHolidays={configs[selectedServiceType]?.weeklyHolidays || []}
              setWeeklyHolidays={(event) => handleDetailsChange(event, selectedServiceType)}
              defaultDateState={defaultDateState}
              handleDateChangeEvent={handleDateChangeEvent}
              handleCancel={handleCancel}
              handleCalendarHoliday={(event, type, label) => handleCalendarHoliday(event, type, label, selectedServiceType)}
              calendarHoliday={configs[selectedServiceType]?.holidays || []}
              handleServiceTypeActiveChange={(event) => handleServiceTypeActiveChange(event, selectedServiceType)}
              serviceTypeName={selectedServiceType}
            />
          )}

          {selectedServiceType === SERVICE_TYPES.CLICK_N_COLLECT && (
            <RenderClickAndCollectServiceTypeDetails
              classes={classes}
              CountryMappingTypeView
              posCount={posCount}
              clickCollectDetails={configs[selectedServiceType]}
              handleClickAndCollectDetailsChange={handleClickAndCollectDetailsChange}
              handleBagChargeChange={(event) => handleBagChargeChange(event, selectedServiceType)}
              handleAdvanceDaysClick={handleAdvanceDaysClick}
              handleServiceTypeActiveChange={handleServiceTypeActiveChange}
            />
          )}

          {selectedServiceType === SERVICE_TYPES.FBC && <RenderFBCTypeDetails classes={classes} fbcDetails={fbcDetails} handleServiceTypeActiveChange={handleServiceTypeActiveChange} />}

          <CountryMappingTypeView classes={classes} areaPosMappingType={areaPosMappingType} />
          <CountrySettingsFooter classes={classes} handleApplyChangesClick={handleApplyChangesClick} />
        </>
      )}

      {/* Modals */}
      {showInfoModal.show && <InfoModal show={showInfoModal.show} oldDates={showInfoModal.oldDates} onClose={() => setShowInfoModal({ show: false, duplicates: [], oldDates: false })} holidays={showInfoModal.duplicates} />}
      {showApplyChangesModal && (
        <CountryChangesConfirmationModal open={showApplyChangesModal} config={configs} onClose={handleApplyChangesModalClose} classes={classes} loading={loading} areaPosMappingType={areaPosMappingType} handleSubmit={handleSubmit} />
      )}
      {showExpressTypeChangeConfirmModal && (
        <ConfirmModal
          show={showExpressTypeChangeConfirmModal}
          title="Confirm Express Type Change"
          description="Slots needs to be re-validated as the express type is changed"
          confirmButtonText="Approve"
          onClose={() => setShowExpressTypeChangeConfirmModal(false)}
          onProceed={handleExpressTypeChangeApprove}
        />
      )}
    </div>
  );
};

export default CountrySettings;

const InfoModal = ({ show, onClose, holidays, oldDates }) => {
  const classes = previewModalStyles({});

  return (
    <div className={classes.modalContainer}>
      <ModalContainer className={classes.modal} open={show} onClose={onClose} disableBackdropClick>
        <div className={`${classes.modalContent} ${classes.modalContentWindow}`}>
          <div className={classes.closeButton}>
            <CloseButton className={classes.closeButtonSVG} onClick={onClose} />
          </div>

          <div className={classes.modalHeader}>
            <div className={classes.modalTitle}>{oldDates ? "Older Dates" : "Duplicate Holidays"}</div>
          </div>

          <div className={classes.modalBody}>
            {oldDates ? (
              <Typography> Please don't use older dates. Use dates tomorrow onwards.</Typography>
            ) : (
              <>
                <Typography className={classes.applyInfoModalBodyContent}>Following dates are already selected as holiday. Please check date range and select appropriately.</Typography>
                <div style={{ marginTop: "10px" }}>{holidays?.map((info) => info).join(", ")}</div>
              </>
            )}
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};
