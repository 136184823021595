import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../../config/theme/theme";

const ruleEngineStyles = makeStyles(
  createStyles({
    root: {
      margin: 0,
      padding: 0,
    },
    accordionDetails: {
      margin: 0,
      padding: "24px",
      display: "block",
    },
    tabPanelStyle: {
      padding: "16px 8px",
    },
    tabStyle: {
      margin: "6px 8px",
      fontSize: "18px",
    },
    typeOfPromiseContainer: {
      padding: "12px",
      margin: "0px 0px 24px 0px",
      width: "100%",
      backgroundColor: COLORS.WHITE,
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
      borderRadius: "8px",
      justifyContent: "space-between",
    },
    posNameContainer: {
      backgroundColor: COLORS.WHITE,
      borderRadius: "8px",
      padding: "10px",
      marginBottom: "24px",
    },
    posnameText: {
      color: COLORS.BLACK,
      fontSize: "20px",
      fontWeight: 600,
    },
    typeOfPromiseText: {
      color: COLORS.PRIMARY_MAIN,
      fontSize: "16px",
      fontWeight: 600,
    },
    tabsBorder: {
      borderBottom: `2px solid ${COLORS.SECONDARY_DARK}`,
      marginTop: "-2px",
    },
    tabsIndicator: {
      backgroundColor: "#0E5AA7",
    },
    ruleContainer: {
      margin: "0px 0px 24px 0px",
      padding: "12px",
      width: "100%",
      backgroundColor: "#F9F9F9",
      borderRadius: "4px",
      border: "1px solid #F9F9F9",
      boxShadow: "0px 1px 4px rgb(0 0 0 / 15%)",
    },
    ruleLabel: {
      fontWeight: 700,
      fontSize: "14px",
    },
    sameAsEarlierCheckbox: {
      marginRight: "8px",
      "&:disabled": {
        opacity: "0.5",
      },
    },
    dFlexEnd: {
      display: "flex",
      justifyContent: "flex-end",
    },
    checkboxLabel: {
      fontSize: "15px",
    },
    disableCloseBtn: {
      color: COLORS.GRAY_LIGHT,
    },
    disableSwitch: {
      opacity: 0.5,
    },
    addVehicleTypeDivStyle: {
      padding: 0,
      margin: 0,
      marginTop: "auto",
      marginBottom: "10px",
    },
    addVehicleTypeTextStyle: {
      fontSize: "14px",
      fontWeight: 600,
      color: "#0E5AA7",
      cursor: "pointer",
      "&:disabled": {
        opacity: "0.5",
      },
      "&:hover": {
        backgroundColor: "#F9F9F9",
      },
    },
    vehicleTypeHeaderDivStyle: {
      margin: 0,
      padding: 0,
      marginBottom: "4px",
    },
    vehicleTypeHeaderSpanStyle: {
      fontSize: "14px",
      fontWeight: 500,
    },
    vehicleTypeSpanStyle: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#0E5AA7",
    },
    vehicleTypeTopBottomMargin: {
      margin: 0,
      padding: 0,
      marginTop: "auto",
      marginBottom: "auto",
    },
    vehicleTypeTexboxStyle: {
      "&:disabled": {
        opacity: "0.5",
      },
    },
    ruleEditingDivStyle: {
      margin: 0,
      marginTop: "16px",
      padding: 0,
    },
    dFlexStart: {
      display: "flex",
      justifyContent: "flex-start",
    },
    formcontrolStyle: {
      marginRight: 0,
      padding: 0,
      "&:disabled": {
        opacity: "0.5",
      },
      "& .Mui-disabled": {
        opacity: "0.3",
      },
    },
    addOtherDays: {
      display: "flex",
      justifyContent: "flex-end",
      placeSelf: "flex-end",
    },
    orderVelocityRuleConfigurationStyle: {
      margin: 0,
      padding: 0,
      paddingBottom: "8px",
    },
    orderVelocityRuleHeaderConfigurationStyle: {
      fontSize: "14px",
      fontWeight: 500,
      margin: 0,
      padding: 0,
      paddingLeft: "4px",
    },
    orderVelocityRuleRemoveStyle: {
      margin: "auto 0px auto 16px",
    },
    orderVelocityRuleStyleRemoveStyle: {
      cursor: "pointer",
    },
    typeFormControlLabelStyle: {
      fontWeight: 600,
      fontSize: "14px",
      color: COLORS.TUATARA,
      margin: "0px 0px 0px 8px",
    },
    automatedConfigRuleHeaderSpanStyle: {
      fontSize: "14px",
      fontWeight: 500,
      margin: "0px 0px 8px 0px",
      padding: 0,
    },
    ruleContainerBoxStyle: {
      width: "100%",
      height: "auto",
      background: COLORS.WHITE,
      border: `1px solid ${COLORS.GRAY}`,
      borderRadius: "8px",
      marginTop: "12px",
      padding: "8px 16px 24px 16px",
      "&:last-child": {
        margin: 0,
        marginTop: "12px",
      },
    },
    addCircleGridStyle: {
      display: "flex",
      justifyContent: "flex-start",
    },
    addCircleStyle: {
      fill: COLORS.DOS_BLUE_DARK,
      cursor: "pointer",
      margin: "auto 8px",
    },
    deleteIconStyle: {
      fill: COLORS.ALIZARIN_CRIMSON,
      cursor: "pointer",
      margin: "auto 8px",
    },
  })
);

export default ruleEngineStyles;
