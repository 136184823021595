import React from "react";

import { Card, CardContent, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";

import { SERVICE_TYPES } from "../../../config/constants";
import { PlasticBagconfigurationViewUtils, ServiceActiveFlagViewUtils } from "../CountrySettingView";
import { ADVANCE_DAYS_DDN_OPTIONS } from "../CountrySettingsUtils";

import RenderAdvanceSlotDays from "./RenderAdvanceSlotDays";

interface RenderClickAndCollectServiceTypeDetailsInterface {
  classes: any;
  posCount: any;
  clickCollectDetails: any;
  handleClickAndCollectDetailsChange: any;
  handleBagChargeChange: any;
  handleAdvanceDaysClick: any;
  handleServiceTypeActiveChange: any;
}

const RenderClickAndCollectServiceTypeDetails = (props: RenderClickAndCollectServiceTypeDetailsInterface) => {
  const { classes, posCount, clickCollectDetails, handleClickAndCollectDetailsChange, handleBagChargeChange, handleAdvanceDaysClick, handleServiceTypeActiveChange } = props;

  return (
    <Card className={classes.serviceTypeCard}>
      <CardContent>
        <Grid container justifyContent="flex-start" spacing={2}>
          <ServiceActiveFlagViewUtils
            classes={classes}
            serviceTypeName={SERVICE_TYPES.CLICK_N_COLLECT}
            serviceActiveFlag={clickCollectDetails.serviceActive}
            isDisabled={false}
            handleServiceTypeActiveChange={handleServiceTypeActiveChange}
          />
          <Grid item xs={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.serviceTypeContentTitle}>
                Next Day Slot Visibility
              </FormLabel>
              <RadioGroup name="cncNextDaySlotVisibility" value={clickCollectDetails.nextDaySlotVisibility} onChange={handleClickAndCollectDetailsChange}>
                <FormControlLabel value={true} control={<Radio color="primary" />} label="On" classes={{ label: classes.formControlLabel }} />
                <FormControlLabel value={false} control={<Radio color="primary" />} label="Off" classes={{ label: classes.formControlLabel }} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {" "}
          </Grid>
          <Grid item xs={3}>
            <PlasticBagconfigurationViewUtils
              classes={classes}
              bagChargeEnabled={clickCollectDetails.bagChargeEnabled}
              handleBagChargeChange={handleBagChargeChange}
              name={SERVICE_TYPES.CLICK_N_COLLECT}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" spacing={0} className={classes.collectionPointGridContainerStyle}>
          <Grid item>
            <Typography className={classes.serviceTypeContentTitle}>{`Total Collection Point : `}</Typography>
          </Grid>
          <Grid item className={classes.collectionPointGridItemStyle}>
            <Typography className={classes.formControlLabel}>{posCount[SERVICE_TYPES.CLICK_N_COLLECT]}</Typography>
          </Grid>
        </Grid>
        <RenderAdvanceSlotDays
          classes={classes}
          advanceSlotDays={clickCollectDetails.advanceSlotDays}
          showAdvanceSlotDaysInput={clickCollectDetails.showAdvanceSlotDaysInput}
          menuItems={ADVANCE_DAYS_DDN_OPTIONS}
          name={"cncAdvanceSlotDays"}
          value={clickCollectDetails.editSlotDays}
          handleChange={handleClickAndCollectDetailsChange}
          handleAdvanceDaysClick={handleAdvanceDaysClick}
          serviceTypeName={SERVICE_TYPES.CLICK_N_COLLECT}
        />
      </CardContent>
    </Card>
  );
};

export default React.memo(RenderClickAndCollectServiceTypeDetails);
