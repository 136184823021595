import produce from "immer";
import CustomAlert from "../../../components/common/CustomAlert/CustomAlert";
import { ALERT_TYPES } from "../../../config/constants";
import { check_if_slot_obj_contains_sub_set } from "../../../utils/ApplicationUtils";
import { DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE } from "../Standard/StandardServiceConstant";

export const validateStandardZoneConfig = (configTabs: DEFAULT_CONFIG_TAB_CONFIGURATIONS_INTERFACE[], selectedConfigTab: any, errors: any) => {
  let currentTab = configTabs[selectedConfigTab];
  let newErrors: any = {};
  if (currentTab.slotOptimizationEnabled) {
    if (!currentTab.capacityThreshold) {
      let errorMsg = "Please enter capacity threshold";
      newErrors.capacityThreshold = errorMsg;
      CustomAlert(ALERT_TYPES.ERROR, errorMsg);
    }

    if (currentTab.capacityThreshold && (Number(currentTab.capacityThreshold) < 50 || Number(currentTab.capacityThreshold) > 100)) {
      let errorMsg = "Accepted capacity threshold values are 50 to 100";
      newErrors.capacityThreshold = errorMsg;
      CustomAlert(ALERT_TYPES.ERROR, errorMsg);
    }
    if (!currentTab.timeThreshold) {
      let errorMsg = "Please enter time threshold";
      newErrors.timeThreshold = errorMsg;
      CustomAlert(ALERT_TYPES.ERROR, errorMsg);
    }
  }

  if (currentTab.bufferCapacityConfig.active) {
    if (currentTab.bufferCapacityConfig) {
      let errorMsg = "Please input all variables for capacity optimization rule.";
      if (!currentTab.bufferCapacityConfig.capacityUtilized) {
        newErrors.capacityUtilized = errorMsg;
        CustomAlert(ALERT_TYPES.ERROR, errorMsg);
      } else if (!currentTab.bufferCapacityConfig.reducedBufferTime) {
        newErrors.reducedBufferTime = errorMsg;
        CustomAlert(ALERT_TYPES.ERROR, errorMsg);
      } else if (!currentTab.bufferCapacityConfig.maxCapacity) {
        newErrors.maxCapacity = errorMsg;
        CustomAlert(ALERT_TYPES.ERROR, errorMsg);
      } else if (Number(currentTab.bufferCapacityConfig.maxCapacity) < 0) {
        newErrors.maxCapacity = errorMsg;
        CustomAlert(ALERT_TYPES.ERROR, errorMsg);
      }
    }
  }

  if (currentTab !== undefined && currentTab.slotsGeneratorForms !== undefined && Array.isArray(currentTab.slotsGeneratorForms) && currentTab.slotsGeneratorForms.length > 0) {
    currentTab.slotsGeneratorForms.forEach((slotObj: any) => {
      if (slotObj !== undefined && slotObj.generatedSlots !== undefined && Array.isArray(slotObj.generatedSlots) && slotObj.generatedSlots.length > 0) {
        const is_slot_obj_valid = check_if_slot_obj_contains_sub_set(slotObj.generatedSlots);
        if (is_slot_obj_valid !== undefined && typeof is_slot_obj_valid === "object" && Object.keys(is_slot_obj_valid).length > 0) {
          let errorMsg = is_slot_obj_valid[Object.keys(is_slot_obj_valid)[0]];
          newErrors.capacityThreshold = errorMsg;
          CustomAlert(ALERT_TYPES.ERROR, errorMsg);
        }
      } else {
        let errorMsg = "No slot found.";
        newErrors.capacityThreshold = errorMsg;
        CustomAlert(ALERT_TYPES.ERROR, errorMsg);
      }
    });
  } else {
    let errorMsg = "Please provide slot configurations";
    newErrors.capacityThreshold = errorMsg;
    CustomAlert(ALERT_TYPES.ERROR, errorMsg);
  }

  let currentTabErrors = produce(errors, (draft: any) => {
    draft[selectedConfigTab] = { ...newErrors };
  });
  return { isError: Object.keys(newErrors).filter((err) => newErrors[err]).length > 0, currentTabErrors };
};
