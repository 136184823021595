import React from "react";
import { Box, Checkbox, ClickAwayListener, FormControlLabel, Grid, Typography } from "@material-ui/core";

import WarningIconBlack from "../../assets/svg/WarningIconBlack.svg";
import ZoomOut from "../../assets/svg/ZoomOut.svg";
import ExpandMoreIcon from "../../assets/svg/ExpandAccordionIcon.svg";
import EmailIcon from "../../assets/svg/email.svg";
import CloseIcon from "../../assets/svg/CloseIcon.svg";

import { ALL_RESULTS_FILTERED, PLEASE_RESET_TRY_AGAIN, PLEASE_CLEAR_ALL_FILTERS_TRY_AGAIN, NO_DATA_PRESENT_ERROR } from "../../../utils/MessageUtils";
import { MAP_MARKER_INTERFACE, MapPolygonInterface } from "../../PlolygonServices/Utils/PlolygonServicesInterface";
import { capitalizeFirstLetter, isArrayValid, isNonEmptyObject, isUndefined } from "../../../utils/DataUtils";

/** GetNoDataPresentAfterSearchApplied */
interface GetNoDataPresentAfterSearchAppliedInterface {
  classes: any;
}
export const GetNoDataPresentAfterSearchApplied = (props: GetNoDataPresentAfterSearchAppliedInterface) => {
  const { classes } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <img src={WarningIconBlack} alt="Edit" />
      </Grid>
      <Grid item xs={12}>
        <Typography> {`${ALL_RESULTS_FILTERED}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography> {`${PLEASE_CLEAR_ALL_FILTERS_TRY_AGAIN}`}</Typography>
      </Grid>
    </Grid>
  );
};

/** GetNoDataPresentAfterFiltereApplied */
interface GetNoDataPresentAfterFiltereAppliedInterface {
  classes: any;
}
export const GetNoDataPresentAfterFiltereApplied = (props: GetNoDataPresentAfterFiltereAppliedInterface) => {
  const { classes } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <img src={WarningIconBlack} alt="Edit" />
      </Grid>
      <Grid item xs={12}>
        <Typography> {`${ALL_RESULTS_FILTERED}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography> {`${PLEASE_RESET_TRY_AGAIN}`}</Typography>
      </Grid>
    </Grid>
  );
};

/** GetNoDataPresentErrorViewUtils */
interface GetNoDataPresentErrorViewUtilsInterface {
  classes: any;
  currentCountry: any;
  ErrorMessage: any;
  SecondaryErrorMessage?: any;
}
export const GetNoDataPresentErrorViewUtils = (props: GetNoDataPresentErrorViewUtilsInterface) => {
  const { classes, currentCountry, ErrorMessage, SecondaryErrorMessage } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <img src={ZoomOut} alt="Edit" />
      </Grid>
      <Grid item xs={12}>
        <Typography> {`${ErrorMessage || NO_DATA_PRESENT_ERROR} ${currentCountry?.name || "country"}`}</Typography>
      </Grid>
      {SecondaryErrorMessage !== undefined && SecondaryErrorMessage !== "" && (
        <Grid item xs={12}>
          <Typography> {`${SecondaryErrorMessage}`}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export const ExpandMoreAccordianIcon = () => {
  return <img src={ExpandMoreIcon} alt="Expand Accordion Icon" />;
};

interface RenderEmailIconInterface {
  classes?: any;
}
export const RenderEmailIcon = (props: RenderEmailIconInterface) => {
  const { classes } = props;
  return <img src={EmailIcon} alt="Email Icon" className={classes.emailIcon || {}} />;
};

interface PolygonInfoWindowViewUtilsInterface {
  classes: any;
  polygonRecord: any;
  handlePolygonInfoWindowClose: any;
  serviceList: any[];
}

export const PolygonInfoWindowViewUtils = (props: PolygonInfoWindowViewUtilsInterface) => {
  const { classes, polygonRecord, handlePolygonInfoWindowClose, serviceList } = props;
  const renderPolygonMappingDetails = (serviceObj: any) => {
    if (!isUndefined(polygonRecord) && !isUndefined(polygonRecord.serviceTypeMapping) && isNonEmptyObject(polygonRecord.serviceTypeMapping) && !isUndefined(serviceObj.name) && !isUndefined(polygonRecord.serviceTypeMapping[serviceObj.name])) {
      return (
        <Typography className={polygonRecord.serviceTypeMapping[serviceObj.name].isServiceable ? classes.polygonInfoWindowSpanStyle : classes.polygonInfoWindowErrorSpanStyle}>{`${
          polygonRecord.serviceTypeMapping[serviceObj.name].isServiceable
            ? `${polygonRecord.serviceTypeMapping[serviceObj.name].posNo} ${polygonRecord.serviceTypeMapping[serviceObj.name].posName} - ${polygonRecord.serviceTypeMapping[serviceObj.name].zoneName}`
            : `Non Serviceable`
        }`}</Typography>
      );
    }
    return <Typography>{`-`}</Typography>;
  };

  return (
    <Box className={classes.polygonInfoWindowBoxStyle}>
      <Grid container>
        <Grid item xs={10} className={classes.polygonInfoWindowMainGridItemStyle}>
          <Typography className={classes.polygonInfoWindowTitleTextStyle}>{`${polygonRecord?.polygonId}`}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.polygonInfoWindowMainGridCloseIconStyle} onClick={(event: any) => handlePolygonInfoWindowClose(event)}>
          <img src={CloseIcon} alt={CloseIcon} />
        </Grid>
        <Grid item xs={12}>
          {!isUndefined(serviceList) && isArrayValid(serviceList) ? (
            serviceList.map((serviceObj: any, serviceObjIndex: number) => (
              <Grid container className={classes.polygonInfoWindowGridContainerStyle} key={`${serviceObjIndex}-objectKeyIndex-serviceType-${serviceObj.name}`}>
                <Grid item xs={3}>
                  <Typography className={classes.polygonInfoWindowTitleTextStyle}>{`${serviceObj?.displayName ? serviceObj.displayName : capitalizeFirstLetter(serviceObj.name)} : `}</Typography>
                </Grid>
                <Grid item xs={9}>
                  {renderPolygonMappingDetails(serviceObj)}
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography>{`Unmapped`}</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

interface PolygonPosZoneInfoWindowViewUtilsInterface {
  classes: any;
  marker: MAP_MARKER_INTERFACE;
  selectServiceTypeClick: any;
  closePolygonPosZoneInfoWindow: (event: any) => void;
  selectServiceZoneTypeClick: any;
}

export const PolygonPosZoneInfoWindowViewUtils = (props: PolygonPosZoneInfoWindowViewUtilsInterface) => {
  const { classes, marker, selectServiceTypeClick, closePolygonPosZoneInfoWindow, selectServiceZoneTypeClick } = props;

  return (
    <ClickAwayListener onClickAway={(event: any) => closePolygonPosZoneInfoWindow(event)}>
      <Box className={classes.polygonInfoWindowBoxStyle}>
        <Grid container>
          <Grid item xs={10} className={classes.polygonInfoWindowMainGridItemStyle}>
            <Typography className={classes.polygonInfoWindowTitleTextStyle}>{`${marker?.posName}`}</Typography>
          </Grid>
          <Grid item xs={2} className={classes.polygonInfoWindowMainGridCloseIconStyle} onClick={(event: any) => closePolygonPosZoneInfoWindow(event)}>
            <img src={CloseIcon} alt={CloseIcon} />
          </Grid>
          {marker &&
            marker.serviceTypes &&
            isArrayValid(marker.serviceTypes) &&
            marker.serviceTypes.map((service: any, serviceIndex: number) => (
              <Box key={`${serviceIndex}-serviceIndex-service`} className={service.isSelected ? classes.posZoneSelectedBoxStyle : classes.posZoneBoxStyle} onClick={() => selectServiceTypeClick(marker, serviceIndex)}>
                <Typography className={service.isSelected ? classes.posZoneSelectedBoxSpanStyle : classes.posZoneBoxSpanStyle}>{`${service.displayName || service.name || "-"}`}</Typography>
              </Box>
            ))}
          {marker.selectedServiceDetails && isNonEmptyObject(marker.selectedServiceDetails) && (
            <>
              <Grid item xs={12} className={classes.polygonInfoWindowMainGridItemStyle}>
                <Typography className={classes.polygonInfoWindowTitleTextStyle}>{`Zones Serving`}</Typography>
              </Grid>
              {marker.selectedServiceDetails &&
                marker.selectedServiceDetails.zones &&
                isArrayValid(marker.selectedServiceDetails.zones) &&
                marker.selectedServiceDetails.zones.map((zone: any, zoneIndex: number) => (
                  <Box key={`${zoneIndex}-zoneIndex-zone`} className={zone.isSelected ? classes.posZoneSelectedBoxStyle : classes.posZoneBoxStyle} onClick={() => selectServiceZoneTypeClick(marker, zone, zoneIndex)}>
                    <Typography className={zone.isSelected ? classes.posZoneSelectedBoxSpanStyle : classes.posZoneBoxSpanStyle}>{`${zone.name || "-"}`}</Typography>
                  </Box>
                ))}
            </>
          )}
        </Grid>
      </Box>
    </ClickAwayListener>
  );
};

interface ChangeConfigOtionProps {
  is_edit_mode: any;
  classes: any;
  use_existing_config_change_handler: any;
  use_existing_checked: any;
  change_existing_checked: any;
  use_change_existing_config_change_handler: any;
}

export const ChangeConfigOtion = (props: ChangeConfigOtionProps) => {
  const { classes, is_edit_mode, use_existing_config_change_handler, use_existing_checked, change_existing_checked, use_change_existing_config_change_handler } = props;
  return (
    <Grid item xs={12} className={classes.dFlexEnd}>
      {is_edit_mode ? (
        <FormControlLabel
          control={<Checkbox className={classes.switchStyle} checked={change_existing_checked} onChange={use_change_existing_config_change_handler} />}
          label={<Typography className={classes.dlvFeeCalculationTextStyle}>Change existing configuration</Typography>}
        />
      ) : (
        <FormControlLabel
          control={<Checkbox className={classes.switchStyle} checked={use_existing_checked} onChange={use_existing_config_change_handler} />}
          label={<Typography className={classes.dlvFeeCalculationTextStyle}>Use existing configuration</Typography>}
        />
      )}
    </Grid>
  );
};
