import { Link } from "react-router-dom";
import { APP_ROUTES, DELIVERY_TYPE, USER_TYPES } from "../../../config/constants";
import { isArrayValid, isUndefined } from "../../../utils/DataUtils";
import { RULE_ENGINE_TEXT_CONSTANT } from "../RuleEngineUtils";

import PrimaryButton from "components/common/PrimaryButton/PrimaryButton";

interface RuleEngineCurrentConfigButtonAccessInterface {
  userInfo: any;
  classes: any;
}

export const RuleEngineCurrentConfigButtonAccess = (props: RuleEngineCurrentConfigButtonAccessInterface) => {
  const { userInfo, classes } = props;
  return userInfo?.userProfile?.role !== USER_TYPES.READONLY_USER ? (
    <Link to={APP_ROUTES.RULE_ENGINE} style={{ textDecoration: "none" }}>
      <PrimaryButton className={classes.backButtonStyles} buttonLabel={`${RULE_ENGINE_TEXT_CONSTANT.VIEW_CURRENT_CONFIGURATIONS}`} buttonVariant="outlined" />
    </Link>
  ) : (
    <></>
  );
};

interface RuleEngineCreateConfigButtonAccessInterface {
  userInfo: any;
  classes: any;
  serviceName: string;
  serviceId: number;
  moveToNewConfigurationScreen: any;
}

export const RuleEngineCreateConfigButtonAccess = (props: RuleEngineCreateConfigButtonAccessInterface) => {
  const { userInfo, classes, serviceName, serviceId, moveToNewConfigurationScreen } = props;

  const isRoleAllowed = () => {
    const { userProfile } = userInfo || {};

    if (!userProfile || !userProfile.role) {
      return false;
    }

    const allowedRoles = [USER_TYPES.READONLY_USER, USER_TYPES.ADMIN, USER_TYPES.APPROVER];
    return !allowedRoles.includes(userProfile.role);
  };

  const isMinuteBasedAccess = () => {
    return !isUndefined(userInfo?.userProfile?.serviceTypeId) && isArrayValid(userInfo?.userProfile?.serviceTypeId) && userInfo?.userProfile?.serviceTypeId?.includes(serviceId);
  };

  return isRoleAllowed() && isMinuteBasedAccess() ? (
    <PrimaryButton className={classes.buttonStyles} buttonLabel={`${RULE_ENGINE_TEXT_CONSTANT.CREATE_NEW_CONFIGURATION}`} buttonVariant="outlined" onClick={(event: any) => moveToNewConfigurationScreen(event, serviceName)} />
  ) : (
    <></>
  );
};

export const getMinuteBasedDeliveryType = (arr: Array<{ deliveryType: string }>) => {
  if (!isUndefined(arr) && isArrayValid(arr)) {
    return arr
      .filter((item) => item?.deliveryType === DELIVERY_TYPE.MINUTE_BASED)
      .sort((a: any, b: any) => {
        const rankA = a?.rank ?? 0;
        const rankB = b?.rank ?? 0;
        return rankA - rankB;
      });
  }
  return [];
};
