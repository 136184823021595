// Material UI
import { PopoverOrigin } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select, { SelectProps } from "@material-ui/core/Select";
import React, { useRef, useState } from "react";
// Assets
import { ReactComponent as downArrowIcon } from "../../assets/svg/DownArrow.svg";
// Styles
import { anchorOriginDefault, selectBoxStyles } from "./SelectBoxStyles";

interface SelectBoxProps extends SelectProps {
  fieldLabel?: string;
  label?: string;
  helperText?: string;
  value: any;
  handleChange: any;
  menuItems: Array<any>;
  error?: boolean;
  menuPaperProps?: any;
  id?: string;
  className?: string;
  anchorOrigin?: PopoverOrigin | undefined;
  hideIconComponent?: boolean;
  labelClass?: string;
  selectClass?: string;
  selectBoxMenuItemTextStyle?: any;
  isDeliveryFee?: any;
}

const SelectBox = (props: SelectBoxProps) => {
  const { fieldLabel, label, labelClass, className, id = "", error, selectClass, value, handleChange, hideIconComponent, menuPaperProps, anchorOrigin, selectBoxMenuItemTextStyle, isDeliveryFee, menuItems = [], ...rest } = props;

  const classes = selectBoxStyles();
  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {fieldLabel && <div className={classes.fieldLabel}>{fieldLabel}</div>}
      <FormControl variant={isDeliveryFee ? "standard" : "outlined"} className={classes.formControl} error={error} disabled={props.disabled}>
        {label && (
          <InputLabel id={`${id}-${label}`} className={labelClass} disabled={props.disabled}>
            {label}
          </InputLabel>
        )}
        <Select
          {...rest}
          displayEmpty
          className={`${className || ""} ${isOpen ? "open " : ""}${label ? "hasLabel" : ""}${selectClass}`}
          ref={anchorEl}
          labelId={label ? `${id}-${label}` : ""}
          value={value}
          onChange={handleChange}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          IconComponent={hideIconComponent ? () => <></> : downArrowIcon}
          MenuProps={{
            classes: {
              list: classes.menuPropsList,
              paper: menuPaperProps || classes.menuPropsPaper,
            },
            anchorEl: anchorEl.current,
            getContentAnchorEl: null,
            anchorOrigin: anchorOrigin || anchorOriginDefault,
          }}
        >
          {menuItems.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item?.displayName ? item.displayName : item.name || ""}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default React.memo(SelectBox);

SelectBox.defaultProps = {
  menuItems: [],
};
