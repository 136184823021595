import { createTheme } from "@material-ui/core/styles";
import AccordionExpandIcon from "../../components/assets/svg/ExpandAccordionIcon.svg";

interface ColorPalette {
  [key: string]: string;
}

export const COLORS: ColorPalette = {
  RAINEE: "#B9C7AE",
  KILLARNEY: "#326F3E",
  //
  LAVENDER_GRAY: "#BFB6DA",
  ROYAL_PURPLE: "#6040B0",
  //
  CASPER: "#ACBDD2",
  BLAKE: "#215796",
  //
  WEWAK: "#f7b0b0",
  FROLY: "#F37172",
  //
  PASTEL: "#E6B98B",
  PUMPKIN_SKIN: "#C1720C",
  //
  BEIGE: "#F5F5DC",
  CLASSIC_BROWN: "#654321",
  //
  PERSIAN_GREEN_LIGHT: "#81D4FA",
  PERSIAN_GREEN: "#009688",
  //
  GREEN_LIGHT: "#80CBC4",
  DARK_GREEN_BACKGROUND: "#007F73",
  //
  PRIMARY_MAIN: "#0E5AA7",
  PRIMARY_LIGHT: "rgba(14, 90, 167, 0.2)",
  PRIMARY_DARK: "#0E5A80",
  SECONDARY_MAIN: "#F5F5F5",
  SECONDARY_LIGHT: "#EEEEEE",
  SECONDARY_DARK: "#E6E6E6",
  TEXT_PRIMARY: "#252525",
  TRANSPARENT_BLUE: "rgba(10, 67, 124, 0.05)",
  BLACK_SHADOW: "rgba(0,0,0,0.05)",
  BLACK_INPUT_BORDER: "rgba(0, 0, 0, 0.23)",
  BLACK_INPUT_LABEL: "rgba(0, 0, 0, 0.54)",
  BLACK_BORDER: "#B2B2B2",
  GOLDEN_YELLOW: "#E3870F",
  AZURE_BLUE: "#0A437C",
  DARK_RED: "#F34041",
  DOVE_GRAY: "#666666",
  DOVE_GRAY_LIGHT: "rgba(102,102,102,0.6)",
  GRAY_LIGHT: "rgb(249, 249, 249)",
  SILVER: "#C4C4C4",
  DARK_GRAY: "#4D4D4D",
  TRANSPARENT: "transparent",
  LOADER_BACKGROUND: "rgba(255, 255, 255, 0.38)",
  LIGHT_RED_BACKGROUND: "#FDF4F4",
  LIGHT_GREEN_BACKGROUND: "#BFE4E1",
  // March 2023
  //
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  DOS_BLUE_DARK: "#0E5AA7",
  DOS_BLUE_LIGHT: "rgba(14, 90, 167, 0.2)",
  DOS_BLUE_SUPER_LIGHT: "#F3F6F8",
  COD_GRAY: "#141414",
  ALABASTER: "#F9F9F9",
  FOAM: "#F1FAFE",
  MATISSE: "#157296",
  BOULDER: "#747474",
  WILDSAND: "#F4F4F4",
  SILVERCHALICE: "#B2B2B2",
  ATHENS_GRAY: "#F7F8F9",
  MERCURY: "#EAEAEA",
  MINE_SHAFT: "#1F1F1F",
  EMPEROR: "#505050",
  MINE_SHAFT_LIGHT: "#2D2D2D",
  TUNDORA: "#4D4D4D",
  MERCURY_LIGHT: "#E6E6E6",
  GIVRY: "#F7E0C2",
  APPLE_GREEN: "#E9F6F1",
  TUATARA: "#252522",
  LIGHT_WHITE: "rgba(0, 0, 0, 0.06)",
  PERSIAN_RED: "#CE3637",
  SEA_GREEN: "#319E60",
  OLD_GOLD: "#CF9E36",
  GREY_SHADE_ONE: "rgba(178, 178, 178, 1)",
  DUSTY_GRAY: "#979797",
  //
  CASHMERE: "#E8BEA3",
  PIPER: "#D65B26",
  //
  CLAM_SHELL: "#CDACAF",
  CLARET: "#7D204E",
  //
  CHICAGO_LIGHT: "#C2C1BE",
  CHICAGO: "#5C5B58",
  //
  ALIZARIN_CRIMSON: "#EE2527",
  GRAY: "#C3C3C3",
};

const checkboxDefaultIcon =
  "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS41IiB5PSIxLjUiIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgcng9IjIuNSIgZmlsbD0id2hpdGUiIHN0cm9rZT0iI0IyQjJCMiIvPgo8L3N2Zz4K)";
const checkboxCheckedIcon =
  "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS41IiB5PSIxLjUiIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgcng9IjIuNSIgZmlsbD0iIzBFNUFBNyIgZmlsbC1vcGFjaXR5PSIwLjEiIHN0cm9rZT0iIzBFNUFBNyIvPgo8cGF0aCBkPSJNNiAxMS44NTVMMTAuMDk2IDE2TDE4IDcuNzg3TDE3LjI0MyA3TDEwLjA4NSAxNC40MzdMNi43NDcgMTEuMDU3TDYgMTEuODU1WiIgZmlsbD0iIzBFNUFBNyIvPgo8L3N2Zz4K)";

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY_MAIN,
    },
  },
  typography: {
    fontFamily: ['"Montserrat"', "Open Sans"].join(","),
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#FAFAFA",
        },
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: COLORS.GRAY_LIGHT,
        boxShadow: "none",
        "&::before": {
          opacity: 0,
        },

        "&.Mui-expanded": {
          marginBottom: "12px",
          marginTop: "12px",
        },
      },
      rounded: {
        borderRadius: "8px !important",
      } /* 
      "&.Mui-expanded": {
        "&.first-child": {
          marginTop: "16px",
        },
      }, */,
    },
    MuiAccordionSummary: {
      root: {
        padding: "16px 24px",
        "&.MuiButtonBase-root .MuiIconButton-edgeEnd": {
          "& .MuiIconButton-label": {
            content: `url(${AccordionExpandIcon})`,
          },
        },
        "&.Mui-focused": {
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      },
      content: {
        color: "#2D2D2D",
        margin: "0px",
        "&.Mui-expanded": {
          margin: "0px",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        minWidth: "auto",
        height: "40px",
        "& .MuiOutlinedInput-notchedOutline": {
          top: "0px",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: COLORS.BLACK_INPUT_BORDER,
          borderWidth: 1,
          top: "0px",
        },
        "&:hover": {
          top: "0px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.BLACK_INPUT_BORDER,
            top: "0px",
          },
        },
        "&.hasLabel": {
          "& .MuiSelect-root": {
            paddingTop: 16,
            paddingBottom: 5,
          },
        },
      },
      adornedEnd: {
        paddingRight: 8,
        "& .MuiSvgIcon-root": {
          padding: 4,
        },
      },
      input: {
        paddingTop: 10.5,
        paddingBottom: 10.5,
        fontSize: 12,
        height: "auto",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiButton: {
      root: {
        padding: "7px 16px",
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 8,
        textTransform: "none",
        "&.primary": {
          backgroundColor: COLORS.PRIMARY_MAIN,
          color: COLORS.WHITE,
          border: `1px solid ${COLORS.TRANSPARENT}`,
          "&$disabled": {
            backgroundColor: COLORS.PRIMARY_LIGHT,
            color: COLORS.WHITE,
          },
          "&:hover": {
            backgroundColor: COLORS.PRIMARY_MAIN,
          },
        },
        "&.secondary": {
          backgroundColor: COLORS.WHITE,
          color: COLORS.PRIMARY_MAIN,
          border: `1px solid ${COLORS.PRIMARY_MAIN}`,
          "&$disabled": {
            color: COLORS.PRIMARY_MAIN,
          },
          "&:hover": {
            backgroundColor: COLORS.WHITE,
          },
        },
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: COLORS.PRIMARY_MAIN,
        },
      },
      outlinedPrimary: {
        border: `1px solid ${COLORS.PRIMARY_MAIN}`,
        "&:hover": {
          backgroundColor: COLORS.WHITE,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "4px",
        color: COLORS.TEXT_PRIMARY,
        "&.Mui-focused": {
          color: COLORS.TEXT_PRIMARY,
          top: 13,
        },
      },
      shrink: {
        top: 15,
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        borderRadius: 0,
        background: checkboxDefaultIcon,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "20px 20px",
        "& svg path": {
          display: "none",
        },
      },
      colorPrimary: {
        "&.Mui-checked": {
          backgroundImage: checkboxCheckedIcon,
        },
      },
      colorSecondary: {
        "&.Mui-checked": {
          backgroundImage: checkboxCheckedIcon,
          color: COLORS.TRANSPARENT,
        },
      },
      indeterminate: {
        backgroundImage: `${checkboxDefaultIcon} !important`,
      },
    },
    MuiSelect: {
      root: {
        paddingTop: 6,
        paddingBottom: 5,
        fontWeight: 500,
      },
      icon: {
        padding: 4,
      },
      select: {
        "&:focus": {
          backgroundColor: "inherit",
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: `${COLORS.TRANSPARENT} !important`,
        "&:hover": {
          backgroundColor: `${COLORS.TRANSPARENT} !important`,
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
      },
    },
    MuiCardContent: {
      root: {
        padding: "24px",
      },
    },
    // PrivateNotchedOutline: {
    //   legendNotched: {
    //     minWidth: "0px",
    //     maxWidth: "0px",
    //   },
    // },
    MuiMenu: {
      paper: {
        maxHeight: `275px`,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "10px",
      },
    },
    // MuiBox: {
    //   root: {
    //     padding: "0px",
    //   },
    // },
    MuiTabs: {
      indicator: {
        backgroundColor: COLORS.PRIMARY_MAIN,
      },
    },
    MuiTab: {
      root: {
        textTransform: "initial",
        minWidth: "60px !important",

        "&:hover": {
          color: COLORS.PRIMARY_MAIN,
          opacity: 1,
        },
        "&:focus": {
          color: COLORS.PRIMARY_MAIN,
        },
        "&.Mui-selected": {
          color: COLORS.PRIMARY_MAIN,
          fontWeight: 600,
        },
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "14px",
      },
    },
    MuiInputBase: {
      input: {
        padding: "2px 0px 7px",
      },
    },
    MuiIconButton: {
      root: {
        "&.hover": {
          backgroundColor: `${COLORS.WHITE} !important`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: COLORS.TEXT_PRIMARY,
        "&.Mui-focused": {
          color: COLORS.TEXT_PRIMARY,
        },
      },
    },
    // Mui: {
    //   "&$disabled": {
    //     color: COLORS.BLACK_BORDER,
    //   },
    // },
    MuiFormControlLabel: {
      root: {
        marginLeft: "0px",
      },
    },
  },
});

export default theme;
