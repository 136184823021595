import produce from "immer";
import moment from "moment";

import CustomAlert from "../../common/CustomAlert/CustomAlert";

import { countObjectProperties, isArrayValid, isEmptyArray, isEmptyOrNull, isNonEmptyObject, isUndefined } from "../../../utils/DataUtils";
import { ALERT_TYPES, DELIVERY_TYPE, FEE_REG_EXP_VALUE, SAVE_CONFIG_ENUM, TYPES_OF_RULE_ENGINES } from "../../../config/constants";
import { weekday } from "../common/constant";
import { getDates } from "../UtilsNew/DeliveryFeeDateTimeUtils";
import {
  DELIVERY_FEE_TEXT_CONSTANT,
  DELIVER_FEE_ACTIVE_FLAG_ERROR,
  EXPRESS_SERVICE_TYPE,
  MOV_THRESHOLD_ACTIVE_FLAG_ERROR,
  SERVICE_FEE_ACTIVE_FLAG_ERROR,
  SLOT_PROXIMITY_FEE_ACTIVE_FLAG_ERROR,
  SLOT_PROXIMITY_FEE_DELIVERY_FEE_ERROR,
  STANDARD_SERVICE_TYPE_NUMBER,
  THRESHOLD_ZERO_ERROR,
  VALID_ABOVE_THRESHOLD_ERROR,
  VALID_BELOW_THRESHOLD_ERROR,
  VALID_DYNAMIC_SERVICE_FEE_ERROR,
  VALID_DYNAMIC_SERVICE_FEE_MAX_ERROR,
  VALID_DYNAMIC_SERVICE_FEE_MIN_ERROR,
  VALID_DYNAMIC_SERVICE_FEE_MIN_MAX_ERROR,
  VALID_DYNAMIC_SERVICE_FEE_MIN_MAX_VALUE_ERROR,
  VALID_DYNAMIC_SERVICE_FEE_PERCENTAGE_ERROR,
  VALID_MOV_THRESHOLD_ERROR,
  VALID_PREFERRED_SLOT_DELIVERY_FEE_ERROR,
  VALID_PREFERRED_SLOT_HIGH_CAPACITY_THRESHOLD_ERROR,
  VALID_PREFERRED_SLOT_LOW_CAPACITY_THRESHOLD_ERROR,
  VALID_PREFERRED_SLOT_PROXIMITY_FEE_MAX_TIME_ERROR,
  VALID_PREFERRED_SLOT_PROXIMITY_FEE_MIN_TIME_ERROR,
  VALID_SERVICE_FEE_ERROR,
  VALID_THRESHOLD_AMOUNT_ERROR,
  VALID_THRESHOLD_ERROR,
} from "./DeliveryFeeConstants";
import {
  DeliveryFeeHeaderDataListInterface,
  DlvFeeConfigStateInterface,
  PosDetails,
  ShipmentObjInterface,
  ShipmentServiceLevelInterface,
  SlotProximityFeeConfigDTOListConfigurationInterface,
  capacityUtilisationConfigurationInterface,
  deliveryFeeRangeConfigDTOListInterface,
  payloadObjectInterface,
  selectedPosServiceTypeZoneInterface,
  shipmentLevelDeliveryFeeConfigsInterface,
} from "./DeliveryFeeInterface";
import { getServicePropositionObject } from "../../../utils/helperFunctions";

export const thresholdAmountRegExp = new RegExp("^[0-9]+$");

export const capitalizeFirstLetter = (nameString: any) => {
  return nameString.charAt(0).toUpperCase() + nameString.slice(1).toLowerCase();
};

export const createCamelCase = (nameString: any) => {
  return nameString.charAt(0).toUpperCase() + nameString.slice(1).toLowerCase();
};

export const isShipmentTypeStandard = (shipmentId: number) => {
  return shipmentId === STANDARD_SERVICE_TYPE_NUMBER;
};

export const isShipmentTypeSlotBased = (deliveryType: string) => {
  return deliveryType && deliveryType === DELIVERY_TYPE.SLOT_BASED;
};

export const displaySlotCapacityText = (deliveryType: string) => {
  return isShipmentTypeSlotBased(deliveryType) ? "-" : "N/A";
};

export const rightBorderStyle = { borderRight: "1px solid #AAC1DF" };

export const _handle_use_existing_config_change = (multiShipmentLevel: any, serviceName: any, changeConfigObj: any, keyName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[serviceName][changeConfigObj][keyName] = !draft.shipmentLevel[serviceName][changeConfigObj][keyName];
      if (keyName === "sameAsEarlier") {
        draft.shipmentLevel[serviceName][changeConfigObj].active = false;
        if (!draft.shipmentLevel[serviceName][changeConfigObj][keyName]) {
          if (draft.activeServiceTypeList.findIndex((item: any) => item === serviceName) === -1) {
            draft.activeServiceTypeList.push(serviceName);
            draft.shipmentLevel[serviceName].isEditMode = true;
          }
        } else {
          // is Deliery Fee / Service Fee / MOV active, if not then remve service typ from pos zone mappimg
          let is_change_toggle_active_count = 0;
          if (!draft.shipmentLevel[serviceName].deliveryFee[keyName]) {
            is_change_toggle_active_count++;
          }
          if (!draft.shipmentLevel[serviceName].promiseSlotFee[keyName]) {
            is_change_toggle_active_count++;
          }
          if (!draft.shipmentLevel[serviceName].serviceFee[keyName]) {
            is_change_toggle_active_count++;
          }
          if (!draft.shipmentLevel[serviceName].minimumOrderValue[keyName]) {
            is_change_toggle_active_count++;
          }
          if (!draft.shipmentLevel[serviceName].slotProximityFee[keyName]) {
            is_change_toggle_active_count++;
          }

          if (is_change_toggle_active_count === 0) {
            draft.activeServiceTypeList = draft.activeServiceTypeList.filter((serviceTypeName: any) => serviceTypeName !== serviceName);
            draft.shipmentLevel[serviceName].isEditMode = false;
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_delivery_fee_calculation_toggle_change_util = (multiShipmentLevel: any, serviceName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[serviceName].deliveryFee.active = !draft.shipmentLevel[serviceName].deliveryFee.active;

      if (draft.shipmentLevel[serviceName].deliveryFee.active) {
        draft.shipmentLevel[serviceName].deliveryFee.orderThreshold = "";
        draft.shipmentLevel[serviceName].deliveryFee.belowThreshold = "";
        draft.shipmentLevel[serviceName].deliveryFee.greaterOrderThresold = "";
        draft.shipmentLevel[serviceName].deliveryFee.aboveThreshold = "";
        draft.shipmentLevel[serviceName].deliveryFee.deliveryFeeRangeConfigDTOList = [];
        draft.shipmentLevel[serviceName].deliveryFee.additionalDeliveryFeeConfigDTOList = [];
        draft.shipmentLevel[serviceName].deliveryFee.showDrawerConfig = true;
        draft.shipmentLevel[serviceName].deliveryFee.updateDrawerConfig = false;
        draft.shipmentLevel[serviceName].deliveryFee.deliveryFeeSlotCapacityConfigDTOList = [];
        draft.shipmentLevel[serviceName].deliveryFee.isDeliveryFeeSlotCapacityConfigApplicable = false;
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_service_fee_calculation_toggle_change_util = (multiShipmentLevel: any, serviceName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[serviceName].serviceFee.active = !draft.shipmentLevel[serviceName].serviceFee.active;

      if (draft.shipmentLevel[serviceName].serviceFee.active) {
        draft.shipmentLevel[serviceName].serviceFee.isDynamic = false;
        draft.shipmentLevel[serviceName].serviceFee.isErrorText = "";
        draft.shipmentLevel[serviceName].serviceFee.staticFees = "";
        draft.shipmentLevel[serviceName].serviceFee.dynamicFees = [];
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_toggle_change = (multiShipmentLevel: any, name: any, toggleName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (multiShipmentLevel) {
      draft.multiShipmentLevel[name][toggleName] = !draft.multiShipmentLevel[name][toggleName];
    } else {
      if (toggleName === "openAccordian") {
        draft.shipmentLevel[name][toggleName] = !draft.shipmentLevel[name][toggleName];
      } else {
        if (draft.updateConfigPosId === "" && draft.updateConfigZoneName === "") {
          if (!draft.shipmentLevel[name][toggleName]) {
            draft.activeServiceTypeList.push(name);
          } else {
            draft.activeServiceTypeList = draft.activeServiceTypeList.filter((serviceTypeName: any) => serviceTypeName !== name);
          }
          draft.shipmentLevel[name][toggleName] = !draft.shipmentLevel[name][toggleName];
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_accordion_open_state_util = (dlvFeeConfigState: any, openStateName: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft[openStateName] = !draft[openStateName];
  });
  return newDlvFeeConfigState;
};

export const _handle_service_fee_static_dynamic_toggle_change_util = (checked: any, multiShipmentLevel: any, serviceName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[serviceName].serviceFee.isDynamic = checked;
      if (checked) {
        draft.shipmentLevel[serviceName].serviceFee.staticFees = "";
        draft.shipmentLevel[serviceName].serviceFee.dynamicFees = [];
        draft.shipmentLevel[serviceName].serviceFee.dynamicFees.push({
          feePercentage: "",
          minValue: "",
          maxValue: "",
        });
      } else {
        draft.shipmentLevel[serviceName].serviceFee.dynamicFees = [];
        draft.shipmentLevel[serviceName].serviceFee.staticFees = "";
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_service_type_delivery_fee_input_change = (inputName: any, multiShipmentLevel: any, name: any, value: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (name === "orderThreshold" && value !== "" && (isNaN(Number(value)) || !FEE_REG_EXP_VALUE.test(value) || parseInt(value) < 0)) {
      return;
    } else if (value !== "" && (isNaN(Number(value)) || !FEE_REG_EXP_VALUE.test(value))) {
      return;
    }

    if (multiShipmentLevel) {
      draft.multiShipmentLevel[inputName][name] = value;
    } else {
      if (name === "orderThreshold") {
        draft.shipmentLevel[inputName].deliveryFee.greaterOrderThresold = value;
      }

      draft.shipmentLevel[inputName].deliveryFee[name] = value;
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_threshold_range_action = (multiShipmentLevel: any, name: any, action: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      if (name !== undefined && name !== "") {
        if (action === "add") {
          let dummy_object = {
            previousOrderThreshold: "",
            newOrderThreshold: "",
            deliveryFee: "",
            isValid: true, // to check if the newOrderThreshold is greater than previousOrderThreshold
            isErrorText: "",
          } as deliveryFeeRangeConfigDTOListInterface;
          if (
            draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList &&
            Array.isArray(draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList) &&
            draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList.length > 0
          ) {
            dummy_object.previousOrderThreshold = draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList[draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList.length - 1].newOrderThreshold;
          } else {
            dummy_object.previousOrderThreshold = draft.shipmentLevel[name].deliveryFee.orderThreshold;
          }
          draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList.push(dummy_object);
        } else if (action === "remove") {
          if (
            draft.shipmentLevel &&
            draft.shipmentLevel[name] &&
            draft.shipmentLevel[name].deliveryFee !== undefined &&
            draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList &&
            Array.isArray(draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList) &&
            draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList.length > 0
          ) {
            draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList.pop();
            if (draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList.length > 0) {
              draft.shipmentLevel[name].deliveryFee.greaterOrderThresold = draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList[draft.shipmentLevel[name].deliveryFee.deliveryFeeRangeConfigDTOList.length - 1].newOrderThreshold;
            } else {
              draft.shipmentLevel[name].deliveryFee.greaterOrderThresold = draft.shipmentLevel[name].deliveryFee.orderThreshold;
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_service_type_threshold_range_input_change = (multiShipmentLevel: any, shipmentName: any, name: any, value: any, rangeObjectIndex: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      if (shipmentName !== undefined && shipmentName !== "" && name !== undefined && name !== "") {
        if (name === "newOrderThreshold" && value !== "" && (isNaN(Number(value)) || !FEE_REG_EXP_VALUE.test(value) || parseInt(value) < 0)) {
          return;
        } else if (value !== "" && (isNaN(Number(value)) || !FEE_REG_EXP_VALUE.test(value))) {
          return;
        }

        if (name === "newOrderThreshold") {
          draft.shipmentLevel[shipmentName].deliveryFee.deliveryFeeRangeConfigDTOList[rangeObjectIndex].isErrorText = "";
          if (Number(value) > Number(draft.shipmentLevel[shipmentName].deliveryFee.deliveryFeeRangeConfigDTOList[rangeObjectIndex].previousOrderThreshold)) {
            draft.shipmentLevel[shipmentName].deliveryFee.greaterOrderThresold = value;
          } else {
            draft.shipmentLevel[shipmentName].deliveryFee.greaterOrderThresold = draft.shipmentLevel[shipmentName].deliveryFee.orderThreshold;
            draft.shipmentLevel[shipmentName].deliveryFee.deliveryFeeRangeConfigDTOList[rangeObjectIndex].isErrorText = "Value may have configuration already present";
            draft.shipmentLevel[shipmentName].deliveryFee.deliveryFeeRangeConfigDTOList.forEach((item: any) => {
              if (
                draft.shipmentLevel[shipmentName].deliveryFee.greaterOrderThresold !== undefined &&
                draft.shipmentLevel[shipmentName].deliveryFee.greaterOrderThresold !== "" &&
                Number(draft.shipmentLevel[shipmentName].deliveryFee.greaterOrderThresold) < Number(item.newOrderThreshold)
              ) {
                draft.shipmentLevel[shipmentName].deliveryFee.greaterOrderThresold = item.newOrderThreshold;
              }
            });
          }
        }
        draft.shipmentLevel[shipmentName].deliveryFee.deliveryFeeRangeConfigDTOList[rangeObjectIndex][name] = value;
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_design_add_additional_configuration = (dlvFeeConfigState: any, shipmentType: any, isMultiShipmentLevel: any, dateTimeDataObject: any, capacity_utilisation_configuration: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.stateConfigurationDialog = false;
    draft.stateConfigurationStateObject = {};
    if (shipmentType && shipmentType !== "") {
      if (!isMultiShipmentLevel) {
        draft.shipmentLevel[shipmentType].deliveryFee.additionalDeliveryFeeConfigDTOList = dateTimeDataObject;
        draft.shipmentLevel[shipmentType].deliveryFee.deliveryFeeSlotCapacityConfigDTOList = capacity_utilisation_configuration;
        draft.shipmentLevel[shipmentType].deliveryFee.isDeliveryFeeSlotCapacityConfigApplicable =
          capacity_utilisation_configuration !== undefined && Array.isArray(capacity_utilisation_configuration) && capacity_utilisation_configuration.length > 0 ? true : false;
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_empty_pos_zone_mapping_data = (dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.posZoneServiceRecord = [];
    draft.posZoneServiceFilteredRecord = [];
    draft.selectedPosServiceTypeZone = [];
    draft.totalRowsCountData = 0;
  });
  return newDlvFeeConfigState;
};

export const _handle_empty_pos_zone_mapping_data_and_loading = (dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.loading = true;
    draft.posZoneServiceRecord = [];
    draft.posZoneServiceFilteredRecord = [];
    draft.selectedPosServiceTypeZone = [];
    draft.totalRowsCountData = 0;
    draft.totalAssignedAreaCount = 0;
  });
  return newDlvFeeConfigState;
};

export const _design_Pos_Zone_Service_List_Record = (responseObject: any) => {
  let listData = [] as any;
  let totalRowsCountData = 0;
  if (responseObject && Array.isArray(responseObject) && responseObject.length > 0) {
    responseObject.forEach((element: any) => {
      const { posId, posNo, storeName } = element;
      const mainPosObject = {
        id: posNo,
        posId: posId,
        posNo: posNo,
        posName: storeName,
        zoneServicetypeList: [] as any,
        serviceTypeRecord: 0,
        zoneTypeListRecord: 0,
        isOpened: false,
        isChecked: false,
        serviceTypeId: "",
      };
      const serviceTypeList = new Set<string>();
      const zoneTypeList = new Set<string>();
      if (element && element.zoneServiceTypeList && Array.isArray(element.zoneServiceTypeList) && element.zoneServiceTypeList.length > 0) {
        const { zoneServiceTypeList } = element;
        zoneServiceTypeList.forEach((elementType: any) => {
          serviceTypeList.add(elementType.serviceType);
          zoneTypeList.add(elementType.zoneName);
          const subPosObject = {
            posId: posId,
            posNo: posNo,
            posName: storeName,
            zoneName: elementType.zoneName,
            zoneId: elementType.zoneId,
            serviceTypeId: elementType.serviceTypeId,
            serviceType: elementType.serviceType,
            isChecked: false,
            cncReferenceId: elementType.cncReferenceId !== "" ? elementType.cncReferenceId : "",
            status: elementType?.status || "",
          };
          mainPosObject.serviceTypeId = elementType?.serviceTypeId || "";
          mainPosObject.zoneServicetypeList.push(subPosObject);
          totalRowsCountData++;
        });
      }
      mainPosObject.serviceTypeRecord = serviceTypeList.size;
      mainPosObject.zoneTypeListRecord = zoneTypeList.size;
      listData.push(mainPosObject);
    });
  }
  const returnObject = {
    totalRowsCountData: totalRowsCountData,
    listData: listData,
  };
  return returnObject;
};

export const _handle_Pos_Zone_Service_List_Record = (dlvFeeConfigState: any, totalRowsCountData: any, listData: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.loading = false;
    draft.posZoneServiceRecord = listData;
    draft.posZoneServiceFilteredRecord = listData;
    draft.selectedPosServiceTypeZone = [];
    draft.totalRowsCountData = totalRowsCountData;
  });
  return newDlvFeeConfigState;
};

export const _handle_empty_pos_zone_mapping_data_and_loading_off = (dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.loading = false;
  });
  return newDlvFeeConfigState;
};

export const _handle_pos_zone_number_open_index = (dlvFeeConfigState: any, posListIdName: any, posIndex: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (
      draft.posZoneServiceFilteredRecord &&
      Array.isArray(draft.posZoneServiceFilteredRecord) &&
      draft.posZoneServiceFilteredRecord.length > 0 &&
      draft.posZoneServiceFilteredRecord[posIndex] &&
      draft.posZoneServiceFilteredRecord[posIndex].id &&
      draft.posZoneServiceFilteredRecord[posIndex].id !== "" &&
      draft.posZoneServiceFilteredRecord[posIndex].id === posListIdName
    ) {
      draft.posZoneServiceFilteredRecord[posIndex].isOpened = !draft.posZoneServiceFilteredRecord[posIndex].isOpened;
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_pos_zone_header_row_check_box_change = (dlvFeeConfigState: any, checked: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.posZoneServiceFilteredRecord && Array.isArray(draft.posZoneServiceFilteredRecord) && draft.posZoneServiceFilteredRecord.length > 0) {
      let totalAssignedAreaCount = 0;
      let selectedPosServiceTypeZone: any = [];
      draft.posZoneServiceFilteredRecord.forEach((posItem: any) => {
        posItem.isChecked = checked;
        posItem.zoneServicetypeList.forEach((posDetails: any) => {
          posDetails.isChecked = checked;
          if (checked) {
            totalAssignedAreaCount++;
            selectedPosServiceTypeZone.push(posDetails);
          } else {
            totalAssignedAreaCount = 0;
            selectedPosServiceTypeZone = [];
          }
        });
      });
      draft.totalAssignedAreaCount = totalAssignedAreaCount;
      draft.selectedPosServiceTypeZone = selectedPosServiceTypeZone;
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_header_main_row_check_box_on_change_utils = (dlvFeeConfigState: any, checked: any, posListObj: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.posZoneServiceFilteredRecord && draft.totalAssignedAreaCount >= 0 && draft.selectedPosServiceTypeZone) {
      // Bug on search
      let posListIndex = 0;
      if (posListObj !== undefined && posListObj.id !== undefined && posListObj.id !== "") {
        posListIndex = draft.posZoneServiceFilteredRecord.findIndex((item: any) => item.id === posListObj.id);
        if (posListIndex >= 0) {
          draft.posZoneServiceFilteredRecord[posListIndex].isChecked = checked;
          draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList.forEach((posDetails: any) => {
            posDetails.isChecked = checked;
            if (checked) {
              draft.totalAssignedAreaCount++;
              draft.selectedPosServiceTypeZone.push(posDetails);
            } else {
              draft.totalAssignedAreaCount--;
              var removeIndex = draft.selectedPosServiceTypeZone.findIndex((item: any) => item.posNo === posDetails.posNo && item.serviceType === posDetails.serviceType && item.zoneName === posDetails.zoneName);
              if (removeIndex !== -1) {
                draft.selectedPosServiceTypeZone.splice(removeIndex, 1);
              }
            }
          });
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_header_inner_row_check_box_on_change_utils = (dlvFeeConfigState: any, checked: any, posListIndex: any, posDataIndex: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.posZoneServiceFilteredRecord && draft.totalAssignedAreaCount >= 0 && draft.selectedPosServiceTypeZone) {
      draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList[posDataIndex].isChecked = checked;

      if (checked) {
        draft.selectedPosServiceTypeZone.push(draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList[posDataIndex]);
        draft.totalAssignedAreaCount++;
      } else {
        const dataToDelete = draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList[posDataIndex];
        var removeIndex = draft.selectedPosServiceTypeZone.findIndex((item: any) => item.posNo === dataToDelete.posNo && item.serviceType === dataToDelete.serviceType && item.zoneName === dataToDelete.zoneName);
        if (removeIndex !== -1) {
          draft.selectedPosServiceTypeZone.splice(removeIndex, 1);
        }
        draft.totalAssignedAreaCount--;
      }

      let totalRowsSelected = 0;
      draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList.forEach((item: any) => {
        if (item.isChecked) {
          totalRowsSelected++;
        }
      });
      if (totalRowsSelected === draft.posZoneServiceFilteredRecord[posListIndex].zoneServicetypeList.length) {
        draft.posZoneServiceFilteredRecord[posListIndex].isChecked = checked;
      } else {
        draft.posZoneServiceFilteredRecord[posListIndex].isChecked = false;
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_delete_selected_row_utils = (dlvFeeConfigState: any, itemData: any, index: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.posZoneServiceFilteredRecord && draft.totalAssignedAreaCount >= 0 && draft.selectedPosServiceTypeZone) {
      let deleted_item_index = draft.selectedPosServiceTypeZone.findIndex((item: any) => item.posNo === itemData.posNo && item.zoneId === itemData.zoneId && item.zoneName === itemData.zoneName);
      if (deleted_item_index > -1) {
        draft.selectedPosServiceTypeZone.splice(deleted_item_index, 1);
        draft.totalAssignedAreaCount--;

        var removeIndex = draft.posZoneServiceFilteredRecord.findIndex((item: any) => item.posNo === itemData.posNo);
        if (removeIndex !== -1) {
          draft.posZoneServiceFilteredRecord[removeIndex].isChecked = false;
          const innerIndex = draft.posZoneServiceFilteredRecord[removeIndex].zoneServicetypeList.findIndex((item: any) => item.posNo === itemData.posNo && item.serviceType === itemData.serviceType && item.zoneName === itemData.zoneName);
          if (innerIndex !== -1) {
            draft.posZoneServiceFilteredRecord[removeIndex].zoneServicetypeList[innerIndex].isChecked = false;
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

function getPosDetailsForPosZone(posDetails: PosDetails): selectedPosServiceTypeZoneInterface {
  return {
    posNo: posDetails.posNo,
    serviceType: posDetails.serviceType,
    serviceTypeId: posDetails.serviceTypeId,
    storeName: posDetails?.posName,
    zone: posDetails?.zoneName,
    zoneId: posDetails.zoneId,
    status: posDetails.status,
  };
}

function getShipmentLevelRecord(): shipmentLevelDeliveryFeeConfigsInterface {
  return {
    status: "",
    active: false,
    changedConfigs: [], // https://mafretail.atlassian.net/browse/DOS-2227
    orderThreshold: "",
    belowThreshold: "",
    aboveThreshold: "",
    additionalDeliveryFeeConfigDTOList: [],
    deliveryFeeRangeConfigDTOList: [],
    showDrawerConfig: true,
    updateDrawerConfig: false,
    deliveryFeeSlotCapacityConfigDTOList: [],
    isDeliveryFeeSlotCapacityConfigApplicable: false,
    serviceFee: { sameAsEarlier: true, isDynamic: false, staticFees: "", dynamicFees: [] },
    minimumOrderValue: { sameAsEarlier: true, movThreshold: "" },
    slotProximityFeeConfigDTOList: [],
  };
}

function validateThreshold(threshold: string, supplierType: string, supplierTypeIndex: number, shipmentId: string | number, responseValue: any, payloadObject: payloadObjectInterface) {
  if (isEmptyOrNull(threshold)) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-thresholdAmount`] = VALID_THRESHOLD_ERROR;
  } else if (Number(threshold) <= 0) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-thresholdAmount`] = THRESHOLD_ZERO_ERROR;
  } else {
    payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].orderThreshold = Number(threshold);
  }
}

function validateBelowThreshold(belowThreshold: string, supplierType: string, supplierTypeIndex: number, shipmentId: string | number, responseValue: any, payloadObject: payloadObjectInterface) {
  if (isEmptyOrNull(belowThreshold)) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-belowThreshold`] = VALID_BELOW_THRESHOLD_ERROR;
  } else {
    payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].belowThreshold = Number(belowThreshold);
  }
}

function validateAboveThreshold(aboveThreshold: string, supplierType: string, supplierTypeIndex: number, shipmentId: string | number, responseValue: any, payloadObject: payloadObjectInterface) {
  if (isEmptyOrNull(aboveThreshold)) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-aboveThreshold`] = VALID_ABOVE_THRESHOLD_ERROR;
  } else {
    payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].aboveThreshold = Number(aboveThreshold);
  }
}

function validateCapacityLowSlotFee(capacityLow: string, slotObjectIndex: number, supplierType: string, supplierTypeIndex: number, responseValue: any): boolean {
  if (isEmptyOrNull(capacityLow)) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-capacityHigh-${slotObjectIndex}`] = VALID_PREFERRED_SLOT_LOW_CAPACITY_THRESHOLD_ERROR;
    return false;
  }
  return true;
}

function validateCapacityHighSlotFee(capacityHigh: string, capacityLow: string, slotObjectIndex: number, supplierType: string, supplierTypeIndex: number, responseValue: any): boolean {
  if (isEmptyOrNull(capacityHigh) || Number(capacityLow) >= Number(capacityHigh)) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-capacityHigh-${slotObjectIndex}`] = VALID_PREFERRED_SLOT_HIGH_CAPACITY_THRESHOLD_ERROR;
    return false;
  }
  return true;
}

function validateCapacityDeliverySlotFee(deliveryFee: string, slotObjectIndex: number, supplierType: string, supplierTypeIndex: number, responseValue: any): boolean {
  if (isEmptyOrNull(deliveryFee)) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFee-${slotObjectIndex}`] = VALID_PREFERRED_SLOT_DELIVERY_FEE_ERROR;
    return false;
  }
  return true;
}

// Slot Proximity Fee Configuration
function validateSlotProximityMinTime(minTimeToSlot: string, slotObjectIndex: number, supplierType: string, supplierTypeIndex: number, responseValue: any): boolean {
  if (isEmptyOrNull(minTimeToSlot)) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-capacityHigh-${slotObjectIndex}`] = VALID_PREFERRED_SLOT_PROXIMITY_FEE_MIN_TIME_ERROR;
    return false;
  }
  return true;
}

function validateSlotProximityMaxTime(maxTimeToSlot: string, minTimeToSlot: string, slotObjectIndex: number, supplierType: string, supplierTypeIndex: number, responseValue: any): boolean {
  if (isEmptyOrNull(maxTimeToSlot) || Number(minTimeToSlot) >= Number(maxTimeToSlot)) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-maxTimeToSlot-${slotObjectIndex}`] = VALID_PREFERRED_SLOT_PROXIMITY_FEE_MAX_TIME_ERROR;
    return false;
  }
  return true;
}

function validateSlotProximityDeliveryFee(deliveryFee: string, slotObjectIndex: number, supplierType: string, supplierTypeIndex: number, responseValue: any): boolean {
  if (isEmptyOrNull(deliveryFee)) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFee-${slotObjectIndex}`] = SLOT_PROXIMITY_FEE_DELIVERY_FEE_ERROR;
    return false;
  }
  return true;
}

function validateGreaterOrderThresold(greaterOrderThresold: string, supplierType: string, supplierTypeIndex: number, shipmentId: string | number, responseValue: any, payloadObject: payloadObjectInterface) {
  if (isEmptyOrNull(greaterOrderThresold)) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-thresholdAmount`] = VALID_THRESHOLD_ERROR;
  } else if (Number(greaterOrderThresold) <= 0) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-thresholdAmount`] = THRESHOLD_ZERO_ERROR;
  } else {
    payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].orderThreshold = Number(greaterOrderThresold);
  }
}

function validateMOVThreshold(movThreshold: string, supplierType: string, supplierTypeIndex: number, shipmentId: string | number, responseValue: any, payloadObject: payloadObjectInterface) {
  if (isEmptyOrNull(movThreshold)) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-serviceFee-movThreshold`] = VALID_MOV_THRESHOLD_ERROR;
  } else if (Number(movThreshold) < 0) {
    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-minimumOrderValue-movThreshold`] = VALID_MOV_THRESHOLD_ERROR;
  } else {
    payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].minimumOrderValue.movThreshold = movThreshold;
  }
}

export const _validate_configuration_save_object = (dlvFeeConfigState: any, type: any) => {
  const payloadObject: payloadObjectInterface = { selectedPosServiceTypeZone: [], shipmentLevelDeliveryFeeConfigs: {} };
  const responseValue: any = {};
  let emptyCount = 0 as any;

  if (isUndefined(dlvFeeConfigState)) return {};

  if (isEmptyArray(dlvFeeConfigState.selectedPosServiceTypeZone)) {
    CustomAlert(ALERT_TYPES.ERROR, "Pos Zone list required");
    return {};
  }

  if (isArrayValid(dlvFeeConfigState.selectedPosServiceTypeZone)) {
    payloadObject.selectedPosServiceTypeZone = dlvFeeConfigState.selectedPosServiceTypeZone.map(getPosDetailsForPosZone);
  }

  if (dlvFeeConfigState.shipmentLevel) {
    const shipmentTypes = Object.keys(dlvFeeConfigState.shipmentLevel);

    const validShipmentLevels = shipmentTypes.filter((shipmentType) => !isEmptyOrNull(shipmentType) && dlvFeeConfigState.shipmentLevel[shipmentType] && isNonEmptyObject(dlvFeeConfigState.shipmentLevel[shipmentType]));

    if (validShipmentLevels.length === 0) {
      CustomAlert(ALERT_TYPES.ERROR, "No valid shipment levels found");
      return {};
    }

    validShipmentLevels.forEach((supplierType: string, supplierTypeIndex: number) => {
      const { shipmentId, isEditMode, status, deliveryFee, serviceFee, minimumOrderValue, promiseSlotFee, slotProximityFee } = dlvFeeConfigState.shipmentLevel[supplierType];
      let no_change_in_configuration = 0 as any;

      if (!isEmptyOrNull(shipmentId) && !isEmptyOrNull(isEditMode) !== undefined && isEditMode) {
        payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId] = { ...getShipmentLevelRecord() };

        if (!isEmptyOrNull(status)) {
          payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].status = status;
        }

        if (!isEmptyOrNull(type) && type === "save") {
          payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].orderThreshold = Number(1); // Adding by default, as API faling with empty values
          payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].belowThreshold = Number(1);
          payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].aboveThreshold = Number(1);
        } else if (!isEmptyOrNull(type) && type === "update") {
          const { id, greaterOrderThresold, belowThreshold, aboveThreshold } = deliveryFee;
          if (!isEmptyOrNull(id)) {
            payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].id = id;
          }
          validateThreshold(String(greaterOrderThresold), supplierType, supplierTypeIndex, shipmentId, responseValue, payloadObject);
          validateBelowThreshold(String(belowThreshold), supplierType, supplierTypeIndex, shipmentId, responseValue, payloadObject);
          validateAboveThreshold(String(aboveThreshold), supplierType, supplierTypeIndex, shipmentId, responseValue, payloadObject);
        }

        if (!isEmptyOrNull(deliveryFee) && isNonEmptyObject(deliveryFee)) {
          if (!deliveryFee.sameAsEarlier) {
            if (deliveryFee.active) {
              payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].changedConfigs.push(SAVE_CONFIG_ENUM.DELIVERY_FEES);
              const { active, orderThreshold, greaterOrderThresold, belowThreshold, aboveThreshold, deliveryFeeRangeConfigDTOList, additionalDeliveryFeeConfigDTOList } = deliveryFee;
              if (active === true) {
                payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].active = true;
              }
              if (!isEmptyOrNull(dlvFeeConfigState.shipmentLevel[supplierType].id)) {
                payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].id = dlvFeeConfigState.shipmentLevel[supplierType].id;
              }
              validateGreaterOrderThresold(String(greaterOrderThresold), supplierType, supplierTypeIndex, shipmentId, responseValue, payloadObject);
              validateBelowThreshold(String(belowThreshold), supplierType, supplierTypeIndex, shipmentId, responseValue, payloadObject);
              validateAboveThreshold(String(aboveThreshold), supplierType, supplierTypeIndex, shipmentId, responseValue, payloadObject);

              if (isArrayValid(deliveryFeeRangeConfigDTOList)) {
                if (isEmptyOrNull(orderThreshold)) {
                  responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-thresholdAmount`] = VALID_THRESHOLD_ERROR;
                } else if (isNaN(orderThreshold) || Number(orderThreshold) <= 0) {
                  responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-thresholdAmount`] = THRESHOLD_ZERO_ERROR;
                } else {
                  payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].deliveryFeeRangeConfigDTOList.push({ orderThreshold: Number(orderThreshold), deliveryFee: Number(belowThreshold) });
                }

                deliveryFeeRangeConfigDTOList.forEach((rangeObject: any, rangeObjectIndex: number) => {
                  if (rangeObject && rangeObject.isErrorText === "") {
                    let pay_load_object = { orderThreshold: "", deliveryFee: "" } as any;
                    const { newOrderThreshold, deliveryFee } = rangeObject;

                    if (rangeObjectIndex + 1 === deliveryFeeRangeConfigDTOList.length) {
                      if (isEmptyOrNull(newOrderThreshold)) {
                        responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}-newOrderThreshold`] = VALID_THRESHOLD_ERROR;
                      } else if (isNaN(newOrderThreshold) || Number(newOrderThreshold) <= 0) {
                        responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}-newOrderThreshold`] = THRESHOLD_ZERO_ERROR;
                      } else {
                        if (isEmptyOrNull(deliveryFee)) {
                          responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}-newOrderThreshold`] = VALID_THRESHOLD_AMOUNT_ERROR;
                        } else {
                          payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].belowThreshold = Number(deliveryFee);
                        }
                      }
                    } else {
                      if (isEmptyOrNull(newOrderThreshold)) {
                        responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}-newOrderThreshold`] = VALID_THRESHOLD_ERROR;
                      } else if (isNaN(newOrderThreshold) || Number(newOrderThreshold) <= 0) {
                        responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}-newOrderThreshold`] = THRESHOLD_ZERO_ERROR;
                      } else {
                        pay_load_object.orderThreshold = Number(newOrderThreshold);
                      }

                      if (isEmptyOrNull(deliveryFee)) {
                        responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-belowThreshold`] = VALID_THRESHOLD_AMOUNT_ERROR;
                      } else {
                        pay_load_object.deliveryFee = Number(deliveryFee);
                      }

                      payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].deliveryFeeRangeConfigDTOList.push(pay_load_object);
                    }
                  } else {
                    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFeeRangeConfigDTOList-${rangeObjectIndex}`] = rangeObject.isErrorText;
                    return;
                  }
                });
              }

              if (additionalDeliveryFeeConfigDTOList && Array.isArray(additionalDeliveryFeeConfigDTOList) && additionalDeliveryFeeConfigDTOList.length > 0) {
                let addition_config_payload_object = prepare_addtional_date_time_payload(additionalDeliveryFeeConfigDTOList, type);
                if (addition_config_payload_object && addition_config_payload_object.error_object && addition_config_payload_object.error_object !== "") {
                  responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-additionalDeliveryFeeConfigDTOList`] = addition_config_payload_object.error_object;
                } else {
                  payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].additionalDeliveryFeeConfigDTOList = addition_config_payload_object.payload;
                }
              }
            } else {
              responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFee-sameAsEarlier-active`] = DELIVER_FEE_ACTIVE_FLAG_ERROR;
            }
          } else {
            no_change_in_configuration++;
          }
        }

        // Service Fee
        if (!isEmptyOrNull(serviceFee) && isNonEmptyObject(serviceFee)) {
          if (!serviceFee.sameAsEarlier) {
            if (serviceFee.active) {
              payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].changedConfigs.push(SAVE_CONFIG_ENUM.SERVICE_FEES);
              const { isDynamic, staticFees, dynamicFees } = serviceFee;

              if (serviceFee.id !== undefined && serviceFee.id !== "") {
                payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].serviceFee.id = serviceFee.id;
              }

              if (isDynamic !== undefined) {
                if (isDynamic) {
                  payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].serviceFee.staticFees = "";
                  payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].serviceFee.isDynamic = isDynamic;
                  if (dynamicFees !== undefined && Array.isArray(dynamicFees) && dynamicFees.length > 0) {
                    dynamicFees.forEach((dynamicfee: any) => {
                      const { feePercentage, minValue, maxValue } = dynamicfee;
                      const dynamicFeesPayloadObject = { feePercentage: "", minValue: minValue, maxValue: maxValue } as any;

                      if (isEmptyOrNull(feePercentage)) {
                        responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-serviceFee-dynamicFees-feePercentage`] = VALID_DYNAMIC_SERVICE_FEE_PERCENTAGE_ERROR;
                      } else if (isNaN(feePercentage) || Number(feePercentage) < 0 || Number(feePercentage) > 100) {
                        responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-serviceFee-dynamicFees-feePercentage`] = VALID_DYNAMIC_SERVICE_FEE_PERCENTAGE_ERROR;
                      } else {
                        dynamicFeesPayloadObject.feePercentage = feePercentage;
                      }

                      if (!isEmptyOrNull(minValue) && !isEmptyOrNull(maxValue)) {
                        if (isNaN(minValue) || Number(minValue) < 0) {
                          responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-serviceFee-dynamicFees-minMaxValue`] = VALID_DYNAMIC_SERVICE_FEE_MIN_ERROR;
                        }
                        if (isNaN(maxValue) || Number(maxValue) < 0) {
                          responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-serviceFee-dynamicFees-minMaxValue`] = VALID_DYNAMIC_SERVICE_FEE_MAX_ERROR;
                        }
                        if (Number(maxValue) <= Number(minValue)) {
                          responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-serviceFee-dynamicFees-minMaxValue`] = VALID_DYNAMIC_SERVICE_FEE_MIN_MAX_VALUE_ERROR;
                        }
                      } else if ((!isEmptyOrNull(minValue) && isEmptyOrNull(maxValue)) || (isEmptyOrNull(minValue) && !isEmptyOrNull(maxValue))) {
                        responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-serviceFee-dynamicFees-minMaxValue`] = VALID_DYNAMIC_SERVICE_FEE_MIN_MAX_ERROR;
                      }

                      payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].serviceFee.dynamicFees.push(dynamicFeesPayloadObject);
                    });
                  } else {
                    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-serviceFee-dynamicFees`] = VALID_DYNAMIC_SERVICE_FEE_ERROR;
                  }
                } else {
                  payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].serviceFee.dynamicFees = [];
                  if (staticFees !== undefined && staticFees !== "") {
                    if (isNaN(staticFees) || Number(staticFees) < 0) {
                      responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-serviceFee-staticFees`] = VALID_SERVICE_FEE_ERROR;
                    } else {
                      payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].serviceFee.staticFees = staticFees;
                    }
                  } else {
                    // responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-serviceFee-staticFees`] = VALID_SERVICE_FEE_ERROR;
                    payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].serviceFee.staticFees = "";
                  }
                }
              }
            } else {
              responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-serviceFee-sameAsEarlier-active`] = SERVICE_FEE_ACTIVE_FLAG_ERROR;
            }
          } else {
            no_change_in_configuration++;
          }
        }

        // MOV Thresold
        if (!isEmptyOrNull(minimumOrderValue) && isNonEmptyObject(minimumOrderValue)) {
          if (!minimumOrderValue.sameAsEarlier) {
            if (minimumOrderValue.active) {
              payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].changedConfigs.push(SAVE_CONFIG_ENUM.MOV);
              validateMOVThreshold(String(minimumOrderValue.movThreshold), supplierType, supplierTypeIndex, shipmentId, responseValue, payloadObject);
            } else {
              responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-minimumOrderValue-sameAsEarlier-active`] = MOV_THRESHOLD_ACTIVE_FLAG_ERROR;
            }
          } else {
            no_change_in_configuration++;
          }
        }

        // Promise Slot Fee
        if (!isEmptyOrNull(promiseSlotFee) && isNonEmptyObject(promiseSlotFee)) {
          if (!promiseSlotFee.sameAsEarlier) {
            if (promiseSlotFee.active) {
              payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].changedConfigs.push(SAVE_CONFIG_ENUM.SLOT_CAPACITY_FEES);
              const { capacityUtilisationConfiguration } = promiseSlotFee;
              if (isArrayValid(capacityUtilisationConfiguration)) {
                capacityUtilisationConfiguration.forEach((slotObject: capacityUtilisationConfigurationInterface, slotObjectIndex: number) => {
                  const { id, capacityLow, capacityHigh, deliveryFee } = slotObject;
                  if (
                    validateCapacityLowSlotFee(String(capacityLow), slotObjectIndex, supplierType, supplierTypeIndex, responseValue) &&
                    validateCapacityHighSlotFee(String(capacityHigh), String(capacityLow), slotObjectIndex, supplierType, supplierTypeIndex, responseValue) &&
                    validateCapacityDeliverySlotFee(String(deliveryFee), slotObjectIndex, supplierType, supplierTypeIndex, responseValue)
                  ) {
                    payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].deliveryFeeSlotCapacityConfigDTOList.push({ id, capacityLow, capacityHigh, deliveryFee, active: true });
                    payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].isDeliveryFeeSlotCapacityConfigApplicable = true;
                  }
                });
                if (responseValue && countObjectProperties(responseValue) === 0) {
                  const maxRangeValue = capacityUtilisationConfiguration[capacityUtilisationConfiguration.length - 1].capacityHigh;
                  if (Number(maxRangeValue) !== 100) {
                    responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-capacityUtilisationConfiguration-maxRangeValue-error`] = DELIVERY_FEE_TEXT_CONSTANT.RANGE_CLOSURE_VALUE_REQUIRED;
                  }
                }
              }
            } else {
              responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-promiseSlotFee-sameAsEarlier-active`] = MOV_THRESHOLD_ACTIVE_FLAG_ERROR;
            }
          } else {
            no_change_in_configuration++;
          }
        }

        // Slot Proximity Fee
        if (!isEmptyOrNull(slotProximityFee) && isNonEmptyObject(slotProximityFee)) {
          if (!slotProximityFee.sameAsEarlier) {
            if (slotProximityFee.active) {
              payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].changedConfigs.push(SAVE_CONFIG_ENUM.SLOT_PROXIMITY_FEES);
              const { slotProximityFeeConfigDTOList } = slotProximityFee;
              if (isArrayValid(slotProximityFeeConfigDTOList)) {
                slotProximityFeeConfigDTOList.forEach((slotObject: SlotProximityFeeConfigDTOListConfigurationInterface, slotObjectIndex: number) => {
                  const { minTimeToSlot, maxTimeToSlot, deliveryFee } = slotObject;
                  if (
                    validateSlotProximityMinTime(String(minTimeToSlot), slotObjectIndex, supplierType, supplierTypeIndex, responseValue) &&
                    validateSlotProximityMaxTime(String(maxTimeToSlot), String(minTimeToSlot), slotObjectIndex, supplierType, supplierTypeIndex, responseValue) &&
                    validateSlotProximityDeliveryFee(String(deliveryFee), slotObjectIndex, supplierType, supplierTypeIndex, responseValue)
                  ) {
                    const obj = { minTimeToSlot: Number(minTimeToSlot) * 60, maxTimeToSlot: Number(maxTimeToSlot) * 60, deliveryFee } as any;
                    if (!isUndefined(slotObject?.id)) {
                      obj.id = slotObject?.id || null;
                    }
                    payloadObject.shipmentLevelDeliveryFeeConfigs[shipmentId].slotProximityFeeConfigDTOList.push(obj);
                  }
                });
              }
            } else {
              responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-slotProximityFee-sameAsEarlier-active`] = SLOT_PROXIMITY_FEE_ACTIVE_FLAG_ERROR;
            }
          } else {
            no_change_in_configuration++;
          }
        }

        // Delivery Fee, Service Fee and MOV sameAsEarlier flag is true, i.e. no changes were made.
        if (no_change_in_configuration === 4) {
          emptyCount++;
        }
      }
    });

    if (emptyCount === Object.keys(dlvFeeConfigState.shipmentLevel).length) {
      CustomAlert(ALERT_TYPES.ERROR, "Minimum one configurtion is required."); // No supplier type is made active for calculation
      return {};
    } else if (responseValue && Object.keys(responseValue).length !== 0) {
      CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
      return {};
    }
  }

  return payloadObject;
};

export const prepare_addtional_date_time_payload = (additionalDlvFeeConfigList: any, type: any) => {
  let addition_config_payload_object = {
    payload: [],
    error_object: "",
  } as any;
  if (additionalDlvFeeConfigList && Array.isArray(additionalDlvFeeConfigList) && additionalDlvFeeConfigList.length > 0) {
    additionalDlvFeeConfigList.forEach((configObj: any) => {
      if (configObj && configObj.daysConfiguration && Array.isArray(configObj.daysConfiguration) && configObj.daysConfiguration.length > 0) {
        configObj.daysConfiguration.forEach((dayObj: any) => {
          if (dayObj && dayObj.days && Array.isArray(dayObj.days) && dayObj.days.length > 0) {
            if (dayObj.timeZoneConfiguration && Array.isArray(dayObj.timeZoneConfiguration) && dayObj.timeZoneConfiguration.length > 0) {
              dayObj.days.forEach((dayName: any) => {
                dayObj.timeZoneConfiguration.forEach((timeObj: any) => {
                  if (timeObj !== undefined && timeObj !== "") {
                    let data_object = {
                      startDate: moment(configObj.startDate).format("YYYY-MM-DD"),
                      endDate: moment(configObj.endDate).format("YYYY-MM-DD"),
                      day: dayName,
                      startTime: moment(timeObj.startTime).format("HH:mm:ss"),
                      endTime: moment(timeObj.endTime).format("HH:mm:ss"),
                      orderThreshold: "",
                      belowThreshold: "",
                      aboveThreshold: "",
                      active: true,
                    } as any;
                    if (timeObj.id !== undefined && timeObj.id !== "") {
                      data_object.id = timeObj.id;
                    }

                    if (isEmptyOrNull(timeObj.thresholdAmount)) {
                      addition_config_payload_object.error_object = "Threshold amount is required";
                      return;
                    } else {
                      data_object.orderThreshold = timeObj.thresholdAmount;
                    }
                    if (isEmptyOrNull(timeObj.thresholdBelowAmount)) {
                      addition_config_payload_object.error_object = "Below threshold amount is required";
                      return;
                    } else {
                      data_object.belowThreshold = timeObj.thresholdBelowAmount;
                    }

                    if (isEmptyOrNull(timeObj.thresholdAboveAmount)) {
                      addition_config_payload_object.error_object = "Above threshold amount is required";
                      return;
                    } else {
                      data_object.aboveThreshold = timeObj.thresholdAboveAmount;
                    }

                    addition_config_payload_object.payload.push(data_object);
                  }
                });
              });
            }
          }
        });
      }
    });
  }
  return addition_config_payload_object;
};

function multiShipmentLevelObject(item: any) {
  return {
    shipmentId: item.id,
    multi_shipment: item.multiShipment,
    isEditMode: false, // main action, "Use this value in calculation"
    enabledEdit: true,
    orderThreshold: "",
    deliveryFee: "",
    isPercentage: false,
  };
}

function shipmentLevelObject(item: any, servicePropositionObj?: any): ShipmentServiceLevelInterface {
  return {
    shipmentId: item.id,
    multi_shipment: item.multiShipment,
    deliveryType: item.deliveryType,
    isEditMode: false,
    openAccordian: false,
    status: "",
    deliveryFee: {
      sameAsEarlier: true,
      active: false,
      enableConfigEdit: true,
      orderThreshold: "",
      belowThreshold: "",
      greaterOrderThresold: "",
      aboveThreshold: "",
      deliveryFeeRangeConfigDTOList: [],
      additionalDeliveryFeeConfigDTOList: [],
      showDrawerConfig: true,
      updateDrawerConfig: false,
      deliveryFeeSlotCapacityConfigDTOList: [],
      isDeliveryFeeSlotCapacityConfigApplicable: false,
    },
    promiseSlotFee: {
      sameAsEarlier: true,
      active: false,
      enableConfigEdit: true,
      capacityUtilisationConfiguration: [],
    },
    serviceFee: {
      sameAsEarlier: true,
      active: false,
      enableConfigEdit: true,
      isDynamic: false,
      isErrorText: "",
      staticFees: "",
      dynamicFees: [],
    },
    minimumOrderValue: {
      sameAsEarlier: true,
      active: false,
      enableConfigEdit: true,
      movThreshold: "",
    },
    slotProximityFee: {
      sameAsEarlier: true,
      active: false,
      enableConfigEdit: true,
      slotProximityFeeConfigDTOList: [{ minTimeToSlot: 0, maxTimeToSlot: "", deliveryFee: "", minTimeToSlotErrorMessage: "", maxTimeToSlotErrorMessage: "", deliveryFeeErrorMessage: "" }],
    },
    displayName: servicePropositionObj?.displayName || "",
    rank: servicePropositionObj?.rank || 999,
  };
}

export const _design_update_delivery_fee_state_object = (responseData: any, dlvFeeConfigState: any, shipmentTypeDetails: any, servicePropositionList: any[]) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.loading = false;
    if (responseData) {
      if (dlvFeeConfigState && dlvFeeConfigState.shipmentLevel && Object.keys(dlvFeeConfigState.shipmentLevel).length === 0) {
        if (shipmentTypeDetails && Array.isArray(shipmentTypeDetails) && shipmentTypeDetails.length > 0) {
          shipmentTypeDetails.forEach((item: any) => {
            if (item.multiShipment) {
              draft.multiShipmentLevel[item.shipment] = { ...multiShipmentLevelObject(item) };
            } else {
              const servicePropositionObj = getServicePropositionObject(servicePropositionList, item?.shipment);
              draft.shipmentLevel[item.shipment] = { ...shipmentLevelObject(item, servicePropositionObj) } as ShipmentServiceLevelInterface;
            }
          });
        }
      }
      const entries = Object.entries(draft.shipmentLevel);
      entries.sort(([, a]: any, [, b]: any) => a?.rank - b?.rank);
      draft.shipmentLevel = Object.fromEntries(entries);

      if (isArrayValid(responseData?.selectedPosServiceTypeZone)) {
        responseData.selectedPosServiceTypeZone.forEach((posZoneConfig: any) => {
          draft.selectedPosServiceTypeZone = [];
          let posZoneObj = prepare_pos_zone_object(posZoneConfig);
          draft.updateConfigPosId = posZoneConfig.posNo;
          draft.updateConfigZoneName = posZoneConfig.zone;
          draft.selectedPosServiceTypeZone.push(posZoneObj);

          if (isNonEmptyObject(responseData?.shipmentLevelDeliveryFeeConfigs)) {
            Object.keys(responseData.shipmentLevelDeliveryFeeConfigs).forEach((dlvFee: any) => {
              if (dlvFee !== undefined && dlvFee !== "") {
                const currentDlvData = responseData.shipmentLevelDeliveryFeeConfigs[dlvFee];
                let shipment_type = shipmentTypeDetails.find((shipmentType: any) => shipmentType.id.toString() === dlvFee.toString());
                if (shipment_type && shipment_type.id && shipment_type.id !== "" && shipment_type.shipment && shipment_type.shipment !== "") {
                  if (shipment_type.multiShipment === false) {
                    if (posZoneObj !== undefined && posZoneObj.status !== undefined && posZoneObj.status !== "") {
                      draft.shipmentLevel[shipment_type.shipment].status = posZoneObj.status;
                    }

                    draft.shipmentLevel[shipment_type.shipment].deliveryFee.sameAsEarlier = true;
                    draft.shipmentLevel[shipment_type.shipment].deliveryFee.enableConfigEdit = false;
                    draft.shipmentLevel[shipment_type.shipment].deliveryFee.active = true;

                    draft.shipmentLevel[shipment_type.shipment].serviceFee.sameAsEarlier = true;
                    draft.shipmentLevel[shipment_type.shipment].serviceFee.enableConfigEdit = false;
                    draft.shipmentLevel[shipment_type.shipment].serviceFee.active = true;

                    draft.shipmentLevel[shipment_type.shipment].minimumOrderValue.sameAsEarlier = true;
                    draft.shipmentLevel[shipment_type.shipment].minimumOrderValue.enableConfigEdit = false;
                    draft.shipmentLevel[shipment_type.shipment].minimumOrderValue.active = true;

                    draft.shipmentLevel[shipment_type.shipment].promiseSlotFee.sameAsEarlier = true;
                    draft.shipmentLevel[shipment_type.shipment].promiseSlotFee.enableConfigEdit = false;
                    draft.shipmentLevel[shipment_type.shipment].promiseSlotFee.active = true;

                    // Delivery Fee
                    if (currentDlvData.id !== undefined && currentDlvData.id !== "") {
                      draft.shipmentLevel[shipment_type.shipment].isEditMode = true;
                      draft.shipmentLevel[shipment_type.shipment].openAccordian = true;

                      draft.shipmentLevel[shipment_type.shipment].deliveryFee.cncReferenceId = currentDlvData.cncReferenceId;
                      draft.shipmentLevel[shipment_type.shipment].deliveryFee.id = currentDlvData.id;

                      draft.shipmentLevel[shipment_type.shipment].deliveryFee.orderThreshold = currentDlvData.orderThreshold;
                      draft.shipmentLevel[shipment_type.shipment].deliveryFee.belowThreshold = currentDlvData.belowThreshold;
                      draft.shipmentLevel[shipment_type.shipment].deliveryFee.greaterOrderThresold = currentDlvData.orderThreshold;
                      draft.shipmentLevel[shipment_type.shipment].deliveryFee.aboveThreshold = currentDlvData.aboveThreshold;

                      if (currentDlvData.showDrawerConfig !== undefined) {
                        draft.shipmentLevel[shipment_type.shipment].deliveryFee.showDrawerConfig = currentDlvData.showDrawerConfig;
                      }

                      // shipmentLevelDeliveryFeeConfigs
                      draft.shipmentLevel[shipment_type.shipment].deliveryFee.deliveryFeeRangeConfigDTOList = [];
                      if (currentDlvData.deliveryFeeRangeConfigDTOList && Array.isArray(currentDlvData.deliveryFeeRangeConfigDTOList) && currentDlvData.deliveryFeeRangeConfigDTOList.length > 0) {
                        currentDlvData.deliveryFeeRangeConfigDTOList.slice().sort((a: any, b: any) => (a.orderThreshold > b.orderThreshold ? 1 : -1));
                        let previousOrderThreshold = "";
                        currentDlvData.deliveryFeeRangeConfigDTOList.forEach((rangeObject: any, rangeObjectIndex: any) => {
                          if (rangeObject !== undefined && rangeObject !== "") {
                            if (rangeObjectIndex === 0) {
                              draft.shipmentLevel[shipment_type.shipment].deliveryFee.orderThreshold = rangeObject.orderThreshold;
                              draft.shipmentLevel[shipment_type.shipment].deliveryFee.belowThreshold = rangeObject.deliveryFee;
                              previousOrderThreshold = rangeObject.orderThreshold;
                            } else {
                              let range_object_data = {
                                id: rangeObject?.id || "",
                                previousOrderThreshold: previousOrderThreshold,
                                newOrderThreshold: rangeObject.orderThreshold,
                                deliveryFee: rangeObject.deliveryFee,
                                isValid: true,
                                isErrorText: "",
                              } as any;

                              if (rangeObject.id !== undefined && rangeObject.id !== "") {
                                range_object_data.id = rangeObject.id;
                              }

                              previousOrderThreshold = rangeObject.orderThreshold;
                              draft.shipmentLevel[shipment_type.shipment].deliveryFee.deliveryFeeRangeConfigDTOList.push(range_object_data);
                            }
                          }
                        });
                        let range_object_data = {
                          isValid: true,
                          isErrorText: "",
                          previousOrderThreshold: previousOrderThreshold,
                          newOrderThreshold: currentDlvData.orderThreshold,
                          deliveryFee: currentDlvData.belowThreshold,
                        } as any;
                        draft.shipmentLevel[shipment_type.shipment].deliveryFee.deliveryFeeRangeConfigDTOList.push(range_object_data);
                      }

                      // additionalDeliveryFeeConfigDTOList
                      draft.shipmentLevel[shipment_type.shipment].deliveryFee.additionalDeliveryFeeConfigDTOList = parseAdditionalDeliveryFeeConfigDTOList(currentDlvData.additionalDeliveryFeeConfigDTOList);
                    }

                    // Service Fee
                    if (currentDlvData.serviceFee !== undefined && currentDlvData.serviceFee.isDynamic !== undefined) {
                      draft.shipmentLevel[shipment_type.shipment].serviceFee.sameAsEarlier = true;
                      draft.shipmentLevel[shipment_type.shipment].serviceFee.enableConfigEdit = false;
                      draft.shipmentLevel[shipment_type.shipment].serviceFee.active = true;

                      if (currentDlvData.serviceFee.id !== undefined && currentDlvData.serviceFee.id !== "") {
                        draft.shipmentLevel[shipment_type.shipment].serviceFee.id = currentDlvData.serviceFee.id;
                      }
                      draft.shipmentLevel[shipment_type.shipment].serviceFee.isDynamic = currentDlvData.serviceFee.isDynamic;
                      draft.shipmentLevel[shipment_type.shipment].serviceFee.isErrorText = "";
                      draft.shipmentLevel[shipment_type.shipment].serviceFee.staticFees = currentDlvData.serviceFee.staticFees;
                      draft.shipmentLevel[shipment_type.shipment].serviceFee.dynamicFees = currentDlvData.serviceFee.dynamicFees;
                    }

                    // MOV
                    if (currentDlvData.minimumOrderValue !== undefined && currentDlvData.minimumOrderValue.movThreshold !== undefined && currentDlvData.minimumOrderValue.movThreshold !== "") {
                      draft.shipmentLevel[shipment_type.shipment].minimumOrderValue.sameAsEarlier = true;
                      draft.shipmentLevel[shipment_type.shipment].minimumOrderValue.enableConfigEdit = false;
                      draft.shipmentLevel[shipment_type.shipment].minimumOrderValue.active = true;

                      draft.shipmentLevel[shipment_type.shipment].minimumOrderValue.movThreshold = currentDlvData.minimumOrderValue.movThreshold;
                    }

                    // Promise Slot Fee
                    if (isArrayValid(currentDlvData?.deliveryFeeSlotCapacityConfigDTOList)) {
                      draft.shipmentLevel[shipment_type.shipment].promiseSlotFee.sameAsEarlier = true;
                      draft.shipmentLevel[shipment_type.shipment].promiseSlotFee.enableConfigEdit = false;
                      draft.shipmentLevel[shipment_type.shipment].promiseSlotFee.active = true;
                      const record = parse_capacity_utilisation_delivery_fee_dto_lits(currentDlvData.deliveryFeeSlotCapacityConfigDTOList);
                      draft.shipmentLevel[shipment_type.shipment].promiseSlotFee.capacityUtilisationConfiguration = record;
                      draft.shipmentLevel[shipment_type.shipment].deliveryFee.isDeliveryFeeSlotCapacityConfigApplicable = currentDlvData.isDeliveryFeeSlotCapacityConfigApplicable;
                    }

                    if (isArrayValid(currentDlvData?.slotProximityFeeConfigDTOList)) {
                      draft.shipmentLevel[shipment_type.shipment].slotProximityFee.sameAsEarlier = true;
                      draft.shipmentLevel[shipment_type.shipment].slotProximityFee.enableConfigEdit = false;
                      draft.shipmentLevel[shipment_type.shipment].slotProximityFee.active = true;
                      const record = parse_slot_proximity_fee_config_dto_lits(currentDlvData?.slotProximityFeeConfigDTOList);
                      draft.shipmentLevel[shipment_type.shipment].slotProximityFee.slotProximityFeeConfigDTOList = record;
                    }
                  }
                }
              }
            });
          }
        });
      }
    }
  });
  return newDlvFeeConfigState;
};

const prepare_pos_zone_object = (posZoneConfig: any) => {
  let posZoneObj = {
    posNo: posZoneConfig.posNo || "",
    serviceType: posZoneConfig.serviceType || "",
    serviceTypeId: posZoneConfig.serviceTypeId || "",
    posName: posZoneConfig.storeName || "",
    zoneName: posZoneConfig.zone || "",
    zoneId: posZoneConfig.zoneId || "",
    cncReferenceId: posZoneConfig.cncReferenceId || "",
    status: posZoneConfig.status || "",
  };
  return posZoneObj;
};

const parseAdditionalDeliveryFeeConfigDTOList = (additionalDeliveryFeeConfigDTOList: any) => {
  let returnData = [] as any;
  if (additionalDeliveryFeeConfigDTOList && Array.isArray(additionalDeliveryFeeConfigDTOList) && additionalDeliveryFeeConfigDTOList.length > 0) {
    additionalDeliveryFeeConfigDTOList.forEach((item: any) => {
      if (item !== undefined && item.startDate !== undefined && item.startDate !== "" && item.endDate !== undefined && item.endDate !== "") {
        if (returnData.length === 0) {
          let configuration_Object = create_configuration_object(item);
          returnData.push(configuration_Object);
        } else {
          let combination_present_index = returnData.findIndex((element: any) => element.startDate === item.startDate && element.endDate === item.endDate);
          if (combination_present_index >= 0) {
            let configuration_Object: any = returnData[combination_present_index];
            if (configuration_Object && configuration_Object.daysConfiguration && Array.isArray(configuration_Object.daysConfiguration) && configuration_Object.daysConfiguration.length > 0) {
              let days_Configuration_present_flag = configuration_Object.daysConfiguration.findIndex((element: any) => element.days.includes(createCamelCase(item.day)));
              if (days_Configuration_present_flag >= 0) {
                if (
                  configuration_Object.daysConfiguration &&
                  configuration_Object.daysConfiguration[days_Configuration_present_flag] !== undefined &&
                  configuration_Object.daysConfiguration[days_Configuration_present_flag].timeZoneConfiguration &&
                  Array.isArray(configuration_Object.daysConfiguration[days_Configuration_present_flag].timeZoneConfiguration) &&
                  configuration_Object.daysConfiguration[days_Configuration_present_flag].timeZoneConfiguration.length > 0
                ) {
                  let time_Object = {
                    id: item.id,
                    active: item.active,
                    startTime: moment(item.startTime, "HH:mm:ss").toDate(),
                    endTime: moment(item.endTime, "HH:mm:ss").toDate(),
                    thresholdAmount: item.orderThreshold,
                    thresholdBelowAmount: item.belowThreshold,
                    thresholdAboveAmount: item.aboveThreshold,
                    enableEdit: true,
                  };
                  configuration_Object.daysConfiguration[days_Configuration_present_flag].timeZoneConfiguration.push(time_Object);
                }
              } else {
                let date_object = {
                  availableDays: calculate_available_days(item.startDate, item.endDate),
                  days: [createCamelCase(item.day)],
                  timeZoneConfiguration: [
                    {
                      id: item.id,
                      active: item.active,
                      startTime: moment(item.startTime, "HH:mm:ss").toDate(),
                      endTime: moment(item.endTime, "HH:mm:ss").toDate(),
                      thresholdAmount: item.orderThreshold,
                      thresholdBelowAmount: item.belowThreshold,
                      thresholdAboveAmount: item.aboveThreshold,
                      enableEdit: true,
                    },
                  ],
                };
                configuration_Object.daysConfiguration.push(date_object);
              }
            }
            returnData.splice(combination_present_index, 1);
            returnData.push(configuration_Object);
          } else {
            let configuration_Object = create_configuration_object(item);
            returnData.push(configuration_Object);
          }
        }
      }
    });
  }

  return calculate_remaining_days(returnData);
};

const calculate_remaining_days = (returnData: any) => {
  if (returnData && Array.isArray(returnData) && returnData.length > 0) {
    returnData.forEach((configItem: any, configIndex: any) => {
      let selectDays: any = [];
      configItem.daysConfiguration.forEach((dayConfig: any) => {
        if (dayConfig && dayConfig.days && Array.isArray(dayConfig.days) && dayConfig.days.length > 0) {
          dayConfig.days.forEach((day: any) => selectDays.push(day));
        }
      });

      if (selectDays && selectDays.length >= 0) {
        returnData[configIndex].daysConfiguration.forEach((dayConfig: any, index: any) => {
          let remainingDaysList = returnData[configIndex].allDays.filter((element: any) => dayConfig.days.indexOf(element.id) !== -1 || (selectDays && selectDays.indexOf(element.id) === -1));
          returnData[configIndex].daysConfiguration[index].availableDays = remainingDaysList;
        });
        returnData[configIndex].selectedDays = selectDays;
        returnData[configIndex].startDate = moment(returnData[configIndex].startDate, "YYYY-MM-DD").toDate();
        returnData[configIndex].endDate = moment(returnData[configIndex].endDate, "YYYY-MM-DD").toDate();
        returnData[configIndex].dates = getDates(returnData[configIndex].startDate, returnData[configIndex].endDate);
      }
    });
  }
  return returnData;
};

const calculate_available_days = (startDate: any, endDate: any) => {
  let daysList: any = [];
  let startDateobj: any = moment(startDate, "YYYYY-MM-DD").toDate();
  let endDateobj: any = moment(endDate, "YYYY-MM-DD").toDate();
  startDateobj.setHours(0, 0, 0, 0);
  endDateobj.setHours(0, 0, 0, 0);
  let diff = (endDateobj.getTime() - startDateobj.getTime()) / (24 * 60 * 60 * 1000);
  if (diff < 7) {
    let dayNumber = startDateobj.getDay();
    while (diff >= 0) {
      daysList.push({ id: weekday[dayNumber], name: weekday[dayNumber] });
      dayNumber = (dayNumber + 1) % 7;
      diff--;
    }
  } else {
    weekday.forEach((item) => {
      daysList.push({ id: item, name: item });
    });
  }
  return daysList;
};

const create_configuration_object = (item: any) => {
  let allDaysArray: any = calculate_available_days(item.startDate, item.endDate);
  let configuration_Object = {
    startDate: item.startDate,
    endDate: item.endDate,
    allDays: allDaysArray,
    remainingDays: weekday,
    selectedDays: [createCamelCase(item.day)],
    daysConfiguration: [
      {
        availableDays: allDaysArray,
        days: [createCamelCase(item.day)],
        timeZoneConfiguration: [
          {
            id: item.id,
            active: item.active,
            startTime: moment(item.startTime, "HH:mm:ss").toDate(),
            endTime: moment(item.endTime, "HH:mm:ss").toDate(),
            thresholdAmount: item.orderThreshold,
            thresholdBelowAmount: item.belowThreshold,
            thresholdAboveAmount: item.aboveThreshold,
            enableEdit: true,
          },
        ],
      },
    ],
  };
  return configuration_Object;
};

export const _handle_service_active_toggle_change = (multiShipmentLevelFlag: any, name: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.updateConfigPosId !== "" && draft.updateConfigZoneName !== "") {
      if (multiShipmentLevelFlag) {
        draft.multiShipmentLevel[name].active = !draft.multiShipmentLevel[name].active;
      } else {
        draft.shipmentLevel[name].active = !draft.shipmentLevel[name].active;
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_edit_update_calculation_toggle_change_util = (multiShipmentLevelFlag: any, name: any, fieldName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.updateConfigPosId !== "" && draft.updateConfigZoneName !== "") {
      if (multiShipmentLevelFlag) {
        draft.multiShipmentLevel[name][fieldName] = !draft.multiShipmentLevel[name][fieldName];
      } else {
        draft.shipmentLevel[name][fieldName] = !draft.shipmentLevel[name][fieldName];
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_remove_time_config_record_row = (multiShipmentLevel: any, shipment_name: any, configIndex: any, daysConfigIndex: any, timeConfigIndex: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (configIndex !== undefined && configIndex >= 0 && daysConfigIndex !== undefined && daysConfigIndex >= 0 && timeConfigIndex !== undefined && timeConfigIndex >= 0) {
      if (multiShipmentLevel !== undefined) {
        if (!multiShipmentLevel) {
          if (shipment_name !== undefined && shipment_name !== "") {
            if (
              draft.shipmentLevel[shipment_name] &&
              draft.shipmentLevel[shipment_name].deliveryFee !== undefined &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList &&
              Array.isArray(draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList) &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList.length > 0 &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration) &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration) &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex] !== undefined
            ) {
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration.splice(timeConfigIndex, 1);
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_change_time_config_record_threshould_data = (multiShipmentLevel: any, shipment_name: any, configIndex: any, daysConfigIndex: any, timeConfigIndex: any, textBoxName: any, dataValue: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (configIndex !== undefined && configIndex >= 0 && daysConfigIndex !== undefined && daysConfigIndex >= 0 && timeConfigIndex !== undefined && timeConfigIndex >= 0) {
      if (multiShipmentLevel !== undefined) {
        if (!multiShipmentLevel) {
          if (shipment_name !== undefined && shipment_name !== "") {
            if (
              draft.shipmentLevel[shipment_name] &&
              draft.shipmentLevel[shipment_name].deliveryFee !== undefined &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList &&
              Array.isArray(draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList) &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList.length > 0 &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration) &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration) &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex] !== undefined
            ) {
              draft.shipmentLevel[shipment_name].deliveryFee.additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex][textBoxName] = dataValue;
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_edit_toggle_additional_config = (multiShipmentLevel: any, shipment_name: any, configIndex: any, daysConfigIndex: any, timeConfigIndex: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (configIndex !== undefined && configIndex >= 0 && daysConfigIndex !== undefined && daysConfigIndex >= 0 && timeConfigIndex !== undefined && timeConfigIndex >= 0) {
      if (multiShipmentLevel !== undefined) {
        if (!multiShipmentLevel) {
          if (shipment_name !== undefined && shipment_name !== "") {
            if (
              draft.shipmentLevel[shipment_name] &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex] !== undefined &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration !== undefined &&
              Array.isArray(draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration) &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration.length > 0 &&
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex] !== undefined
            ) {
              draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex].enableEdit =
                !draft.shipmentLevel[shipment_name].additionalDeliveryFeeConfigDTOList[configIndex].daysConfiguration[daysConfigIndex].timeZoneConfiguration[timeConfigIndex].enableEdit;
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_edit_threshold_rane_toggle_change_enable_Edit_util = (multiShipmentLevel: any, shipment_name: any, rangeObjectIndex: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (multiShipmentLevel !== undefined) {
      if (!multiShipmentLevel) {
        if (shipment_name !== undefined && shipment_name !== "") {
          if (
            draft.shipmentLevel &&
            draft.shipmentLevel[shipment_name] &&
            draft.shipmentLevel[shipment_name].deliveryFeeRangeConfigDTOList &&
            Array.isArray(draft.shipmentLevel[shipment_name].deliveryFeeRangeConfigDTOList) &&
            draft.shipmentLevel[shipment_name].deliveryFeeRangeConfigDTOList.length > 0
          ) {
            draft.shipmentLevel[shipment_name].deliveryFeeRangeConfigDTOList[rangeObjectIndex].enableEdit = !draft.shipmentLevel[shipment_name].deliveryFeeRangeConfigDTOList[rangeObjectIndex].enableEdit;
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _design_delivery_fee_listing_screen_object = (responseData: any, servicePropositionList: any[]) => {
  let deliveryFeeHeaderDataList = { id: "POS No.", posName: "POS Name", zoneName: "Zone Name", headerRowsValues: [], headerInnerRowsValues: [], actions: "Actions" } as DeliveryFeeHeaderDataListInterface;
  if (isArrayValid(responseData)) {
    responseData.forEach((shipmentDetail: any) => {
      let count: number = 999;
      if (shipmentDetail && !shipmentDetail.multiShipment) {
        const { id, shipment, deliveryType } = shipmentDetail;
        const servicePropositionObj = getServicePropositionObject(servicePropositionList, shipment);
        const rank: number = servicePropositionObj?.rank || count;
        let colSpan: number = 6;
        if (isShipmentTypeSlotBased(deliveryType)) {
          colSpan = 8; // Adding Preferred Slot Fees and Slot Proximity Fees
        }
        deliveryFeeHeaderDataList.headerRowsValues.push({ shipmentId: id, shipmentName: shipment, displayName: servicePropositionObj?.displayName || "", rank: rank, rowSpan: 1, colSpan: colSpan, deliveryType });
      }
    });
    deliveryFeeHeaderDataList.headerRowsValues.sort((a, b) => a.rank - b.rank);
  }
  return deliveryFeeHeaderDataList;
};

export const _design_delivery_fee_list_data = (responseData: any, deliveryFeeState: any) => {
  let responseObject = { responseList: [], pageNumber: 0, maxRecordPresent: deliveryFeeState.size || 0 } as any;
  if (responseData && responseData.content && Array.isArray(responseData.content) && responseData.content.length > 0) {
    responseObject.pageNumber = responseData.number;
    responseObject.maxRecordPresent = responseData.totalElements;
    responseData.content.forEach((config: any) => {
      if (config) {
        if (config.selectedPosServiceTypeZone && Array.isArray(config.selectedPosServiceTypeZone) && config.selectedPosServiceTypeZone.length > 0) {
          config.selectedPosServiceTypeZone.forEach((configIndex: any) => {
            let objData = {
              id: configIndex.posNo,
              storeName: configIndex.storeName,
              zone: configIndex.zone,
              zoneId: configIndex.zoneId,
              shipmentLevelDeliveryFeeConfigs: config.shipmentLevelDeliveryFeeConfigs,
              active: configIndex.active,
            } as any;
            responseObject.responseList.push(objData);
          });
        }
      }
    });
  }
  return responseObject;
};

export const _handle_view_change_configuration_util = (dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.isShipmentLevelConfigurationView = !draft.isShipmentLevelConfigurationView;
  });
  return newDlvFeeConfigState;
};

export const _handle_toggle_change_is_multi_shipment_calculation_util = (dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (draft.multiShipmentLevel && Object.keys(draft.multiShipmentLevel).length > 0) {
      Object.keys(draft.multiShipmentLevel).forEach((multiShipmentLevelName: any) => {
        draft.multiShipmentLevel[multiShipmentLevelName].isPercentage = !draft.multiShipmentLevel[multiShipmentLevelName].isPercentage;
      });
    }
    draft.isMultiShipmentCalculationPercentageBased = !draft.isMultiShipmentCalculationPercentageBased;
  });
  return newDlvFeeConfigState;
};

export const _handle_service_multi_shiment_input_change = (inputName: any, multiShipmentLevel: any, name: any, value: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (value !== "" && (isNaN(Number(value)) || !FEE_REG_EXP_VALUE.test(value))) {
      return;
    }
    if (multiShipmentLevel) {
      draft.multiShipmentLevel[inputName][name] = value;
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_edit_multi_shipment_enable_toggle_change_util = (multiShipmentLevel: any, shipment_name: any, toggleName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (multiShipmentLevel) {
      draft.multiShipmentLevel[shipment_name][toggleName] = !draft.multiShipmentLevel[shipment_name][toggleName];
    }
  });
  return newDlvFeeConfigState;
};

export const _validate_multi_shipment_configuration_object = (dlvFeeConfigState: any) => {
  const payloadObject = {
    multiShipmentConfigList: [],
    deliveryFeeCountryConfigDTO: {
      // movThreshold: dlvFeeConfigState?.orderLevelMovThreshold?.movThreshold || "",
      // id: dlvFeeConfigState?.orderLevelMovThreshold?.id || 0,
    },
  } as any;

  let responseValue: any = {};

  if (dlvFeeConfigState.multiShipmentLevel) {
    let emptyCount = 0 as any;

    Object.keys(dlvFeeConfigState.multiShipmentLevel).forEach((supplierType: any, supplierTypeIndex: any) => {
      if (supplierType !== "" && dlvFeeConfigState.multiShipmentLevel[supplierType] && dlvFeeConfigState.multiShipmentLevel[supplierType].isEditMode === true) {
        const { shipmentId, orderThreshold, deliveryFee } = dlvFeeConfigState.multiShipmentLevel[supplierType];

        let shipment_object = {
          shipmentTypeId: shipmentId,
          isPercentage: dlvFeeConfigState.isMultiShipmentCalculationPercentageBased,
        } as any;

        if (isEmptyOrNull(orderThreshold)) {
          responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-orderThreshold`] = "Enter valid Threshold amount";
        } else {
          shipment_object.orderThreshold = Number(orderThreshold);
        }

        if (isEmptyOrNull(deliveryFee)) {
          responseValue[`supplierType-${supplierType}-${supplierTypeIndex}-deliveryFee`] = "Enter valid Above Threshold amount";
        } else {
          shipment_object.deliveryFee = Number(deliveryFee);
        }

        payloadObject.multiShipmentConfigList.push(shipment_object);
      } else {
        emptyCount++;
      }
    });

    if (dlvFeeConfigState !== undefined && dlvFeeConfigState.orderLevelMovThreshold !== undefined) {
      const { movThreshold, id = 0, originalMovThreshold } = dlvFeeConfigState.orderLevelMovThreshold;
      payloadObject.deliveryFeeCountryConfigDTO.id = id;
      payloadObject.deliveryFeeCountryConfigDTO.movThreshold = originalMovThreshold;
      //
      if (!dlvFeeConfigState.orderLevelMovThreshold.sameAsEarlier) {
        payloadObject.deliveryFeeCountryConfigDTO.movThreshold = movThreshold;
        if (dlvFeeConfigState.orderLevelMovThreshold.active) {
          if (!isEmptyOrNull(movThreshold)) {
            if (isNaN(movThreshold) || Number(movThreshold) < 0) {
              responseValue[`orderLevelMovThreshold-orderLevelMovThreshold-movThreshold`] = VALID_MOV_THRESHOLD_ERROR;
            }
          }
        } else {
          responseValue[`orderLevelMovThreshold-sameAsEarlier-active`] = MOV_THRESHOLD_ACTIVE_FLAG_ERROR;
        }
      }
    }

    if (responseValue && Object.keys(responseValue).length !== 0) {
      CustomAlert(ALERT_TYPES.ERROR, responseValue[Object.keys(responseValue)[0]]);
      return {};
    } else {
      return payloadObject;
    }
  }

  return payloadObject;
};

export const _design_update_multi_shipment_delivery_fee_state_object = (data: any, dlvFeeConfigState: any, payload: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.loading = false;
    draft.isMultiShipmentCalculationPercentageBased = false;

    if (data && Array.isArray(data) && data.length > 0) {
      Object.keys(draft.multiShipmentLevel).forEach((supplierType: any) => {
        if (supplierType !== undefined && supplierType !== "") {
          const shipmentIdValue = dlvFeeConfigState.multiShipmentLevel[supplierType].shipmentId;
          if (shipmentIdValue !== undefined && shipmentIdValue !== "") {
            var multi_shipment_record_Index = data.findIndex((item: any) => item.shipmentTypeId === shipmentIdValue);
            let data_object = {} as any;
            if (multi_shipment_record_Index !== -1) {
              data_object = data[multi_shipment_record_Index];
              draft.multiShipmentLevel[supplierType].id = data_object.id;
              draft.isMultiShipmentCalculationPercentageBased = data_object.isPercentage !== undefined ? data_object.isPercentage : false;
            }
            draft.multiShipmentLevel[supplierType].orderThreshold = data_object.orderThreshold !== undefined ? data_object.orderThreshold : "";
            draft.multiShipmentLevel[supplierType].deliveryFee = data_object.deliveryFee !== undefined ? data_object.deliveryFee : "";
            draft.multiShipmentLevel[supplierType].enabledEdit = Object.keys(data_object).length !== 0 ? true : false;
            draft.multiShipmentLevel[supplierType].isEditMode = Object.keys(data_object).length !== 0 ? true : false;
            draft.multiShipmentLevel[supplierType].isPercentage = data_object.isPercentage !== undefined ? data_object.isPercentage : false;
          }
        }
      });
    }

    if (payload !== undefined && payload.deliveryFeeCountryConfigDTO !== undefined && typeof payload.deliveryFeeCountryConfigDTO === "object" && payload.deliveryFeeCountryConfigDTO.id !== undefined && payload.deliveryFeeCountryConfigDTO.id !== "") {
      const { movThreshold = "", id } = payload.deliveryFeeCountryConfigDTO;
      draft.orderLevelMovThreshold.id = id;
      draft.orderLevelMovThreshold.movThreshold = movThreshold;
      draft.orderLevelMovThreshold.originalMovThreshold = movThreshold;
      draft.orderLevelMovThreshold.sameAsEarlier = true;
      draft.orderLevelMovThreshold.enableConfigEdit = true;
      draft.orderLevelMovThreshold.active = false;
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_service_type_drawer_checkbox_input_change = (inputName: any, multiShipmentLevel: any, objName: any, checked: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[inputName].deliveryFee[objName] = checked;
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_change_capacity_utilisation_field_data = (multiShipmentLevel: any, shipment_name: any, configIndex: any, textBoxName: any, dataValue: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (configIndex !== undefined && configIndex >= 0) {
      if (multiShipmentLevel !== undefined) {
        if (!multiShipmentLevel) {
          if (shipment_name !== undefined && shipment_name !== "") {
            if (
              draft.shipmentLevel[shipment_name] &&
              draft.shipmentLevel[shipment_name].deliveryFee &&
              draft.shipmentLevel[shipment_name].deliveryFee.deliveryFeeSlotCapacityConfigDTOList &&
              Array.isArray(draft.shipmentLevel[shipment_name].deliveryFee.deliveryFeeSlotCapacityConfigDTOList) &&
              draft.shipmentLevel[shipment_name].deliveryFee.deliveryFeeSlotCapacityConfigDTOList.length > 0 &&
              draft.shipmentLevel[shipment_name].deliveryFee.deliveryFeeSlotCapacityConfigDTOList[configIndex] !== undefined
            ) {
              draft.shipmentLevel[shipment_name].deliveryFee.deliveryFeeSlotCapacityConfigDTOList[configIndex][textBoxName] = dataValue;
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_edit_toggle_change_capacity_utilisation_data = (multiShipmentLevel: any, shipment_name: any, configIndex: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (configIndex !== undefined && configIndex >= 0) {
      if (multiShipmentLevel !== undefined) {
        if (!multiShipmentLevel) {
          if (shipment_name !== undefined && shipment_name !== "") {
            if (
              draft.shipmentLevel[shipment_name] &&
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList &&
              Array.isArray(draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList) &&
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList.length > 0 &&
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList[configIndex] !== undefined
            ) {
              draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList[configIndex].enableEdit = !draft.shipmentLevel[shipment_name].deliveryFeeSlotCapacityConfigDTOList[configIndex].enableEdit;
            }
          }
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const prepare_capacity_utilisation_record_payload = (record_list: any, type: any) => {
  let response_payload_object = {
    payload: [],
    error_object: "",
  } as any;
  if (record_list && Array.isArray(record_list) && record_list.length > 0) {
    record_list.forEach((configObj: any) => {
      if (configObj !== undefined) {
        let data_object = {
          capacityLow: "",
          capacityHigh: "",
          deliveryFee: "",
          active: true,
        } as any;

        if (configObj.id !== undefined && configObj.id !== "") {
          data_object.id = configObj.id;
        }

        if (isEmptyOrNull(configObj.capacityLow)) {
          response_payload_object.error_object = "Start Range value is required";
          return;
        } else {
          data_object.capacityLow = configObj.capacityLow;
        }

        if (isEmptyOrNull(configObj.capacityHigh)) {
          response_payload_object.error_object = "Start End value is required";
          return;
        } else {
          data_object.capacityHigh = configObj.capacityHigh;
        }

        if (isEmptyOrNull(configObj.deliveryFee)) {
          response_payload_object.error_object = "Delivery Fee is required";
          return;
        } else {
          data_object.deliveryFee = configObj.deliveryFee;
        }
        response_payload_object.payload.push(data_object);
      }
    });
  }
  return response_payload_object;
};

const parse_capacity_utilisation_delivery_fee_dto_lits = (record_list: any) => {
  let returnData = [] as any;
  if (isArrayValid(record_list)) {
    record_list.forEach((recordItem: any) => {
      const dataObject = {
        id: recordItem?.id || "",
        capacityLow: !isUndefined(recordItem?.capacityLow) ? Number(recordItem?.capacityLow) : "",
        capacityHigh: recordItem?.capacityHigh || "",
        deliveryFee: !isUndefined(recordItem?.deliveryFee) ? Number(recordItem?.deliveryFee) : "",
        active: recordItem?.active || true,
        enableEdit: true,
        capacityLowErrorMessage: "",
        capacityHighErrorMessage: "",
        capacityFeeErrorMessage: "",
      } as capacityUtilisationConfigurationInterface;
      returnData.push(dataObject);
    });
  }
  return returnData;
};

const parse_slot_proximity_fee_config_dto_lits = (record_list: any) => {
  let returnData = [] as any;
  if (isArrayValid(record_list)) {
    record_list.forEach((recordItem: any) => {
      const dataObject = {
        id: recordItem?.id || "",
        minTimeToSlot: !isUndefined(recordItem?.minTimeToSlot) ? (Number(recordItem?.minTimeToSlot) > 0 ? Math.floor(Number(recordItem?.minTimeToSlot) / 60) : 0) : "",
        maxTimeToSlot: !isUndefined(recordItem?.maxTimeToSlot) ? Math.floor(Number(recordItem?.maxTimeToSlot) / 60) : "",
        deliveryFee: !isUndefined(recordItem?.deliveryFee) ? Number(recordItem?.deliveryFee) : "",
        minTimeToSlotErrorMessage: "",
        maxTimeToSlotErrorMessage: "",
        deliveryFeeErrorMessage: "",
      } as SlotProximityFeeConfigDTOListConfigurationInterface;
      returnData.push(dataObject);
    });
  }
  return returnData;
};

export const design_custom_pos_zone_mapping_filtered_list_based_on_search = (dlvFeeConfigState: any, searchText: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    draft.posZoneSearchText = searchText;
    if (draft.posZoneServiceRecord && Array.isArray(draft.posZoneServiceRecord) && draft.posZoneServiceRecord.length > 0) {
      // is selected data present
      let selected_list = [] as any;
      draft.posZoneServiceFilteredRecord.forEach((list: any) => {
        if (list.isChecked === false) {
          let no_inner_selected: any = false;
          list.zoneServicetypeList.forEach((innerList: any) => {
            if (innerList.isChecked === true) {
              no_inner_selected = true;
            }
          });
          if (no_inner_selected === true) {
            selected_list.push(list);
          }
        } else {
          selected_list.push(list);
        }
      });
      //
      let result_list = [];
      if (draft.posZoneSearchText !== undefined && draft.posZoneSearchText !== "") {
        let list_data = draft.posZoneServiceRecord.filter((list: any) => list.posNo && list.posNo.toLowerCase().includes(searchText.toLowerCase()));
        result_list = selected_list.concat(list_data);
      } else {
        result_list = selected_list.concat(draft.posZoneServiceRecord);
      }

      // remove duplicate
      let map = new Map();
      result_list.forEach((item: any) => {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      });
      draft.posZoneServiceFilteredRecord = Array.from(map.values());
    }
  });
  return newDlvFeeConfigState;
};

export const design_selected_pos_zone_mapping_filtered_list_based_on_search = (dlvFeeConfigState: any) => {
  if (dlvFeeConfigState.selectedPosServiceTypeZone && Array.isArray(dlvFeeConfigState.selectedPosServiceTypeZone) && dlvFeeConfigState.selectedPosServiceTypeZone.length > 0) {
    let filteredList = [...dlvFeeConfigState.selectedPosServiceTypeZone];
    if (dlvFeeConfigState.assignedPosSearchText !== undefined && dlvFeeConfigState.assignedPosSearchText !== "") {
      filteredList = filteredList.filter((list: any) => list.posNo && list.posNo.toLowerCase().includes(dlvFeeConfigState.assignedPosSearchText.toLowerCase()));
    }
    return filteredList;
  }
  return [];
};

export const handle_static_sevice_fees_input_change_util = (event: any, multiShipmentLevel: any, serviceName: any, name: any, value: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[serviceName].serviceFee[name] = value;
      draft.shipmentLevel[serviceName].serviceFee.dynamicFees = [];
      draft.shipmentLevel[serviceName].serviceFee.isErrorText = "";
    }
  });
  return newDlvFeeConfigState;
};

export const handle_dynamic_sevice_fees_input_change_util = (event: any, multiShipmentLevel: any, serviceName: any, name: any, value: any, dynamicObjIndex: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[serviceName].serviceFee.dynamicFees[dynamicObjIndex][name] = value;
      draft.shipmentLevel[serviceName].serviceFee.staticFees = "";
      if (name === "maxValue") {
        if (
          draft !== undefined &&
          draft.shipmentLevel !== undefined &&
          draft.shipmentLevel[serviceName] !== undefined &&
          draft.shipmentLevel[serviceName].serviceFee !== undefined &&
          draft.shipmentLevel[serviceName].serviceFee.dynamicFees !== undefined &&
          draft.shipmentLevel[serviceName].serviceFee.dynamicFees[dynamicObjIndex] !== undefined &&
          draft.shipmentLevel[serviceName].serviceFee.dynamicFees[dynamicObjIndex].minValue !== undefined &&
          draft.shipmentLevel[serviceName].serviceFee.dynamicFees[dynamicObjIndex].minValue !== "" &&
          Number(draft.shipmentLevel[serviceName].serviceFee.dynamicFees[dynamicObjIndex].minValue) >= Number(value)
        ) {
          draft.shipmentLevel[serviceName].serviceFee.isErrorText = "Min Value cannot be greater than or equal to max value";
        } else {
          draft.shipmentLevel[serviceName].serviceFee.isErrorText = "";
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const config_change_existing_handler_utils = (event: any, multiShipmentLevel: any, serviceName: any, changeConfigObj: any, keyName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { checked } = event.target;

      draft.shipmentLevel[serviceName][changeConfigObj].enableConfigEdit = checked;
      draft.shipmentLevel[serviceName][changeConfigObj].sameAsEarlier = true;
      if (checked) {
        draft.shipmentLevel[serviceName][changeConfigObj].sameAsEarlier = false;
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_mov_threshold_calculation_toggle_change_util = (multiShipmentLevel: any, serviceName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[serviceName].minimumOrderValue.active = !draft.shipmentLevel[serviceName].minimumOrderValue.active;

      if (draft.shipmentLevel[serviceName].minimumOrderValue.active) {
        draft.shipmentLevel[serviceName].minimumOrderValue.movThreshold = "";
      }
    }
  });
  return newDlvFeeConfigState;
};

export const handle_mov_threshold_input_change_util = (event: any, multiShipmentLevel: any, serviceName: any, name: any, value: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[serviceName].minimumOrderValue[name] = value;
    }
  });
  return newDlvFeeConfigState;
};

export const handlePromiseSlotFeeCalculationToggleChangeUtil = (multiShipmentLevel: boolean, serviceName: string, dlvFeeConfigState: DlvFeeConfigStateInterface) => {
  let newDlvFeeConfigState: DlvFeeConfigStateInterface = produce(dlvFeeConfigState, (draft: DlvFeeConfigStateInterface) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[serviceName].promiseSlotFee.active = !draft.shipmentLevel[serviceName].promiseSlotFee.active;
      draft.shipmentLevel[serviceName].promiseSlotFee.capacityUtilisationConfiguration = [];
      if (draft.shipmentLevel[serviceName].promiseSlotFee.active) {
        draft.shipmentLevel[serviceName].promiseSlotFee.capacityUtilisationConfiguration.push({
          capacityLow: "0",
          capacityHigh: "100",
          deliveryFee: "0",
          active: true,
          enableEdit: true,
        });
      }
    }
  });
  return newDlvFeeConfigState;
};

export const promiseSlotExistingConfigChangeHandlerUtils = (serviceName: any, fieldName: any, dlvFeeConfigState: DlvFeeConfigStateInterface) => {
  let newDlvFeeConfigState: DlvFeeConfigStateInterface = produce(dlvFeeConfigState, (draft: DlvFeeConfigStateInterface) => {
    draft.shipmentLevel[serviceName][fieldName].sameAsEarlier = !draft.shipmentLevel[serviceName][fieldName].sameAsEarlier;
    draft.shipmentLevel[serviceName][fieldName].active = false;
    if (!draft.shipmentLevel[serviceName][fieldName].sameAsEarlier) {
      if (draft.activeServiceTypeList.findIndex((item: any) => item === serviceName) === -1) {
        draft.activeServiceTypeList.push(serviceName);
        draft.shipmentLevel[serviceName].isEditMode = true;
      }
    } else {
      // is Deliery Fee / Service Fee / MOV active, if not then remve service typ from pos zone mappimg
      let is_change_toggle_active_count = 0;
      if (!draft.shipmentLevel[serviceName].deliveryFee.sameAsEarlier) {
        is_change_toggle_active_count++;
      }
      if (!draft.shipmentLevel[serviceName].promiseSlotFee.sameAsEarlier) {
        is_change_toggle_active_count++;
      }
      if (!draft.shipmentLevel[serviceName].serviceFee.sameAsEarlier) {
        is_change_toggle_active_count++;
      }
      if (!draft.shipmentLevel[serviceName].minimumOrderValue.sameAsEarlier) {
        is_change_toggle_active_count++;
      }

      if (is_change_toggle_active_count === 0) {
        draft.activeServiceTypeList = draft.activeServiceTypeList.filter((serviceTypeName: string) => serviceTypeName !== serviceName);
        draft.shipmentLevel[serviceName].isEditMode = false;
      }
    }
  });
  return newDlvFeeConfigState;
};

export const configPromiseSlotChangeExistingHandlerUtils = (event: any, serviceName: any, fieldName: any, dlvFeeConfigState: DlvFeeConfigStateInterface) => {
  let newDlvFeeConfigState: DlvFeeConfigStateInterface = produce(dlvFeeConfigState, (draft: DlvFeeConfigStateInterface) => {
    if (event && event.target) {
      const { checked } = event.target;
      draft.shipmentLevel[serviceName][fieldName].enableConfigEdit = checked;
      draft.shipmentLevel[serviceName][fieldName].sameAsEarlier = true;
      if (checked) {
        draft.shipmentLevel[serviceName][fieldName].sameAsEarlier = false;
        if (isEmptyArray(draft.shipmentLevel[serviceName][fieldName].capacityUtilisationConfiguration)) {
          draft.shipmentLevel[serviceName][fieldName].capacityUtilisationConfiguration.push({
            capacityLow: "0",
            capacityHigh: "100",
            deliveryFee: "0",
            active: true,
            enableEdit: true,
          });
        }
      }
    }
  });
  return newDlvFeeConfigState;
};

export const updateSlotCapacityValueUtils = (dlvFeeConfigState: DlvFeeConfigStateInterface, name: string, value: string | number, slotObjIndex: number, serviceName: string, fieldName: string) => {
  let newDlvFeeConfigState: DlvFeeConfigStateInterface = produce(dlvFeeConfigState, (draft: DlvFeeConfigStateInterface) => {
    draft.shipmentLevel[serviceName][fieldName].capacityUtilisationConfiguration[slotObjIndex][name] = value;
  });
  return newDlvFeeConfigState;
};

export const handlePromiseSlotRemoveCapacityRangeUtils = (dlvFeeConfigState: DlvFeeConfigStateInterface, slotObjIndex: number, serviceName: string, fieldName: string) => {
  let newDlvFeeConfigState: DlvFeeConfigStateInterface = produce(dlvFeeConfigState, (draft: DlvFeeConfigStateInterface) => {
    draft.shipmentLevel[serviceName][fieldName].capacityUtilisationConfiguration.splice(slotObjIndex, 1);
  });
  return newDlvFeeConfigState;
};

function validateCapacityLow(capacityLow: string | number): string {
  if (isEmptyOrNull(capacityLow)) {
    return "Required";
  }
  if (Number(capacityLow) < 0) {
    return "Value cannot be less than zero";
  }
  return "";
}

function validateCapacityHigh(capacityHigh: string | number, capacityLow: string | number): string {
  if (isEmptyOrNull(capacityHigh)) {
    return "Required";
  }
  if (Number(capacityHigh) > 100) {
    return "Invalid Range";
  }
  if (Number(capacityHigh) <= Number(capacityLow)) {
    return "Value may have configuration already present";
  }
  return "";
}

function validateCapacityFee(deliveryFee: string | number): string {
  if (isEmptyOrNull(deliveryFee)) {
    return "Required";
  }
  return "";
}

export const handlePromiseSlotAddCapacityRangeUtils = (dlvFeeConfigState: DlvFeeConfigStateInterface, serviceName: string, fieldName: string) => {
  let newDlvFeeConfigState: DlvFeeConfigStateInterface = produce(dlvFeeConfigState, (draft: DlvFeeConfigStateInterface) => {
    let capacityAllOk: boolean = true;
    draft.shipmentLevel[serviceName][fieldName].capacityUtilisationConfiguration.forEach((slotObj: capacityUtilisationConfigurationInterface, slotObjIndex: number) => {
      const capacityLowErrorMessage = validateCapacityLow(slotObj.capacityLow);
      const capacityHighErrorMessage = validateCapacityHigh(slotObj.capacityHigh, slotObj.capacityLow);
      const capacityFeeErrorMessage = validateCapacityFee(slotObj.deliveryFee);

      draft.shipmentLevel[serviceName][fieldName].capacityUtilisationConfiguration[slotObjIndex].capacityLowErrorMessage = capacityLowErrorMessage;
      draft.shipmentLevel[serviceName][fieldName].capacityUtilisationConfiguration[slotObjIndex].capacityHighErrorMessage = capacityHighErrorMessage;
      draft.shipmentLevel[serviceName][fieldName].capacityUtilisationConfiguration[slotObjIndex].capacityFeeErrorMessage = capacityFeeErrorMessage;

      capacityAllOk = isEmptyOrNull(capacityLowErrorMessage) && isEmptyOrNull(capacityHighErrorMessage) && isEmptyOrNull(capacityFeeErrorMessage);
    });

    if (capacityAllOk) {
      const capacityUtilisationConfigurationLength = draft.shipmentLevel[serviceName][fieldName].capacityUtilisationConfiguration.length;
      const max_range_value = draft.shipmentLevel[serviceName][fieldName].capacityUtilisationConfiguration[capacityUtilisationConfigurationLength - 1].capacityHigh;
      if (Number(max_range_value) < 100) {
        draft.shipmentLevel[serviceName][fieldName].capacityUtilisationConfiguration.push({
          capacityLow: max_range_value,
          capacityHigh: "100",
          deliveryFee: "0",
          active: true,
          capacity_low_error_message: "",
          capacity_high_error_message: "",
          capacity_fee_error_message: "",
          enableEdit: true,
        });
      }
    }
  });
  return newDlvFeeConfigState;
};

export const _handle_slot_proxomity_fee_calculation_toggle_change_util = (multiShipmentLevel: any, serviceName: any, dlvFeeConfigState: any) => {
  let newDlvFeeConfigState: any = produce(dlvFeeConfigState, (draft: any) => {
    if (!multiShipmentLevel) {
      draft.shipmentLevel[serviceName].slotProximityFee.active = !draft.shipmentLevel[serviceName].slotProximityFee.active;
      if (draft.shipmentLevel[serviceName].slotProximityFee.active) {
        draft.shipmentLevel[serviceName].slotProximityFee.slotProximityFeeConfigDTOList = [{ minTimeToSlot: 0, maxTimeToSlot: "", deliveryFee: "", minTimeToSlotErrorMessage: "", maxTimeToSlotErrorMessage: "", deliveryFeeErrorMessage: "" }];
      }
    }
  });
  return newDlvFeeConfigState;
};

export const handleSlotProximityFeeChangeUtil = (dlvFeeConfigState: DlvFeeConfigStateInterface, name: string, value: string | number, slotObjIndex: number, serviceName: string) => {
  let newDlvFeeConfigState: DlvFeeConfigStateInterface = produce(dlvFeeConfigState, (draft: DlvFeeConfigStateInterface) => {
    draft.shipmentLevel[serviceName].slotProximityFee.slotProximityFeeConfigDTOList[slotObjIndex][name] = value;
  });
  return newDlvFeeConfigState;
};
