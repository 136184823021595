import { createStyles, makeStyles } from "@material-ui/core";
import { COLORS } from "../../config/theme/theme";

const commonStyles = makeStyles(() =>
  createStyles({
    root: { margin: "16px 0px 24px 0px" },
    accordion: {
      marginTop: "24px",
      backgroundColor: COLORS.WHITE,
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    },
    accordionSummary: {
      fontSize: "18px",
      fontWeight: 600,
      borderBottom: `1px solid ${COLORS.SECONDARY_DARK}`,
    },
    accordionDetails: {
      display: "block",
    },
    accordionDetailsDeliveryPromiseTable: {
      margin: 0,
      marginTop: "24px",
      padding: 0,
      display: "block",
      maxHeight: "calc(100vh - 354px)",
      overflow: "auto",
    },
    fullScreenStyle: {
      width: "100%",
      height: "100%",
    },
  })
);

export default commonStyles;
