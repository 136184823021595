import React from "react";

import { Box, Grid, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

import SelectBox from "../../../common/SelectBox/SelectBox";
import TimePicker from "../../../common/TimePicker/TimePicker";
import MultiSelectBox from "../../../common/MultiSelectBox/MultiSelectBox";

import { DEFAULT_RULE_CONFIG, RULE_ENGINE_TEXT_CONSTANT, TimeConfigItem, calculateAvailableDaysForSelection, getDefaultOrFixedTimeMinutes } from "../../RuleEngineUtils";
import { ChangeConfigOtion } from "../ServiceRuleEngineUtils";
import { DEFAULT_TIME_HOURS } from "../ServiceRuleEngine";
import { isArrayValid, isUndefined } from "utils/DataUtils";
import LinkButton from "components/common/LinkButton/LinkButton";
import { HEADER_NAME_CONSTANT } from "components/RuleEnginePage/CurrentConfiguration/CurrentConfigConstant";

interface RenderMinimumPromiseTypeConfigurationInterface {
  classes: any;
  config: DEFAULT_RULE_CONFIG;
  onMinTimeIsSameAsEarlierChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMinTimeConfigEdit: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isAddMinimumDeliveryPromiseOtherDaysButtonDisabled: () => boolean;
  onAddMinimumDeliveryPromiseOtherDays: () => void;
  onMinConfigDaysChange: (name: string, minimumConfigObjIndex: number, event: React.ChangeEvent<HTMLInputElement>) => void;
  onMinConfigChange: (name: string, minimumConfigObjIndex: number, timeConfigObjIndex: number, event: React.ChangeEvent<HTMLInputElement> | any) => void;
  handleAddMinimumConfigSlotButton: (minimumConfigObjIndex: number) => void;
  handleRemoveMinimumConfigSlotButton: (minimumConfigObjIndex: number, timeConfigObjIndex: number) => void;
  handleRemoveDeliveryPromiseOtherDays: (minimumConfigObjIndex: number) => void;
}

const RenderMinimumPromiseTypeConfiguration = (props: RenderMinimumPromiseTypeConfigurationInterface) => {
  const {
    classes,
    config,
    onMinTimeIsSameAsEarlierChange,
    handleMinTimeConfigEdit,
    isAddMinimumDeliveryPromiseOtherDaysButtonDisabled,
    onAddMinimumDeliveryPromiseOtherDays,
    onMinConfigDaysChange,
    onMinConfigChange,
    handleAddMinimumConfigSlotButton,
    handleRemoveMinimumConfigSlotButton,
    handleRemoveDeliveryPromiseOtherDays,
  } = props;

  const showAddOtherDaysSpan = (minimumConfigObjIndex: number) => minimumConfigObjIndex === 0;
  const showAddIcon = (timeConfigObjIndex: number) => timeConfigObjIndex === 0;

  return (
    <Grid container className={classes.ruleContainer} spacing={0}>
      <Grid item xs={9} className={classes.ruleLabel}>
        {`${RULE_ENGINE_TEXT_CONSTANT.MINIMUM_PROMISE_TIME}`}
      </Grid>
      <ChangeConfigOtion
        config={config}
        checked={config.promiseConfig.minTimeSameAsEarlier}
        classes={classes}
        xsValue={4}
        checkChangeHandler={onMinTimeIsSameAsEarlierChange}
        changeExisting={config.promiseConfig.enableMinTimeEdit}
        onConfigEdit={handleMinTimeConfigEdit}
      />

      {(!config.promiseConfig.minTimeSameAsEarlier || config.isEditMode) && (
        <>
          {isArrayValid(config.promiseConfig.minimumPromiseRuleValues) &&
            config.promiseConfig.minimumPromiseRuleValues.map((minimumConfigObj: any, minimumConfigObjIndex: number) => (
              <Box className={classes.ruleContainerBoxStyle}>
                <Grid item xs={12} className={classes.ruleEditingDivStyle} key={`${minimumConfigObjIndex}-minimum-Config-Obj-Index`}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid item xs={6}>
                      <MultiSelectBox
                        label={`${RULE_ENGINE_TEXT_CONSTANT.DAYS}`}
                        menuitems={calculateAvailableDaysForSelection(minimumConfigObjIndex, config.promiseConfig.minimumPromiseRuleValues)}
                        value={minimumConfigObj.configuredDays}
                        onChange={(e: any) => onMinConfigDaysChange(RULE_ENGINE_TEXT_CONSTANT.CONFIGURED_DAYS, minimumConfigObjIndex, e)}
                        disabled={config.isEditMode && !config.promiseConfig.enableMinTimeEdit}
                        doNotShowAllInAllSelected={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {showAddOtherDaysSpan(minimumConfigObjIndex) ? (
                        config.promiseConfig.enableMinTimeEdit &&
                        !isAddMinimumDeliveryPromiseOtherDaysButtonDisabled() && (
                          <Grid container spacing={1} justifyContent="flex-end">
                            <Grid item xs={12} className={classes.addOtherDays}>
                              <LinkButton buttonLabel={`${HEADER_NAME_CONSTANT.ADD_OTHER_DAYS}`} disabled={isAddMinimumDeliveryPromiseOtherDaysButtonDisabled()} onClick={onAddMinimumDeliveryPromiseOtherDays} />
                            </Grid>
                          </Grid>
                        )
                      ) : (
                        <Grid container spacing={1} justifyContent="flex-end">
                          <Grid item xs={12} className={classes.addOtherDays}>
                            {config.promiseConfig.enableMinTimeEdit && <DeleteIcon className={classes.deleteIconStyle} onClick={() => handleRemoveDeliveryPromiseOtherDays(minimumConfigObjIndex)} />}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {!isUndefined(minimumConfigObj?.timeConfig) && isArrayValid(minimumConfigObj.timeConfig) && (
                    <>
                      <Grid container spacing={1}>
                        <Grid item xs={2}>
                          <Typography>{`${RULE_ENGINE_TEXT_CONSTANT.START_TIME}`}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography>{`${RULE_ENGINE_TEXT_CONSTANT.END_TIME}`}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography>{`${RULE_ENGINE_TEXT_CONSTANT.MIN_TIME_HOUR}`}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography>{`${RULE_ENGINE_TEXT_CONSTANT.MIN_TIME_MINUTES}`}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        {minimumConfigObj.timeConfig.map((timeConfigObj: TimeConfigItem, timeConfigObjIndex: number) => (
                          <>
                            <Grid item xs={2}>
                              <TimePicker
                                value={timeConfigObj.startTime}
                                handleChange={(date: any) => onMinConfigChange(RULE_ENGINE_TEXT_CONSTANT.FIELD_LABEL_START_TIME, minimumConfigObjIndex, timeConfigObjIndex, date)}
                                fieldLabel={""}
                                className={undefined}
                                timerProps={undefined}
                                disabled={!config.promiseConfig.enableMinTimeEdit}
                                ampm={false}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <TimePicker
                                value={timeConfigObj.endTime}
                                handleChange={(date: any) => onMinConfigChange(RULE_ENGINE_TEXT_CONSTANT.FIELD_LABEL_END_TIME, minimumConfigObjIndex, timeConfigObjIndex, date)}
                                fieldLabel={""}
                                className={undefined}
                                timerProps={undefined}
                                disabled={!config.promiseConfig.enableMinTimeEdit}
                                ampm={false}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <SelectBox
                                fieldLabel=""
                                menuItems={DEFAULT_TIME_HOURS}
                                value={timeConfigObj.minTimeHour}
                                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => onMinConfigChange(RULE_ENGINE_TEXT_CONSTANT.FIELD_LABEL_MIN_TIME_HOUR, minimumConfigObjIndex, timeConfigObjIndex, event)}
                                disabled={!config.promiseConfig.enableMinTimeEdit}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <SelectBox
                                fieldLabel=""
                                menuItems={getDefaultOrFixedTimeMinutes(timeConfigObj.minTimeHour)}
                                value={timeConfigObj.minTimeMinutes}
                                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => onMinConfigChange(RULE_ENGINE_TEXT_CONSTANT.FIELD_LABEL_MIN_TIME_MINUTES, minimumConfigObjIndex, timeConfigObjIndex, event)}
                                disabled={!config.promiseConfig.enableMinTimeEdit}
                              />
                            </Grid>
                            <Grid item xs={4} className={classes.addCircleGridStyle}>
                              {config.promiseConfig.enableMinTimeEdit && showAddIcon(timeConfigObjIndex) ? (
                                <AddCircleIcon className={classes.addCircleStyle} onClick={() => handleAddMinimumConfigSlotButton(minimumConfigObjIndex)} />
                              ) : (
                                config.promiseConfig.enableMinTimeEdit && <DeleteIcon className={classes.deleteIconStyle} onClick={() => handleRemoveMinimumConfigSlotButton(minimumConfigObjIndex, timeConfigObjIndex)} />
                              )}
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            ))}
        </>
      )}
    </Grid>
  );
};

export default React.memo(RenderMinimumPromiseTypeConfiguration);
