import { DELIVERY_TYPE } from "../../../config/constants";
import { COLORS } from "../../../config/theme/theme";

export const latitudeLongitudeValueRegExp = new RegExp("/^-?d*.?d+$/");

export const IS_POLYGON_COUNT_IN_HEADER_VISIBLE = true as boolean;

export const HEADER_TEXT = "Polygon Services";
export const TABLE_BODY_NO_KML_FILE_ISSUE = "No KML file was updated for";
export const TABLE_BODY_UPLOAD_KML_FILE_ISSUE = "Please upload the KML file and retry.";
export const ALL_RESULTS_FILTERED = "All results filtered.";
export const PLEASE_RESET_TRY_AGAIN = "Please reset filters and try again";
export const PLEASE_CLEAR_ALL_FILTERS_TRY_AGAIN = "Please reset your criteria and try again";

export const NO_POLYGON_LIST_FOUND_FOR_SUBMIT = "No polygon record found. Please reload!";
export const NO_POS_ZONE_SELECTED_ERROR = "Please select a POS zone or mark non-serviceable";
export const ERROR_OCCURRED_WHILE_SAVING = "An error occurred, please try again";

export const CONFIGURATIONS_UPDATED_SUCCESSFULLY = "Successfully saved changes.";

export const polygon_count_obj = [
  {
    id: "totalPolygon",
    label: "Total Polygons",
    value: 0,
  },
  {
    id: "mappedPolygon",
    label: "Mapped to POS",
    value: 0,
  },
  {
    id: "nonServiceablePolygon",
    label: "Non Serviceable",
    value: 0,
  },
  {
    id: "unMappedPolygon",
    label: "Unmapped",
    value: 0,
  },
];

export const POLYGON_ID_LOCAL_STORAGE = "PolygonIdToEdit";

export const SERVICE_TYPE_RECORD_LIST = [
  { id: "STANDARD", name: "Standard" },
  { id: "EXPRESS", name: "Express" },
  { id: "DEFAULT", name: "Default" },
];

export const ALL_SERVICE_TYPE = ["STANDARD", "EXPRESS", "DEFAULT"];

export const SERVICE_TYPE_LIST_REQUIRED = "Please select at least one service type";
export const LATITUDE_LONGITUDE_ERROR = "Please enter valid latitude/longitude";
export const LATITUDE_LONGITUDE_EMPTY_ERROR = "Please enter latitude/longitude";
export const NO_POLYGON_EXIST_FOR_COUNTRY = "No polygons exist for country";

export const POLYGON_VIEW_TYPE = { LISTING: "listing", MAP: "Map" };

export const POLYGON_MAP_COLORS_SCHEME = {
  STANDARD_COLOR_SCHEME: { name: "Standard", fillColor: COLORS.RAINEE, strokeColor: COLORS.KILLARNEY },
  EXPRESS_COLOR_SCHEME: { name: "Express", fillColor: COLORS.LAVENDER_GRAY, strokeColor: COLORS.ROYAL_PURPLE },
  DEFAULT_COLOR_SCHEME: { name: "Default", fillColor: COLORS.CASPER, strokeColor: COLORS.BLAKE },
  NON_SERVICEABLE_COLOR_SCHEME: { name: "Non serviceable", fillColor: COLORS.CASHMERE, strokeColor: COLORS.PIPER },
  MULTIPLE_SERVICES_COLOR_SCHEME: { name: "Multiple services", fillColor: COLORS.CLAM_SHELL, strokeColor: COLORS.CLARET },
  UNMAPPED_COLOR_SCHEME: { name: "Unmapped", fillColor: COLORS.CHICAGO_LIGHT, strokeColor: COLORS.CHICAGO },
} as any;

export const POLYGON_CONSTANT_TEXT = {
  POLYGON_ID: "Polygon ID",
  POLYGON_NAME: "Polygon Name",
  LATLNG: "Lat Lng",
  POLYGON_ID_VALUE: "polygonId",
  POLYGON_NAME_VALUE: "polygonName",
  LATLNG_VALUE: "LatLng",
  CREATE_NEW_CONFIGURATION_BUTTON: "Create New Configuration",
  MARK_AS_NON_SERVICEABLE: "Mark as non serviceable",
  START_TIME: "Start Time",
  END_TIME: "End Time",
  POS_ZONE: "POS Zone",
  POLYGON_ID_MAPPING: "Polygon ID Mapping",
  ADD_MAPPING: "Add Mapping",
  USE_THIS_VALUE_IN_CALCULATION: "Use this value in calculation",
  ERROR_WHILE_SAVING: "Error while saving!",
  POLYGON_IDS: "Polygon IDs",
  VIEW: "View",
  MAPPED_POLYGON_IDS: "Mapped Polygon IDs",
  ACTION: "Action",
  BACK_TO_LIST: "Back To List",
  PROVIDE_VALID_START_END_TIME: "Please provide valid Start and End time",
  TIME_RANGE_ISSUE_ADD_MAPPING: "Time range already provided for 24 hours. Please change Start time or End Time",
  ERROR_CREATE_CONFIG: "Error while creating configuration",
  NO_MESSAGE_AVAILABLE: "No message available",
  POLYGON_ID_REQUIRED: "Polygon Id list is required",
  NO_CONFIGURATION_FOUND: "No configurtion changes found",
  Please_PROVIDE_MAPPING_RECORD: "Please provide mapping record",
  NO_POS_ZONE_SELECTED_ERROR: "Please select a POS zone or mark non-serviceable",
  STANDARD: "STANDARD",
  EXPRESS: "EXPRESS",
  DEFAULT: "DEFAULT",
  NO_POLYGON_ID_FOUND: "No Polygon ID found",
  ERROR_FETCHING_CONFIG: "Error while fetching Polygon ID configuration",
  NON_SERVICEABLE_AREA: "Non Serviceable Area",
  POLYGON_ID_PARAM: "polygonId",
  UPDATE_CONFIGURATION: "Edit",
  SAVE: "Save",
  UPDATE: "Update",
  POLYGON_ID_UPDATE_ERROR_REQUIRED: "Polygon ID not found for update. Please refresh.",
  BACK_END_TIME_FORMAT: "HH:mm:ss",
  VIEW_TIME_FORMAT: "HH:mm",
  FBC: "FBC",
  SERVICE_TYPE: "Service Type",
};

export const POLYGON_SERVICE_SEARCH_TYPES = [{ name: POLYGON_CONSTANT_TEXT.POLYGON_ID, value: POLYGON_CONSTANT_TEXT.POLYGON_ID_VALUE }];

export const POLYGON_SERVICE_MAP_VIEW_SEARCH_TYPES = [
  { name: POLYGON_CONSTANT_TEXT.POLYGON_ID, value: POLYGON_CONSTANT_TEXT.POLYGON_ID_VALUE },
  { name: POLYGON_CONSTANT_TEXT.LATLNG, value: POLYGON_CONSTANT_TEXT.LATLNG_VALUE },
];

export const ONE_DAY_IN_MILLI_SECONDS = 24 * 60 * 60 * 1000;

export const DELIVERY_TYPES_ALLOWED_FOR_POLYGON = [DELIVERY_TYPE.DAY_BASED, DELIVERY_TYPE.MINUTE_BASED, DELIVERY_TYPE.SLOT_BASED];
