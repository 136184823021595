import React, { useRef } from "react";

import MapContainer from "../../common/CustomGoogleMap/MapContainer";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import { MapPolygonInterface, PlolygonServicesInitialStateInteface, mapOptionStyles } from "../Utils/PlolygonServicesInterface";
import { CoordinatesInterface } from "../../../config/constants";
import { isArrayValid, isUndefined } from "../../../utils/DataUtils";

interface PolygonMapServiceInterface {
  classes: any;
  appStateObj: PlolygonServicesInitialStateInteface;
  fetchPolygonServiceMapRecordnBasedOnBoundaries: (northEast: CoordinatesInterface, southWest: CoordinatesInterface) => void;
  openPolygonPosZoneInfoWindow: (markerIndex: number) => void;
  handlePolygonClick: (event: any, polygon: MapPolygonInterface) => void;
  viewPolygonInfoWindow: any;
  posZoneMarkerInfoWindow: any;
}

const PolygonMapService = (props: PolygonMapServiceInterface) => {
  const { classes, appStateObj, fetchPolygonServiceMapRecordnBasedOnBoundaries, openPolygonPosZoneInfoWindow, handlePolygonClick, viewPolygonInfoWindow, posZoneMarkerInfoWindow } = props;
  const refMap = useRef() as any;

  const onIdle = () => {
    if (refMap && refMap.current) {
      var bounds = refMap.current.getBounds();
      if (bounds) {
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();
        const northEastCord: CoordinatesInterface = { lat: Number(ne.lat()), lng: Number(ne.lng()) };
        const southWestCord: CoordinatesInterface = { lat: Number(sw.lat()), lng: Number(sw.lng()) };
        fetchPolygonServiceMapRecordnBasedOnBoundaries(northEastCord, southWestCord);
      }
    }
  };

  return (
    <>
      <Grid container className={classes.colorSchemeGridContainerStyle}>
        {!isUndefined(appStateObj?.serviceBasedColorRecord) &&
          isArrayValid(appStateObj?.serviceBasedColorRecord) &&
          appStateObj.serviceBasedColorRecord.map((serviceObj: any) => (
            <Grid item className={classes.colorSchemeGridItemStyle}>
              <Box style={{ backgroundColor: serviceObj.strokeColor }} className={classes.colorSchemeBoxStyle}></Box>
              <Typography className={classes.colorSchemeSpanStyle}>{`${serviceObj.displayName || serviceObj.name}`}</Typography>
            </Grid>
          ))}
      </Grid>
      <Card className={classes.mapContainerCardStyle}>
        <MapContainer
          refMap={refMap}
          defaultCenter={appStateObj.mapDefaultCenter}
          center={appStateObj.mapCenter}
          zoom={appStateObj.mapZoom}
          onIdle={onIdle}
          markers={appStateObj.mapMarkersRecord}
          polygons={appStateObj.mapPolygonsRecord}
          handlePolygonClick={handlePolygonClick}
          polygonStateObj={appStateObj}
          viewPolygonInfoWindow={viewPolygonInfoWindow}
          posZoneMarkerInfoWindow={posZoneMarkerInfoWindow}
          openPolygonPosZoneInfoWindow={openPolygonPosZoneInfoWindow}
          mapOptionStyles={mapOptionStyles}
        />
      </Card>
    </>
  );
};

export default React.memo(PolygonMapService);
