import React from "react";

import { Box, Grid, Tab, Typography } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import { userStyles } from "./Styles/PolygonDetailsDrawerStyles";
import { PolygonDetailsSideDrawerInterface } from "../../Utils/PlolygonServicesInterface";

import CustomDialog from "../../../common/Dialog";
import PolygonDetailsHistoryRecord from "./PolygonDetailsHistoryRecord";
import { isArrayValid, isUndefined } from "utils/DataUtils";

interface PolygonDetailsDrawerInterface {
  polygonDetailsSideDrawerObj: PolygonDetailsSideDrawerInterface;
  handleClose: any;
  handlePolygonDetailsServiceTypeTabChange: any;
  serviceListRecord: any[];
}

const PolygonDetailsDrawer = (props: PolygonDetailsDrawerInterface) => {
  const classes = userStyles();
  const { polygonDetailsSideDrawerObj, handleClose, handlePolygonDetailsServiceTypeTabChange, serviceListRecord } = props;

  const publishDialogPaperProps = { classes: { root: classes.dialogRoot }, square: true };

  const getDialogTitleContent = () => {
    return (
      <>
        <Grid container className={classes.titleNameGridContainer}>
          <Grid item xs={12}>
            <Typography className={classes.titleNameSpanStyle}>{`Action History: ${polygonDetailsSideDrawerObj.polygonId || "ID"}`}</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.titleSubHeaderNameGridContainer}>
          <Typography className={classes.titleSubHeadingNameSpanStyle}>{`Displaying for the last 30 days`}</Typography>
        </Grid>
      </>
    );
  };

  const getDialogDetailsContent = () => {
    return (
      <Box>
        <TabContext value={polygonDetailsSideDrawerObj.serviceType || (!isUndefined(serviceListRecord) && isArrayValid(serviceListRecord) && !isUndefined(serviceListRecord[0]) && serviceListRecord[0]?.name) || "STANDARD"}>
          <Box>
            <TabList onChange={handlePolygonDetailsServiceTypeTabChange} className={classes.tabSelectionBox} classes={{ indicator: classes.indicator }}>
              {!isUndefined(serviceListRecord) &&
                isArrayValid(serviceListRecord) &&
                serviceListRecord.map((serviceObj: any) => <Tab label={`${serviceObj?.displayName || serviceObj?.name || ""}`} value={`${serviceObj?.name || ""}`} className={classes.tabLabelSpanStyle} />)}
            </TabList>
          </Box>
          <TabPanel value={`${polygonDetailsSideDrawerObj.serviceType}`} className={classes.tabPanelBoxStyle}>
            <PolygonDetailsHistoryRecord classes={classes} polygonDetailsSideDrawerObj={polygonDetailsSideDrawerObj} />
          </TabPanel>
        </TabContext>
      </Box>
    );
  };

  return (
    <CustomDialog open={polygonDetailsSideDrawerObj.openDrawer} PaperProps={publishDialogPaperProps} isLoadingData={polygonDetailsSideDrawerObj.loading} title={getDialogTitleContent()} content={getDialogDetailsContent()} handleClose={handleClose} />
  );
};

export default React.memo(PolygonDetailsDrawer);
