import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import commonStyles from "../RuleEngineCommonStyles";
import currentConfigStyle from "./CurrentConfigurationStyles";
// import Table from "../../common/Table/Table";
import CustomAlert from "../../common/CustomAlert/CustomAlert";
import SearchHeaderCustom from "../../common/SearchHeaderCustom/SearchHeaderCustom";
import Loader from "../../common/Loader/Loader";

import { ALERT_TYPES, API_RESPONSE_STATUS, APP_ROUTES, DELIVERY_PROMISE_SEARCH_TYPES, SERVICE_TYPES } from "../../../config/constants";
import { serviceSelectPropositionRecord } from "../../../config/redux/configurationsSlice.js";
import { getMinuteBasedDeliveryType, RuleEngineCreateConfigButtonAccess } from "../Utils/RuleEngineAccessUtils";
import { selectUserDetails } from "../../Login/redux/userSlice";
import { fetchCurrentConfigurationBasedOnSearchAction, selectDeliveryPromiseServiceCurrConfiguration } from "../redux/ruleEngineSlice";
import {
  designErrorScreenObject,
  designLoadingScreenObject,
  designResetScreenObject,
  designSuccessScreenObject,
  getCurrentConfigTableColumnHeader,
  renderActionColumn,
  renderColumnCell,
  renderCountIncrementalTime,
  renderDynamicPromise,
  renderItemCountRule,
  renderLastMileTime,
  renderMaximumPromiseTime,
  renderMaximumPromiseTimeForVisibility,
  renderMinimumPromiseTime,
  renderPosName,
  renderScalableItems,
  renderStatus,
  renderWeightRule,
} from "./CurrentConfigUtils";
import { HEADER_NAME_CONSTANT, LISTING_SCREEN_APP_STATE_INTERFACE, REQUEST_PARAM_INTEFACE } from "./CurrentConfigConstant";
import { isArrayValid, isEmptyOrNull, isUndefined } from "../../../utils/DataUtils";
import { canChangeRuleEngineConfig } from "../RuleEngineAccessControl";
import { GetNoDataPresentAfterFiltereApplied, GetNoDataPresentAfterSearchApplied, GetNoDataPresentErrorViewUtils } from "../../common/App/AppViewUtils";

interface DeliveryPromiseServiceCurrentConfigurationsInterface {
  userInfo: any;
}

const DeliveryPromiseServiceCurrentConfigurations = (props: DeliveryPromiseServiceCurrentConfigurationsInterface) => {
  const { userInfo } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const commonClasses = commonStyles();
  const classes = currentConfigStyle();

  const servicePropositionList = useSelector(serviceSelectPropositionRecord);
  const { currentCountry } = useSelector((state: any) => state.appConfig);

  const [appState, setAppState] = useState<LISTING_SCREEN_APP_STATE_INTERFACE>({
    loading: false,
    minuteBasedDelivery: [],
    tableColumns: [],
    expandedAccordion: "",
    tableRows: [],
    apiParams: { query: "", currentPage: 0, size: 40, maxRecordPresent: 0 },
    searchTypeValue: "posNo",
    searchValue: "",
  });

  const getServiceCurrentConfigurations = async (requestPayload: REQUEST_PARAM_INTEFACE, newAppState: LISTING_SCREEN_APP_STATE_INTERFACE) => {
    const { payload }: any = await dispatch(fetchCurrentConfigurationBasedOnSearchAction(requestPayload));
    if (!isUndefined(payload?.data?.content)) {
      const newAppStateTwo = designSuccessScreenObject(newAppState, payload.data.number || 0, payload.data.totalElements || 0, payload.data.content);
      setAppState(newAppStateTwo);
    } else {
      let errorMessage: string = "Error occured while processing your request.";
      if (!isUndefined(payload?.message) && !isEmptyOrNull(payload?.message)) {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
      const newAppStateTwo = designErrorScreenObject(newAppState);
      setAppState(newAppStateTwo);
    }
  };

  const fetchServiceCurrentConfigurations = (pageNumber: any, query: any, name: any) => {
    const requestPayload: REQUEST_PARAM_INTEFACE = { serviceTypeName: name || SERVICE_TYPES.EXPRESS, countryId: currentCountry.countryId, page: pageNumber, size: appState.apiParams.size } as any;
    if (!isUndefined(query) && !isEmptyOrNull(query)) {
      requestPayload.search = query;
    }
    const newAppState = designLoadingScreenObject(appState, name, pageNumber, query);
    setAppState(newAppState);
    getServiceCurrentConfigurations(requestPayload, newAppState);
  };

  const designAPIParamQuery = (serviceName: string, currentPage: any, searchTypeValue: any, searchValue: any) => {
    let query = "";
    if (!isEmptyOrNull(searchTypeValue) && !isEmptyOrNull(searchValue)) {
      if (searchTypeValue === "posNo") {
        query = `${searchTypeValue}:${searchValue.substring(0, 3)}`;
      } else {
        query = `${searchTypeValue}:${searchValue}`;
      }
    }
    fetchServiceCurrentConfigurations(currentPage, query, serviceName);
  };

  const fetchNextRecord = (serviceName: string, pageNumber: any) => {
    let searchTypeValue = !isEmptyOrNull(appState.searchTypeValue) ? appState.searchTypeValue : "";
    let searchValue = !isEmptyOrNull(appState.searchValue) ? appState.searchValue : "";
    designAPIParamQuery(serviceName, pageNumber, searchTypeValue, searchValue);
  };

  const resetDeliveryPromiseRecord = (serviceName: string) => {
    const requestPayload: REQUEST_PARAM_INTEFACE = { serviceTypeName: serviceName || SERVICE_TYPES.EXPRESS, countryId: currentCountry.countryId, page: 0, size: appState.apiParams.size } as any;
    const newAppState = designResetScreenObject(appState);
    setAppState(newAppState);
    getServiceCurrentConfigurations(requestPayload, newAppState);
  };

  const handleSearchTypeChange = (serviceName: string, selected: any) => {
    if (selected !== undefined && selected !== "") {
      setAppState({ ...appState, searchTypeValue: selected });
    }
  };

  const handleSearchBoxTextChange = (serviceName: string, event: any) => {
    if (!isUndefined(event?.target)) {
      const { value } = event.target;
      setAppState({ ...appState, searchValue: value });
    }
  };

  const handleSearchIconClick = (serviceName: string) => {
    if (appState.searchTypeValue) {
      if (appState.searchValue) {
        designAPIParamQuery(serviceName, appState.apiParams.currentPage, appState.searchTypeValue, appState.searchValue);
      } else {
        CustomAlert(ALERT_TYPES.ERROR, "Please provide search value");
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, "Please provide search type");
    }
  };

  const handleSearchBoxKeyDownChange = (serviceName: string, event: any) => {
    if ((event.key !== undefined && event.key === "Enter") || event.keyCode === 13) {
      handleSearchIconClick(serviceName);
    }
  };

  const handleAccordionChange = (event: any, name: string, isExpanded: boolean) => {
    event?.stopPropagation();
    event?.preventDefault();
    if (isExpanded && !isEmptyOrNull(currentCountry.countryId)) {
      fetchServiceCurrentConfigurations(appState.apiParams.currentPage || 0, appState.apiParams.query || "", name);
    } else {
      setAppState({ ...appState, loading: false, expandedAccordion: "", apiParams: { query: "", currentPage: 0, size: 40, maxRecordPresent: 0 }, searchTypeValue: "posNo", searchValue: "", tableRows: [] });
    }
  };

  const moveToNewConfigurationScreen = (event: any, serviceName: string) => {
    if (!isUndefined(event) && !isUndefined(serviceName)) {
      event?.stopPropagation();
      event?.preventDefault();
      history.push(`${APP_ROUTES.RULE_ENGINE_CURRENT_CONFIGURATION}/${serviceName}`);
    }
  };

  useEffect(() => {
    if (!isUndefined(servicePropositionList) && isArrayValid(servicePropositionList)) {
      setAppState({ ...appState, minuteBasedDelivery: getMinuteBasedDeliveryType(servicePropositionList), tableColumns: getCurrentConfigTableColumnHeader(userInfo), tableRows: [] });
    }
  }, [userInfo, servicePropositionList]);

  return (
    <>
      {!isUndefined(appState?.minuteBasedDelivery) &&
        isArrayValid(appState.minuteBasedDelivery) &&
        appState.minuteBasedDelivery.map((delivery: any, index: number) => (
          <Accordion
            key={`${index}-${delivery.name}`}
            expanded={appState.expandedAccordion === delivery.name}
            className={commonClasses.accordion}
            onChange={(event: any) => handleAccordionChange(event, delivery.name, appState.expandedAccordion !== delivery.name)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={commonClasses.accordionSummary}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>{delivery.displayName || delivery.name}</Grid>
                <Grid item>
                  <RuleEngineCreateConfigButtonAccess userInfo={userInfo} classes={classes} serviceName={delivery?.name || ""} serviceId={delivery?.id || ""} moveToNewConfigurationScreen={moveToNewConfigurationScreen} />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={commonClasses.accordionDetailsDeliveryPromiseTable}>
              <div className={commonClasses.fullScreenStyle}>
                <Grid container>
                  <Grid item xs={6} style={{ paddingLeft: "16px" }}>
                    <SearchHeaderCustom
                      searchTypes={DELIVERY_PROMISE_SEARCH_TYPES}
                      searchType={appState.searchTypeValue || "posNo"}
                      handleSearchTypeChange={(value: any) => handleSearchTypeChange(delivery.name, value)}
                      searchValue={appState.searchValue || ""}
                      handleSearchBoxTextChange={(event: any) => handleSearchBoxTextChange(delivery.name, event)}
                      handleSearchBoxKeyDownChange={(event: any) => handleSearchBoxKeyDownChange(delivery.name, event)}
                      placeholderText={`Search ${(appState.searchTypeValue && DELIVERY_PROMISE_SEARCH_TYPES.find((x: any) => x.value === appState.searchTypeValue)?.name) || ""}`}
                      handleIconClick={() => handleSearchIconClick(delivery.name)}
                      resetRecord={() => resetDeliveryPromiseRecord(delivery.name)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.tableStyle}>
                      {appState.loading ? (
                        <Loader />
                      ) : (
                        <TableContainer style={{ position: "relative" }}>
                          <Table padding="none" size="small" stickyHeader={true}>
                            <TableHead>
                              <TableRow>
                                {appState.tableColumns.map((header: any) => (
                                  <TableCell key={`${header.headerName}`} padding="none" size="small" align="left" className={classes.headerStyle}>{`${header.headerName}`}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {!isUndefined(appState.tableRows) && isArrayValid(appState.tableRows) ? (
                                appState.tableRows.map((rowData: any) => (
                                  <TableRow key={`${rowData.posNo}`}>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderColumnCell(classes, rowData?.posNo)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderPosName(classes, rowData?.posName)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderColumnCell(classes, rowData?.zoneName)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderStatus(classes, rowData)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderDynamicPromise(classes, rowData)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderMinimumPromiseTime(classes, rowData)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderItemCountRule(classes, rowData)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderWeightRule(classes, rowData)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderCountIncrementalTime(classes, rowData)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderScalableItems(classes, rowData)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderLastMileTime(classes, rowData)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderMaximumPromiseTime(classes, rowData)}
                                    </TableCell>
                                    <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                      {renderMaximumPromiseTimeForVisibility(classes, rowData)}
                                    </TableCell>
                                    {userInfo && canChangeRuleEngineConfig(userInfo) && (
                                      <TableCell align="left" className={`${classes.cellDataStyle}`}>
                                        {renderActionColumn(classes, rowData)}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell rowSpan={1} colSpan={appState.tableColumns.length} className={classes.noDataRowStyle}>
                                    {!isUndefined(appState?.searchValue) && !isEmptyOrNull(appState.searchValue) ? (
                                      <GetNoDataPresentAfterSearchApplied classes={classes} />
                                    ) : (
                                      <GetNoDataPresentErrorViewUtils classes={classes} currentCountry={currentCountry} ErrorMessage={`No Delivery Promise Record found.`} />
                                    )}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                {isArrayValid(appState.tableRows) && (
                                  <TablePagination
                                    className={classes.tablePaginationStyle}
                                    colSpan={appState.tableColumns.length}
                                    rowsPerPageOptions={[appState.apiParams.size]}
                                    count={appState.apiParams.maxRecordPresent}
                                    rowsPerPage={appState.apiParams.size}
                                    page={appState.apiParams.currentPage}
                                    onPageChange={(event, pageNumber) => {
                                      fetchNextRecord && fetchNextRecord(delivery.name, pageNumber);
                                    }}
                                  />
                                )}
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TableContainer>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};

const CurrentConfiguration = () => {
  const classes = currentConfigStyle();
  const userInfo = useSelector(selectUserDetails);

  return (
    <div className={classes.root}>
      <div className={classes.headerRoot}>
        <div className={classes.header}>
          <span className={classes.heading}>{`${HEADER_NAME_CONSTANT.DELIVERY_PROMISE_CURRENT_CONFIGURATION}`}</span>
        </div>
      </div>
      {userInfo && <DeliveryPromiseServiceCurrentConfigurations userInfo={userInfo} />}
    </div>
  );
};

export default React.memo(CurrentConfiguration);
