import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";

import { ExpandMoreAccordianIcon, RenderEmailIcon } from "../common/App/AppViewUtils";
import { ClickAndCollectStatuSwitch } from "../POSList/posUtils/PosAccessUtils";
import { getFullAddress } from "../../utils/helperFunctions";

import RenderServiceAllInformation from "../CompleteSetup/SlotsInformation/common/RenderServiceAllInformation";

interface RenderCollectionPointsInterface {
  classes: any;
  tab: any;
  collectionPoints: any;
  userInfo: any;
  handleCollectionPointToggleSwitch: any;
  serviceTypeName: any;
  servicePropositionList: any;
}

const RenderCollectionPoints = (props: RenderCollectionPointsInterface) => {
  const { classes, tab, collectionPoints, userInfo, handleCollectionPointToggleSwitch, serviceTypeName, servicePropositionList } = props;

  const getCollectionPointDetailsByCollectionName = (cpName: any, allCollectionPoints: any) => {
    let allCollectionPointsObj = { ...allCollectionPoints };
    let cpDetails = allCollectionPointsObj && cpName && allCollectionPointsObj[cpName] && allCollectionPointsObj[cpName].collectionPointDetails;
    return cpDetails || {};
  };

  return (
    <div className={classes.collectionPoints}>
      {collectionPoints !== undefined &&
        typeof collectionPoints === "object" &&
        Object.keys(collectionPoints).length > 0 &&
        Object.keys(collectionPoints).map((cp, cpIndex) => (
          <Accordion key={cp} className={classes.accordion} onClick={(e) => e.stopPropagation()}>
            <AccordionSummary expandIcon={<ExpandMoreAccordianIcon />} className={`${classes.accordionSummary} ${classes.collectionPointAccSummary}`} onClick={(e) => e.stopPropagation()}>
              <Grid container>
                <Grid item xs={6}>
                  {`${cp} (Capacity - ${collectionPoints[cp].capacityAmountAtCollectionPointLevel || "0"})`}
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end">
                    <ClickAndCollectStatuSwitch
                      userInfo={userInfo}
                      checked={getCollectionPointDetailsByCollectionName(cp, collectionPoints).active}
                      handleCollectionPointToggleSwitch={(e: any) => handleCollectionPointToggleSwitch(e, cp, collectionPoints)}
                      classes={classes}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.collectionPointAccDetails}>
              {tab !== undefined && tab.collectionPoints !== undefined && tab.collectionPoints[cp] !== undefined && collectionPoints[cp].collectionPointDetails !== undefined && (
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <div className={classes.fieldTitle}>Contact Name</div>
                    <div className={classes.fieldData}>{collectionPoints[cp].collectionPointDetails?.contactName}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className={classes.fieldTitle}>Email</div>
                    <div className={`${classes.fieldData} ${classes.dFlex}`}>
                      <RenderEmailIcon classes={classes} />
                      {collectionPoints[cp].collectionPointDetails?.contactEmail}
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={classes.fieldTitle}>Contact No.</div>
                    <div className={classes.fieldData}>{collectionPoints[cp].collectionPointDetails?.contactNumber}</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={classes.fieldTitle}>Coordinates</div>
                    <div className={classes.fieldData}>{`${collectionPoints[cp].collectionPointDetails?.latitude}, ${collectionPoints[cp].collectionPointDetails?.longitude}`}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className={classes.fieldTitle}>Address</div>
                    <div className={classes.fieldData}>{getFullAddress(collectionPoints[cp].collectionPointDetails)}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className={classes.fieldTitle}>Reference Id</div>
                    <div className={classes.fieldData}>{collectionPoints[cp].collectionPointDetails?.referenceId}</div>
                  </Grid>
                </Grid>
              )}
              <div className={classes.horizontalLine} />
              <RenderServiceAllInformation classes={classes} tab={tab} cp={cp} serviceTypeName={serviceTypeName} servicePropositionList={servicePropositionList} />
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};

export default React.memo(RenderCollectionPoints);
