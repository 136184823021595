import React from "react";

import { TableCell, TableHead, TableRow } from "@material-ui/core";

import { userStyles } from "../Styles/DeliveryFeePageStyles";
import { isShipmentTypeSlotBased } from "../Utils/DeliveryFeeUtils";

interface DeliveryFeeCardHeaderInterface {
  deliveryFeeHeaderData: any;
}

const DeliveryFeeCardHeader = (props: DeliveryFeeCardHeaderInterface) => {
  const classes = userStyles();
  const { deliveryFeeHeaderData } = props;

  return (
    <TableHead className={classes.tableHeaderStyle}>
      <TableRow>
        <TableCell padding="none" size="small" align="left" rowSpan={2} className={`${classes.cellHeaderStyle} ${classes.cellHeaderPosNoStyle}`}>
          POS No.
        </TableCell>
        <TableCell padding="none" size="small" align="left" rowSpan={2} className={`${classes.cellHeaderStyle} ${classes.cellHeaderPosNameStyle}`}>
          POS Name
        </TableCell>
        <TableCell padding="none" size="small" align="left" rowSpan={2} className={`${classes.cellHeaderStyle} ${classes.cellHeaderZoneNameStyle}`}>
          Zone Name
        </TableCell>

        {deliveryFeeHeaderData &&
          deliveryFeeHeaderData.headerRowsValues !== undefined &&
          Array.isArray(deliveryFeeHeaderData.headerRowsValues) &&
          deliveryFeeHeaderData.headerRowsValues.length > 0 &&
          deliveryFeeHeaderData.headerRowsValues.map((headerObj: any, headerObjIndex: any) => (
            <TableCell
              padding="none"
              size="small"
              align="center"
              rowSpan={headerObj.rowSpan}
              colSpan={headerObj.colSpan}
              className={`${classes.cellHeaderStyle} ${classes.cellHeaderServiceTypeStyle}`}
              key={`${headerObjIndex}-deliveryFeeHeaderData-shipmentName-${headerObj?.shipmentName || "service type"}`}
            >
              {headerObj?.displayName ? headerObj.displayName : headerObj.shipmentName}
            </TableCell>
          ))}
        <TableCell padding="none" size="small" align="left" rowSpan={2} className={`${classes.cellHeaderStyle} ${classes.cellHeaderActionStyle}`}>
          Actions
        </TableCell>
      </TableRow>
      <TableRow>
        {deliveryFeeHeaderData &&
          deliveryFeeHeaderData.headerRowsValues !== undefined &&
          Array.isArray(deliveryFeeHeaderData.headerRowsValues) &&
          deliveryFeeHeaderData.headerRowsValues.length > 0 &&
          deliveryFeeHeaderData.headerRowsValues.map((headerObj: any, headerObjIndex: any) => (
            <>
              <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle} key={`01-${headerObjIndex}-${headerObjIndex}-headerRowsValues-status`}>
                Status
              </TableCell>
              <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle} key={`02-${headerObjIndex}-${headerObjIndex}-headerRowsValues-Threshold`}>
                Threshold
              </TableCell>
              <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle} key={`03-${headerObjIndex}-${headerObjIndex}-headerRowsValues-above-Threshold`}>
                Fees Above Threshold
              </TableCell>
              <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle} key={`04-${headerObjIndex}-${headerObjIndex}-headerRowsValues-below-Threshold`}>
                Fees Below Threshold
              </TableCell>
              <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle} key={`05-${headerObjIndex}-${headerObjIndex}-headerRowsValues-service-fee`}>
                Service Fee
              </TableCell>
              {isShipmentTypeSlotBased(headerObj?.deliveryType) && (
                <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle} key={`07-${headerObjIndex}-${headerObjIndex}-headerRowsValues-SlotCapacity`}>
                  Preferred Slot Fees
                </TableCell>
              )}
              {isShipmentTypeSlotBased(headerObj?.deliveryType) && (
                <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle} key={`07-${headerObjIndex}-${headerObjIndex}-headerRowsValues-SlotCapacity`}>
                  Slot Proximity Fees
                </TableCell>
              )}
              <TableCell padding="none" size="small" align="center" className={classes.cellSubHeaderStyle} key={`06-${headerObjIndex}-${headerObjIndex}-headerRowsValues-minimumOrderValue`}>
                MOV
              </TableCell>
            </>
          ))}
      </TableRow>
    </TableHead>
  );
};

export default React.memo(DeliveryFeeCardHeader);
