import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Material UI Components
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import { unwrapResult } from "@reduxjs/toolkit";
import produce from "immer";
import lodashGet from "lodash.get";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import ConfirmModal from "../../../components/common/ConfirmModal/ConfirmModal";
import CustomAlert from "../../../components/common/CustomAlert/CustomAlert";
// Constants
import { ALERT_TYPES, API_RESPONSE_STATUS, APP_ROUTES, APPLICATION_TEXT_CONSTANT, BUTTON_TEXT_CONSTANT, DAYS_DROPDOWN_OPTIONS, HH_MM_SS, PHARMACY, SERVICE_TYPES, STATUS_OK } from "../../../config/constants";
import { selectCurrentCountryDetails } from "../../../config/redux/appConfigSlice";
import { selectAllAreas, selectAllCountries, selectAllRegions, selectCollectionPointsTypes, selectServiceTypes, serviceSelectPropositionRecord } from "../../../config/redux/configurationsSlice";
import { convertMinutesToHours } from "../../../utils/helperFunctions";
import CustomBreadcrumbs from "../../common/CustomBreadcrumbs/CustomBreadcrumbs";
// Components
import LinkButton from "../../common/LinkButton/LinkButton";
import OverlayLoader from "../../common/Loader/OverlayLoader";
import PageTitle from "../../common/PageTitle/PageTitle";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import RemoveButton from "../../common/RemoveButton/RemoveButton";
import SelectBox from "../../common/SelectBox/SelectBox";
import TextBox from "../../common/TextBox";
import { fetchPOSDetailsByPOSNo } from "../../CreatePOS/redux/posSlice";
import BasicServiceDetails from "../common/BasicServiceDetails/BasicServiceDetails";
import ProductType from "../common/ProductType/ProductType";
import CreateTemplateSlots from "../common/Slots/CreateTemplateSlots";
import EditTemplateSlots from "../common/Slots/EditTemplateSlots";
import {
  createCnCTemplateSlot,
  createTemplateSlots,
  deleteCnCTemplateSlot,
  deleteZoneConfig,
  fetchSlotsInformationByServiceType,
  makeCnCTemplateSlotEditable,
  resetSlotsInformation,
  updateCnCTemplateSlot,
  updateTemplateSlots,
} from "../redux/templateSlotsSlice";
import { COLLECTION_POINT_EDFAULT_STATE, copyAddressDetailsFromPOSAddress, validateZoneConfig } from "../utils/CollectAndClickUtils";
import { DEFAULT_SLOTS_GENERATOR_FORMS_VALUES, DEFAULT_TEMPLATE_SLOT, foodSplitRegex, PRE_SELECTED_FOOD_SUB_TYPES } from "../utils/constants";
import { generateSlots } from "../utils/serviceUtils";
import { getFoodTypesFromFoodSubTypes, getPOSBreadCum, handleFoodSubType, handleProductType, productTypesToBeSent } from "../utils/SlotServicesUtils";
import clickAndCollectStyles from "./ClickAndCollectStyles";

const ClickAndCollect = ({ serviceTypeId, serviceTypeName, displayName }) => {
  const classes = clickAndCollectStyles();

  const { posNo } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const { posDetails, fetchStatus, loading: fetchingDetails } = useSelector((state) => state.pos);
  const { fetchingSlots, click_n_collect, isDeleting } = useSelector((state) => state.slots);

  const isPosTypePharmacy = lodashGet(posDetails, "posType", "") === PHARMACY;

  const countries = useSelector(selectAllCountries);
  const regions = useSelector(selectAllRegions);
  const areas = useSelector(selectAllAreas);
  const servicePropositionList = useSelector(serviceSelectPropositionRecord);
  const { data: collectionPointsTypes } = useSelector(selectCollectionPointsTypes);
  const { name: currentCountryName } = useSelector(selectCurrentCountryDetails);

  const [selectedConfigTab, setSelectedConfigTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refetchConfig, setRefetchConfig] = useState(false);

  const [configTabs, setConfigTabs] = useState([
    {
      zoneName: "Zone01",
      foodTypes: "",
      foodSubType: PRE_SELECTED_FOOD_SUB_TYPES,
      collectionPoints: [{ ...COLLECTION_POINT_EDFAULT_STATE, country: currentCountryName }],
      status: "",
    },
  ]);

  const [errors, setErrors] = useState([
    {
      foodTypes: "",
    },
  ]);

  const [showDeleteZCModal, setShowDeleteZCModal] = useState(false);

  const collectionPointRef = React.useRef(null);

  const getAreasForSelectedRegion = (tabIndex, collectionPointIndex) => {
    let selectedRegionId = configTabs[tabIndex].collectionPoints[collectionPointIndex].region;
    let availableAreas = [];
    if (selectedRegionId) {
      availableAreas = areas.filter((a) => a.regionId === parseInt(selectedRegionId));
    }

    return availableAreas;
  };

  const handleTabChange = (event, tabIndex) => {
    setSelectedConfigTab(tabIndex);
  };

  const executeScroll = (ref) => ref.current.scrollIntoView({ behavior: "smooth" });

  /**
   *
   * @param {number} tabIndexToBeDeleted
   * @param {eventObj} e
   * Handler to show the delete confirm modal while deleting a zone config
   */
  const handleDeleteZoneConfigClick = (tabIndexToBeDeleted, e) => {
    e.stopPropagation();

    setSelectedConfigTab(tabIndexToBeDeleted);
    setShowDeleteZCModal(true);
  };

  const resetConfigTab = (tabIndexToBeReset) => {
    let defaultConfig = {
      zoneName: "Zone01",
      foodTypes: "",
      foodSubType: PRE_SELECTED_FOOD_SUB_TYPES,
      collectionPoints: [{ ...COLLECTION_POINT_EDFAULT_STATE, country: currentCountryName }],
      status: "",
    };

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndexToBeReset] = { ...defaultConfig, zoneName: `Zone01` };
    });

    setConfigTabs(newTabs);
  };

  const removeZoneTabAfterDeletion = (tabIndexToBeDeleted) => {
    resetConfigTab(tabIndexToBeDeleted);
  };

  /**
   * Method to delete the zone config
   */
  const deleteConfigTab = async (tabIndexToBeDeleted) => {
    let zoneId = configTabs[tabIndexToBeDeleted].zoneId;

    // If the tabs config is being saved at the backend, call the API to delete
    if (zoneId) {
      setLoading(true);
      const {
        payload: { data, message: deleteError },
      } = await dispatch(
        deleteZoneConfig({
          serviceTypeName,
          zoneConfigId: configTabs[tabIndexToBeDeleted].zoneId,
          posNo: posNo,
        })
      );
      setShowDeleteZCModal(false);

      if (data === true) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Zone configuration deleted successfully!");
        removeZoneTabAfterDeletion(tabIndexToBeDeleted);
        // After every transaction related to service type config changes refetch all slot details from server for sync.
        setRefetchConfig((prev) => !prev);
      } else {
        if (deleteError) {
          setLoading(false);
          CustomAlert(ALERT_TYPES.ERROR, deleteError);
          return;
        }
      }
      return;
    }
  };

  const addCollectionPoint = (tabIndex, e) => {
    let newCollectionPoint = {
      ...COLLECTION_POINT_EDFAULT_STATE,
      country: currentCountryName,
      ...copyAddressDetailsFromPOSAddress(posDetails),
    };

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints.push(newCollectionPoint);
    });

    setConfigTabs(newTabs);
    e.stopPropagation();

    setTimeout(() => {
      executeScroll(collectionPointRef);
    }, 200);
  };

  const deleteCollectionPoint = (tabIndex, cpIndexToBeDeleted) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints.splice(cpIndexToBeDeleted, 1);
    });

    setConfigTabs(newTabs);
  };

  const handleCollectionPointNameAndType = (tabIndex, collectionPointIndex, event) => {
    const { name, value } = event.target;
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints[collectionPointIndex][name] = value;
    });

    setConfigTabs(newTabs);
  };

  const handleAddressSameAsPOSCheckbox = (tabIndex, collectionPointIndex, event) => {
    const { checked } = event.target;

    let newTabs = produce(configTabs, (draft) => {
      if (checked) {
        draft[selectedConfigTab].collectionPoints[collectionPointIndex] = {
          ...draft[selectedConfigTab].collectionPoints[collectionPointIndex],
          ...copyAddressDetailsFromPOSAddress(posDetails),
          addressSameAsPos: checked,
        };
      } else {
        draft[tabIndex].collectionPoints[collectionPointIndex].addressSameAsPos = checked;
      }
    });

    setConfigTabs(newTabs);
  };

  /**
   *
   * @param {number} tabIndex
   * @param {number} collectionPointIndex
   * @param {*} event
   * Method to update address fields state for collection points
   */
  const handleCollectionPointAddressFields = (tabIndex, collectionPointIndex, event) => {
    const { name, value } = event.target;
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints[collectionPointIndex][name] = value;
    });

    setConfigTabs(newTabs);
  };

  /**
   *
   * @param {number} tabIndex Index of current slected tab
   * @param {number} collectionPointIndex Index of collection point form
   * @param {*} slotsGeneratorFormIndex Index of slots generator form
   * @param {*} event
   */
  const handleSlotsGeneratorFormFields = (tabIndex, collectionPointIndex, slotsGeneratorFormIndex, event) => {
    const { name, value } = event.target;

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[slotsGeneratorFormIndex][name] = value;
    });

    setConfigTabs(newTabs);
  };

  /**
   *
   * @param {number} tabIndex
   * @param {number} collectionPointIndex
   * @param {number} slotsGeneratorFormIndex
   * @param {string} elemName
   * @param {Date} date
   * Method to update the service start and end time state
   */
  const handleServiceStartAndEndTime = (tabIndex, collectionPointIndex, slotsGeneratorFormIndex, date, elemName) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[slotsGeneratorFormIndex][elemName] = date;
    });

    setConfigTabs(newTabs);
  };

  /**
   *
   * @param {*} tabIndex
   * @param {*} collectionPointIndex
   * @param {*} slotsGeneratorFormIndex
   * @param {*} slotIndex
   * @param {*} event
   * Method to select/unselect individual slot
   */
  const handleIndividualSlotCheckbox = (tabIndex, collectionPointIndex, slotsGeneratorFormIndex, slotIndex, event) => {
    const { name, checked } = event.target;

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[slotsGeneratorFormIndex].generatedSlots[slotIndex][name] = checked;
    });

    setConfigTabs(newTabs);
  };

  /**
   *
   * @param {*} tabIndex
   * @param {*} collectionPointIndex
   * @param {*} slotsGeneratorFormIndex
   * @param {*} slotIndex
   * @param {*} event
   * Method to update the state of individual generated slot
   */
  const handleIndividualSlotFieldsChange = (tabIndex, collectionPointIndex, slotsGeneratorFormIndex, slotIndex, event) => {
    const { name, value } = event.target;

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[slotsGeneratorFormIndex].generatedSlots[slotIndex][name] = value;
    });

    setConfigTabs(newTabs);
  };

  /** Handling of edit flow - Start */
  const makeSlotEditable = (slot) => {
    if (!slot || !slot.id) return;

    dispatch(makeCnCTemplateSlotEditable({ serviceTypeName, slotData: slot, canBeUpdated: true }));
  };

  const cancelEditingASlot = (tabIndex, collectionPointIndex, generatorFormIndex, index, slot) => {
    if (!slot || !slot.id) return;
    dispatch(makeCnCTemplateSlotEditable({ serviceTypeName, slotData: slot, canBeUpdated: false }));

    const {
      clickAndCollect: { displayName },
    } = slot;

    let unEditedSlotFromStore = click_n_collect.length && click_n_collect[tabIndex].collectionPoints[displayName].dayWiseSlots[slot.day].filter((item) => item.id === slot.id)[0];

    let { minutes: bufferTimeMinutes, hours: bufferTimeHours } = convertMinutesToHours(unEditedSlotFromStore.bufferTime);

    let unEditedSlot = {
      ...unEditedSlotFromStore,
      time: `${moment(unEditedSlotFromStore.startTime, HH_MM_SS).format("HH:mm")} - ${moment(unEditedSlotFromStore.endTime, HH_MM_SS).format("HH:mm")}`,
      startTime: moment(unEditedSlotFromStore.startTime, HH_MM_SS),
      endTime: moment(unEditedSlotFromStore.endTime, HH_MM_SS),
      bufferTimeHours,
      bufferTimeMinutes,
    };

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = { ...unEditedSlot };
    });

    setConfigTabs(newTabs);
  };

  const editSlotTime = (tabIndex, collectionPointIndex, generatorFormIndex, index, time, elem) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index][elem] = time;
    });

    setConfigTabs(newTabs);
  };

  const updateTemplateSlotHandler = async (tabIndex, collectionPointIndex, generatorFormIndex, index, slot) => {
    setLoading(true);
    let requestBody = {
      ...slot,
      id: slot.id,
      posId: slot.posId,
      zoneId: slot.zoneId,
      day: slot.day,
      startTime: moment(slot.startTime).format(HH_MM_SS),
      endTime: moment(slot.endTime).format(HH_MM_SS),
      slotCapacity: slot.slotCapacity,
      bufferTime: slot.bufferTimeHours * 60 + slot.bufferTimeMinutes,
      serviceTypeId,
      createdAt: slot?.createdAt || "",
    };
    const { payload } = await dispatch(updateCnCTemplateSlot({ serviceTypeName, slotData: requestBody }));

    if (payload && payload.data && payload.data.id) {
      // After every transaction related to service type config changes refetch all slot details from server for sync.
      setRefetchConfig((prev) => !prev);
    }
    if (payload && payload.message) {
      setLoading(false);
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }

    // TODO remove commented code after testing as we are fetching all configuration anfter action result

    // if (data) {
    //   let newTabs = produce(configTabs, (draft) => {
    //     const { hours, minutes } = convertMinutesToHours(data.bufferTime);

    //     draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[
    //       index
    //     ] = {
    //       ...data,
    //       bufferTimeHours: hours,
    //       bufferTimeMinutes: minutes,
    //       startTime: moment(data.startTime, HH_MM_SS),
    //       endTime: moment(data.endTime, HH_MM_SS),
    //       time: `${moment(data.startTime, HH_MM_SS).format("HH:mm")} - ${moment(data.endTime, HH_MM_SS).format(
    //         "HH:mm"
    //       )}`,
    //     };
    //   });

    //   setConfigTabs(newTabs);
    // }
  };

  const handleDeleteTemplateSlot = async (tabIndex, collectionPointIndex, generatorFormIndex, slot) => {
    if (!slot || !slot.id) {
      let newTabs = produce(configTabs, (draft) => {
        let allSlots = draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots;
        let filteredSlots = allSlots.filter((item) => !!item.id);
        draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots = [...filteredSlots];
      });
      setConfigTabs(newTabs);
      return;
    }
    setLoading(true);
    const payload = await dispatch(deleteCnCTemplateSlot({ serviceTypeName, slotData: slot }));

    let deletedSlot = unwrapResult(payload);
    if (deletedSlot && deletedSlot.data && deletedSlot.data.id) {
      // After every transaction related to service type config changes refetch all slot details from server for sync.
      setRefetchConfig((prev) => !prev);
    }

    if (deletedSlot && deletedSlot.message) {
      CustomAlert(ALERT_TYPES.ERROR, deletedSlot.message);
      setLoading(false);
      return;
    }

    // TODO remove commented code after testing as we are fetching all configuration anfter action result
    // if (deletedSlot) {
    //   let newTabs = produce(configTabs, (draft) => {
    //     let allSlots =
    //       draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots;
    //     let filteredSlots = allSlots.filter((item) => item.id !== deletedSlot.id);
    //     draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[
    //       generatorFormIndex
    //     ].generatedSlots = [...filteredSlots];
    //   });
    //   setConfigTabs(newTabs);
    // }
  };

  const addNewSlot = (tabIndex, collectionPointIndex, generatorFormIndex, day) => {
    let collectionPointName = configTabs[tabIndex].collectionPoints[collectionPointIndex].displayName;

    let { collectionPointDetails } = click_n_collect[tabIndex].collectionPoints[collectionPointName];

    let newTabs = produce(configTabs, (draft) => {
      let newSlot = {
        ...DEFAULT_TEMPLATE_SLOT,
        clickAndCollect: { ...collectionPointDetails },
        posId: posDetails.id,
        day: day.join(","),
        zoneId: draft[tabIndex].zoneId,
        isNewSlot: true,
        slotAction: "ADD",
      };

      draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.push(newSlot);
    });

    setConfigTabs(newTabs);
  };

  const handleSaveNewTemplateSlot = async (tabIndex, collectionPointIndex, generatorFormIndex, index, slot) => {
    let newTemplateSlotBody = {
      clickAndCollect: { ...slot.clickAndCollect },
      posId: slot.posId,
      zoneId: slot.zoneId,
      day: slot.day,
      startTime: moment(slot.startTime).format(HH_MM_SS),
      endTime: moment(slot.endTime).format(HH_MM_SS),
      bufferTime: parseInt(slot.bufferTimeHours * 60 + slot.bufferTimeMinutes),
      slotCapacity: parseInt(slot.slotCapacity),
      serviceTypeId,
    };
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].isCreating = true;
    });
    setConfigTabs(newTabs);
    setLoading(true);
    const { payload } = await dispatch(createCnCTemplateSlot({ serviceTypeName, slotData: newTemplateSlotBody }));
    if (payload && payload.data) {
      CustomAlert(ALERT_TYPES.SUCCESS, "New template slot is created successfully");
      // After every transaction related to service type config changes refetch all slot details from server for sync.
      setRefetchConfig((prev) => !prev);

      // TODO remove commented code after testing as we are fetching all configuration anfter action result
    }
    // if (data) {
    //   newTabs = produce(configTabs, (draft) => {
    //     const { hours, minutes } = convertMinutesToHours(data.bufferTime);
    //     draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[
    //       index
    //     ] = {
    //       ...data,
    //       clickAndCollect: { ...newTemplateSlotBody.clickAndCollect },
    //       bufferTimeHours: hours,
    //       bufferTimeMinutes: minutes,
    //       startTime: moment(data.startTime, HH_MM_SS),
    //       endTime: moment(data.endTime, HH_MM_SS),
    //       time: `${moment(data.startTime, HH_MM_SS).format("HH:mm")} - ${moment(data.endTime, HH_MM_SS).format(
    //         "HH:mm"
    //       )}`,
    //       isCreating: false,
    //       isCreated: true,
    //     };
    //   });
    //   setConfigTabs(newTabs);
    //   CustomAlert(ALERT_TYPES.SUCCESS, "New template slot is created successfully");
    // }
    if (payload && payload.message) {
      newTabs = produce(configTabs, (draft) => {
        draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = {
          ...draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index],
          isCreating: false,
          isCreated: false,
        };
      });
      setConfigTabs(newTabs);
      setLoading(false);
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }
  };
  /** Handling of edit flow - end */

  /**
   *
   * @param {*} tabIndex
   * @param {*} collectionPointIndex
   * @param {*} slotsGeneratorFormIndex
   * The below method generates slots based on users input in the generator form
   */
  const getGeneratedSlots = (tabIndex, collectionPointIndex, slotsGeneratorFormIndex = 0) => {
    const slotsGeneratorFormFields = configTabs[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[slotsGeneratorFormIndex];

    const calculatedSlots = generateSlots({ ...slotsGeneratorFormFields });

    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[slotsGeneratorFormIndex].generatedSlots = [...calculatedSlots];
    });

    setConfigTabs(newTabs);
  };

  const resetSlots = (tabIndex, collectionPointIndex, generatorFormIndex) => {
    const newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex] = {
        ...DEFAULT_SLOTS_GENERATOR_FORMS_VALUES,
      };
    });

    setConfigTabs(newTabs);
  };

  const makeCollectionPointAddressObject = (collectionPoint, isEdit = false) => {
    let clickAndCollectAddressDetailsObj = {
      displayName: collectionPoint.displayName,
      clickAndCollectType: collectionPoint.clickAndCollectType,
      referenceId: collectionPoint.referenceId,
      addressSameAsPos: collectionPoint.addressSameAsPos,
      addressLine1: collectionPoint.addressLine1,
      addressLine2: collectionPoint.addressLine2,
      country: collectionPoint.country,
      region: collectionPoint.region,
      area: collectionPoint.area,
      street: collectionPoint.street,
      poBox: collectionPoint.poBox,
      latitude: collectionPoint.latitude,
      longitude: collectionPoint.longitude,
      contactName: collectionPoint.contactName,
      contactEmail: collectionPoint.contactEmail,
      contactNumber: collectionPoint.contactNumber,
    };

    if (isEdit) {
      clickAndCollectAddressDetailsObj = {
        ...clickAndCollectAddressDetailsObj,
        collectionPointId: collectionPoint.collectionPointId,
        zoneId: collectionPoint.zoneId,
        active: collectionPoint.active,
      };
    }

    return clickAndCollectAddressDetailsObj;
  };

  const createTemplateSlotsHandler = async (configurations) => {
    setLoading(true);
    const {
      payload: { zoneConfig, message },
    } = await dispatch(
      createTemplateSlots({
        posNo: posDetails.posNo,
        zoneConfigurations: configurations,
        service: "click_n_collect",
        zoneConfigIndex: selectedConfigTab,
        servicePropositionList,
      })
    );

    if (message) {
      setLoading(false);
      CustomAlert(ALERT_TYPES.ERROR, message);
    }

    if (zoneConfig && zoneConfig.id) {
      // After every transaction related to service type config changes refetch all slot details from server for sync.
      setRefetchConfig((prev) => !prev);
      CustomAlert(ALERT_TYPES.SUCCESS, "Zone Configurations saved successfully");
    }
  };

  const saveZoneConfigurations = (configTabIndex = selectedConfigTab) => {
    const { isError, currentTabErrors } = validateZoneConfig(configTabs, selectedConfigTab, errors);
    setErrors(currentTabErrors);
    if (!isError) {
      let templateSlotsToBeSent = [];

      let tab = configTabs[configTabIndex];

      tab.collectionPoints.forEach((collectionPoint) => {
        collectionPoint.slotsGeneratorForms.forEach((form) => {
          let days = form.days;

          let generatedSlots = form.generatedSlots;
          days.forEach((day) => {
            let newSlots = generatedSlots.map((s) => ({
              posId: posDetails.id,
              day,
              startTime: moment(s.startTime).format("HH:mm:ss"),
              endTime: moment(s.endTime).format("HH:mm:ss"),
              slotCapacity: s.slotCapacity,
              bufferTime: s.bufferTimeHours * 60 + s.bufferTimeMinutes,
              clickAndCollect: makeCollectionPointAddressObject(collectionPoint),
              active: false,
            }));

            templateSlotsToBeSent = [...templateSlotsToBeSent, ...newSlots];
          });
        });
      });

      let configurationsToBeSent = {
        foodTypeMapping: {
          serviceTypeId,
          ...productTypesToBeSent(tab.foodTypes, tab.foodSubType, isPosTypePharmacy),
        },
        zoneConfig: {
          zoneName: tab.zoneName,
          serviceTypeMappingId: posDetails.serviceType.filter((s) => s.serviceTypeId === serviceTypeId)[0].id,
          status: "",
        },
        templateSlots: templateSlotsToBeSent,
        selectedAreas: [],
      };

      createTemplateSlotsHandler(configurationsToBeSent);
    }
  };

  // TODO need to set as common method for all
  const transformDataIntoZoneTabsFormat = useCallback((slots) => {
    let newConfigTabs = slots.map((slot) => ({
      zoneId: slot.zoneConfig.id,
      zoneName: slot.zoneConfig.zoneName,
      foodTypes: getFoodTypesFromFoodSubTypes(slot.foodTypeMapping.foodTypes),
      foodSubType: slot.foodTypeMapping.foodTypes?.join(foodSplitRegex),
      collectionPoints: Object.keys(slot.collectionPoints).map((cp) => ({
        ...slot.collectionPoints[cp].collectionPointDetails,
        slotsGeneratorForms: Object.keys(slot.collectionPoints[cp].dayWiseSlots).map((dayWiseSlot) => ({
          isEdit: true,
          days: [dayWiseSlot],
          generatedSlots: slot.collectionPoints[cp].dayWiseSlots[dayWiseSlot].map((slot) => ({
            ...slot,
            startTime: moment(slot.startTime, HH_MM_SS),
            endTime: moment(slot.endTime, HH_MM_SS),
            status: slot.status,
            time: `${moment(slot.startTime, HH_MM_SS).format("HH:mm")} - ${moment(slot.endTime, HH_MM_SS).format("HH:mm")}`,
            bufferTimeHours: convertMinutesToHours(slot.bufferTime).hours,
            bufferTimeMinutes: convertMinutesToHours(slot.bufferTime).minutes,
            orderCutOff: moment(slot.startTime, HH_MM_SS).subtract(convertMinutesToHours(slot.bufferTime).minutes, "m").format("HH:mm"),
            createdAt: slot?.createdAt || "",
            slotAction: "CHANGE",
          })),
        })),
      })),
      status: slot?.zoneConfig?.status || "",
      createdAt: slot?.zoneConfig?.createdAt || "",
    }));

    setConfigTabs(newConfigTabs);
  }, []);

  const handleUpdateZoneConfigurationsSubmit = async (configs) => {
    setLoading(true);
    const {
      payload: { zoneConfig, message },
    } = await dispatch(
      updateTemplateSlots({
        posNo,
        zoneConfigurations: configs,
        service: "click_n_collect",
        zoneConfigIndex: selectedConfigTab,
        servicePropositionList,
      })
    );

    if (message) {
      setLoading(false);
      CustomAlert(ALERT_TYPES.ERROR, message);
    }

    if (zoneConfig && zoneConfig.id) {
      // After every transaction related to service type config changes refetch all slot details from server for sync.
      setRefetchConfig((prev) => !prev);
      CustomAlert(ALERT_TYPES.SUCCESS, "Zone Configurations updated successfully");
    }
  };

  const updateZoneConfigurations = (configTabIndex) => {
    const { isError, currentTabErrors } = validateZoneConfig(configTabs, selectedConfigTab, errors);
    setErrors(currentTabErrors);
    if (!isError) {
      let templateSlotsToBeSent = [];

      let tab = configTabs[configTabIndex];
      tab.collectionPoints.forEach((collectionPoint) => {
        collectionPoint.slotsGeneratorForms.forEach((form) => {
          let days = form.days;

          let generatedSlots = form.generatedSlots;
          days.forEach((day) => {
            let newSlots = generatedSlots.map((s) => ({
              id: s.id,
              posId: posDetails.id,
              day,
              startTime: moment(s.startTime).format("HH:mm:ss"),
              endTime: moment(s.endTime).format("HH:mm:ss"),
              slotCapacity: s.slotCapacity,
              bufferTime: s.bufferTimeHours * 60 + s.bufferTimeMinutes,
              clickAndCollect: makeCollectionPointAddressObject(collectionPoint, true),
              // active: false,
              zoneId: s.zoneId,
              status: s.status,
              createdAt: s.createdAt || "",
            }));

            templateSlotsToBeSent = [...templateSlotsToBeSent, ...newSlots];
          });
        });
      });

      let configurationsToBeSent = {
        foodTypeMapping: {
          serviceTypeId,
          ...productTypesToBeSent(tab.foodTypes, tab.foodSubType, isPosTypePharmacy),
        },
        zoneConfig: {
          id: tab.zoneId,
          zoneName: tab.zoneName,
          serviceTypeMappingId: posDetails.serviceType.filter((s) => s.serviceTypeId === serviceTypeId)[0].id,
          status: tab.status || "", //
          createdAt: tab.createdAt || "",
        },
        templateSlots: templateSlotsToBeSent,
        selectedAreas: [],
      };

      handleUpdateZoneConfigurationsSubmit(configurationsToBeSent);
    }
  };

  const updateAddressDetailsInTheCollectionPoint = () => {
    if (posDetails && posDetails.posNo) {
      setConfigTabs((previousTabs) => {
        let firstCollectionPoint = previousTabs[selectedConfigTab].collectionPoints[0];
        let isAddressSameAsPos = firstCollectionPoint.addressSameAsPos;
        if (isAddressSameAsPos && !firstCollectionPoint.collectionPointId) {
          let newTabs = produce(previousTabs, (draft) => {
            draft[selectedConfigTab].collectionPoints[0] = {
              ...draft[selectedConfigTab].collectionPoints[0],
              ...copyAddressDetailsFromPOSAddress(posDetails),
            };
          });
          return newTabs;
        }
        return previousTabs;
      });
    }
  };

  useEffect(() => {
    if (fetchStatus === API_RESPONSE_STATUS.SUCCEEDED) {
      updateAddressDetailsInTheCollectionPoint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus]);

  useEffect(() => {
    if (posNo && !posDetails.posNo && servicePropositionList?.length) {
      dispatch(fetchPOSDetailsByPOSNo(posNo));
    }
  }, [dispatch, posNo, serviceTypeId, serviceTypeName, posDetails.posNo, servicePropositionList?.length]);

  useEffect(() => {
    const fetchSlots = () => {
      dispatch(fetchSlotsInformationByServiceType({ posNo, serviceType: serviceTypeId, serviceTypeName, servicePropositionList }));
      setLoading(false);
    };
    if (posNo) {
      fetchSlots();
    }
  }, [dispatch, posNo, serviceTypeId, serviceTypeName, refetchConfig]);

  /** clear slots data on unmount of the component */
  useEffect(() => {
    return () => {
      dispatch(resetSlotsInformation());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      click_n_collect.length &&
      (!click_n_collect[selectedConfigTab] ||
        (click_n_collect[selectedConfigTab] && !click_n_collect[selectedConfigTab].createError && !click_n_collect[selectedConfigTab].isCreating && !click_n_collect[selectedConfigTab].isUpdating && !click_n_collect[selectedConfigTab].updateError))
      // configTabs[selectedConfigTab].zoneId !== click_n_collect[selectedConfigTab].zoneConfig.id
    ) {
      transformDataIntoZoneTabsFormat(click_n_collect);
    }
  }, [click_n_collect, selectedConfigTab]);

  useEffect(() => {
    if (click_n_collect && click_n_collect.length && click_n_collect[selectedConfigTab] && click_n_collect[selectedConfigTab].collectionPoints) {
      if (click_n_collect[selectedConfigTab].collectionPoints !== configTabs[selectedConfigTab].collectionPoints.length) {
        transformDataIntoZoneTabsFormat(click_n_collect);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [click_n_collect, selectedConfigTab, transformDataIntoZoneTabsFormat]);

  useEffect(() => {
    if (isPosTypePharmacy) {
      let newConfigTabs = produce(configTabs, (draft) => {
        draft[selectedConfigTab].foodTypes = "";
      });

      setConfigTabs(newConfigTabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPosTypePharmacy, selectedConfigTab]);

  const renderTitle = () => <PageTitle title={`POS - ${posNo}`} />;

  const renderBasicServiceDtails = () => (
    <BasicServiceDetails
      serviceTypeId={serviceTypeId}
      serviceTypeName="Click & Collect"
      country={posDetails && posDetails.posAddress && posDetails.posAddress.country}
      region={posDetails && posDetails.posAddress && posDetails.posAddress.region}
      displayName={displayName}
    />
  );

  const renderHorizontalLine = () => {
    return <div style={{ border: "1px solid #E6E6E6", margin: "24px -25px " }}></div>;
  };

  const renderSlotsGeneratorForm = (configTabIndex, collectionPointIndex, generatorFormIndex) => {
    const isEditMode = !!configTabs[configTabIndex].collectionPoints[collectionPointIndex].collectionPointId;
    const collectionPointId = isEditMode ? configTabs[configTabIndex].collectionPoints[collectionPointIndex].collectionPointId : "";
    const { days, slotDurationHours, slotDurationMinutes, bufferTimeHours, bufferTimeMinutes, serviceStartTime, serviceEndTime, defaultSlotCapacity, generatedSlots } =
      configTabs[configTabIndex].collectionPoints[collectionPointIndex].slotsGeneratorForms[generatorFormIndex];

    const { clickAndCollect: { displayName: collectionPointName } = {} } =
      isEditMode && collectionPointId
        ? click_n_collect.length && click_n_collect[configTabIndex] && click_n_collect[configTabIndex].templateSlots && click_n_collect[configTabIndex].templateSlots.find((s) => s.clickAndCollect.collectionPointId === collectionPointId)
        : "";

    const dayWiseSlots = isEditMode
      ? click_n_collect.length &&
        click_n_collect[configTabIndex] &&
        click_n_collect[configTabIndex].collectionPoints &&
        click_n_collect[configTabIndex].collectionPoints[collectionPointName] &&
        click_n_collect[configTabIndex].collectionPoints[collectionPointName].dayWiseSlots[days[0]]
      : [];

    return (
      <div className={classes.generatorFormContainer} style={{ marginTop: "12px" }}>
        {isEditMode ? (
          <EditTemplateSlots
            serviceTypeName={serviceTypeName}
            days={days}
            generatedSlots={generatedSlots}
            dayWiseSlots={dayWiseSlots}
            handleIndividualSlotCheckbox={(slotIndex, e) => handleIndividualSlotCheckbox(configTabIndex, collectionPointIndex, generatorFormIndex, slotIndex, e.target.checked)}
            handleIndividualSlotFieldsChange={(slotIndex, e) => handleIndividualSlotFieldsChange(configTabIndex, collectionPointIndex, generatorFormIndex, slotIndex, e)}
            handleIndividualSlotTimeChange={(slotIndex, date, elem) => editSlotTime(configTabIndex, collectionPointIndex, generatorFormIndex, slotIndex, date, elem)}
            makeSlotEditable={(slotIndex, slot) => makeSlotEditable(slot)}
            cancelEditingASlot={(slotIndex, slot) => cancelEditingASlot(configTabIndex, collectionPointIndex, generatorFormIndex, slotIndex, slot)}
            updateTemplateSlot={(slotIndex, slot) => updateTemplateSlotHandler(configTabIndex, collectionPointIndex, generatorFormIndex, slotIndex, slot)}
            deleteTemplateSlot={(slotIndex, slot) => handleDeleteTemplateSlot(configTabIndex, collectionPointIndex, generatorFormIndex, slot)}
            addNewTemplateSlot={() => addNewSlot(configTabIndex, collectionPointIndex, generatorFormIndex, days)}
            saveNewTemplateSlot={(slotIndex, slot) => handleSaveNewTemplateSlot(configTabIndex, collectionPointIndex, generatorFormIndex, slotIndex, slot)}
          />
        ) : (
          <CreateTemplateSlots
            serviceTypeName={serviceTypeName}
            days={days}
            remainingDays={DAYS_DROPDOWN_OPTIONS}
            daysDisabled
            slotDurationHours={slotDurationHours}
            slotDurationMinutes={slotDurationMinutes}
            bufferTimeHours={bufferTimeHours}
            bufferTimeMinutes={bufferTimeMinutes}
            serviceStartTime={serviceStartTime}
            serviceEndTime={serviceEndTime}
            defaultSlotCapacity={defaultSlotCapacity}
            generatedSlots={generatedSlots}
            handleDaysChange={() => {}}
            handleSlotsGeneratorFormFields={(e) => handleSlotsGeneratorFormFields(configTabIndex, collectionPointIndex, generatorFormIndex, e)}
            handleServiceTimeChange={(date, elem) => handleServiceStartAndEndTime(configTabIndex, collectionPointIndex, generatorFormIndex, date, elem)}
            handleGenerateSlots={() => getGeneratedSlots(configTabIndex, collectionPointIndex, generatorFormIndex)}
            handleResetSlots={() => resetSlots(configTabIndex, collectionPointIndex, generatorFormIndex)}
            handleIndividualSlotFieldsChange={(slotIndex, e) => handleIndividualSlotFieldsChange(configTabIndex, collectionPointIndex, generatorFormIndex, slotIndex, e)}
            handleIndividualSlotCheckbox={(slotIndex, e) => handleIndividualSlotCheckbox(configTabIndex, collectionPointIndex, generatorFormIndex, slotIndex, e.target.checked)}
          />
        )}
      </div>
    );
  };

  const renderCollectionPointsAccordion = (configTab, tabIndex, collectionPointIndex) => {
    const { displayName, clickAndCollectType, referenceId, addressSameAsPos, slotsGeneratorForms, collectionPointId } = configTabs[tabIndex].collectionPoints[collectionPointIndex];

    const isEditMode = !!collectionPointId;

    return (
      <Accordion className={classes.collectionPointAccordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: "0px" }}>
          <Grid container onClick={(e) => e.stopPropagation()}>
            <Grid item xs={10}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextBox fieldLabel="Display Name" name="displayName" placeholderText="Enter Display Name" value={displayName} handleChange={(e) => handleCollectionPointNameAndType(tabIndex, collectionPointIndex, e)} />
                </Grid>
                <Grid item xs={4}>
                  <SelectBox name="clickAndCollectType" fieldLabel="Collection Point Type" menuItems={collectionPointsTypes} value={clickAndCollectType} handleChange={(e) => handleCollectionPointNameAndType(tabIndex, collectionPointIndex, e)} />
                </Grid>
                <Grid item xs={4}>
                  <TextBox fieldLabel="Reference Id" name="referenceId" placeholderText="Enter Reference Id" value={referenceId} handleChange={(e) => handleCollectionPointNameAndType(tabIndex, collectionPointIndex, e)} />
                </Grid>
              </Grid>
            </Grid>
            {collectionPointIndex !== 0 && !isEditMode ? (
              <RemoveButton
                style={{
                  position: "absolute",
                  right: "-1px",
                  top: "-10px",
                }}
                onClick={() => deleteCollectionPoint(tabIndex, collectionPointIndex)}
              />
            ) : null}
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block", padding: "16px 0px 0px 0px" }}>
          {/* Address same as POS Checkbox */}
          <div>
            <FormControlLabel
              style={{ marginLeft: "0px" }}
              control={<Checkbox checked={addressSameAsPos} onChange={(e) => handleAddressSameAsPOSCheckbox(tabIndex, collectionPointIndex, e)} />}
              label={<Typography className={classes.checkboxLabel}>Address same as POS</Typography>}
            />
          </div>

          {/* Address form */}
          {
            <div className={classes.addressContainer} style={{ marginTop: "24px" }}>
              <div className={classes.accordionTitle}>Address</div>
              <Typography style={{ marginTop: "18px" }} className={classes.smallTitle}>
                Customer Service Details
              </Typography>
              <Grid container spacing={2} style={{ marginTop: "8px" }}>
                <Grid item xs={4}>
                  <TextBox name="contactName" label="Name" value={configTab.collectionPoints[collectionPointIndex].contactName} disabled={addressSameAsPos} handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)} />
                </Grid>

                <Grid item xs={4}>
                  <TextBox
                    name="contactEmail"
                    label="Email"
                    value={configTab.collectionPoints[collectionPointIndex].contactEmail}
                    disabled={addressSameAsPos}
                    handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextBox
                    type="number"
                    name="contactNumber"
                    label="Phone Number"
                    value={configTab.collectionPoints[collectionPointIndex].contactNumber}
                    disabled={addressSameAsPos}
                    handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)}
                  />
                </Grid>
              </Grid>
              <Typography style={{ margin: "18px 0px" }} className={classes.smallTitle}>
                Location Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <SelectBox name="country" label="Country" menuItems={countries} value={configTab.collectionPoints[collectionPointIndex].country} handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)} disabled />
                </Grid>
                <Grid item xs={4}>
                  <SelectBox
                    name="region"
                    label="Region"
                    menuItems={regions}
                    value={configTab.collectionPoints[collectionPointIndex].region}
                    disabled={addressSameAsPos}
                    handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectBox
                    name="area"
                    label="Area"
                    menuItems={getAreasForSelectedRegion(tabIndex, collectionPointIndex)}
                    value={configTab.collectionPoints[collectionPointIndex].area}
                    disabled={addressSameAsPos}
                    handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextBox
                    name="addressLine1"
                    label="Address Line 1"
                    required
                    value={configTab.collectionPoints[collectionPointIndex].addressLine1}
                    disabled={addressSameAsPos}
                    handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextBox
                    name="addressLine2"
                    label="Address Line 2"
                    value={configTab.collectionPoints[collectionPointIndex].addressLine2}
                    disabled={addressSameAsPos}
                    handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextBox name="street" label="Street" value={configTab.collectionPoints[collectionPointIndex].street} disabled={addressSameAsPos} handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)} />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextBox name="poBox" label="PO Box" value={configTab.collectionPoints[collectionPointIndex].poBox} disabled={addressSameAsPos} handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)} />
                </Grid>
                <Grid item xs={4}>
                  <TextBox
                    name="latitude"
                    label="Latitude"
                    type="number"
                    required
                    value={configTab.collectionPoints[collectionPointIndex].latitude}
                    disabled={addressSameAsPos}
                    handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextBox
                    name="longitude"
                    label="Longitude"
                    type="number"
                    required
                    value={configTab.collectionPoints[collectionPointIndex].longitude}
                    disabled={addressSameAsPos}
                    handleChange={(e) => handleCollectionPointAddressFields(tabIndex, collectionPointIndex, e)}
                  />
                </Grid>
              </Grid>
            </div>
          }
          {renderHorizontalLine()}

          {slotsGeneratorForms.map((sgf, sgfIndex) => (
            <div key={sgfIndex}>{renderSlotsGeneratorForm(tabIndex, collectionPointIndex, sgfIndex)}</div>
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderConfgureSlots = (configTab, configTabIndex) => {
    return (
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: "8px 24px" }}>
          <Grid container style={{ alignItems: "center" }}>
            <Grid item xs={7}>
              <Typography className={classes.accordionTitle}>Slots</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block", padding: "0px 24px 24px 24px" }}>
          <div>
            <Grid container justifyContent="flex-end">
              <LinkButton buttonLabel={"Add Collection Point"} onClick={(e) => addCollectionPoint(configTabIndex, e)} />
            </Grid>

            {configTab.collectionPoints.map((collectionPoint, collectionPointIndex) => (
              <div key={collectionPointIndex} style={{ backgroundColor: "#F9F9F9", marginBottom: "12px" }} ref={collectionPointRef}>
                {renderCollectionPointsAccordion(configTab, configTabIndex, collectionPointIndex)}
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderServiceConfigurations = () => {
    return (
      <Card className={classes.cards}>
        <CardContent style={{ padding: "0px 16px 16px 16px" }}>
          <TabContext value={selectedConfigTab.toString()}>
            <Grid container style={{ alignItems: "center", borderBottom: "1px solid #e8e8e8" }}>
              <Grid item xs={9}>
                <Tabs
                  value={selectedConfigTab}
                  onChange={handleTabChange}
                  classes={{
                    root: classes.tabsRoot,
                    indicator: classes.tabsIndicator,
                  }}
                  variant="scrollable"
                >
                  {configTabs.map((configTab, configTabIndex) => (
                    <Tab
                      id={`config-tab-${configTabIndex}`}
                      value={configTabIndex}
                      key={configTabIndex}
                      disableRipple
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                        labelIcon: classes.tabLabelIcon,
                      }}
                      label={configTab.zoneName}
                      icon={configTab.zoneId ? <RemoveButton className={classes.deleteConfigTabIcon} onClick={(e) => handleDeleteZoneConfigClick(configTabIndex, e)} /> : null}
                    ></Tab>
                  ))}
                </Tabs>
              </Grid>
              {/* <Grid item xs={3} style={{ textAlign: "right" }}>
                <LinkButton
                  buttonLabel="Add Zone Configuration"
                  onClick={addConfigTabs}
                  disabled={!click_n_collect.length}
                />
              </Grid> */}
            </Grid>

            {configTabs.map((configTab, configTabIndex) => (
              <TabPanel key={configTabIndex} value={configTabIndex.toString()} index={configTabIndex} style={{ padding: "0px" }}>
                <Accordion className={classes.accordionStyle}>
                  <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Typography className={classes.accordionTitle}>{`${APPLICATION_TEXT_CONSTANT.ADDITIONAL_ZONE_CONFIGURATION}`}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {!isPosTypePharmacy && (
                      <ProductType
                        isProductTypeDisabled={configTab.zoneId}
                        foodType={configTab.foodTypes}
                        foodSubType={configTab.foodSubType}
                        error={errors[configTabIndex] && errors[configTabIndex].foodTypes}
                        onFoodTypeChange={(e, checked) => {
                          setConfigTabs(handleProductType(configTabs, configTabIndex, e, checked));
                        }}
                        onSubFoodTypeChange={(e) => {
                          setConfigTabs(handleFoodSubType(configTabs, configTabIndex, e));
                        }}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>

                {/* Configure slots accordion */}
                {renderConfgureSlots(configTab, configTabIndex)}

                {/* Save zone configurations button*/}
                <Grid container style={{ marginTop: "24px" }}>
                  <Grid item xs={6}></Grid>
                  {configTab.zoneId ? (
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                      <PrimaryButton
                        buttonLabel={click_n_collect[selectedConfigTab] && click_n_collect[selectedConfigTab].isUpdating ? "updating..." : `Update ${configTabs[selectedConfigTab]?.zoneName} Configurations`}
                        disabled={click_n_collect[selectedConfigTab] && click_n_collect[selectedConfigTab].isUpdating}
                        onClick={() => updateZoneConfigurations(selectedConfigTab)}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                      <PrimaryButton
                        buttonLabel={click_n_collect[selectedConfigTab] && click_n_collect[selectedConfigTab].isCreating ? "creating..." : `Save ${configTabs[selectedConfigTab]?.zoneName} Configurations`}
                        disabled={click_n_collect[selectedConfigTab] && (click_n_collect[selectedConfigTab].isCreating || click_n_collect[selectedConfigTab].isCreated)}
                        onClick={() => saveZoneConfigurations(selectedConfigTab)}
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
            ))}
          </TabContext>
        </CardContent>
      </Card>
    );
  };

  const renderButtons = () => {
    return (
      <Grid container direction="row" className={classes.footerButtons}>
        <Grid item xs={6}>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item className={classes.footerButtonsStyle}>
              <Link to={`${APP_ROUTES.POS_LIST}`} className={classes.linkstyle}>
                <PrimaryButton buttonLabel={`${BUTTON_TEXT_CONSTANT.BACK_TO_POS_LIST}`} buttonVariant="outlined" disabled={false} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item className={classes.footerButtonsStyle}>
              <PrimaryButton buttonLabel={`${BUTTON_TEXT_CONSTANT.BACK_TO_POS}`} buttonVariant="outlined" disabled={!click_n_collect.length} onClick={() => history.push(`${APP_ROUTES.COMPLETE_SETUP}/${posDetails.posNo}`)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.mainContainer}>
      <OverlayLoader loading={fetchingDetails || fetchingSlots || loading} />
      {renderTitle()}
      {/* Basic Service details */}
      {renderBasicServiceDtails()}
      {/* Service Configurations */}
      {renderServiceConfigurations()}
      {/* Footer Buttons */}
      {renderButtons()}

      <ConfirmModal
        show={showDeleteZCModal}
        title="Delete Zone Config"
        description="All the data for the zone configuration would be permanently lost. Do you wish to proceed?"
        confirmButtonText="Approve"
        isLoading={isDeleting}
        onClose={() => setShowDeleteZCModal(false)}
        onProceed={() => deleteConfigTab(selectedConfigTab)}
      />
    </div>
  );
};

export default ClickAndCollect;
