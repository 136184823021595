import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route, useHistory } from "react-router";
import { Switch, useLocation } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { useStyles } from "./common/Styles/AppStyles";

// Constans
import { API_RESPONSE_STATUS, APP_ROUTES, CURRENT_COUNTRY, NEW_PATH_SELECTED, SIDE_MENU_LABEL, USER_INFO_DOS } from "../config/constants";

// actions
import {
  fetchAllAreasOfCountry,
  fetchAllCollectionPointsTypes,
  fetchAllRegionsOfCountry,
  fetchAllUserRolesTypes,
  fetchListOfCountries,
  fetchPOSTypes,
  fetchServiceInActiveStatus,
  fetchServicePropositionRecord,
  fetchServiceTypes,
  selectAllActiveInActiveServiceTypeRecordService,
  serviceSelectPropositionRecord,
} from "../config/redux/configurationsSlice";
import { isArrayValid, isEmptyOrNull } from "./../utils/DataUtils";
import { ReactComponent as CloseIconButton } from "./assets/svg/closeButton.svg";

// Common Components
import { CountryRoute, PosRoute, PrivateRoute, RuleEngineRoute, DeliveryFeeRoute } from "./common/PrivateRoute";
import { fetchCountrySettings } from "../components/CountrySettings/redux/countrySettingsSlice";
import { selectCountrySettings } from "./CountrySettings/redux/countrySettingsSlice";
import CreatePOS from "./CreatePOS/CreatePOS";
import { validateUserToken } from "./Login/redux/userSlice";
import { fetchAllAdditionalServicesTypes } from "./UserManagement/Redux/UserManagementSlice";

// Custom Components
import TopMenu from "./common/TopMenu/TopMenu";
import SideMenu from "./common/SideMenu/SideMenu";
import Loader from "./common/Loader/Loader";

// Route Component
import SSOLoginPage from "./Login/sso/SSOLoginPage";
import POSList from "./POSList/POSList";
import CompleteSetup from "./CompleteSetup/CompleteSetup";
import CountrySettings from "./CountrySettings/CountrySettings";
import CurrentConfiguration from "./RuleEnginePage/CurrentConfiguration/CurrentCofiguration";
import RuleEngine from "./RuleEnginePage/RuleEnginePage";
import PlasticBagCurrentCofiguration from "./SingleUsePlastic/CurrentConfiguration/PlasticBagCurrentCofiguration";
import EditPlasticBagConfiguration from "./SingleUsePlastic/EditPlasticBagConfiguration";
import SingleUsePlasticPage from "./SingleUsePlastic/SingleUsePlasticPage";
import UserManagementPage from "./UserManagement/UserManagementPage";
import DeliveryFeePage from "./DeliveryFee/DeliveryFeePage";
import AddUpdateDlvFeeConfigPage from "./DeliveryFee/Configuration/AddUpdateDlvFeeConfigPage";
import ConfigurationPreview from "./DeliveryFee/ConfigurationPreview";
import { changeCountry } from "config/redux/appConfigSlice";
import { getRuleEngineItems, getTopBarMenuItems } from "./common/TopMenu/TopMenuUtil";

// December 2022
import ApprovalRequestRecordPage from "./ApprovalRequest/ApprovalRequestRecordPage";
import NewRequestForServiceAndSlot from "./ApprovalRequest/newRequest/NewRequestForServiceAndSlot";
import ApprovalRequestDetailsPage from "./ApprovalRequest/ApprovalRequestDetailsPage";
import { fetchPendingApprovalRequest, selectAllPendingApprovalRequestRecordListServices } from "./ApprovalRequest/Redux/ApprovalRequestSlice";

// May 2023
import PlolygonServicesPage from "./PlolygonServices/PlolygonServicesPage";
import { findTerm } from "../utils/ApplicationUtils";

// Dashboard
import DashboardPage from "./Dashboard/DashboardPage";
import PlolygonServicesConfigurationPage from "./PlolygonServices/PlolygonServicesConfigurationPage";

const ToastCloseIcon = ({ closeToast }) => {
  return <CloseIconButton onClick={closeToast} className="close-icon" />;
};

const App = () => {
  const { pathname } = useLocation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [appState, setAppState] = useState({
    isLoading: false,
    inValiduser: false,
    openSideMenu: false,
    SideBarMenuItemsList: [],
    selectedIndex: 0,
    openRuleEngineMenu: false,
    ruleEngineItems: [],
  });

  // Selectors Start
  const { isLoggedIn, userDetails } = useSelector((state) => state.user);
  const { fetchStatus: countrySettingsFetchStatus } = useSelector(selectCountrySettings);
  const { countries, serviceTypes, posTypes, regions, areas, collectionPointsTypes, roles, servicePropositionRecord } = useSelector((state) => state.configurations, shallowEqual);
  const { additionalServices } = useSelector((state) => state.userMgmtConfigurations, shallowEqual);
  const pendingApprovalRequestRecordListService = useSelector(selectAllPendingApprovalRequestRecordListServices); // Approval Flow, Show pending record in the side menu
  const selectAllActiveInActiveServiceTypeRecord = useSelector(selectAllActiveInActiveServiceTypeRecordService);
  const servicePropositionList = useSelector(serviceSelectPropositionRecord);
  // Selectors End

  let userInfo = localStorage.getItem(USER_INFO_DOS);
  userInfo = !isEmptyOrNull(userInfo) ? JSON.parse(userInfo) : userInfo;

  const mouseOverEventOnSideMenu = (event) => {
    event.preventDefault();
    if (!appState.openSideMenu) {
      const currentLocation = window.location.pathname;
      let openRuleEngineMenu = false;
      let selectedIndex = appState.selectedIndex;
      if (!isEmptyOrNull(currentLocation)) {
        if (currentLocation.includes(APP_ROUTES.DELIVERY_FEE) || currentLocation.includes(APP_ROUTES.RULE_ENGINE) || currentLocation.includes(APP_ROUTES.SINGLE_USE_PLASTIC)) {
          openRuleEngineMenu = true;
          if (currentLocation.includes(APP_ROUTES.RULE_ENGINE)) {
            selectedIndex = 6;
          } else if (currentLocation.includes(APP_ROUTES.DELIVERY_FEE)) {
            selectedIndex = 7;
          } else if (currentLocation.includes(APP_ROUTES.SINGLE_USE_PLASTIC)) {
            selectedIndex = 8;
          }
        }
      }
      setAppState({ ...appState, openSideMenu: true, openRuleEngineMenu: openRuleEngineMenu, selectedIndex: selectedIndex });
    }
    window.dispatchEvent(new CustomEvent("resize"));
  };

  const mouseOverEventOnMainArea = (event) => {
    event.preventDefault();
    if (appState.openSideMenu) {
      const currentLocation = window.location.pathname;
      let selectedIndex = getSelectedIndexOnFirstLoad(currentLocation);
      setAppState({ ...appState, openSideMenu: false, openRuleEngineMenu: false, selectedIndex: selectedIndex });
      window.dispatchEvent(new CustomEvent("resize"));
    }
  };

  const fetch_application_record_list = (selected_country_obj) => {
    dispatch(changeCountry(selected_country_obj));
    dispatch(fetchCountrySettings(selected_country_obj));

    if (regions.fetchStatus === API_RESPONSE_STATUS.IDLE && regions.fetchStatus !== API_RESPONSE_STATUS.LOADING) {
      dispatch(fetchAllRegionsOfCountry(selected_country_obj?.countryId));
    }
    if (areas.fetchStatus === API_RESPONSE_STATUS.IDLE && areas.fetchStatus !== API_RESPONSE_STATUS.LOADING) {
      dispatch(fetchAllAreasOfCountry(selected_country_obj?.countryId));
    }
    if (serviceTypes.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchServiceTypes());
    }
    if (posTypes.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchPOSTypes());
    }
    if (collectionPointsTypes.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchAllCollectionPointsTypes());
    }
    if (roles.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchAllUserRolesTypes());
    }
    if (additionalServices.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchAllAdditionalServicesTypes());
    }
    if (servicePropositionRecord.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchServicePropositionRecord());
    }

    dispatch(fetchPendingApprovalRequest({ countryId: selected_country_obj?.countryId, status: ["PENDING"] }));
    dispatch(fetchServiceInActiveStatus(selected_country_obj?.countryId));
  };

  const _fetch_country_list_record = () => {
    if (countries.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchListOfCountries());
    }
  };

  const handleListItemClick = (event, selected_index, toLink) => {
    setAppState({ ...appState, selectedIndex: selected_index });
    localStorage.setItem(NEW_PATH_SELECTED, toLink);
  };

  const getSelectedIndexOnFirstLoad = (_new_path_name) => {
    if (_new_path_name !== undefined && _new_path_name !== null && _new_path_name !== "") {
      switch (_new_path_name) {
        case APP_ROUTES.DASHBOARD:
          return 0;
        case findTerm(_new_path_name, APP_ROUTES.POS_LIST):
        case findTerm(_new_path_name, APP_ROUTES.COMPLETE_SETUP):
        case findTerm(_new_path_name, APP_ROUTES.CREATE_POS):
          return 1;
        case findTerm(_new_path_name, APP_ROUTES.RULE_ENGINE):
        case findTerm(_new_path_name, APP_ROUTES.DELIVERY_FEE):
        case findTerm(_new_path_name, APP_ROUTES.SINGLE_USE_PLASTIC):
          return 2;
        case findTerm(_new_path_name, APP_ROUTES.POLYGON_SERVICES):
          return 3;
        case findTerm(_new_path_name, APP_ROUTES.APPROVAL_REQUESTS):
          return 4;
        case findTerm(_new_path_name, APP_ROUTES.USER_MANAGEMENT):
          return 5;
        default:
          return 0;
      }
    }
  };

  const checkUserInfo = async () => {
    if (!isLoggedIn && userInfo?.token) {
      let response = await dispatch(validateUserToken(userInfo.token));
      if (response && response.payload && response.payload.error) {
        setAppState({ ...appState, inValiduser: true });
      }
    }
    if (isLoggedIn) {
      setAppState({ ...appState, isLoading: true });
      _fetch_country_list_record();
    } else {
      history.replace(APP_ROUTES.LOGIN);
    }
  };

  const update_country_value = () => {
    let selected_country_obj = null;
    let storedCountry = !isEmptyOrNull(localStorage.getItem(CURRENT_COUNTRY)) ? JSON.parse(localStorage.getItem(CURRENT_COUNTRY)) : null;
    if (!isEmptyOrNull(storedCountry?.countryId) && !isEmptyOrNull(userDetails?.userProfile?.countryId) && isArrayValid(userDetails?.userProfile?.countryId) && userDetails.userProfile.countryId.includes(storedCountry?.countryId)) {
      selected_country_obj = storedCountry;
    } else {
      if (!isEmptyOrNull(userDetails?.userProfile?.countryId) && isArrayValid(userDetails?.userProfile?.countryId)) {
        let userAccessedCountries = countries.data.filter((item) => userDetails.userProfile.countryId.includes(item?.countryId));
        userAccessedCountries.forEach((country) => {
          if (isEmptyOrNull(selected_country_obj) && !isEmptyOrNull(country?.countryId) && isArrayValid(countries?.data) && countries.data.includes(country)) {
            selected_country_obj = country;
          }
        });
      } else {
        selected_country_obj = countries.data[0];
      }
      localStorage.setItem(CURRENT_COUNTRY, JSON.stringify(selected_country_obj));
    }
    if (!isEmptyOrNull(selected_country_obj)) {
      fetch_application_record_list(selected_country_obj);
    }
  };

  const handleMultiOptionListItemClick = (event, sideItemIndex, collapsable_list_item_name) => {
    if (!isEmptyOrNull(collapsable_list_item_name)) {
      if (collapsable_list_item_name === SIDE_MENU_LABEL.RULE_ENGINE_SET_UP) {
        setAppState({ ...appState, selectedIndex: sideItemIndex, openRuleEngineMenu: !appState.openRuleEngineMenu });
      }
    }
  };

  useEffect(() => {
    const allDataLoaded = [countries, serviceTypes, posTypes, regions, roles, additionalServices].every((data) => data?.fetchStatus !== API_RESPONSE_STATUS.IDLE);
    if (isLoggedIn && allDataLoaded) {
      const _new_path_name = !isEmptyOrNull(localStorage.getItem(NEW_PATH_SELECTED)) ? localStorage.getItem(NEW_PATH_SELECTED) : pathname !== APP_ROUTES.LOGIN ? pathname : APP_ROUTES.DASHBOARD;
      history.push(!isEmptyOrNull(_new_path_name) ? _new_path_name : APP_ROUTES.DASHBOARD);
      setAppState({
        ...appState,
        isLoading: false,
        selectedIndex: getSelectedIndexOnFirstLoad(_new_path_name),
        SideBarMenuItemsList: !isEmptyOrNull(userDetails?.userProfile) ? getTopBarMenuItems(userDetails.userProfile) : [],
        ruleEngineItems: !isEmptyOrNull(userDetails?.userProfile) ? getRuleEngineItems(userDetails.userProfile) : [],
      });
    }
  }, [isLoggedIn, countries.fetchStatus && serviceTypes.fetchStatus && posTypes.fetchStatus && regions.fetchStatus && roles.fetchStatus && additionalServices.fetchStatus]);

  useEffect(() => {
    isArrayValid(countries?.data) && countrySettingsFetchStatus === API_RESPONSE_STATUS.IDLE && update_country_value();
  }, [countries.data, countries.data.length, countrySettingsFetchStatus]);

  useEffect(() => {
    isLoggedIn !== undefined && checkUserInfo();
  }, [isLoggedIn]);

  return (
    <>
      {!appState.inValiduser && appState.isLoading ? (
        <Loader />
      ) : (
        <div className={classes.root} data-test="AppComponent">
          {!isEmptyOrNull(userDetails?.userProfile?.role) && pathname !== APP_ROUTES.LOGIN && (
            <>
              <TopMenu handleListItemClick={handleListItemClick} selectAllActiveInActiveServiceTypeRecord={selectAllActiveInActiveServiceTypeRecord} />
              <SideMenu
                openSideMenu={appState.openSideMenu}
                classes={classes}
                SideBarMenuItemsList={appState.SideBarMenuItemsList}
                mouseOverEventOnSideMenu={mouseOverEventOnSideMenu}
                selectedIndex={appState.selectedIndex}
                handleListItemClick={handleListItemClick}
                pendingApprovalRequestRecordListService={pendingApprovalRequestRecordListService}
                userDetails={userDetails}
                handleMultiOptionListItemClick={handleMultiOptionListItemClick}
                openRuleEngineMenu={appState.openRuleEngineMenu}
                ruleEngineItems={appState.ruleEngineItems}
              />
            </>
          )}
          <main className={classes.content} onMouseOver={mouseOverEventOnMainArea}>
            <div className={classes.mainRouteDiv}>
              <Switch>
                <Route exact path={APP_ROUTES.LOGIN} component={SSOLoginPage} />
                {/* {  Country Setting   } */}
                {/*  */}
                <CountryRoute path={`${APP_ROUTES.COUNTRY_SETTINGS}/:countryName`} component={CountrySettings} userProfile={userDetails} />
                {/* POS routes */}
                {/*  */}
                <PrivateRoute path={`${APP_ROUTES.POS_LIST}`} component={POSList} />
                <PosRoute path={`${APP_ROUTES.CREATE_POS}`} component={CreatePOS} userRole={userDetails?.userProfile?.role} />
                <PosRoute path={`${APP_ROUTES.COMPLETE_SETUP}/:posNo?`} component={CompleteSetup} userRole={userDetails?.userProfile?.role} />
                {/* Approval Request */}
                {/*  */}
                <PrivateRoute path={`${APP_ROUTES.NEW_APPROVAL_REQUESTS}`} component={NewRequestForServiceAndSlot} />
                <PrivateRoute exact path={`${APP_ROUTES.APPROVAL_REQUESTS}`} component={ApprovalRequestRecordPage} />
                <PrivateRoute exact path={`${APP_ROUTES.APPROVAL_REQUESTS_DETAILS}`} component={ApprovalRequestDetailsPage} history={history} />
                {/* Rule Engine Routes */}
                {/*  */}
                <RuleEngineRoute exact path={`${APP_ROUTES.RULE_ENGINE}`} component={CurrentConfiguration} userProfile={userDetails} />
                <RuleEngineRoute path={`${APP_ROUTES.RULE_ENGINE_CURRENT_CONFIGURATION}/:serviceName?`} component={RuleEngine} userProfile={userDetails} />
                {/* User Routes */}
                {/*  */}
                <PrivateRoute exact path={`${APP_ROUTES.USER_MANAGEMENT}/:user?`} component={UserManagementPage} />
                {/* Single Use Plastic */}
                {/*  */}
                <PrivateRoute exact path={`${APP_ROUTES.SINGLE_USE_PLASTIC}`} component={SingleUsePlasticPage} />
                <PrivateRoute exact path={`${APP_ROUTES.SINGLE_USE_PLASTIC_CURRENT_CONFIGURATION}`} component={PlasticBagCurrentCofiguration} />
                <PrivateRoute exact path={`${APP_ROUTES.EDIT_SINGLE_USE_PLASTIC_CURRENT_CONFIGURATION}`} component={EditPlasticBagConfiguration} history={history} />
                {/* Delivery Fee  */}
                {/*  */}
                <DeliveryFeeRoute exact path={`${APP_ROUTES.DELIVERY_FEE}`} component={DeliveryFeePage} userRole={userDetails?.userProfile?.role} />
                <DeliveryFeeRoute exact path={`${APP_ROUTES.DELIVERY_FEE_CONFIGURATION}`} component={AddUpdateDlvFeeConfigPage} userRole={userDetails?.userProfile?.role} />
                <DeliveryFeeRoute exact path={`${APP_ROUTES.DELIVERY_FEE_PREVIEW_CONFIGURATION}`} component={ConfigurationPreview} userRole={userDetails?.userProfile?.role} />
                {/* Polygon Services  */}
                {/*  */}
                <PrivateRoute exact path={`${APP_ROUTES.POLYGON_SERVICES}`} component={PlolygonServicesPage} />
                <PrivateRoute exact path={`${APP_ROUTES.POLYGON_SERVICES_CONFIGURATION}`} component={PlolygonServicesConfigurationPage} />
                {/* Dashboard Services  */}
                {/*  */}
                <PrivateRoute exact path={`${APP_ROUTES.DASHBOARD}`} component={DashboardPage} />
              </Switch>
            </div>
          </main>
        </div>
      )}
      <ToastContainer position="top-center" transition={Slide} hideProgressBar closeButton={ToastCloseIcon} autoClose={5000} />
    </>
  );
};

export default React.memo(App);
