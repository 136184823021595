import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

// Material UI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// Constants
import { BasicDetailsServiceType } from "components/POSList/posUtils/PosAccessUtils";
import { ALERT_TYPES, APP_ROUTES, PHARMACY, VALIDATE_POS_NUMBER } from "../../config/constants";
import { selectAllAreas, selectAllCountries, selectAllRegions, selectPosTypes, selectServiceTypes, serviceSelectPropositionRecord } from "../../config/redux/configurationsSlice";
import { isObjectEmpty } from "../../utils/helperFunctions";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import CustomBreadcrumbs from "../common/CustomBreadcrumbs/CustomBreadcrumbs";
import Loader from "../common/Loader/Loader";
import PageTitle from "../common/PageTitle/PageTitle";
import PrimaryButton from "../common/PrimaryButton/PrimaryButton";
import SearchableDropdown from "../common/SearchableDropdown/SearchableDropdown";
import SelectBox from "../common/SelectBox/SelectBox";

// Common Components
import TextBox from "../common/TextBox";

// Actions
import { createPOS, fetchPOSDetailsByPOSNo, updatePOS } from "../CreatePOS/redux/posSlice";
import { selectUserDetails } from "../Login/redux/userSlice";

// Styles
import { basicDetailsStyles } from "./BasicDetailsStyle";
import { posDetailsInRequiredFormat } from "./Helper";

const isValidLatitudeOrLongitudeValue = (val = 0, min = -180, max = 180) => {
  return val >= min && val <= max;
};

function Basic() {
  const { posNo } = useParams();
  const classes = basicDetailsStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  // const serviceTypes = useSelector(selectServiceTypes);
  const servicePropositionList = useSelector(serviceSelectPropositionRecord);
  const posTypes = useSelector(selectPosTypes);
  const regions = useSelector(selectAllRegions);
  const areas = useSelector(selectAllAreas);
  const countries = useSelector(selectAllCountries);
  const userInfo = useSelector(selectUserDetails);
  const {
    currentCountry: { name: currentCountryName },
  } = useSelector((state) => state.appConfig);
  const { loading, fetching, posDetails: savedPOSDetails } = useSelector((state) => state.pos);

  const isEditMode = !!posNo || !isObjectEmpty(savedPOSDetails);

  const [serviceType, setServiceType] = useState("");
  const [posDetails, setPosDetails] = useState({ storeName: "", posType: "", posNumber: "" });
  const [posAddress, setPosAddress] = useState({
    contactId: 0,
    contactName: "",
    email: "",
    contactNo: "",
    country: currentCountryName,
    region: "",
    area: "",
    iso: currentCountryName,
    addressLine1: "",
    addressLine2: "",
    street: "",
    poBox: "",
    latitude: "",
    longitude: "",
  });
  const [errors, setErrors] = useState({
    storeName: "",
    posType: "",
    posNumber: "",
    contactName: "",
    email: "",
    contactNo: "",
    country: "",
    region: "",
    area: "",
    iso: "",
    addressLine1: "",
    addressLine2: "",
    street: "",
    poBox: "",
    latitude: "",
    longitude: "",
    serviceType: "",
  });

  const getAreasForSelectedRegion = React.useMemo(() => {
    let availableAreas = [];
    if (posAddress.region) {
      availableAreas = areas.filter((a) => a.regionId === parseInt(posAddress.region));
    }
    return availableAreas;
  }, [areas, posAddress.region]);

  const handlePosDetailsChange = (event) => {
    const { name, value } = event.target;
    setPosDetails((details) => ({ ...details, [name]: value }));
    if (name === "posType") {
      setServiceType("");
    }
    if (value) {
      setErrors((err) => ({ ...err, [name]: "" }));
    }
  };

  /**
   * Method to handle product type selection
   * @param {object} event
   */
  const handleServiceType = (event) => {
    const { value } = event.target;

    let alreadySelectedServiceTypes = serviceType ? serviceType.split(", ") : [];

    if (alreadySelectedServiceTypes.includes(value)) {
      alreadySelectedServiceTypes = alreadySelectedServiceTypes.filter((type) => type !== value);
    } else {
      alreadySelectedServiceTypes.push(value);
    }

    setServiceType(alreadySelectedServiceTypes.join(", "));

    setErrors({ ...errors, serviceType: "" });
  };

  const handlePosAddressChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setPosAddress((address) => ({ ...address, [name]: value }));
      switch (name) {
        case "addressLine1":
          if (posAddress.addressLine1 !== null) {
            setErrors({ ...errors, addressLine1: "" });
          }
          break;
        case "latitude":
          if (posAddress.latitude !== null) {
            setErrors({ ...errors, latitude: "" });
          }
          break;
        case "longitude":
          if (posAddress.longitude !== null) {
            setErrors({ ...errors, longitude: "" });
          }
          break;
        case "region":
          if (posAddress.region !== null) {
            setErrors({ ...errors, region: "" });
          }
          break;
        case "area":
          if (posAddress.area !== null) {
            setErrors({ ...errors, area: "" });
          }
          break;
        default:
          return "";
      }
    },
    [errors, posAddress.addressLine1, posAddress.latitude, posAddress.longitude, posAddress.region, posAddress.area]
  );

  const handleAreaChange = (event, selectedArea) => {
    setPosAddress((address) => ({ ...address, area: selectedArea }));
  };

  const getPOSNumberValidationErrorMsg = () => {
    let validationMsg = "";
    if (!posDetails.posNumber) {
      validationMsg = "Please enter POS number";
      return validationMsg;
    }

    if (posDetails.posType !== PHARMACY) {
      if (posDetails.posNumber && posDetails.posNumber.length !== 3) {
        validationMsg = "POS number should have only 3 characters";
        return validationMsg;
      }
      if (posDetails.posNumber && !VALIDATE_POS_NUMBER.test(posDetails.posNumber)) {
        validationMsg = "Special characters are not allowed for non-pharmacy pos types";
        return validationMsg;
      }
    }

    return validationMsg;
  };

  const validateForm = () => {
    let newErrors = {};
    if (!posDetails.storeName) {
      newErrors = { ...newErrors, storeName: "Please enter store name" };
    }

    if (!posDetails.posType) {
      newErrors = { ...newErrors, posType: "Please enter POS type" };
    }

    newErrors = { ...newErrors, posNumber: getPOSNumberValidationErrorMsg() };

    if (!posAddress.addressLine1) {
      newErrors = { ...newErrors, addressLine1: "Please enter your address" };
    }
    if (!posAddress.latitude) {
      newErrors = { ...newErrors, latitude: "Please enter latitude" };
    } else {
      if (posAddress.latitude && !isValidLatitudeOrLongitudeValue(posAddress.latitude, -90, 90)) {
        newErrors = {
          ...newErrors,
          latitude: "Accepted values are from -90 to +90",
        };
      }
    }
    if (!posAddress.longitude) {
      newErrors = { ...newErrors, longitude: "Please enter longitude" };
    } else {
      if (posAddress.longitude && !isValidLatitudeOrLongitudeValue(posAddress.longitude)) {
        newErrors = {
          ...newErrors,
          longitude: "Accepted values are from -180 to +180",
        };
      }
    }
    if (!serviceType) {
      newErrors = {
        ...newErrors,
        serviceType: "Please select a service type!",
      };
    }

    if (posAddress.contactName) {
      const regex = /^[a-z0-9 ]+$/i;
      if (regex.test(posAddress.contactName) === false) {
        newErrors = { ...newErrors, contactName: "Please enter a valid store name" };
      } else {
        newErrors = { ...newErrors, contactName: "" };
      }
    } else {
      newErrors = { ...newErrors, contactName: "" };
    }

    if (posAddress.email) {
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      newErrors = { ...newErrors, email: "" };
      if (regex.test(posAddress.email) === false) {
        newErrors = { ...newErrors, email: "Please enter valid email address!" };
      }
    } else {
      newErrors = { ...newErrors, email: "" };
    }

    if (posAddress.contactNo) {
      newErrors = { ...newErrors, contactNo: "" };
      if (posAddress.contactNo.length < 10) {
        newErrors = { ...newErrors, contactNo: "Please enter valid phone number!" };
      }
    } else {
      newErrors = { ...newErrors, contactNo: "" };
    }

    if (!posAddress.region) {
      newErrors = { ...newErrors, region: "Please enter Region/City name" };
    } else {
      newErrors = { ...newErrors, region: "" };
    }

    if (!posAddress.area) {
      newErrors = { ...newErrors, area: "Please enter area name" };
    } else {
      newErrors = { ...newErrors, area: "" };
    }

    setErrors({ ...errors, ...newErrors });
    return Object.keys(newErrors).filter((err) => newErrors[err]).length === 0;
  };

  const isServiceTypeDisabled = (serviceTypeId) => {
    let isDisabled = false;
    if (isEditMode) {
      isDisabled = savedPOSDetails && savedPOSDetails.services ? !!savedPOSDetails.services.find((item) => item.id === serviceTypeId) : false;
    }

    return isDisabled;
  };

  const fetchPOSDetails = useCallback(async () => {
    if (posNo && servicePropositionList?.length) {
      dispatch(fetchPOSDetailsByPOSNo(posNo));
    }
  }, [dispatch, posNo, servicePropositionList]);

  const handleSubmit = async () => {
    if (validateForm()) {
      const {
        payload: { message, posNo, id: posCreatedId, services },
      } = await dispatch(createPOS(posDetailsInRequiredFormat(posDetails, serviceType, posAddress, currentCountryName)));

      if (posCreatedId) {
        CustomAlert(ALERT_TYPES.SUCCESS, "POS created successfully");
        if (posDetails.posType === "FBC") {
          history.push(`${APP_ROUTES.COMPLETE_SETUP}/${posNo}`);
        } else {
          history.push(`${APP_ROUTES.CREATE_POS}${APP_ROUTES.SERVICE_AND_SLOTS}/${services[0].name.toLowerCase()}/${posNo}`);
        }
      }

      if (message) {
        CustomAlert(ALERT_TYPES.ERROR, message);
      }
    }
  };

  const handlePOSUpdate = async () => {
    if (validateForm()) {
      const {
        payload: { message, id: posCreatedId },
      } = await dispatch(updatePOS(posDetailsInRequiredFormat(posDetails, serviceType, posAddress, currentCountryName)));

      if (posCreatedId) {
        CustomAlert(ALERT_TYPES.SUCCESS, "POS details updated successfully");
      }

      if (message) {
        CustomAlert(ALERT_TYPES.ERROR, message);
      }
    }
  };

  const handleServiceTypeCheckBox = (serviceType, typeId) => {
    let serviceTypeArray = serviceType ? serviceType.split(", ") : [];
    let typeyIdStr = typeId ? typeId.toString() : "";
    if (serviceTypeArray.indexOf(typeyIdStr) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isEditMode) {
      fetchPOSDetails();
    }
  }, [isEditMode, fetchPOSDetails]);

  useEffect(() => {
    if (savedPOSDetails && Object.keys(savedPOSDetails).length) {
      const { posContacts, posAddress: savedPOSAddress, serviceType: savedServiceType } = savedPOSDetails;
      setPosDetails((posDetails) => ({
        ...posDetails,
        storeName: savedPOSDetails.storeName,
        posType: savedPOSDetails.posType,
        posNumber: savedPOSDetails.posNo,
      }));

      setPosAddress((posAddress) => ({
        ...posAddress,
        contactId: posContacts[0].id || 0,
        contactName: posContacts[0].name || "",
        email: posContacts[0].email || "",
        contactNo: posContacts[0].phoneNumber,
        ...savedPOSAddress,
      }));

      setServiceType(savedServiceType.reduce((acc, value) => [...acc, value.serviceTypeId], []).join(", "));
    }
  }, [savedPOSDetails]);

  return (
    <div className={classes.mainContainer}>
      {/* <CustomBreadcrumbs links={BREADCRUMB_LINKS} /> */}
      <PageTitle title={isEditMode ? "Update POS" : "Create New POS"} />
      {fetching && <Loader />}

      <>
        {!fetching && (
          <>
            <Card className={classes.cards}>
              <CardContent>
                <Typography className={classes.cardTitle}>Primary Information</Typography>
                <Grid container className={classes.container}>
                  <Grid item xs={4} className={classes.gridSection}>
                    <TextBox
                      label="Store Name"
                      name="storeName"
                      value={posDetails.storeName}
                      handleChange={handlePosDetailsChange}
                      error={!!errors.storeName}
                      helperText={errors.storeName}
                      textBoxId="storename"
                      required
                    />
                  </Grid>
                  <Grid item xs={4} className={classes.gridSection}>
                    {posTypes && (
                      <SelectBox name="posType" label="POS Type *" menuItems={posTypes} value={posDetails.posType} handleChange={handlePosDetailsChange} required error={!!errors.posType} />
                    )}
                    {errors && errors.posType ? <div className={classes.error}>{errors.posType}</div> : null}
                  </Grid>
                  <Grid item xs={4}>
                    <TextBox
                      label="POS Number"
                      name="posNumber"
                      value={posDetails.posNumber}
                      handleChange={handlePosDetailsChange}
                      error={!!errors.posNumber}
                      helperText={errors.posNumber}
                      textBoxId="POSNumber"
                      disabled={isEditMode || !isObjectEmpty(savedPOSDetails)}
                      required
                    ></TextBox>
                  </Grid>
                </Grid>
                <BasicDetailsServiceType
                  classes={classes}
                  userInfo={userInfo}
                  serviceTypes={servicePropositionList}
                  serviceType={serviceType}
                  handleServiceType={handleServiceType}
                  isServiceTypeDisabled={isServiceTypeDisabled}
                  errors={errors}
                  posType={posDetails.posType}
                  handleServiceTypeCheckBox={handleServiceTypeCheckBox}
                />
              </CardContent>
            </Card>
            <Card className={classes.cards}>
              <CardContent>
                <Typography className={classes.cardTitle}>Address Information</Typography>

                <div className={classes.customerDetailsContainer}>
                  <Typography className={classes.smallTitle}>Customer Service Details</Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextBox
                        label="Name"
                        name="contactName"
                        value={posAddress.contactName}
                        handleChange={handlePosAddressChange}
                        error={!!errors.contactName}
                        helperText={errors.contactName}
                        inputProps={{ maxLength: 100 }}
                        textBoxId="storeNameAddressTextBox"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextBox
                        fullWidth
                        variant="outlined"
                        handleChange={handlePosAddressChange}
                        value={posAddress.email}
                        error={!!errors.email}
                        helperText={errors.email}
                        textBoxId="email"
                        label="Email"
                        name="email"
                        type="email"
                      ></TextBox>
                    </Grid>
                    <Grid item xs={4}>
                      <TextBox
                        fullWidth
                        variant="outlined"
                        handleChange={handlePosAddressChange}
                        value={posAddress.contactNo}
                        inputProps={{ minlength: 15, maxLength: 15 }}
                        error={!!errors.contactNo}
                        helperText={errors.contactNo}
                        textBoxId="contact-no"
                        label="Phone Number"
                        name="contactNo"
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </div>

                <div className={classes.storeDetailsContainer}>
                  <Typography className={classes.smallTitle}>Location Details</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4} className={classes.addressSectionFirstField}>
                      <SelectBox name="country" label="Country" menuItems={countries} value={posAddress.country} handleChange={handlePosAddressChange} disabled />
                    </Grid>
                    <Grid item xs={4} className={classes.addressSectionSecondField}>
                      <TextBox
                        name="region"
                        label="Region/City"
                        value={posAddress.region}
                        handleChange={handlePosAddressChange}
                        inputProps={{ maxLength: 100 }}
                        error={!!errors.region}
                        helperText={errors.region}
                        required
                      />
                      {/* <SelectBox
                        name="region"
                        label="Region"
                        // disabled={isEditMode}
                        menuItems={regions}
                        value={posAddress.region}
                        handleChange={handlePosAddressChange}
                      /> */}
                    </Grid>
                    <Grid item xs={4} className={classes.addressSectionFirstField}>
                      <TextBox
                        name="area"
                        label="Area"
                        value={posAddress.area}
                        handleChange={handlePosAddressChange}
                        inputProps={{ maxLength: 100 }}
                        error={!!errors.area}
                        helperText={errors.area}
                        required
                      />
                      {/* <SearchableDropdown
                        menuItems={getAreasForSelectedRegion}
                        name="area"
                        label="Area"
                        value={posAddress.area}
                        // disabled={isEditMode}
                        handleChange={handleAreaChange}
                      /> */}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.addressSectionFields}>
                    <Grid item xs={4}>
                      <TextBox
                        name="addressLine1"
                        label="Address Line 1"
                        value={posAddress.addressLine1}
                        handleChange={handlePosAddressChange}
                        inputProps={{ maxLength: 100 }}
                        error={!!errors.addressLine1}
                        helperText={errors.addressLine1}
                        required
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextBox
                        name="addressLine2"
                        label="Address Line 2"
                        value={posAddress.addressLine2}
                        handleChange={handlePosAddressChange}
                        inputProps={{ maxLength: 100 }}
                        error={!!errors.addressLine2}
                        helperText={errors.addressLine2}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextBox name="street" label="Street" value={posAddress.street} handleChange={handlePosAddressChange} inputProps={{ maxLength: 50 }} required={false} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.addressSectionFields}>
                    <Grid item xs={4} className={classes.addressSectionSecondField}>
                      <TextBox name="poBox" label="PO Box" value={posAddress.poBox} handleChange={handlePosAddressChange} inputProps={{ maxLength: 10 }} textBoxId="poBox" required={false} />
                    </Grid>

                    <Grid item xs={4} className={classes.addressSectionFirstField}>
                      <TextBox
                        textBoxId="latitude"
                        label="Latitude"
                        name="latitude"
                        type="number"
                        handleChange={handlePosAddressChange}
                        value={posAddress.latitude}
                        error={!!errors.latitude}
                        helperText={errors.latitude}
                        required
                      />
                    </Grid>

                    <Grid item xs={4} className={classes.addressSectionSecondField}>
                      <TextBox
                        name="longitude"
                        label="Longitude"
                        type="number"
                        textBoxId="longitude"
                        handleChange={handlePosAddressChange}
                        value={posAddress.longitude}
                        error={!!errors.longitude}
                        helperText={errors.longitude}
                        required
                      />
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>
            <Grid container className={classes.buttonContainer}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} justifyContent="flex-end">
                  {isEditMode ? (
                    <>
                      <Grid item>
                        <Link to={posNo ? `${APP_ROUTES.COMPLETE_SETUP}/${posNo}` : APP_ROUTES.POS_LIST} className={classes.linkstyle}>
                          <PrimaryButton buttonLabel="Back to POS" buttonVariant="outlined" />
                        </Link>
                      </Grid>
                      <Grid item>
                        <PrimaryButton buttonLabel={loading ? "loading..." : "UPDATE"} onClick={handlePOSUpdate} disabled={loading} />{" "}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item>
                        <Link to={`${APP_ROUTES.POS_LIST}`} className={classes.linkstyle}>
                          <PrimaryButton buttonLabel="CANCEL" buttonVariant="outlined" />
                        </Link>
                      </Grid>
                      <Grid item>
                        <PrimaryButton buttonLabel={loading ? "loading..." : "SAVE AND PROCEED"} onClick={handleSubmit} disabled={loading} />{" "}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </div>
  );
}

export default Basic;
