import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userStyles } from "./Styles/DeliveryFeePageStyles";

import {
  fetchDlvFeeConfigurationList,
  fetchDlvFeeConfigurationListWithSearch,
  fetchShipmentTypeRecordList,
  resetDlvFeeSingleConfigurationRecordServiceState,
  selectDlvFeeConfigurationRecord,
  selectShipmentTypeDetailsRecord,
} from "./Redux/DeliveryFeeSlice";
import { DeliveryFeeInitialState, DeliveryFeeInitialStateInterface } from "./Utils/DeliveryFeeInterface";
import { _design_delivery_fee_listing_screen_object, _design_delivery_fee_list_data } from "./Utils/DeliveryFeeUtils";
import { selectUserDetails } from "../Login/redux/userSlice";
import { ALERT_TYPES, API_RESPONSE_STATUS } from "../../config/constants";
import { serviceSelectPropositionRecord } from "../../config/redux/configurationsSlice";

import Loader from "../common/Loader/Loader";
import DeliveryFeeCardSection from "./Card/DeliveryFeeCardSection";
import DeliveryFeeHeader from "./common/DeliveryFeeHeader";
import DeliveryFeeSearchSection from "./common/DeliveryFeeSearchSection";
import CustomAlert from "components/common/CustomAlert/CustomAlert";

const DeliveryFeePage = () => {
  const classes = userStyles();

  const { currentCountry } = useSelector((state: any) => state.appConfig);
  const userInfo: any = useSelector(selectUserDetails);
  const shipmentTypeDetailsService = useSelector(selectShipmentTypeDetailsRecord);
  const dlvFeeConfigurationRecordService = useSelector(selectDlvFeeConfigurationRecord);
  const servicePropositionList = useSelector(serviceSelectPropositionRecord);

  const appDispatch = useDispatch();

  const [deliveryFeeState, setDeliveryFeeState] = useState<DeliveryFeeInitialStateInterface>(DeliveryFeeInitialState);
  const [searchObj, setSearchObj] = useState({ searchTypeValue: "posNo", searchValue: "" });

  const fetchConfigurationListRecord = (page: any, size: any, query: any) => {
    if (query !== undefined && query !== "") {
      appDispatch(fetchDlvFeeConfigurationListWithSearch({ countryName: currentCountry.name, payload: { page: deliveryFeeState.page, size: deliveryFeeState.size, search: query } }));
    } else {
      appDispatch(fetchDlvFeeConfigurationList({ countryName: currentCountry.name, payload: { page: page, size: size } }));
    }
  };

  const designAPIParamQuery = (currentPage: any, size: any, searchTypeValue: any, searchValue: any) => {
    let query = "";
    if (searchTypeValue !== undefined && searchTypeValue !== "") {
      if (searchValue !== undefined && searchValue !== "") {
        if (searchTypeValue === "posNo") {
          query = `${searchTypeValue}:${searchValue.substring(0, 3)}`;
        } else {
          query = `${searchTypeValue}:${searchValue}`;
        }
      }
    }
    fetchConfigurationListRecord(currentPage, size, query);
  };

  const configureShipmentTypeData = () => {
    if (shipmentTypeDetailsService.data && Array.isArray(shipmentTypeDetailsService.data) && shipmentTypeDetailsService.data.length > 0) {
      let newDlvFeeConfigState = _design_delivery_fee_listing_screen_object(shipmentTypeDetailsService.data, servicePropositionList);
      setDeliveryFeeState({ ...deliveryFeeState, deliveryFeeHeaderData: newDlvFeeConfigState });
      setSearchObj({ ...searchObj, searchTypeValue: "posNo", searchValue: "" });
      designAPIParamQuery(deliveryFeeState.page, deliveryFeeState.size, "", "");
    } else {
      setDeliveryFeeState({ ...deliveryFeeState, deliveryFeeHeaderData: {}, loading: false });
    }
  };

  const configureDlvFeeConfigurationRecord = () => {
    if (dlvFeeConfigurationRecordService && dlvFeeConfigurationRecordService.data) {
      const { responseList, pageNumber, maxRecordPresent } = _design_delivery_fee_list_data(dlvFeeConfigurationRecordService.data, deliveryFeeState);
      setDeliveryFeeState({ ...deliveryFeeState, deliveryFeeListData: responseList, page: pageNumber, maxRecordPresent: maxRecordPresent, loading: false });
    } else {
      setDeliveryFeeState({ ...deliveryFeeState, loading: false });
    }
  };

  const fetchNextRecord = (event: any, pageNumber: any) => {
    setDeliveryFeeState({ ...deliveryFeeState, loading: true });
    let searchTypeValue = "";
    let searchValue = "";
    if (searchObj !== undefined && searchObj.searchTypeValue !== undefined && searchObj.searchTypeValue !== "") {
      searchTypeValue = searchObj.searchTypeValue;
    }
    if (searchObj !== undefined && searchObj.searchValue !== undefined && searchObj.searchValue !== "") {
      searchValue = searchObj.searchValue;
    }
    designAPIParamQuery(pageNumber, deliveryFeeState.size, searchTypeValue, searchValue);
  };

  const resetDeliveryFeeRecord = () => {
    setSearchObj({ ...searchObj, searchTypeValue: "posNo", searchValue: "" });
    setDeliveryFeeState({ ...deliveryFeeState, loading: true });
    designAPIParamQuery(deliveryFeeState.page, deliveryFeeState.size, "", "");
  };

  const handleSearchTypeChange = (selected: any) => {
    if (selected !== undefined && selected !== "") {
      setSearchObj({ ...searchObj, searchTypeValue: selected });
    }
  };

  const handleSearchBoxTextChange = (event: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { value } = event.target;
      setSearchObj({ ...searchObj, searchValue: value });
    }
  };

  const handleSearchIconClick = () => {
    if (searchObj !== undefined && searchObj.searchTypeValue !== undefined && searchObj.searchTypeValue !== "") {
      if (searchObj !== undefined && searchObj.searchValue !== undefined && searchObj.searchValue !== "") {
        setDeliveryFeeState({ ...deliveryFeeState, loading: true });
        designAPIParamQuery(deliveryFeeState.page, deliveryFeeState.size, searchObj.searchTypeValue, searchObj.searchValue);
      } else {
        CustomAlert(ALERT_TYPES.ERROR, "Please provide search value");
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, "Please provide search type");
    }
  };

  const handleSearchBoxKeyDownChange = (event: any) => {
    if ((event.key !== undefined && event.key === "Enter") || event.keyCode === 13) {
      handleSearchIconClick();
    }
  };

  useEffect(() => {
    if (dlvFeeConfigurationRecordService !== undefined && dlvFeeConfigurationRecordService.error !== undefined && dlvFeeConfigurationRecordService.error !== "") {
      setDeliveryFeeState({ ...deliveryFeeState, loading: false });
    }
  }, [dlvFeeConfigurationRecordService, dlvFeeConfigurationRecordService.error]);

  useEffect(() => {
    dlvFeeConfigurationRecordService && dlvFeeConfigurationRecordService.data && configureDlvFeeConfigurationRecord();
  }, [dlvFeeConfigurationRecordService, dlvFeeConfigurationRecordService.data]);

  useEffect(() => {
    shipmentTypeDetailsService && shipmentTypeDetailsService.fetchStatus !== undefined && shipmentTypeDetailsService.fetchStatus !== API_RESPONSE_STATUS.LOADING && shipmentTypeDetailsService.data && configureShipmentTypeData();
  }, [shipmentTypeDetailsService, shipmentTypeDetailsService.fetchStatus, shipmentTypeDetailsService.data]);

  useEffect(() => {
    shipmentTypeDetailsService &&
      shipmentTypeDetailsService.fetchStatus !== undefined &&
      shipmentTypeDetailsService.fetchStatus !== API_RESPONSE_STATUS.LOADING &&
      shipmentTypeDetailsService.error &&
      shipmentTypeDetailsService.error !== "" &&
      setDeliveryFeeState({ ...deliveryFeeState, deliveryFeeHeaderData: {}, deliveryFeeListData: [], loading: false });
  }, [shipmentTypeDetailsService, shipmentTypeDetailsService.fetchStatus, shipmentTypeDetailsService.error]);

  useEffect(() => {
    setDeliveryFeeState({ ...deliveryFeeState, deliveryFeeHeaderData: {}, deliveryFeeListData: [], loading: true });
    appDispatch(fetchShipmentTypeRecordList());
    appDispatch(resetDlvFeeSingleConfigurationRecordServiceState());
  }, [servicePropositionList, appDispatch]);

  return (
    <div className={classes.container}>
      <DeliveryFeeHeader userInfo={userInfo} />
      {deliveryFeeState.loading ? (
        <Loader />
      ) : (
        <>
          <DeliveryFeeSearchSection
            deliveryFeeState={deliveryFeeState}
            resetDeliveryFeeRecord={resetDeliveryFeeRecord}
            searchObj={searchObj}
            handleSearchTypeChange={handleSearchTypeChange}
            handleSearchBoxTextChange={handleSearchBoxTextChange}
            handleSearchBoxKeyDownChange={handleSearchBoxKeyDownChange}
            handleSearchIconClick={handleSearchIconClick}
          />
          <DeliveryFeeCardSection
            deliveryFeeHeaderData={deliveryFeeState.deliveryFeeHeaderData}
            deliveryFeeListData={deliveryFeeState.deliveryFeeListData}
            pageSize={deliveryFeeState.size}
            page={deliveryFeeState.page}
            rowCount={deliveryFeeState.maxRecordPresent}
            fetchNextRecord={fetchNextRecord}
            userInfo={userInfo}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(DeliveryFeePage);
