import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { ReactComponent as DeleteSlotIcon } from "../../assets/svg/DeleteIcon.svg";
import { DAYS_DROPDOWN_OPTIONS, SERVICE_TYPES } from "../../../config/constants";
import { PlasticBagconfigurationViewUtils, ServiceActiveFlagViewUtils } from "../CountrySettingView";
import MultiSelectBox from "../../common/MultiSelectBox/MultiSelectBox";
import CountryDefaultTypeDatePicker from "../CountryDefaultTypeDatePicker";

interface RenderDefaultTypeDetailsInterface {
  classes: any;
  defaultDetails: any;
  handleBagChargeChange: any;
  weeklyHolidays: any;
  setWeeklyHolidays: any;
  defaultDateState: any;
  handleDateChangeEvent: any;
  handleCancel: any;
  handleCalendarHoliday: any;
  calendarHoliday: any;
  handleServiceTypeActiveChange: any;
  serviceTypeName: any;
}

const RenderDefaultTypeDetails = (props: RenderDefaultTypeDetailsInterface) => {
  const { classes, defaultDetails, serviceTypeName, handleBagChargeChange, weeklyHolidays, setWeeklyHolidays, defaultDateState, handleDateChangeEvent, handleCancel, handleCalendarHoliday, calendarHoliday, handleServiceTypeActiveChange } = props;

  return (
    <Card className={classes.serviceTypeCard}>
      <CardContent>
        <Grid container justifyContent="flex-start" spacing={2}>
          <ServiceActiveFlagViewUtils classes={classes} serviceTypeName={serviceTypeName} serviceActiveFlag={defaultDetails.serviceActive} isDisabled={false} handleServiceTypeActiveChange={handleServiceTypeActiveChange} />
          <Grid item xs={3}>
            <>
              <Grid item xs={12}>
                <Typography className={classes.formControlLabel}>{`Weekly Holidays`}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.defaultHolidayGridItem}>
                <MultiSelectBox name="holidays" menuitems={DAYS_DROPDOWN_OPTIONS} value={weeklyHolidays} onChange={setWeeklyHolidays} />
              </Grid>
              {weeklyHolidays &&
                weeklyHolidays.length > 0 &&
                weeklyHolidays.map((holiday: any, index: any) => (
                  <Grid item xs={12} className={classes.defaultHolidayGridItem} key={`${index}-${holiday}`}>
                    <Typography className={`${classes.formControlLabel} ${classes.capitalize}`}>{holiday}</Typography>
                  </Grid>
                ))}
            </>
          </Grid>
          <Grid item xs={3}>
            <>
              <Grid item xs={12}>
                <Typography className={classes.formControlLabel}>{`Calendar Holidays`}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.defaultHolidayGridItem}>
                <Grid container justifyContent="flex-start" spacing={1}>
                  <Grid item xs={10}>
                    <CountryDefaultTypeDatePicker classes={classes} defaultDateState={defaultDateState} handleDateChangeEvent={handleDateChangeEvent} handleCancel={handleCancel} />
                  </Grid>
                  <Grid item xs={2} className={classes.addCalendarDateIconGridItem}>
                    <AddCircleOutlineIcon className={classes.addCalendarDateIconStyle} onClick={(e) => handleCalendarHoliday(e, "ADD", defaultDateState.dateLabel)} />
                  </Grid>
                </Grid>
              </Grid>
              {calendarHoliday &&
                calendarHoliday.length > 0 &&
                calendarHoliday
                  .filter((holiday: any) => holiday.offDate || holiday.dateLabel)
                  .map((holiday: any, index: any) => (
                    <Grid container spacing={1} key={`${index}-${holiday.offDate}`}>
                      <Grid item xs={12} className={classes.defaultCustomHolidayCalendarGridItem}>
                        <DeleteSlotIcon className={classes.deleteCalendarIconStyle} onClick={(e: any) => handleCalendarHoliday(e, "REMOVE", holiday.offDate || holiday.dateLabel)} />
                        <Typography className={classes.formControlLabel}>{holiday.offDate || holiday.dateLabel}</Typography>
                      </Grid>
                    </Grid>
                  ))}
            </>
          </Grid>
          <Grid item xs={3}>
            {""}
          </Grid>
          <Grid item xs={3}>
            <PlasticBagconfigurationViewUtils classes={classes} bagChargeEnabled={defaultDetails.bagChargeEnabled} handleBagChargeChange={handleBagChargeChange} name={SERVICE_TYPES.DEFAULT} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default React.memo(RenderDefaultTypeDetails);
