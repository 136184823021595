import React from "react";

import { Card, Grid, Typography } from "@material-ui/core";
import { DELIVERY_TYPE } from "../../../config/constants";

import ApprovalRequestSlotDetailsForStandard from "./ApprovalRequestSlotDetailsForStandard";
import ApprovalRequestSlotDetailsForExpress from "./ApprovalRequestSlotDetailsForExpress";
import { isEmptyOrNull } from "utils/DataUtils";
import { getServicePropositionDeliveryType } from "utils/helperFunctions";

interface ApprovalRequestSlotDataDetailsPageInterface {
  classes: any;
  slot_data_object_key: any;
  slot_data_object: any;
  serviceTypeName: any;
  stateObj?: any;
  slotBoxClass: any;
  servicePropositionList: any;
}

const ApprovalRequestSlotDataDetailsPage = (props: ApprovalRequestSlotDataDetailsPageInterface) => {
  const { classes, slot_data_object_key, slot_data_object, serviceTypeName, stateObj, slotBoxClass, servicePropositionList } = props;

  return (
    <Card className={slot_data_object_key === "slots" ? classes.slotsCarDivStyleOriginal : classes.slotsCarDivStyleRequested}>
      <Grid container spacing={0} className={classes.slotsCardGridStyle}>
        <Grid item xs={12} className={classes.approverDetailsMainSlotHeadingDivStyle}>
          <Typography className={slot_data_object_key === "slots" ? classes.approverDetailsHeadingValueOriginalSpanStyle : classes.approverDetailsHeadingValueRequestedSpanStyle}>{`${
            slot_data_object_key === "slots" ? "Slots - Original Record" : "Slots - New Requested"
          }`}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} className={slotBoxClass}>
        {stateObj !== undefined && Array.isArray(stateObj) && stateObj.length > 0 ? (
          stateObj.map((slot: any, slot_index: any) => (
            <Grid item xs={12} key={`${slot_index}-slot_index-stateObj-originalConfigTab`} className={classes.slotsAccordionMaindiv}>
              {!isEmptyOrNull(serviceTypeName) ? (
                getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.SLOT_BASED ? (
                  <ApprovalRequestSlotDetailsForStandard classes={classes} slot_day_data={slot} slot_data_object_key={slot_data_object_key} />
                ) : getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.MINUTE_BASED ? (
                  <ApprovalRequestSlotDetailsForExpress classes={classes} slot_day_data={slot} slot_data_object_key={slot_data_object_key} />
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </Grid>
          ))
        ) : slot_data_object !== undefined && typeof slot_data_object === "object" && Object.keys(slot_data_object).length > 0 ? (
          Object.keys(slot_data_object).map((slot_day_data: any, slot_day_data_index: any) => (
            <Grid item xs={12} key={`${slot_day_data_index}-slot_day_data_index-slot_data_object`} className={classes.slotsAccordionMaindiv}>
              {!isEmptyOrNull(serviceTypeName) ? (
                getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.SLOT_BASED ? (
                  <ApprovalRequestSlotDetailsForStandard classes={classes} slot_day_data={slot_day_data} slot_data_object={slot_data_object} slot_data_object_key={slot_data_object_key} />
                ) : getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.MINUTE_BASED ? (
                  <ApprovalRequestSlotDetailsForExpress classes={classes} slot_day_data={slot_day_data} slot_data_object={slot_data_object} slot_data_object_key={slot_data_object_key} />
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </Grid>
          ))
        ) : (
          <></>
        )}
      </Grid>
    </Card>
  );
};

export default React.memo(ApprovalRequestSlotDataDetailsPage);
