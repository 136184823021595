import * as POSService from "../../../api/POSService";

import moment from "moment";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { addNewTemplateSlot, deleteTemplateSlot, sendConfigurationForApprovalAPI, updateTemplateSlot } from "../../../api/TemplateSlotsService";
import { ALL_DAYS_VALUES, API_RESPONSE_STATUS, DELIVERY_TYPE, OBJECT_SERVICE_TYPE, SERVICE_TYPES } from "../../../config/constants";
import { getKeyNameFromZoneTab } from "utils/ServiceTypeUtils";
import { TEXT_CONSTANT } from "../DefaultService/DefaultInterface";
import { getServicePropositionDeliveryType } from "../../../utils/helperFunctions";

const designDefaultServiceDayWiseSlot = (allSupplierTypes: any, configResponse: any) => {
  let allSupplierTypesData: any = allSupplierTypes.reduce((acc: any, cp: any) => ({ ...acc, [cp]: {} }), {});
  allSupplierTypesData = allSupplierTypes.reduce((acc: any, cp: any) => {
    let allSupplierTypesDataObj: any = configResponse.defaultServiceTemplateSlots[cp];
    if (allSupplierTypesDataObj?.length === 0) {
      return [];
    }
    allSupplierTypesDataObj = allSupplierTypesDataObj?.map((dataObj: any) =>
      dataObj?.id !== undefined ? (dataObj?.cutOff === undefined ? { ...dataObj, cutOff: null } : { ...dataObj, cutOff: moment(dataObj?.cutOff, TEXT_CONSTANT.SAVE_DATE_FORMAT) }) : null
    );
    return {
      ...acc,
      [cp]: {
        ...allSupplierTypesDataObj,
        dayWiseSlots: ALL_DAYS_VALUES.reduce((daysAcc, day) => ({ ...daysAcc, [day]: allSupplierTypesDataObj.filter((cpo: any) => cpo.day.toLowerCase() === day.toLowerCase()) }), {}),
      },
    };
  }, {});

  if (allSupplierTypesData !== undefined && Object.keys(allSupplierTypesData).length > 0) {
    Object.keys(allSupplierTypesData).forEach((supplierType: any) => {
      // let total_slot_capacity_at_service_level = 0;
      if (supplierType !== undefined && allSupplierTypesData[supplierType] !== undefined && allSupplierTypesData[supplierType].dayWiseSlots !== undefined && Object.keys(allSupplierTypesData[supplierType].dayWiseSlots).length > 0) {
        Object.keys(allSupplierTypesData[supplierType].dayWiseSlots).forEach((dayValue: any) => {
          if (
            dayValue !== undefined &&
            allSupplierTypesData[supplierType].dayWiseSlots[dayValue] !== undefined &&
            Array.isArray(allSupplierTypesData[supplierType].dayWiseSlots[dayValue]) &&
            allSupplierTypesData[supplierType].dayWiseSlots[dayValue].length > 0
          ) {
            let maximum_service_type_slot_capacity = allSupplierTypesData[supplierType].dayWiseSlots[dayValue].reduce((a: any, b: any) => a + b.slotCapacity, 0);
            // total_slot_capacity_at_service_level = total_slot_capacity_at_service_level + maximum_service_type_slot_capacity;
            allSupplierTypesData[supplierType].dayWiseSlots[dayValue].forEach((daydata: any) => {
              daydata.maximum_slot_capacity = maximum_service_type_slot_capacity;
            });
          }
        });
        // allSupplierTypesData[supplierType][0].total_slot_capacity_at_service_level = total_slot_capacity_at_service_level;
      }
    });
  }

  return allSupplierTypesData;
};

// Method used to transform result for all service rather than default
const groupTemplateSlotsByDay = (configsResponse: any, serviceName: string, servicePropositionList: any) => {
  if (getServicePropositionDeliveryType(servicePropositionList, serviceName) !== DELIVERY_TYPE.DAY_BASED) {
    let newResponse = {
      ...configsResponse,
      dayWiseSlots: ALL_DAYS_VALUES.reduce(
        (acc, day) => ({
          ...acc,
          [day]: configsResponse.templateSlots?.filter((slot: any) => slot.day.toLowerCase() === day.toLowerCase()),
        }),
        {}
      ),
    };
    return newResponse;
  } else {
    let allSupplierTypes: any = Object.keys(configsResponse.defaultServiceTemplateSlots);
    let allSupplierTypesData: any = designDefaultServiceDayWiseSlot(allSupplierTypes, configsResponse);
    let newResponse = { ...configsResponse, supplierTypes: allSupplierTypesData };
    return newResponse;
  }
};

export const groupClickAndCollectResponseByCollectionPoints: any = (configResponse: any) => {
  let allCollectionPoints: any = [...new Set(configResponse.templateSlots.map((ts: any) => ts.clickAndCollect.displayName))];
  let collectionPointsData: any = allCollectionPoints.reduce(
    (acc: any, cp: any) => ({
      ...acc,
      [cp]: {
        collectionPointDetails: configResponse.templateSlots.find((s: any) => s.clickAndCollect.displayName.toLowerCase() === cp.toLowerCase()).clickAndCollect,
        templateSlots: configResponse.templateSlots.filter((s: any) => s.clickAndCollect.displayName.toLowerCase() === cp.toLowerCase()),
        capacityAmountAtCollectionPointLevel:
          configResponse.templateSlots !== undefined ? configResponse.templateSlots.filter((s: any) => s.clickAndCollect.displayName.toLowerCase() === cp.toLowerCase()).reduce((a: any, b: any) => a + b.slotCapacity, 0) : 0,
      },
    }),
    {}
  );
  collectionPointsData = allCollectionPoints.reduce((acc: any, cp: any) => {
    let collectionPointObj: any = collectionPointsData[cp];

    return {
      ...acc,
      [cp]: {
        ...collectionPointObj,
        dayWiseSlots: ALL_DAYS_VALUES.reduce(
          (daysAcc, day) => ({
            ...daysAcc,
            [day]: collectionPointObj.templateSlots.filter((cpo: any) => cpo.day.toLowerCase() === day.toLowerCase()),
          }),
          {}
        ),
      },
    };
  }, {});

  if (collectionPointsData !== undefined && Object.keys(collectionPointsData).length > 0) {
    Object.keys(collectionPointsData).forEach((collPoint: any) => {
      if (collectionPointsData[collPoint] !== undefined && collectionPointsData[collPoint].dayWiseSlots !== undefined) {
        Object.keys(collectionPointsData[collPoint].dayWiseSlots).forEach((dayValue) => {
          if (collectionPointsData[collPoint].dayWiseSlots[dayValue] !== undefined && Array.isArray(collectionPointsData[collPoint].dayWiseSlots[dayValue]) !== undefined && collectionPointsData[collPoint].dayWiseSlots[dayValue].length > 0) {
            let maximum_slot_capacity = collectionPointsData[collPoint].dayWiseSlots[dayValue].reduce((a: any, b: any) => a + b.slotCapacity, 0);
            collectionPointsData[collPoint].dayWiseSlots[dayValue].forEach((daydata: any) => {
              daydata.maximum_slot_capacity = maximum_slot_capacity;
            });
          }
        });
      }
    });
  }

  let newResponse = {
    ...configResponse,
    collectionPoints: collectionPointsData,
    capacityAmountAtZoneLevel: configResponse.templateSlots !== undefined ? configResponse.templateSlots.reduce((a: any, b: any) => a + b.slotCapacity, 0) : 0,
  };
  return newResponse;
};

export const groupDefaultResponseBySupplierType: any = (configResponse: any) => {
  let capacityCount = 0;
  let allSupplierTypes: any = Object.keys(configResponse.defaultServiceTemplateSlots);
  let allSupplierTypesData: any = designDefaultServiceDayWiseSlot(allSupplierTypes, configResponse);

  Object.keys(configResponse.defaultServiceTemplateSlots).forEach((type: any) => {
    configResponse.defaultServiceTemplateSlots[type].forEach((obj: any) => {
      if (obj !== undefined && obj.slotCapacity !== undefined) {
        capacityCount = capacityCount + obj.slotCapacity;
      }
    });
  });

  let newResponse = { ...configResponse, supplierTypes: allSupplierTypesData, capacityAmountAtZoneLevel: capacityCount };

  return newResponse;
};

export const createTemplateSlots: any = createAsyncThunk("create/createTemplateSlots", async ({ posNo, service, zoneConfigIndex, zoneConfigurations, servicePropositionList }: any, { rejectWithValue }) => {
  try {
    const {
      response: { data },
    }: any = await POSService.createTemplateSlots(posNo, zoneConfigurations);
    if (getServicePropositionDeliveryType(servicePropositionList, service) === DELIVERY_TYPE.CLICK_N_COLLECT) {
      let transformedResponse = groupClickAndCollectResponseByCollectionPoints(data);
      return transformedResponse;
    } else if (getServicePropositionDeliveryType(servicePropositionList, service) === DELIVERY_TYPE.DAY_BASED) {
      let transformedResponse = groupTemplateSlotsByDay(data, service, servicePropositionList);
      return transformedResponse;
    } else {
      let transformedResponse = groupTemplateSlotsByDay(data, service, servicePropositionList);
      return transformedResponse;
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateTemplateSlots: any = createAsyncThunk("update/updateTemplateSlots", async ({ posNo, service, zoneConfigIndex, zoneConfigurations, servicePropositionList }: any, { rejectWithValue }) => {
  try {
    const {
      response: { data },
    }: any = await POSService.updateTemplateSlots(posNo, zoneConfigurations);
    let transformedResponse = {};
    if (getServicePropositionDeliveryType(servicePropositionList, service) !== DELIVERY_TYPE.CLICK_N_COLLECT) {
      transformedResponse = { ...data.find((r: any) => getKeyNameFromZoneTab(r, "id") === getKeyNameFromZoneTab(zoneConfigurations, "id")) };
      transformedResponse = groupTemplateSlotsByDay(transformedResponse, service, servicePropositionList);
      return transformedResponse;
    } else {
      transformedResponse = { ...data.find((r: any) => getKeyNameFromZoneTab(r, "id") === getKeyNameFromZoneTab(zoneConfigurations, "id")) };
      transformedResponse = groupClickAndCollectResponseByCollectionPoints(transformedResponse);
      return transformedResponse;
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchSlotsInformationByServiceType: any = createAsyncThunk("slots/fetchSlotsInformationByServiceType", async ({ posNo, serviceType, serviceTypeName, servicePropositionList }: any, { rejectWithValue }) => {
  try {
    const {
      response: { data },
    }: any = await POSService.fetchTemplateSlots(posNo, { serviceType });
    let transformedResponse = {};
    if (data.slots) {
      if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.CLICK_N_COLLECT) {
        transformedResponse = {
          ...data,
          slots: data.slots.map((r: any) => {
            return groupClickAndCollectResponseByCollectionPoints(r);
          }),
        };
        return transformedResponse;
      } else {
        transformedResponse = { ...data, slots: data.slots.map((r: any) => ({ ...groupTemplateSlotsByDay(r, serviceTypeName.toUpperCase(), servicePropositionList) })) };
      }
    } else {
      return { slots: [] };
    }
    return transformedResponse;
  } catch (err) {
    console.log("err :: ", err);
    return rejectWithValue(err);
  }
});

export const makeTemplateSlotEditable: any = createAsyncThunk("template/makeEditable", ({ serviceTypeName, slotData, canBeUpdated }: any) => {
  return canBeUpdated;
});

export const makeCnCTemplateSlotEditable: any = createAsyncThunk("template/Cnc/makeEditable", ({ serviceTypeName, slotData, canBeUpdated }: any) => {
  return canBeUpdated;
});

export const createNewTemplateSlot: any = createAsyncThunk("templateSlot/create", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await addNewTemplateSlot(slotData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createCnCTemplateSlot: any = createAsyncThunk("templateSlot/CnC/create", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await addNewTemplateSlot(slotData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateTemplateSlotBySlotId: any = createAsyncThunk("templateSlot/updateTemplateSlotBySlotId", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await updateTemplateSlot(slotData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateCnCTemplateSlot: any = createAsyncThunk("templateSlot/CnC/update", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await updateTemplateSlot(slotData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteATemplateSlot: any = createAsyncThunk("template/delete", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await deleteTemplateSlot(slotData.id);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteCnCTemplateSlot: any = createAsyncThunk("templateSlot/CnC/delete", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await deleteTemplateSlot(slotData.id);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteZoneConfig: any = createAsyncThunk("zoneConfig/delete", async ({ serviceTypeName, zoneConfigId, posNo }: any, { rejectWithValue }) => {
  try {
    let requestObject = {
      posNo: posNo,
      serviceTypeName: serviceTypeName,
      zoneId: zoneConfigId,
    };
    const { response }: any = await POSService.deleteZoneConfigByZoneId(requestObject);
    return response;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const makeDefaultTemplateSlotEditable: any = createAsyncThunk("template/makeDefaultTemplateSlotEditable", ({ serviceTypeName, slotData, canBeUpdated, objectKey }: any) => {
  return canBeUpdated;
});

export const updateDefaultTemplateSlotBySlotId: any = createAsyncThunk("templateSlot/updateDefaultTemplateSlotBySlotId", async ({ serviceTypeName, slotData }: any, { rejectWithValue }) => {
  try {
    const { response }: any = await updateTemplateSlot(slotData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const sendConfigurationForApproval: any = createAsyncThunk("templateSlot/sendConfigurationForApprovalAPI", async (data: any, { rejectWithValue }) => {
  try {
    const payload: any = await sendConfigurationForApprovalAPI(data);
    return payload;
  } catch (err) {
    return rejectWithValue(err);
  }
});

/**
 *
 * STANDARD capacity Optimization added
 * */
export const fetchSlotsInformationForStandard: any = createAsyncThunk("slots/fetchSlotsByService", async ({ posNo, serviceType, serviceTypeName, servicePropositionList }: any, { rejectWithValue }) => {
  try {
    const {
      response: { data },
    }: any = await POSService.fetchStandardTemplateSlotsVersionThree(posNo, { serviceType });
    let transformedResponse = {};
    if (data.slots) {
      transformedResponse = { ...data, slots: data.slots.map((r: any) => ({ ...groupTemplateSlotsByDay(r, serviceTypeName.toUpperCase(), servicePropositionList) })) };
    } else {
      return { slots: [] };
    }
    return transformedResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createTemplateSlotsForStandard: any = createAsyncThunk("create/createTemplateSlotsForStandard", async ({ posNo, service, zoneConfigIndex, zoneConfigurations, servicePropositionList }: any, { rejectWithValue }) => {
  try {
    const {
      response: { data },
    }: any = await POSService.createTemplateSlotsForStandard(posNo, zoneConfigurations);
    let transformedResponse = groupTemplateSlotsByDay(data, service, servicePropositionList);
    return transformedResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateTemplateSlotsForStandard: any = createAsyncThunk("update/updateTemplateSlotsForStandard", async ({ posNo, service, zoneConfigIndex, zoneConfigurations, servicePropositionList }: any, { rejectWithValue }) => {
  try {
    const {
      response: { data },
    }: any = await POSService.updateTemplateSlotsForStandard(posNo, zoneConfigurations);
    let transformedResponse = {};
    transformedResponse = { ...data.find((r: any) => getKeyNameFromZoneTab(r, "id") === getKeyNameFromZoneTab(zoneConfigurations, "id")) };
    transformedResponse = groupTemplateSlotsByDay(transformedResponse, service, servicePropositionList);
    return transformedResponse;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateDynamicRoutingForStandard: any = createAsyncThunk("update/updateDynamicRoutingForStandard", async (dynamicRoutingPayload: any, { rejectWithValue }) => {
  try {
    const { response }: any = await POSService.updateDynamicRoutingForStandard(dynamicRoutingPayload);
    if (response?.success) {
      return response?.data || {};
    }
    return {};
  } catch (err) {
    return rejectWithValue(err);
  }
});

const commonFetchInitialState = {
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  error: "",
  data: [] as any,
  loading: false,
};

const initialState = {
  standard: [],
  express: [],
  default: [],
  click_n_collect: [],
  fetchingSlots: false,
  fetchingSlotsError: "",
  configurationForApproval: { ...commonFetchInitialState },
};

const slotsSlice: any = createSlice({
  name: "slots",
  initialState,
  reducers: {
    resetSlotsInformation: (state: any, action: any) => {
      return initialState;
    },
  },
  extraReducers: {
    [createTemplateSlots.pending]: (state: any, action: any) => {
      const { service, zoneConfigIndex, servicePropositionList } = action.meta.arg;
      let serviceTypeName = service;
      if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.MINUTE_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.EXPRESS;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.CLICK_N_COLLECT) {
        serviceTypeName = OBJECT_SERVICE_TYPE.CLICK_N_COLLECT;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.DAY_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.DEFAULT;
      }
      state[serviceTypeName][zoneConfigIndex] = { ...state[serviceTypeName][zoneConfigIndex], isCreating: true, isCreated: false, createError: "" };
    },
    [createTemplateSlots.fulfilled]: (state: any, action: any) => {
      const { service, zoneConfigIndex, servicePropositionList } = action.meta.arg;
      let serviceTypeName = service;
      if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.MINUTE_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.EXPRESS;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.CLICK_N_COLLECT) {
        serviceTypeName = OBJECT_SERVICE_TYPE.CLICK_N_COLLECT;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.DAY_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.DEFAULT;
      }
      state[serviceTypeName][zoneConfigIndex] = { ...action.payload, isCreating: false, isCreated: true };
    },
    [createTemplateSlots.rejected]: (state: any, action: any) => {
      const { service, zoneConfigIndex, servicePropositionList } = action.meta.arg;
      let serviceTypeName = service;
      if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.MINUTE_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.EXPRESS;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.CLICK_N_COLLECT) {
        serviceTypeName = OBJECT_SERVICE_TYPE.CLICK_N_COLLECT;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.DAY_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.DEFAULT;
      }
      state[serviceTypeName][zoneConfigIndex] = { isCreating: false, isCreated: false, createError: action.payload.message };
    },
    [updateTemplateSlots.pending]: (state: any, action: any) => {
      const { service, zoneConfigIndex, servicePropositionList } = action.meta.arg;
      let serviceTypeName = service;
      if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.MINUTE_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.EXPRESS;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.CLICK_N_COLLECT) {
        serviceTypeName = OBJECT_SERVICE_TYPE.CLICK_N_COLLECT;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.DAY_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.DEFAULT;
      }
      state[serviceTypeName][zoneConfigIndex] = { ...state[serviceTypeName][zoneConfigIndex], isUpdating: true, isUpdated: false, updateError: "" };
    },
    [updateTemplateSlots.fulfilled]: (state: any, action: any) => {
      const { service, zoneConfigIndex, servicePropositionList } = action.meta.arg;
      let serviceTypeName = service;
      if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.MINUTE_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.EXPRESS;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.CLICK_N_COLLECT) {
        serviceTypeName = OBJECT_SERVICE_TYPE.CLICK_N_COLLECT;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.DAY_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.DEFAULT;
      }
      state[serviceTypeName][zoneConfigIndex] = { ...action.payload, isUpdating: false, isUpdated: true };
    },
    [updateTemplateSlots.rejected]: (state: any, action: any) => {
      const { service, zoneConfigIndex, servicePropositionList } = action.meta.arg;
      let serviceTypeName = service;
      if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.MINUTE_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.EXPRESS;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.CLICK_N_COLLECT) {
        serviceTypeName = OBJECT_SERVICE_TYPE.CLICK_N_COLLECT;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.DAY_BASED) {
        serviceTypeName = OBJECT_SERVICE_TYPE.DEFAULT;
      }
      state[serviceTypeName][zoneConfigIndex] = { ...state[serviceTypeName][zoneConfigIndex], isUpdating: false, isUpdated: false, updateError: action.payload.message };
    },
    [fetchSlotsInformationByServiceType.pending]: (state: any, action: any) => {
      const { serviceTypeName, servicePropositionList } = action.meta.arg;
      state.fetchingSlots = true;
      state.fetchingSlotsError = "";
      if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.MINUTE_BASED) {
        state.express = [];
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.CLICK_N_COLLECT) {
        state.click_n_collect = [];
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.DAY_BASED) {
        state.default = [];
      } else {
        state[serviceTypeName] = [];
      }
    },
    [fetchSlotsInformationByServiceType.fulfilled]: (state: any, action: any) => {
      const { serviceTypeName, servicePropositionList } = action.meta.arg;
      const { payload } = action;
      state.fetchingSlots = false;
      if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.MINUTE_BASED) {
        state.express = payload.slots;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.CLICK_N_COLLECT) {
        state.click_n_collect = payload.slots;
      } else if (getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.DAY_BASED) {
        state.default = payload.slots;
      } else {
        state[serviceTypeName] = payload.slots;
      }
    },
    [fetchSlotsInformationByServiceType.rejected]: (state: any, action: any) => {
      const { payload } = action;
      state.fetchingSlots = false;
      state.fetchingSlotsError = payload.message;
    },
    [makeTemplateSlotEditable.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
        payload,
      } = action;
      state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).dayWiseSlots[day].find((slot: any) => slot.id === slotId).canBeUpdated = payload;
    },
    [makeCnCTemplateSlotEditable.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
        payload,
      } = action;
      state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).collectionPoints[displayName].dayWiseSlots[day].find((slot: any) => slot.id === slotId).canBeUpdated = payload;
    },
    [createNewTemplateSlot.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day },
          },
        },
        payload,
      } = action;
      state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).dayWiseSlots[day].push(payload.data);
    },
    [createCnCTemplateSlot.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              clickAndCollect: { displayName },
            },
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId);
      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].push(payload.data);
    },
    [createCnCTemplateSlot.rejected]: (state: any, action: any) => {},

    [updateTemplateSlotBySlotId.pending]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
      } = action;
      state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).dayWiseSlots[day].find((slot: any) => slot.id === slotId).isUpdating = true;
      state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).dayWiseSlots[day].find((slot: any) => slot.id === slotId).updateError = "";
    },
    [updateTemplateSlotBySlotId.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).dayWiseSlots[day].findIndex((slot: any) => slot.id === slotId);

      state[serviceTypeName][zoneIndex].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...payload.data,
        isUpdating: false,
        isUpdated: true,
        updateError: "",
        id: slotId,
      };
    },
    [updateTemplateSlotBySlotId.rejected]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).dayWiseSlots[day].findIndex((slot: any) => slot.id === slotId);

      state[serviceTypeName][zoneIndex].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...state[serviceTypeName][zoneIndex].dayWiseSlots[day][slotToBeUpdateIndex],
        isUpdating: false,
        isUpdated: false,
        updateError: payload.message,
        id: slotId,
      };
    },

    [deleteATemplateSlot.pending]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
      } = action;
      state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).dayWiseSlots[day].find((slot: any) => slot.id === slotId).isDeleting = true;
    },
    [deleteATemplateSlot.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
          },
        },
      } = action;
      let filteredSlots = state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).dayWiseSlots[day].filter((slot: any) => slot.id !== slotId);
      state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).dayWiseSlots[day] = filteredSlots;
    },
    [updateCnCTemplateSlot.pending]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].findIndex((sl: any) => sl.id === slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex].isUpdating = true;
    },
    [updateCnCTemplateSlot.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].findIndex((sl: any) => sl.id === slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...payload.data,
        isUpdating: false,
        isUpdated: true,
      };
    },
    [updateCnCTemplateSlot.rejected]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].findIndex((sl: any) => sl.id === slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex],
        ...payload.data,
        isUpdating: false,
        isUpdated: false,
      };
    },
    [deleteCnCTemplateSlot.pending]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].findIndex((sl: any) => sl.id === slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex].isDeleting = true;
    },
    [deleteCnCTemplateSlot.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId);

      let filteredSlots = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].filter((s: any) => s.id !== slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day] = filteredSlots;
    },
    [deleteCnCTemplateSlot.rejected]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: {
              zoneId,
              day,
              id: slotId,
              clickAndCollect: { displayName },
            },
          },
        },
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day].findIndex((sl: any) => sl.id === slotId);

      state[serviceTypeName][zoneIndex].collectionPoints[displayName].dayWiseSlots[day][slotToBeUpdateIndex].isDeleting = false;
    },
    /** Delete Zone config */
    [deleteZoneConfig.pending]: (state: any) => {
      state.isDeleting = true;
      state.deleteError = "";
    },
    [deleteZoneConfig.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: { serviceTypeName, zoneConfigId },
        },
      } = action;
      state.isDeleting = false;

      state[serviceTypeName] = state[serviceTypeName].filter((s: any) => getKeyNameFromZoneTab(s, "id") !== zoneConfigId);
    },
    [deleteZoneConfig.rejected]: (state: any, action: any) => {
      const { payload } = action;
      state.isDeleting = false;
      state.deleteError = payload.message;
    },
    [makeDefaultTemplateSlotEditable.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
            objectKey,
          },
        },
        payload,
      } = action;
      state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).supplierTypes[objectKey].dayWiseSlots[day].find((slot: any) => slot.id === slotId).canBeUpdated = payload;
    },
    [updateDefaultTemplateSlotBySlotId.pending]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
            objectKey,
          },
        },
      } = action;

      state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).supplierTypes[objectKey].dayWiseSlots[day].find((slot: any) => slot.id === slotId).isUpdating = true;
      state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).supplierTypes[objectKey].dayWiseSlots[day].find((slot: any) => slot.id === slotId).updateError = "";
    },
    [updateDefaultTemplateSlotBySlotId.fulfilled]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
            objectKey,
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).supplierTypes[objectKey].dayWiseSlots[day].findIndex((slot: any) => slot.id === slotId);

      state[serviceTypeName][zoneIndex].supplierTypes[objectKey].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...payload.data,
        isUpdating: false,
        isUpdated: true,
        updateError: "",
        id: slotId,
      };
    },
    [updateDefaultTemplateSlotBySlotId.rejected]: (state: any, action: any) => {
      const {
        meta: {
          arg: {
            serviceTypeName,
            slotData: { zoneId, day, id: slotId },
            objectKey,
          },
        },
        payload,
      } = action;
      let zoneIndex = state[serviceTypeName].findIndex((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId);
      let slotToBeUpdateIndex = state[serviceTypeName].find((item: any) => getKeyNameFromZoneTab(item, "id") === zoneId).supplierTypes[objectKey].dayWiseSlots[day].findIndex((slot: any) => slot.id === slotId);

      state[serviceTypeName][zoneIndex].supplierTypes[objectKey].dayWiseSlots[day][slotToBeUpdateIndex] = {
        ...state[serviceTypeName][zoneIndex].supplierTypes[objectKey].dayWiseSlots[day][slotToBeUpdateIndex],
        isUpdating: false,
        isUpdated: false,
        updateError: payload.message,
        id: slotId,
      };
    },
    [sendConfigurationForApproval.pending]: (state: any) => {
      state.configurationForApproval.fetchStatus = API_RESPONSE_STATUS.LOADING;
      state.configurationForApproval.loading = true;
      state.configurationForApproval.data = [];
      state.configurationForApproval.error = "";
    },
    [sendConfigurationForApproval.fulfilled]: (state: any, action: any) => {
      state.configurationForApproval.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
      state.configurationForApproval.loading = true;
      state.configurationForApproval.data = action.payload;
    },
    [sendConfigurationForApproval.rejected]: (state: any, action: any) => {
      state.configurationForApproval.fetchStatus = API_RESPONSE_STATUS.FAILED;
      state.configurationForApproval.loading = true;
      state.configurationForApproval.error = action.error.message;
    },
    /** fetchSlotsInformationForStandard */
    [fetchSlotsInformationForStandard.pending]: (state: any, action: any) => {
      state.fetchingSlots = true;
      state.fetchingSlotsError = "";
      state.standard = [];
    },
    [fetchSlotsInformationForStandard.fulfilled]: (state: any, action: any) => {
      const { payload } = action;
      state.fetchingSlots = false;
      state.standard = payload.slots;
    },
    [fetchSlotsInformationForStandard.rejected]: (state: any, action: any) => {
      const { payload } = action;
      state.fetchingSlots = false;
      state.fetchingSlotsError = payload.message;
    },
    /** updateTemplateSlotsForStandard */
    [updateTemplateSlotsForStandard.pending]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state.standard[zoneConfigIndex] = {
        ...state[service][zoneConfigIndex],
        isUpdating: true,
        isUpdated: false,
        updateError: "",
      };
    },
    [updateTemplateSlotsForStandard.fulfilled]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state.standard[zoneConfigIndex] = { ...action.payload, isUpdating: false, isUpdated: true };
    },
    [updateTemplateSlotsForStandard.rejected]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state.standard[zoneConfigIndex] = {
        ...state[service][zoneConfigIndex],
        isUpdating: false,
        isUpdated: false,
        updateError: action.payload.message,
      };
    },
    /** createTemplateSlotsForStandard */
    [createTemplateSlotsForStandard.pending]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state.standard[zoneConfigIndex] = {
        ...state[service][zoneConfigIndex],
        isCreating: true,
        isCreated: false,
        createError: "",
      };
    },
    [createTemplateSlotsForStandard.fulfilled]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state.standard[zoneConfigIndex] = { ...action.payload, isCreating: false, isCreated: true };
    },
    [createTemplateSlotsForStandard.rejected]: (state: any, action: any) => {
      const { service, zoneConfigIndex } = action.meta.arg;
      state.standard[zoneConfigIndex] = { isCreating: false, isCreated: false, createError: action.payload.message };
    },
    /** updateDynamicRoutingForStandard */
    [updateDynamicRoutingForStandard.pending]: (state: any, action: any) => {},
    [updateDynamicRoutingForStandard.fulfilled]: (state: any, action: any) => {},
    [updateDynamicRoutingForStandard.rejected]: (state: any, action: any) => {},
  },
});

export default slotsSlice.reducer;

export const { resetSlotsInformation } = slotsSlice.actions;
