import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import produce from "immer";
import moment from "moment";

import expressServiceStyles from "./ExpressStyles";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import { ALERT_TYPES, APP_ROUTES, BUTTON_TEXT_CONSTANT, EXPRESS_TYPES, HH_MM_SS, OBJECT_SERVICE_TYPE, STORE_STATE } from "../../../config/constants";
import { TEXT_CONSTANT } from "../../../config/constants";
import { serviceSelectPropositionRecord } from "../../../config/redux/configurationsSlice";
import { fetchPOSDetailsByPOSNo } from "../../CreatePOS/redux/posSlice";
import { ALL_DAYS_VALUES, DEFAULT_ON_DEMAND_SLOT, DEFAULT_ON_DEMAND_SLOT_GENERATOR_FORM, DEFAULT_TAB_CONFIGURATIONS, PRE_SELECTED_FOOD_SUB_TYPES } from "../utils/constants";
import { check_if_slot_obj_contains_sub_set } from "../../../utils/ApplicationUtils";
import { calculateAvailableDaysForSelection, createNextZoneName, createServiceConfigPayload } from "../utils/serviceUtils";
import { handleBusyTimeFieldsChange, handleConfigurationStateSwitch, handleFoodSubType, handleProductType, handleZoneStateSwitchUtils } from "../utils/SlotServicesUtils";
import { fetchPendingApprovalRequest, selectAllPendingApprovalRequestRecordListServices } from "../../ApprovalRequest/Redux/ApprovalRequestSlice";

import ConfirmModal from "../../../components/common/ConfirmModal/ConfirmModal";
import CustomAlert from "../../../components/common/CustomAlert/CustomAlert";
import OverlayLoader from "../../common/Loader/OverlayLoader";
import PageTitle from "../../common/PageTitle/PageTitle";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import RemoveButton from "../../common/RemoveButton/RemoveButton";
import BasicServiceDetails from "../common/BasicServiceDetails/BasicServiceDetails";
import BulkUpdateExpressPopUp from "./common/BulkUpdateExpressPopUp";
import RenderZoneConfigurationsCard from "../common/RenderZoneConfigurationsCard";
import CreateOnDemandSlots from "./OnDemand/CreateOnDemandSlots";
import EditOnDemandSlots from "./OnDemand/EditOnDemandSlots";
import RenderConfigureSlotsAccordion from "./common/RenderConfigureSlotsAccordion";
import PreviewApprovalRequestPopUp from "../../ApprovalRequest/common/PreviewApprovalRequestPopUp";

import {
  createNewTemplateSlot,
  createTemplateSlots,
  deleteATemplateSlot,
  deleteZoneConfig,
  fetchSlotsInformationByServiceType,
  resetSlotsInformation,
  sendConfigurationForApproval,
  updateTemplateSlotBySlotId,
  updateTemplateSlots,
} from "../redux/templateSlotsSlice";
import { ExpressServiceInitialState } from "./ExpressInterface";
import { selectUserDetails } from "../../Login/redux/userSlice";
import { is_product_type_visible, is_user_creating_approval_request, is_user_creating_updating_pos, is_zone_add_allowed, is_zone_delete_allowed } from "./Utils/ExpressAccessUtils";
import { _handle_date_change_event_util, is_request_already_presnt_by_admin_util, reset_bulk_update_config_day, reset_bulk_update_configuration_for_given_day } from "utils/ServiceTypeUtils";
import {
  _handle_clear_bulk_update_confimation_pop_up_utils,
  _handle_close_bulk_update_confimation_pop_up_utils,
  _handle_day_delected_for_bulk_update_utils,
  _handle_open_bulk_update_confimation_pop_up_utils,
} from "../BulkUpdateCommon/BulkUpdateCommon";
import {
  _validate_send_for_approval_form,
  add_new_template_slot_util,
  cancel_editing_a_slot_util,
  delete_template_slot_util,
  handle_design_config_tab_utils,
  update_make_slot_editable,
  validateExpressZoneConfig,
  validate_generator_form_before_bulk_update,
} from "./Utils/ExpressUtils";
import { isEmptyOrNull } from "../../../utils/DataUtils";

const Express = ({ serviceTypeId, serviceTypeName, expressType, displayName }) => {
  const { posNo } = useParams();

  const classes = expressServiceStyles();
  const history = useHistory();

  const dispatch = useDispatch();

  const userInfo = useSelector(selectUserDetails);
  const { posDetails, loading: fetchingDetails } = useSelector((state) => state.pos);
  const { fetchingSlots, express, isDeleting } = useSelector((state) => state.slots);
  const servicePropositionList = useSelector(serviceSelectPropositionRecord);
  const pendingApprovalRequestRecordListService = useSelector(selectAllPendingApprovalRequestRecordListServices);
  const {
    currentCountry: { countryId },
  } = useSelector((state) => state.appConfig);

  const isExpressTypeOnDemand = expressType === EXPRESS_TYPES.ON_DEMAND.value;

  const [expressServiceState, setExpressServiceState] = useState(ExpressServiceInitialState);
  const [errors, setErrors] = useState([{ foodTypes: "", selectedAreas: "" }]);
  const [configTabs, setConfigTabs] = useState([
    {
      zoneName: "Zone01",
      foodTypes: "",
      foodSubType: PRE_SELECTED_FOOD_SUB_TYPES,
      slotsGeneratorForms: [{ days: ALL_DAYS_VALUES, generatedSlots: [{ ...DEFAULT_ON_DEMAND_SLOT }] }],
      selectedAreas: [],
      showFirstSlotAtOption: false,
      showFirstSlotAt: null,
      showServiceCutOffTimeOption: false,
      serviceCutOffTime: null,
    },
  ]);

  const transformConfigForUI = (slots) => {
    let new_config_tabs = handle_design_config_tab_utils(slots);
    setConfigTabs(new_config_tabs);
    setExpressServiceState({
      ...expressServiceState,
      loading: false,
      configurationBulkUpdatedObj: {
        isConfigurationBulkUpdated: false,
        bulkUpdatedDays: [],
      },
      originalConfigTab: new_config_tabs,
    });
  };

  const fetchSlots = () => {
    if (!isEmptyOrNull(posNo) && !isEmptyOrNull(serviceTypeId) && !isEmptyOrNull(serviceTypeName)) {
      dispatch(fetchSlotsInformationByServiceType({ posNo, serviceType: serviceTypeId, serviceTypeName: OBJECT_SERVICE_TYPE.EXPRESS, servicePropositionList }));
      setExpressServiceState({ ...expressServiceState, loading: false });
    }
  };

  const openSwitchZonePopUpWarningPopUp = (tabIndex) => {
    setExpressServiceState({ ...expressServiceState, loading: true, showZoneChangeWarningPopUp: true, temporarySelectedZone: tabIndex });
  };

  const closeSwitchZonePopUpWarningPopUp = () => {
    setExpressServiceState({ ...expressServiceState, loading: false, showZoneChangeWarningPopUp: false, temporarySelectedZone: "" });
  };

  const call_send_for_approval = async (payload_Object) => {
    const { payload } = await dispatch(sendConfigurationForApproval(payload_Object));
    if (payload && payload.response !== undefined && payload.response.data === "success") {
      CustomAlert(ALERT_TYPES.SUCCESS, `Configuration was successfully sent for approval.`);
      dispatch(fetchPendingApprovalRequest({ countryId: countryId, status: ["PENDING"] }));
      history.push(APP_ROUTES.POS_LIST);
    } else {
      setExpressServiceState({ ...expressServiceState, loading: false });
      let errorMessage = "Error Occurred";
      if (payload.message && payload.message !== "No message available") {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    }
  };

  const updateSelectedTabIndex = (tabIndex) => {
    setExpressServiceState({
      ...expressServiceState,
      loading: true,
      selectedConfigTab: tabIndex,
      activationDate: "",
      activationDatelabel: "",
      isDateValid: false,
      data_present_obj: {},
      showZoneChangeWarningPopUp: false,
      temporarySelectedZone: "",
    });
  };

  const handleTabChange = (e, tabIndex) => {
    if (is_user_creating_approval_request(userInfo)) {
      if (expressServiceState !== undefined && expressServiceState.activationDate !== undefined && expressServiceState.activationDate !== "") {
        const pay_load_object = _validate_send_for_approval_form(expressServiceState, configTabs, countryId, false, "");
        if (pay_load_object && typeof pay_load_object === "object" && Object.keys(pay_load_object).length !== 0) {
          openSwitchZonePopUpWarningPopUp(tabIndex);
          return;
        }
      }
    }
    updateSelectedTabIndex(tabIndex);
  };

  const addConfigTabs = () => {
    let newConfigTab = {
      ...DEFAULT_TAB_CONFIGURATIONS,
      foodTypes: "",
      slotsGeneratorForms: [{ days: ALL_DAYS_VALUES, generatedSlots: [{ ...DEFAULT_ON_DEMAND_SLOT }] }],
      zoneName: createNextZoneName(configTabs),
      state: STORE_STATE.AVAILABLE,
      stateEdit: false,
      busyDuration: 0,
      busyDurationHour: "",
      busyDurationMinutes: "",
    };
    let newTabs = [...configTabs, newConfigTab];
    let newError = { foodTypes: "" };
    let newErrors = produce(errors, (draft) => {
      draft.push(newError);
    });
    setConfigTabs(newTabs);
    setErrors(newErrors);
    setExpressServiceState({ ...expressServiceState, selectedConfigTab: configTabs.length });
  };

  const handleDeleteZoneConfigClick = (tabIndexToBeDeleted, e) => {
    e.stopPropagation();
    setExpressServiceState({ ...expressServiceState, selectedConfigTab: tabIndexToBeDeleted, showDeleteZCModal: true });
  };

  const resetConfigTab = (tabIndexToBeReset) => {
    let defaultConfig = {
      ...DEFAULT_TAB_CONFIGURATIONS,
      foodTypes: "",
      slotsGeneratorForms: [{ days: ALL_DAYS_VALUES, generatedSlots: [{ ...DEFAULT_ON_DEMAND_SLOT }] }],
    };
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndexToBeReset] = { ...defaultConfig, zoneName: `Zone01` };
    });
    setConfigTabs(newTabs);
    setExpressServiceState({ ...expressServiceState, showDeleteZCModal: false, loading: false });
  };

  const removeZoneTabAfterDeletion = (tabIndexToBeDeleted) => {
    if (configTabs.length > 1) {
      let newTabs = produce(configTabs, (draft) => {
        draft.splice(tabIndexToBeDeleted, 1);
      });
      setExpressServiceState({ ...expressServiceState, selectedConfigTab: configTabs.length - 2, showDeleteZCModal: false, loading: false });
      setConfigTabs(newTabs);
      let newErrors = produce(errors, (draft) => {
        draft.splice(tabIndexToBeDeleted, 1);
      });
      setErrors(newErrors);
    } else {
      resetConfigTab(tabIndexToBeDeleted);
    }
  };

  const deleteConfigTab = async (tabIndexToBeDeleted) => {
    let zoneId = configTabs[tabIndexToBeDeleted].zoneId;
    if (!zoneId) {
      removeZoneTabAfterDeletion(tabIndexToBeDeleted);
    } else {
      setExpressServiceState({ ...expressServiceState, loading: true });
      const {
        payload: { data, message: deleteError },
      } = await dispatch(deleteZoneConfig({ serviceTypeName: OBJECT_SERVICE_TYPE.EXPRESS, zoneConfigId: configTabs[tabIndexToBeDeleted].zoneId, posNo: posNo }));
      if (data === true || (data !== undefined && data.success !== undefined && data.success === true)) {
        CustomAlert(ALERT_TYPES.SUCCESS, "Zone configuration deleted successfully!");
        removeZoneTabAfterDeletion(tabIndexToBeDeleted);
        if (!isEmptyOrNull(posNo) && !isEmptyOrNull(serviceTypeId) && !isEmptyOrNull(serviceTypeName)) {
          dispatch(fetchSlotsInformationByServiceType({ posNo, serviceType: serviceTypeId, serviceTypeName: OBJECT_SERVICE_TYPE.EXPRESS, servicePropositionList }));
        }
      } else {
        if (deleteError) {
          CustomAlert(ALERT_TYPES.ERROR, deleteError);
        } else {
          CustomAlert(ALERT_TYPES.ERROR, "Error occured");
        }
        setExpressServiceState({ ...expressServiceState, loading: false, showDeleteZCModal: false });
      }
    }
  };

  const handleDaysChange = (tabIndex, generatorFormIndex, value) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].days = value;
    });
    setConfigTabs(newTabs);
  };

  const handleAddOnDemandSlot = (tabIndex, generatorFormIndex) => {
    let newTabs = produce(configTabs, (draft) => {
      let newOnDemandSlot = { ...DEFAULT_ON_DEMAND_SLOT };
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.push(newOnDemandSlot);
    });
    setConfigTabs(newTabs);
  };

  const handleRemoveOnDemandSlot = (tabIndex, generatorFormIndex, slotIndex) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots.splice(slotIndex, 1);
    });
    setConfigTabs(newTabs);
  };

  const handleOnDemandServiceTimeChange = (tabIndex, generatorFormIndex, slotIndex, date, fieldName) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slotIndex][fieldName] = date;
      if (draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slotIndex].slotAction !== "ADD") {
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[slotIndex].slotAction = "UPDATE";
      }
    });
    setConfigTabs(newTabs);
  };

  const handleServiceCutOffAndShowSlotAtTime = (tabIndex, time, element) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex][element] = time;
    });
    setConfigTabs(newTabs);
  };

  const handleIndividualSlotFieldsChange = (tabIndex, generatorFormIndex, index, event) => {
    const { name, value } = event.target;
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index][name] = value;
      if (draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].slotAction !== "ADD") {
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index].slotAction = "UPDATE";
      }
    });
    setConfigTabs(newTabs);
  };

  const handleCustomOnDemandSlotFieldsChange = (tabIndex, generatorFormIndex, index, name, value) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index][name] = value;
    });
    setConfigTabs(newTabs);
  };

  const addNewSlot = (tabIndex, generatorFormIndex, day) => {
    let new_config_tabs = add_new_template_slot_util(configTabs, tabIndex, generatorFormIndex, day, posDetails);
    setConfigTabs(new_config_tabs);
  };

  const updateOnDemandSlot = async (tabIndex, generatorFormIndex, index, slot) => {
    setExpressServiceState({ ...expressServiceState, loading: true });
    let onDemandSlotPayload = { ...DEFAULT_ON_DEMAND_SLOT, ...slot, startTime: moment(slot.startTime).format(HH_MM_SS), endTime: moment(slot.endTime).format(HH_MM_SS) };
    const {
      payload: { message, data },
    } = await dispatch(updateTemplateSlotBySlotId({ serviceTypeName: OBJECT_SERVICE_TYPE.EXPRESS, slotData: onDemandSlotPayload }));
    if (message) {
      setExpressServiceState({ ...expressServiceState, loading: false });
      CustomAlert(ALERT_TYPES.ERROR, message);
    }
    if (data) {
      let newTabs = produce(configTabs, (draft) => {
        draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots[index] = {
          ...data,
          startTime: moment(data.startTime, HH_MM_SS),
          endTime: moment(data.endTime, HH_MM_SS),
          time: `${moment(data.startTime, HH_MM_SS).format("HH:mm")} - ${moment(data.endTime, HH_MM_SS).format("HH:mm")}`,
        };
      });
      setConfigTabs(newTabs);
      setExpressServiceState({ ...expressServiceState, loading: false });
    }
  };

  const handleSaveNewOnDemandSlot = async (tabIndex, generatorFormIndex, index, slot) => {
    let newTemplateSlotBody = {
      ...slot,
      posId: slot.posId,
      zoneId: slot.zoneId,
      day: slot.day,
      bufferTime: 0,
      startTime: moment(slot.startTime).format(HH_MM_SS),
      endTime: moment(slot.endTime).format(HH_MM_SS),
      serviceTypeId,
    };
    setExpressServiceState({ ...expressServiceState, loading: true });
    const { payload } = await dispatch(createNewTemplateSlot({ serviceTypeName: OBJECT_SERVICE_TYPE.EXPRESS, slotData: newTemplateSlotBody }));
    setExpressServiceState({ ...expressServiceState, loading: false });
    if (payload && payload.data) {
      if (!isEmptyOrNull(posNo) && !isEmptyOrNull(serviceTypeId) && !isEmptyOrNull(serviceTypeName)) {
        dispatch(fetchSlotsInformationByServiceType({ posNo, serviceType: serviceTypeId, serviceTypeName: OBJECT_SERVICE_TYPE.EXPRESS, servicePropositionList }));
      }
    } else if (payload && payload.message) {
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    }
  };

  const updateProductTypeConfigTabs = (configTabs, configTabIndex, e, checked) => {
    setConfigTabs(handleProductType(configTabs, configTabIndex, e, checked));
  };

  const updateFoodSubTypeConfigTabs = (configTabs, configTabIndex, e) => {
    setConfigTabs(handleFoodSubType(configTabs, configTabIndex, e));
  };

  // Approval Request
  //
  //
  const handleDateChangeEvent = (event, picker) => {
    setExpressServiceState({ ...expressServiceState, loading: true });
    let newServiceState = _handle_date_change_event_util(expressServiceState, event, picker, pendingApprovalRequestRecordListService, posNo, configTabs, serviceTypeName);
    setExpressServiceState(newServiceState);
  };

  const handleCancel = (event, picker) => {
    event.preventDefault();
    picker.element.val("");
    setExpressServiceState({ ...expressServiceState, activationDate: "", activationDatelabel: "", isDateValid: false, data_present_obj: {} });
  };

  const send_request_for_approval = (requesterComments) => {
    setExpressServiceState({ ...expressServiceState, loading: true, showApprovalRequestPopUpPreview: false });
    if (expressServiceState !== undefined && expressServiceState.activationDate !== undefined && expressServiceState.activationDate !== "") {
      const pay_load_object = _validate_send_for_approval_form(expressServiceState, configTabs, countryId, true, requesterComments);
      if (pay_load_object && typeof pay_load_object === "object" && Object.keys(pay_load_object).length !== 0) {
        call_send_for_approval(pay_load_object);
      } else {
        setExpressServiceState({ ...expressServiceState, loading: false });
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, "Please provide implementation date.");
      setExpressServiceState({ ...expressServiceState, loading: false });
    }
  };

  const preview_request_for_approval = () => {
    if (expressServiceState !== undefined && expressServiceState.activationDate !== undefined && expressServiceState.activationDate !== "") {
      const pay_load_object = _validate_send_for_approval_form(expressServiceState, configTabs, countryId, true, "");
      if (pay_load_object && typeof pay_load_object === "object" && Object.keys(pay_load_object).length !== 0) {
        setExpressServiceState({ ...expressServiceState, loading: true, showApprovalRequestPopUpPreview: true });
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, "Please provide implementation date.");
      setExpressServiceState({ ...expressServiceState, loading: false });
    }
  };

  const close_preview_request_for_approval = () => {
    setExpressServiceState({ ...expressServiceState, loading: false, showApprovalRequestPopUpPreview: false });
  };

  const isAddOtherDaysButtonDisabled = (tabIndex) => {
    let isButtonDisabled = false;
    let daysSelectedSoFar = configTabs[tabIndex].slotsGeneratorForms.reduce((days, value) => [...days, ...value.days], []);
    let remainingDays = ALL_DAYS_VALUES.filter((day) => !daysSelectedSoFar.includes(day));
    if (remainingDays.length === 0) isButtonDisabled = true;
    return isButtonDisabled;
  };

  const addOtherDays = (tabIndex, e) => {
    e.stopPropagation();
    let newForm = { ...DEFAULT_ON_DEMAND_SLOT_GENERATOR_FORM };
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms.push(newForm);
    });
    setConfigTabs(newTabs);
  };

  const deleteConfigureSlotsOption = (tabIndex, slotGenertorFormIndex) => {
    let newTabs = produce(configTabs, (draft) => {
      draft[tabIndex].slotsGeneratorForms.splice(slotGenertorFormIndex, 1);
    });
    setConfigTabs(newTabs);
  };

  // Bulk Update
  //
  const handleBulkUpdateAction = (configTabIndex, generatorFormIndex) => {
    if (configTabs !== undefined && Array.isArray(configTabs) && configTabs.length > 0) {
      if (configTabIndex !== undefined && configTabIndex >= 0 && configTabs[configTabIndex] !== undefined && configTabs[configTabIndex].slotsGeneratorForms !== undefined) {
        const is_all_slots_valid = validate_generator_form_before_bulk_update(configTabs[configTabIndex]);
        if (is_all_slots_valid !== undefined && is_all_slots_valid === true) {
          const { slotsGeneratorForms } = configTabs[configTabIndex];
          if (
            slotsGeneratorForms !== undefined &&
            Array.isArray(slotsGeneratorForms) &&
            slotsGeneratorForms.length > 0 &&
            generatorFormIndex !== undefined &&
            generatorFormIndex !== "" &&
            generatorFormIndex >= 0 &&
            slotsGeneratorForms[generatorFormIndex] !== undefined &&
            slotsGeneratorForms[generatorFormIndex].generatedSlots !== undefined
          ) {
            const is_slot_obj_valid = check_if_slot_obj_contains_sub_set(slotsGeneratorForms[generatorFormIndex].generatedSlots);
            if (is_slot_obj_valid !== undefined && typeof is_slot_obj_valid === "object" && Object.keys(is_slot_obj_valid).length > 0) {
              CustomAlert(ALERT_TYPES.ERROR, is_slot_obj_valid[Object.keys(is_slot_obj_valid)[0]]);
              return;
            } else {
              let allDays = [];
              const currentDay = slotsGeneratorForms[generatorFormIndex]?.days[0];
              slotsGeneratorForms.forEach((slot) => {
                if (slot !== undefined && slot.days !== undefined && Array.isArray(slot.days) && slot.days.length > 0) {
                  if (slot.days[0] !== undefined && slot.days[0] !== "" && slot.days[0] !== currentDay) {
                    allDays = [...allDays, ...slot.days];
                  }
                }
              });
              let newExpressServiceState = _handle_open_bulk_update_confimation_pop_up_utils(expressServiceState, slotsGeneratorForms, configTabIndex, generatorFormIndex, currentDay, allDays);
              setExpressServiceState(newExpressServiceState);
            }
          }
        }
      }
    }
  };

  const handleCloseBulkUpdateConfimationPopUp = () => {
    let newExpressServiceState = _handle_close_bulk_update_confimation_pop_up_utils(expressServiceState);
    setExpressServiceState(newExpressServiceState);
  };

  const handleDaySelectedForBulkUpdate = (dayValue) => {
    if (dayValue !== undefined && dayValue !== "") {
      let newExpressServiceState = _handle_day_delected_for_bulk_update_utils(expressServiceState, expressServiceState.selctedDays, expressServiceState.configuredDays, dayValue);
      setExpressServiceState(newExpressServiceState);
    }
  };

  const handleBulkUpdateConfirmAction = () => {
    if (expressServiceState !== undefined && expressServiceState.selctedDays !== undefined && Array.isArray(expressServiceState.selctedDays) && expressServiceState.selctedDays.length > 0) {
      let newExpressServiceState = _handle_clear_bulk_update_confimation_pop_up_utils(expressServiceState, expressServiceState.selctedDays);
      if (configTabs !== undefined && Array.isArray(configTabs) && configTabs.length > 0) {
        if (
          expressServiceState.configTabIndex !== undefined &&
          expressServiceState.configTabIndex >= 0 &&
          configTabs[expressServiceState.configTabIndex] !== undefined &&
          configTabs[expressServiceState.configTabIndex].slotsGeneratorForms !== undefined
        ) {
          const { slotsGeneratorForms } = configTabs[expressServiceState.configTabIndex];
          if (
            slotsGeneratorForms !== undefined &&
            Array.isArray(slotsGeneratorForms) &&
            slotsGeneratorForms.length > 0 &&
            expressServiceState.generatorFormIndex !== undefined &&
            expressServiceState.generatorFormIndex >= 0 &&
            slotsGeneratorForms[expressServiceState.generatorFormIndex] !== undefined
          ) {
            const slotForm = JSON.parse(JSON.stringify(expressServiceState.currentBulkUpdateConfigurationSummary));
            if (slotForm !== undefined && slotForm.generatedSlots !== undefined && Array.isArray(slotForm.generatedSlots) && slotForm.generatedSlots.length > 0) {
              let newTabs = produce(configTabs, (draft) => {
                draft[expressServiceState.configTabIndex].slotsGeneratorForms.forEach((slot, slotIndex) => {
                  if (
                    slot !== undefined &&
                    slot.days !== undefined &&
                    Array.isArray(slot.days) &&
                    slot.days.length > 0 &&
                    slot.days[0] !== undefined &&
                    slot.days[0] !== "" &&
                    expressServiceState.currentDay !== undefined &&
                    expressServiceState.currentDay !== "" &&
                    slot.days[0] !== expressServiceState.currentDay &&
                    expressServiceState.selctedDays.indexOf(slot.days[0]) > -1
                  ) {
                    let slot_form_copy = JSON.parse(JSON.stringify(slotForm));
                    slot_form_copy.generatedSlots.forEach((data) => {
                      data.day = slot.days[0];
                      data.id = "";
                      data.slotAction = "ADD";
                    });
                    draft[expressServiceState.configTabIndex].slotsGeneratorForms[slotIndex].generatedSlots = [];
                    draft[expressServiceState.configTabIndex].slotsGeneratorForms[slotIndex].generatedSlots = [...slot_form_copy.generatedSlots];
                  }
                });
              });
              setConfigTabs(newTabs);
              setExpressServiceState(newExpressServiceState);
            }
          }
        }
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, TEXT_CONSTANT.BULK_UPDATE_NO_DAYS_SELECTED);
    }
  };

  const resetCurrentConfigurationAfterBulkUpdateRevert = (configTabIndex, generatorFormIndex, day) => {
    if (day !== undefined && day !== "" && configTabIndex !== "" && configTabIndex >= 0 && generatorFormIndex !== undefined && generatorFormIndex >= 0) {
      let new_config_tab = reset_bulk_update_configuration_for_given_day(configTabs, configTabIndex, generatorFormIndex, day, expressServiceState);
      setConfigTabs(new_config_tab);
      let newServiceState = reset_bulk_update_config_day(expressServiceState, day);
      setExpressServiceState(newServiceState);
    }
  };

  const saveZoneConfigurations = async ({ quotaSetting, ...configs }) => {
    setExpressServiceState({ ...expressServiceState, loading: true });
    const { payload } = await dispatch(createTemplateSlots({ posNo, zoneConfigurations: configs, service: OBJECT_SERVICE_TYPE.EXPRESS, zoneConfigIndex: expressServiceState.selectedConfigTab, servicePropositionList }));
    if (payload && payload.message) {
      setExpressServiceState({ ...expressServiceState, loading: false });
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    } else if (payload && payload.zoneConfig && payload.zoneConfig.id) {
      CustomAlert(ALERT_TYPES.SUCCESS, "Zone Configurations saved successfully");
      fetchSlots();
    }
  };

  const updateZoneConfigurations = async ({ quotaSetting, ...configs }) => {
    const requestObject = { posNo, zoneConfigurations: configs, service: OBJECT_SERVICE_TYPE.EXPRESS, zoneConfigIndex: expressServiceState.selectedConfigTab, servicePropositionList };
    if (
      expressServiceState !== undefined &&
      expressServiceState.configurationBulkUpdatedObj !== undefined &&
      expressServiceState.configurationBulkUpdatedObj.bulkUpdatedDays !== undefined &&
      Array.isArray(expressServiceState.configurationBulkUpdatedObj.bulkUpdatedDays) &&
      expressServiceState.configurationBulkUpdatedObj.bulkUpdatedDays.length > 0
    ) {
      requestObject.zoneConfigurations = {
        ...requestObject.zoneConfigurations,
        updatedDays: [...expressServiceState.configurationBulkUpdatedObj.bulkUpdatedDays],
      };
    }
    setExpressServiceState({ ...expressServiceState, loading: true, configurationBulkUpdatedObj: { isConfigurationBulkUpdated: false, bulkUpdatedDays: [] } });
    const { payload } = await dispatch(updateTemplateSlots(requestObject));
    if (payload && payload.message) {
      setExpressServiceState({ ...expressServiceState, loading: false, configurationBulkUpdatedObj: { isConfigurationBulkUpdated: false, bulkUpdatedDays: [] } });
      CustomAlert(ALERT_TYPES.ERROR, payload.message);
    } else if (payload && payload.zoneConfig && payload.zoneConfig.id) {
      CustomAlert(ALERT_TYPES.SUCCESS, "Zone Configurations updated successfully");
      fetchSlots();
    }
  };

  const handleSaveOrUpdateZoneConfigurationsSubmit = (configTabIndex = expressServiceState.selectedConfigTab, isUpdate = false) => {
    const { isError, currentTabErrors } = validateExpressZoneConfig(configTabs, expressServiceState.selectedConfigTab, errors);
    setErrors(currentTabErrors);
    if (!isError) {
      let tab = configTabs[configTabIndex];
      let configurationsPayload = createServiceConfigPayload(tab, serviceTypeId, posDetails);
      if (isUpdate) {
        updateZoneConfigurations(configurationsPayload);
      } else {
        saveZoneConfigurations(configurationsPayload);
      }
    }
  };

  const makeSlotEditable = (tabIndex, generatorFormIndex, index, slot) => {
    if (!slot || !slot.id) return;
    if (tabIndex !== undefined && tabIndex >= 0 && generatorFormIndex !== undefined && generatorFormIndex >= 0 && index !== undefined && index >= 0) {
      let new_config_tabs = update_make_slot_editable(configTabs, tabIndex, generatorFormIndex, index);
      setConfigTabs(new_config_tabs);
    }
  };

  const handleDeleteTemplateSlot = async (tabIndex, generatorFormIndex, slotIndex, slot) => {
    if (is_user_creating_approval_request(userInfo)) {
      let new_config_tabs = delete_template_slot_util(configTabs, tabIndex, generatorFormIndex, slotIndex);
      setConfigTabs(new_config_tabs);
    } else {
      if (!slot || !slot.id) {
        let newTabs = produce(configTabs, (draft) => {
          let allSlots = draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots;
          let filteredSlots = allSlots.filter((item) => !!item.id);
          draft[tabIndex].slotsGeneratorForms[generatorFormIndex].generatedSlots = [...filteredSlots];
        });
        setConfigTabs(newTabs);
        return;
      } else {
        setExpressServiceState({ ...expressServiceState, loading: true });
        const payload = await dispatch(deleteATemplateSlot({ serviceTypeName: OBJECT_SERVICE_TYPE.EXPRESS, slotData: slot }));
        let deletedSlot = unwrapResult(payload);
        if (deletedSlot && deletedSlot.data && deletedSlot.data.id) {
          fetchSlots();
        } else {
          setExpressServiceState({ ...expressServiceState, loading: false });
        }
      }
    }
  };

  const cancelEditingASlot = (tabIndex, generatorFormIndex, index, slot) => {
    if (!slot || !slot.id) return;
    let newTabs = cancel_editing_a_slot_util(configTabs, tabIndex, generatorFormIndex, index, slot, express);
    setConfigTabs(newTabs);
  };

  /**
   * Enabling users to define time limit for busy periods
   */

  const handleChangeConfigurationStateSwitch = (configTabs, configTabIndex, event) => {
    setConfigTabs(handleConfigurationStateSwitch(configTabs, configTabIndex, event));
  };

  const handleZoneStateSwitch = (configTabs, configTabIndex, event) => {
    setConfigTabs(handleZoneStateSwitchUtils(configTabs, configTabIndex, event));
  };

  const onBusyTimeFieldsChange = (configTabs, configTabIndex, event, name) => {
    setConfigTabs(handleBusyTimeFieldsChange(configTabs, configTabIndex, event, name));
  };

  /**
   * Components
   */
  const renderOnDemandSlotsGeneratorForm = (configTab, configTabIndex, generatorFormIndex) => {
    let remainingDays = calculateAvailableDaysForSelection(generatorFormIndex, configTab);
    const { days, generatedSlots } = configTabs[configTabIndex].slotsGeneratorForms[generatorFormIndex];
    const isEditMode = !!configTab.zoneId;
    const dayWiseSlots = isEditMode ? express && express.length && express[configTabIndex] && express[configTabIndex].dayWiseSlots && express[configTabIndex].dayWiseSlots[days[0]] : null;

    return (
      <div className={classes.slotsGeneratorFormContainer}>
        {generatorFormIndex !== 0 && !isEditMode ? <RemoveButton className={classes.deleteSlotsGeneratorForm} onClick={() => deleteConfigureSlotsOption(configTabIndex, generatorFormIndex)} /> : null}
        {isEditMode ? (
          <EditOnDemandSlots
            tabIndex={configTabIndex}
            formIndex={generatorFormIndex}
            days={days}
            generatedSlots={generatedSlots}
            handleOnDemandServiceTimeChange={handleOnDemandServiceTimeChange} // Slot Change Function
            handleIndividualSlotFieldsChange={handleIndividualSlotFieldsChange} // Slot Change Function
            handleCustomOnDemandSlotFieldsChange={handleCustomOnDemandSlotFieldsChange} // Slot Change Function
            addNewOnDemandSlot={addNewSlot}
            updateOnDemandSlot={updateOnDemandSlot}
            handleSaveNewOnDemandSlot={handleSaveNewOnDemandSlot}
            loading={expressServiceState.loading}
            configurationBulkUpdatedObj={expressServiceState.configurationBulkUpdatedObj}
            handleBulkUpdateAction={() => handleBulkUpdateAction(configTabIndex, generatorFormIndex)}
            resetCurrentConfigurationAfterBulkUpdateRevert={(day) => resetCurrentConfigurationAfterBulkUpdateRevert(configTabIndex, generatorFormIndex, day)}
            userInfo={userInfo}
            makeSlotEditable={(slotIndex, slot) => makeSlotEditable(configTabIndex, generatorFormIndex, slotIndex, slot)}
            deleteTemplateSlot={(slotIndex, slot) => handleDeleteTemplateSlot(configTabIndex, generatorFormIndex, slotIndex, slot)}
            dayWiseSlots={dayWiseSlots}
            cancelEditingASlot={(slotIndex, slot) => cancelEditingASlot(configTabIndex, generatorFormIndex, slotIndex, slot)}
          />
        ) : (
          <CreateOnDemandSlots
            tabIndex={configTabIndex}
            formIndex={generatorFormIndex}
            days={days}
            remainingDays={remainingDays}
            generatedSlots={generatedSlots}
            handleDaysChange={handleDaysChange}
            handleAddOnDemandSlot={handleAddOnDemandSlot}
            handleRemoveOnDemandSlot={handleRemoveOnDemandSlot}
            handleIndividualSlotFieldsChange={handleIndividualSlotFieldsChange}
            handleOnDemandServiceTimeChange={handleOnDemandServiceTimeChange}
          />
        )}
      </div>
    );
  };

  const renderConfigureSlotsAccordion = (configTab, configTabIndex) => {
    return (
      <RenderConfigureSlotsAccordion
        classes={classes}
        userInfo={userInfo}
        configTab={configTab}
        configTabIndex={configTabIndex}
        isAddOtherDaysButtonDisabled={isAddOtherDaysButtonDisabled}
        addOtherDays={addOtherDays}
        isExpressTypeOnDemand={isExpressTypeOnDemand}
        renderOnDemandSlotsGeneratorForm={renderOnDemandSlotsGeneratorForm}
      />
    );
  };

  useEffect(() => {
    setExpressServiceState({
      ...expressServiceState,
      request_already_presnt_by_admin_error: is_request_already_presnt_by_admin_util(configTabs, expressServiceState, pendingApprovalRequestRecordListService, posDetails),
      loading: false,
    });
  }, [expressServiceState.selectedConfigTab, pendingApprovalRequestRecordListService, pendingApprovalRequestRecordListService.fetchStatus]);

  useEffect(() => {
    if (posNo && !posDetails.posNo && servicePropositionList?.length) {
      dispatch(fetchPOSDetailsByPOSNo(posNo));
    }
  }, [dispatch, posNo, serviceTypeId, serviceTypeName, posDetails.posNo, servicePropositionList?.length]);

  useEffect(() => {
    if (posNo && serviceTypeId) {
      fetchSlots();
    }
  }, [dispatch, posNo, serviceTypeId, serviceTypeName]);

  useEffect(() => {
    if (express && express.length && !expressServiceState.loading) {
      transformConfigForUI(express);
      dispatch(fetchPendingApprovalRequest({ countryId: countryId, status: ["PENDING"] }));
    }
  }, [express, fetchingSlots]);

  useEffect(() => {
    return () => {
      dispatch(resetSlotsInformation());
    };
  }, [dispatch]);

  return (
    <>
      <OverlayLoader loading={fetchingDetails || fetchingSlots || expressServiceState.loading} />
      <PageTitle title={`POS - ${posNo}`} />

      {is_user_creating_updating_pos(userInfo) && (
        <>
          <BasicServiceDetails
            serviceTypeId={serviceTypeId}
            serviceTypeName={serviceTypeName}
            expressType={EXPRESS_TYPES[expressType].label}
            country={posDetails && posDetails.posAddress && posDetails.posAddress.country}
            region={posDetails && posDetails.posAddress && posDetails.posAddress.region}
            servicePropositionList={servicePropositionList}
            displayName={displayName}
          />
          {expressServiceState !== undefined && expressServiceState.request_already_presnt_by_admin_error !== undefined && expressServiceState.request_already_presnt_by_admin_error !== "" && (
            <Card className={classes.errorCardStyle}>
              <CardContent>
                <Typography className={classes.errorTextSpanStyle}>{`${expressServiceState.request_already_presnt_by_admin_error}`}</Typography>
              </CardContent>
            </Card>
          )}
        </>
      )}

      <RenderZoneConfigurationsCard
        classes={classes}
        serviceTypeName={serviceTypeName}
        userInfo={userInfo}
        service={express}
        serviceState={expressServiceState}
        handleTabChange={handleTabChange}
        configTabs={configTabs}
        handleDeleteZoneConfigClick={handleDeleteZoneConfigClick}
        addConfigTabs={addConfigTabs}
        isPosTypePharmacy={false}
        errors={errors}
        updateProductTypeConfigTabs={updateProductTypeConfigTabs}
        updateFoodSubTypeConfigTabs={updateFoodSubTypeConfigTabs}
        handleDateChangeEvent={handleDateChangeEvent}
        handleCancel={handleCancel}
        handleSaveOrUpdateZoneConfigurationsSubmit={handleSaveOrUpdateZoneConfigurationsSubmit}
        preview_request_for_approval={preview_request_for_approval}
        // access
        is_product_type_visible={is_product_type_visible}
        is_user_creating_approval_request={is_user_creating_approval_request}
        is_zone_add_allowed={is_zone_add_allowed}
        is_zone_delete_allowed={is_zone_delete_allowed}
        // component
        renderConfigureSlotsAccordion={renderConfigureSlotsAccordion}
        handleServiceCutOffAndShowSlotAtTime={handleServiceCutOffAndShowSlotAtTime}
        // Enabling users to define time limit for busy periods
        handleChangeConfigurationStateSwitch={handleChangeConfigurationStateSwitch}
        handleZoneStateSwitch={handleZoneStateSwitch}
        onBusyTimeFieldsChange={onBusyTimeFieldsChange}
        //
        servicePropositionList={servicePropositionList}
      />

      <Grid container direction="row" className={classes.footerButtons}>
        <Grid item xs={6}>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item className={classes.footerButtonsStyle}>
              <Link to={`${APP_ROUTES.POS_LIST}`} className={classes.linkstyle}>
                <PrimaryButton buttonLabel={`${BUTTON_TEXT_CONSTANT.BACK_TO_POS_LIST}`} buttonVariant="outlined" disabled={false} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item className={classes.footerButtonsStyle}>
              <PrimaryButton buttonLabel={`${BUTTON_TEXT_CONSTANT.BACK_TO_POS}`} buttonVariant="outlined" disabled={!express.length} onClick={() => history.push(`${APP_ROUTES.COMPLETE_SETUP}/${posDetails.posNo}`)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <>
        {expressServiceState !== undefined && expressServiceState.showDeleteZCModal !== undefined && expressServiceState.showDeleteZCModal && (
          <ConfirmModal
            show={expressServiceState.showDeleteZCModal}
            title="Delete Zone Config"
            description="All the data for the zone configuration would be permanently lost. Do you wish to proceed?"
            confirmButtonText="Approve"
            isLoading={isDeleting}
            onClose={() => setExpressServiceState({ ...expressServiceState, showDeleteZCModal: false })}
            onProceed={() => deleteConfigTab(expressServiceState.selectedConfigTab)}
          />
        )}

        {expressServiceState !== undefined && expressServiceState.openBulkUpdateConfirmationPopUp !== undefined && expressServiceState.openBulkUpdateConfirmationPopUp && (
          <BulkUpdateExpressPopUp
            popUpOpenFlag={expressServiceState.openBulkUpdateConfirmationPopUp}
            currentConfiguration={expressServiceState.currentBulkUpdateConfigurationSummary}
            configuredDays={expressServiceState.configuredDays}
            selctedDays={expressServiceState.selctedDays}
            currentDay={expressServiceState.currentDay}
            handleClose={handleCloseBulkUpdateConfimationPopUp}
            handleDaySelectedForBulkUpdate={handleDaySelectedForBulkUpdate}
            handleBulkUpdateConfirmAction={handleBulkUpdateConfirmAction}
          />
        )}

        {expressServiceState !== undefined && expressServiceState.showZoneChangeWarningPopUp !== undefined && expressServiceState.showZoneChangeWarningPopUp && (
          <ConfirmModal
            show={expressServiceState.showZoneChangeWarningPopUp}
            title="Change Zone"
            description="All the data for the zone configuration would be permanently lost. Do you wish to proceed?"
            confirmButtonText="Change"
            isLoading={false}
            onClose={() => closeSwitchZonePopUpWarningPopUp()}
            onProceed={() => updateSelectedTabIndex(expressServiceState.temporarySelectedZone)}
          />
        )}

        {expressServiceState !== undefined && expressServiceState.showApprovalRequestPopUpPreview !== undefined && expressServiceState.showApprovalRequestPopUpPreview && (
          <PreviewApprovalRequestPopUp
            popUpOpenFlag={expressServiceState.showApprovalRequestPopUpPreview}
            handleClose={close_preview_request_for_approval}
            originalConfigTab={expressServiceState.originalConfigTab}
            selectedConfigTab={expressServiceState.selectedConfigTab}
            configTabs={configTabs}
            send_request_for_approval={send_request_for_approval}
            serviceTypeName={serviceTypeName ? serviceTypeName.toUpperCase() : "EXPRESS"}
            servicePropositionList={servicePropositionList}
          />
        )}
      </>
    </>
  );
};

export default Express;
