import React from "react";
import { Link } from "react-router-dom";

import { TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";

import Pencil from "../../assets/svg/Pencil.svg";
import EyeIconDetails from "../../assets/svg/EyeIconDetails.svg";

import { APP_ROUTES, USER_TYPES, ZONE_CHANGE_STATUS } from "../../../config/constants";
import { userStyles } from "../Styles/DeliveryFeePageStyles";
import { TABLE_BODY_NO_DATA_AVAILABLE } from "../Utils/DeliveryFeeConstants";
import { ServiceFeeTooltipViewUtil, SlotCapacityTooltipViewUtil, SlotProximityFeeTooltipViewUtil, ThresholdRangeTooltipViewUtil } from "../Configuration/utils/DeliveryFeeConfigViewUtils";
import { isArrayValid, isNonEmptyArray, isUndefined } from "../../../utils/DataUtils";
import { displaySlotCapacityText, isShipmentTypeSlotBased, rightBorderStyle } from "../Utils/DeliveryFeeUtils";

interface DeliveryFeeCardBodyInterface {
  deliveryFeeListData: any;
  deliveryFeeHeaderData: any;
  userInfo: any;
}
const DeliveryFeeCardBody = (props: DeliveryFeeCardBodyInterface) => {
  const classes = userStyles();
  const { deliveryFeeListData, deliveryFeeHeaderData, userInfo } = props;

  const isListDataPresent = () => {
    return deliveryFeeListData?.length > 0;
  };

  const getColSpan = () => {
    return deliveryFeeHeaderData?.headerRowsValues?.length > 0 ? 4 + deliveryFeeHeaderData?.headerRowsValues?.length * 3 : 4;
  };

  const isHeaderRowsValuesResent = () => {
    return deliveryFeeHeaderData?.headerRowsValues?.length > 0;
  };

  const isDeliveryFeeEditEnabled = () => {
    return userInfo?.userProfile?.role && (userInfo?.userProfile?.role === USER_TYPES.MAF_ADMIN || userInfo?.userProfile?.role === USER_TYPES.SUPER_ADMIN);
  };

  const isStatusActivated = (fee: any, headerType: any) => {
    return fee?.shipmentLevelDeliveryFeeConfigs[headerType?.shipmentId]?.status === ZONE_CHANGE_STATUS.ACTIVATED;
  };

  const isDeliveryFeeRange = (fee: any, headerType: any) => {
    return (
      !isUndefined(fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].deliveryFeeRangeConfigDTOList) &&
      isNonEmptyArray(fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].deliveryFeeRangeConfigDTOList) &&
      fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].deliveryFeeRangeConfigDTOList.length > 0
    );
  };

  const renderPosDetails = (fee: any) => {
    return (
      <>
        <TableCell className={`${classes.cellDataStickyStyle} ${classes.cellHeaderPosNoStyle}`}>{fee?.id || "-"}</TableCell>
        <TableCell className={`${classes.cellDataStickyStyle} ${classes.cellHeaderPosNameStyle}`}>{fee?.storeName || "-"}</TableCell>
        <TableCell className={`${classes.cellDataStickyStyle} ${classes.cellHeaderZoneNameStyle}`}>{fee?.zone || "-"}</TableCell>
      </>
    );
  };

  const renderTableColumnValue = (value: string | number) => {
    return <Typography className={classes.thresholdValueTextStyle}>{value}</Typography>;
  };

  const renderTableColumnClickableValue = (value: string | number) => {
    return <Typography className={classes.rangeConfiguredTextStyle}>{value}</Typography>;
  };

  const renderEyeIconDetails = () => {
    return <img src={EyeIconDetails} alt="EyeIconDetails" className={classes.actionIconStyle} />;
  };

  const renderPencilIcon = () => {
    return <img src={Pencil} alt="Edit" className={classes.actionIconStyle} />;
  };

  const renderDeliveryRangeComponent = (fee: any, headerType: any) => {
    return (
      <Tooltip
        classes={{ tooltip: classes.thresholdRangeTooltip }}
        title={
          <ThresholdRangeTooltipViewUtil
            classes={classes}
            orderThreshold={fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].orderThreshold}
            aboveThreshold={fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].aboveThreshold}
            belowThreshold={fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].belowThreshold}
            deliveryFeeRangeConfigDTOList={fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].deliveryFeeRangeConfigDTOList}
          />
        }
      >
        {renderTableColumnClickableValue(`Range`)}
      </Tooltip>
    );
  };

  const renderTableRowAction = (fee: any) => {
    return (
      <div className={classes.actionDiv}>
        {isDeliveryFeeEditEnabled() && (
          <Link to={{ pathname: `${APP_ROUTES.DELIVERY_FEE_CONFIGURATION}`, search: `?posNo=${fee.id}&zoneName=${fee.zone}` }} style={{ margin: "auto" }}>
            {renderPencilIcon()}
          </Link>
        )}
        <Link to={{ pathname: `${APP_ROUTES.DELIVERY_FEE_PREVIEW_CONFIGURATION}`, search: `?posNo=${fee.id}&zoneName=${fee.zone}` }} style={{ margin: "auto" }}>
          {renderEyeIconDetails()}
        </Link>
      </div>
    );
  };

  const renderEmptyDeliveryFeeTableCell = (key: string) => {
    return (
      <TableCell align="center" className={classes.cellDataStyle} key={key}>
        {renderTableColumnValue("-")}
      </TableCell>
    );
  };

  const renderEmptyDeliveryFee = (fee: any, headerTypeIndex: number, headerType: any) => {
    return (
      <>
        {renderEmptyDeliveryFeeTableCell(`1-${headerTypeIndex}-${headerTypeIndex}-status-headerRowsValues-${fee.id}`)}
        {renderEmptyDeliveryFeeTableCell(`2-${headerTypeIndex}-${headerTypeIndex}-threshold-headerRowsValues-${fee.id}`)}
        {renderEmptyDeliveryFeeTableCell(`3-${headerTypeIndex}-${headerTypeIndex}-above-threshold-headerRowsValues-${fee.id}`)}
        {renderEmptyDeliveryFeeTableCell(`4-${headerTypeIndex}-${headerTypeIndex}-below-threshold-headerRowsValues-${fee.id}`)}
        {renderEmptyDeliveryFeeTableCell(`5-${headerTypeIndex}-${headerTypeIndex}-service-fee-headerRowsValues-${fee.id}`)}
        {isShipmentTypeSlotBased(headerType?.deliveryType) && (
          <>
            <TableCell align="center" className={classes.cellDataStyle} key={`7-${headerTypeIndex}-${headerTypeIndex}-service-fee-headerRowsValues-${fee.id}`}>
              {displaySlotCapacityText(headerType?.deliveryType)}
            </TableCell>
            <TableCell align="center" className={classes.cellDataStyle} key={`8-${headerTypeIndex}-${headerTypeIndex}-slot-Proximity-Fee-headerRowsValues-${fee.id}`}>
              {displaySlotCapacityText(headerType?.deliveryType)}
            </TableCell>
          </>
        )}
        <TableCell align="center" className={classes.cellDataStyle} key={`6-${headerTypeIndex}-${headerTypeIndex}-minimumOrderValue-threshold-headerRowsValues-${fee.id}`} style={rightBorderStyle}>
          {"-"}
        </TableCell>
      </>
    );
  };

  const renderPreferedSlotFee = (fee: any, headerType: any, headerTypeIndex: number) => {
    return (
      <TableCell align="center" className={classes.cellDataStyle} key={`${headerTypeIndex}-SlotCapacity-threshold-headerRowsValues-${fee.id}`}>
        {isArrayValid(fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].deliveryFeeSlotCapacityConfigDTOList) ? (
          <Tooltip
            classes={{ tooltip: classes.thresholdRangeTooltip }}
            title={<SlotCapacityTooltipViewUtil classes={classes} deliveryFeeSlotCapacityConfigDTOList={fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].deliveryFeeSlotCapacityConfigDTOList} />}
          >
            {renderTableColumnClickableValue(`Active`)}
          </Tooltip>
        ) : (
          displaySlotCapacityText(headerType.deliveryType)
        )}
      </TableCell>
    );
  };

  const renderSlotProximityFee = (fee: any, headerType: any, headerTypeIndex: number) => {
    return (
      <TableCell align="center" className={classes.cellDataStyle} key={`${headerTypeIndex}-slotProximityFee-slotTime-headerRowsValues-${fee.id}`}>
        {isArrayValid(fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].slotProximityFeeConfigDTOList) ? (
          <Tooltip
            classes={{ tooltip: classes.thresholdRangeTooltip }}
            title={<SlotProximityFeeTooltipViewUtil classes={classes} slotProximityFeeConfigDTOList={fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].slotProximityFeeConfigDTOList} />}
          >
            {renderTableColumnClickableValue(`Active`)}
          </Tooltip>
        ) : (
          displaySlotCapacityText(headerType.deliveryType)
        )}
      </TableCell>
    );
  };

  const renderServiceFee = (fee: any, headerType: any, headerTypeIndex: number) => {
    return (
      <>
        {fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].serviceFee !== undefined && fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].serviceFee.isDynamic !== undefined ? (
          fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].serviceFee.isDynamic ? (
            <Tooltip classes={{ tooltip: classes.thresholdRangeTooltip }} title={<ServiceFeeTooltipViewUtil classes={classes} dynamicFeeRange={fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].serviceFee.dynamicFees} />}>
              <Typography className={classes.rangeConfiguredTextStyle}>{`Dynamic`}</Typography>
            </Tooltip>
          ) : fee?.shipmentLevelDeliveryFeeConfigs[headerType?.shipmentId]?.serviceFee?.staticFees !== undefined ? (
            renderTableColumnValue(fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].serviceFee?.staticFees)
          ) : (
            "-"
          )
        ) : (
          "-"
        )}
      </>
    );
  };

  return (
    <TableBody>
      {isListDataPresent() ? (
        deliveryFeeListData.map((fee: any, index: any) => (
          <TableRow key={`DeliveryFeeDataRecord-${index}`}>
            {renderPosDetails(fee)}
            {isHeaderRowsValuesResent() &&
              deliveryFeeHeaderData.headerRowsValues.map((headerType: any, headerTypeIndex: any) =>
                fee?.shipmentLevelDeliveryFeeConfigs && fee?.shipmentLevelDeliveryFeeConfigs[headerType?.shipmentId] ? (
                  <>
                    <TableCell align="center" className={classes.cellDataStyle} key={`${headerTypeIndex}-status-headerRowsValues-${fee.id}`}>
                      {renderTableColumnValue(isStatusActivated(fee, headerType) ? "Active" : "In-active")}
                    </TableCell>
                    <TableCell align="center" className={classes.cellDataStyle} key={`${headerTypeIndex}-threshold-headerRowsValues-${fee.id}`}>
                      {isDeliveryFeeRange(fee, headerType) ? renderDeliveryRangeComponent(fee, headerType) : renderTableColumnValue(fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].orderThreshold)}
                    </TableCell>
                    <TableCell align="center" className={classes.cellDataStyle} key={`${headerTypeIndex}-above-threshold-headerRowsValues-${fee.id}`}>
                      {renderTableColumnValue(isDeliveryFeeRange(fee, headerType) ? "" : fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].aboveThreshold)}
                    </TableCell>
                    <TableCell align="center" className={classes.cellDataStyle} key={`${headerTypeIndex}-below-threshold-headerRowsValues-${fee.id}`}>
                      {renderTableColumnValue(isDeliveryFeeRange(fee, headerType) ? "" : fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].belowThreshold)}
                    </TableCell>
                    <TableCell align="center" className={classes.cellDataStyle} key={`${headerTypeIndex}-service-fee-headerRowsValues-${fee.id}`}>
                      {renderServiceFee(fee, headerType, headerTypeIndex)}
                    </TableCell>
                    {isShipmentTypeSlotBased(headerType.deliveryType) && renderPreferedSlotFee(fee, headerType, headerTypeIndex)}
                    {isShipmentTypeSlotBased(headerType.deliveryType) && renderSlotProximityFee(fee, headerType, headerTypeIndex)}
                    <TableCell align="center" className={classes.cellDataStyle} key={`${headerTypeIndex}-minimumOrderValue-threshold-headerRowsValues-${fee.id}`} style={rightBorderStyle}>
                      {fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId]?.minimumOrderValue?.movThreshold !== undefined ? fee.shipmentLevelDeliveryFeeConfigs[headerType.shipmentId].minimumOrderValue.movThreshold : "-"}
                    </TableCell>
                  </>
                ) : (
                  renderEmptyDeliveryFee(fee, headerTypeIndex, headerType)
                )
              )}
            <TableCell className={classes.cellActionStyle}> {renderTableRowAction(fee)} </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell rowSpan={1} colSpan={getColSpan()} className={classes.noDataRowStyle}>{`${TABLE_BODY_NO_DATA_AVAILABLE}`}</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default React.memo(DeliveryFeeCardBody);
