import { ALL_DAYS_VALUES } from "../utils/constants";

export interface DefaultServiceProps {
  serviceTypeId: string;
  serviceTypeName: string;
  isPOSMappingAreaBased: boolean;
  displayName: string;
}

export interface DEFAULTRANGEGENERATORFORM {
  minimumDays: string;
  maximumDays: string;
  bufferTime: any;
  defaultSlotCapacity: string;
  days: any;
  generatedRange: any;
  generatedSlots?: any;
  cutOff: string | null;
}

export const DEFAULT_RANGE_GENERATOR_FORM_VALUES: DEFAULTRANGEGENERATORFORM = {
  minimumDays: "",
  maximumDays: "",
  bufferTime: "",
  defaultSlotCapacity: "",
  days: [],
  generatedRange: [],
  generatedSlots: [],
  cutOff: null,
};

export const DEFAULT_CONFIG_TAB = {
  zoneName: "",
  foodTypes: ["NON_FOOD"],
  slotsGeneratorForms: {
    carrefour: [{ ...DEFAULT_RANGE_GENERATOR_FORM_VALUES, days: ALL_DAYS_VALUES }],
    supplier: [{ ...DEFAULT_RANGE_GENERATOR_FORM_VALUES, days: ALL_DAYS_VALUES }],
  },
  selectedAreas: [],
};

export interface slotsGeneratorFormsnterface {
  carrefour: Array<DEFAULTRANGEGENERATORFORM>;
  supplier: Array<DEFAULTRANGEGENERATORFORM>;
}
export interface ConfigTabsState {
  zoneName: string;
  foodTypes: Array<string>;
  slotsGeneratorForms: slotsGeneratorFormsnterface;
  selectedAreas?: Array<any>;
  zoneId?: string;
  isCreating: boolean;
  isUpdating: boolean;
}

export const tabInitValues = [
  {
    zoneName: "Zone01",
    foodTypes: ["NON_FOOD"],
    selectedAreas: [],
    isCreating: false,
    isUpdating: false,
    slotsGeneratorForms: {
      carrefour: [{ ...DEFAULT_RANGE_GENERATOR_FORM_VALUES, days: ALL_DAYS_VALUES }],
      supplier: [{ ...DEFAULT_RANGE_GENERATOR_FORM_VALUES, days: ALL_DAYS_VALUES }],
    },
  },
];

export const TEXT_CONSTANT = {
  CARREFOUR: "carrefour",
  SUPPLIER: "supplier",
  MINIMUM_DAYS_ERROR: "Please enter minimum days",
  MAXIMUM_DAYS_ERROR: "Please enter maximum days",
  MINIMUM_DAYS_SAME_MAXIMUM_DAYS_ERROR: "Minimum days cannot be same/greater than maximum days",
  CUT_OFF_TIME_ERROR: "Please enter cut off time",
  MINIMUM_DAYS: "Minimum Days",
  MAXIMUM_DAYS: " Maximum Days",
  CUT_OFF: "Cut Off",
  ACTIONS: "Actions",
  GET_DATE_FORMAT: "HH:mm",
  SAVE_DATE_FORMAT: "HH:mm:ss",
};

export const currentAllowedDeliveryTypes = [TEXT_CONSTANT.CARREFOUR, TEXT_CONSTANT.SUPPLIER];
