import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { DELIVERY_FEE_BOX_TITLE } from "../../../Utils/DeliveryFeeConstants";

import SwitchButton from "../../../../common/SwitchButton/SwitchButton";
import TextBoxWithLabel from "../../../../common/TextBoxWithLabel/TextBoxWithLabel";

import ThresholdRangeDeliveryFeeView from "../ThresholdRangeDeliveryFeeView";
import { AddConfigurationsText, AddThresoldRangeText, ChangeConfigOtion } from "../../utils/DeliveryFeeConfigViewUtils";
import AdditionalDeliveryFeeConfigList from "../AdditionalDeliveryFeeConfigList";

interface DeliveryFeeConfigurationInterface {
  classes: any;
  name: any;
  multiShipmentLevel: any;
  stateObj: any;
  isEditMode: any;
  stateLevelIndex: any;
  updateConfigPosId: any;
  updateConfigZoneName: any;
  enableThisFeature: any;
  use_existing_config_change_handler: any;
  config_change_existing_handler: any;
  _handle_delivery_fee_calculation_toggle_change: any;
  handleDeliveryFeeInputChange: any;
  _handle_click_add_threshold_range: any;
  _handle_click_delete_threshold_range: any;
  handleRangeInputChange: any;
  handleDrawerConfigChange: any;
  _handle_open_configuration_dialog: any;
  removeTimeConfigRecord: any;
  handleAdditionalConfigInputChange: any;
  handleCapacityUtilisationConfigInputChange: any;
}

const DeliveryFeeConfiguration = (props: DeliveryFeeConfigurationInterface) => {
  const {
    classes,
    name,
    multiShipmentLevel,
    stateObj,
    isEditMode,
    stateLevelIndex,
    updateConfigPosId,
    updateConfigZoneName,
    enableThisFeature,
    use_existing_config_change_handler,
    config_change_existing_handler,
    _handle_delivery_fee_calculation_toggle_change,
    handleDeliveryFeeInputChange,
    _handle_click_add_threshold_range,
    _handle_click_delete_threshold_range,
    handleRangeInputChange,
    handleDrawerConfigChange,
    _handle_open_configuration_dialog,
    removeTimeConfigRecord,
    handleAdditionalConfigInputChange,
    handleCapacityUtilisationConfigInputChange,
  } = props;

  const _check_if_parent_configuratopn_present = (orderThreshold: any, belowThreshold: any, aboveThreshold: any) => {
    if (multiShipmentLevel === false) {
      if (orderThreshold !== "" && belowThreshold !== "" && aboveThreshold !== "") {
        return true;
      }
    }
    return false;
  };

  return (
    <Grid container className={classes.dlvFeeServiceTypeDiv} key={`${name}-${stateLevelIndex}-Delivery-Fee`}>
      <Grid container>
        <Grid item xs={4} className={classes.dlvFeeServiceTypeTextDivStyle}>
          <Typography className={classes.dlvFeeServiceTypeTextStyle}>{DELIVERY_FEE_BOX_TITLE}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.marginAuto}>
          {!stateObj.sameAsEarlier && (
            <Grid container justifyContent="flex-end">
              <Grid item xs={10} className={classes.useCalculationDivStyle}>
                <Typography className={classes.dlvFeeCalculationTextStyle}>Use this value in calculation</Typography>
              </Grid>
              <Grid item xs={2}>
                <SwitchButton
                  className={isEditMode && !stateObj.enableConfigEdit ? classes.disableSwitch : classes.switchStyle}
                  size="small"
                  checked={stateObj.active}
                  disabled={false}
                  handleChange={(e: any) => _handle_delivery_fee_calculation_toggle_change(e, multiShipmentLevel, name)}
                  leftLabel={undefined}
                  rightLabel={undefined}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={4} className={classes.marginAuto}>
          <Grid container justifyContent="flex-end">
            <ChangeConfigOtion
              is_edit_mode={updateConfigPosId === "" && updateConfigZoneName === "" ? false : true}
              classes={classes}
              use_existing_checked={stateObj.sameAsEarlier}
              use_existing_config_change_handler={(e: any) => use_existing_config_change_handler(e, false, name, "deliveryFee", "sameAsEarlier", enableThisFeature)}
              change_existing_checked={stateObj.enableConfigEdit}
              use_change_existing_config_change_handler={(e: any) => config_change_existing_handler(e, false, name, "deliveryFee", "enableConfigEdit", enableThisFeature)}
            />
          </Grid>
        </Grid>
      </Grid>

      {stateObj !== undefined && stateObj.active !== undefined && stateObj.active == true && (
        <>
          <Grid container className={classes.thresholdInputMainDiv}>
            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Less than (Threshold Value)"}
                name="orderThreshold"
                value={stateObj.orderThreshold}
                placeholderText="Less than (Threshold Value) "
                handleChange={(e: any) => handleDeliveryFeeInputChange(e, multiShipmentLevel, name)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={stateObj.enableConfigEdit ? (stateObj && stateObj.deliveryFeeRangeConfigDTOList && Array.isArray(stateObj.deliveryFeeRangeConfigDTOList) && stateObj.deliveryFeeRangeConfigDTOList.length > 0 ? true : false) : true}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Delivery Fees Below Threshold"}
                name="belowThreshold"
                value={stateObj.belowThreshold}
                placeholderText="Delivery Fees Below Threshold"
                handleChange={(e: any) => handleDeliveryFeeInputChange(e, multiShipmentLevel, name)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={!stateObj.enableConfigEdit}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={3} className={classes.actionIconDivStyle} style={{ display: "flex", justifyContent: "flex-start" }}>
              {stateObj.enableConfigEdit &&
                stateObj &&
                stateObj.deliveryFeeRangeConfigDTOList &&
                Array.isArray(stateObj.deliveryFeeRangeConfigDTOList) &&
                stateObj.deliveryFeeRangeConfigDTOList.length === 0 &&
                _check_if_parent_configuratopn_present(stateObj.orderThreshold, stateObj.belowThreshold, stateObj.aboveThreshold) && (
                  <AddThresoldRangeText
                    classes={classes}
                    name={name}
                    multiShipmentLevel={multiShipmentLevel}
                    orderThreshold={stateObj.orderThreshold}
                    belowThreshold={stateObj.belowThreshold}
                    aboveThreshold={stateObj.aboveThreshold}
                    _handle_click_add_threshold_range={_handle_click_add_threshold_range}
                  />
                )}
            </Grid>
          </Grid>

          {stateObj && stateObj.deliveryFeeRangeConfigDTOList && Array.isArray(stateObj.deliveryFeeRangeConfigDTOList) && stateObj.deliveryFeeRangeConfigDTOList.length > 0 && (
            <ThresholdRangeDeliveryFeeView
              classes={classes}
              name={name}
              multiShipmentLevel={multiShipmentLevel}
              deliveryFeeRangeConfigDTOList={stateObj.deliveryFeeRangeConfigDTOList}
              handleRangeInputChange={handleRangeInputChange}
              _handle_click_add_threshold_range={_handle_click_add_threshold_range}
              _handle_click_delete_threshold_range={_handle_click_delete_threshold_range}
              stateObj={stateObj}
            />
          )}

          <Grid container className={classes.thresholdInputMainDiv}>
            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Greater than or Equals to (Threshold Value)"}
                name="greaterOrderThresold"
                value={stateObj.greaterOrderThresold}
                placeholderText="Greater than or Equals to (Threshold Value)"
                handleChange={(e: any) => handleDeliveryFeeInputChange(e, multiShipmentLevel, name)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={true}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={3} className={classes.thresholdInputDiv}>
              <TextBoxWithLabel
                label={"Delivery Fees Above Threshold"}
                name="aboveThreshold"
                value={stateObj.aboveThreshold}
                placeholderText="Delivery Fees Above Threshold"
                handleChange={(e: any) => handleDeliveryFeeInputChange(e, multiShipmentLevel, name)}
                type="number"
                className={classes.rightPaddingStyle}
                disabled={!stateObj.enableConfigEdit}
              ></TextBoxWithLabel>
            </Grid>

            <Grid item xs={3} className={classes.actionIconDivStyle} style={{ display: "flex", justifyContent: "flex-start" }}>
              {stateObj.enableConfigEdit && stateObj && stateObj.active === true && name && name !== "" && _check_if_parent_configuratopn_present(stateObj.orderThreshold, stateObj.belowThreshold, stateObj.aboveThreshold) ? (
                <Grid container justifyContent="flex-start" spacing={0}>
                  <Grid item xs={12}>
                    <AddConfigurationsText
                      classes={classes}
                      name={name}
                      multiShipmentLevel={multiShipmentLevel}
                      orderThreshold={stateObj.orderThreshold}
                      belowThreshold={stateObj.belowThreshold}
                      aboveThreshold={stateObj.aboveThreshold}
                      _handle_open_configuration_dialog={_handle_open_configuration_dialog}
                    />
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>

            {/* <Grid item xs={3} className={classes.marginAuto}>
              {name !== undefined && name === STANDARD_SERVICE_TYPE ? (
                <Grid item xs={12} className={!stateObj.enableConfigEdit ? classes.disabledDrawerConfigurationInputDiv : classes.drawerConfigurationInputDiv}>
                  <FormControlLabel
                    className={classes.serviceTypeItem}
                    key={name}
                    control={
                      <Checkbox
                        checked={stateObj.updateDrawerConfig}
                        value={stateObj.updateDrawerConfig}
                        onChange={(e: any) => handleDrawerConfigChange(e, "updateDrawerConfig", multiShipmentLevel, name)}
                      />
                    }
                    label={<Typography className={classes.drawerConfigSpanLabel}>{"Use existing drawer configuration"}</Typography>}
                    disabled={!stateObj.enableConfigEdit}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid> */}
          </Grid>

          {stateObj && stateObj.additionalDeliveryFeeConfigDTOList && Array.isArray(stateObj.additionalDeliveryFeeConfigDTOList) && stateObj.additionalDeliveryFeeConfigDTOList.length > 0 && (
            <Grid container className={classes.addConfigurationInputDivStyle}>
              <AdditionalDeliveryFeeConfigList
                feeConfigList={stateObj.additionalDeliveryFeeConfigDTOList}
                classes={classes}
                stateObj={stateObj}
                shipment_name={name}
                multiShipmentLevel={multiShipmentLevel}
                removeTimeConfigRecord={removeTimeConfigRecord}
                handleAdditionalConfigInputChange={handleAdditionalConfigInputChange}
              />
            </Grid>
          )}
          {/* 
          {stateObj && stateObj.deliveryFeeSlotCapacityConfigDTOList && Array.isArray(stateObj.deliveryFeeSlotCapacityConfigDTOList) && stateObj.deliveryFeeSlotCapacityConfigDTOList.length > 0 && (
            <Grid container className={classes.addConfigurationInputDivStyle}>
              <AdditionalCapacityUtilisationConfigList
                feeConfigList={stateObj.deliveryFeeSlotCapacityConfigDTOList}
                classes={classes}
                shipment_name={name}
                multiShipmentLevel={multiShipmentLevel}
                stateObj={stateObj}
                handleCapacityUtilisationConfigInputChange={handleCapacityUtilisationConfigInputChange}
              />
            </Grid>
          )} */}
        </>
      )}
    </Grid>
  );
};

export default React.memo(DeliveryFeeConfiguration);
