import React from "react";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import searchIcon from "../../assets/svg/SearchIcon.svg";

import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import Loader from "../../common/Loader/Loader";
import Table from "../../common/Table/Table";
import TextBox from "../../common/TextBox";
import { UserManagementState } from "../UserManagementInterface";

interface UserManagementCardInterface {
  classes: any;
  userState: UserManagementState;
  handleSearch: any;
  handleExportCSVClick: any;
  userDataLoading: any;
  additionalServicesList: any;
  userDialogState: any;
  tableHeaderColumns: any;
  pageSize: any;
  rowCount: any;
  pageNumber: any;
  fetchNextRecord: any;
}

const UserManagementCard = (props: UserManagementCardInterface) => {
  const { classes, userState, handleSearch, handleExportCSVClick, userDataLoading, additionalServicesList, userDialogState, tableHeaderColumns, pageSize, rowCount, pageNumber, fetchNextRecord } =
    props;

  return (
    <Card className={classes.cardRoot}>
      {/* <div className={classes.topHeaderDiv}>
        <Grid container className={classes.searchAndFilterContainer} justifyContent="space-between">
          <Grid item xs={4} className={classes.searchFilter}>
            <TextBox
              textBoxId="searchTextBox"
              placeholderText="Quick search by User Name"
              startAdornment={
                <InputAdornment position="start">
                  <IconButton edge="start" size="small" disabled>
                    <img src={searchIcon} alt="search" className={classes.searchIconStyle} />
                  </IconButton>
                </InputAdornment>
              }
              value={userState.searchText}
              handleChange={handleSearch}
              disabled
            ></TextBox>
          </Grid>
          <Grid item xs={2}>
            {""}
          </Grid>
          <Grid item xs={4}>
            <PrimaryButton
              buttonLabel={userState.isExporting ? "Exporting..." : "EXPORT TO EXCEL"}
              buttonVariant="outlined"
              // disabled={userState.isExporting}
              disabled={true}
              onClick={handleExportCSVClick}
              className={classes.exportButton}
            />
          </Grid>
        </Grid>
      </div> */}
      <>
        {userDataLoading || userDialogState.isLoading ? (
          <Loader />
        ) : additionalServicesList && userDialogState.userObj.userDataRecord && !userDialogState.isLoading ? (
          <div className={classes.tableDivStyle}>
            <Table
              columnsData={tableHeaderColumns}
              rowsData={additionalServicesList.length > 0 && userDialogState.userObj.userDataRecord && userDialogState.userObj.userDataRecord.length > 0 ? userDialogState.userObj.userDataRecord : []}
              hideFooter={false}
              pageSize={pageSize}
              rowCount={rowCount}
              pageNumber={pageNumber}
              fetchNextRecord={fetchNextRecord}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    </Card>
  );
};

export default React.memo(UserManagementCard);
