import React from "react";

import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import basicServiceDetailsStyles from "./BasicServiceDetailsStyles";

import { getServicePropositionDeliveryType } from "../../../../utils/helperFunctions";
import { DELIVERY_TYPE } from "../../../../config/constants";

const BasicServiceDetails = ({ serviceTypeId, serviceTypeName, expressType = "", country, region, servicePropositionList, displayName }) => {
  const classes = basicServiceDetailsStyles();

  return (
    <Accordion defaultExpanded={false} className={classes.accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
        <Typography align="left" className={classes.cardTitle}>
          {displayName || serviceTypeName || ""}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsStyle}>
        <Grid container spacing={3} className={classes.detailsContainer}>
          <Grid item xs={2}>
            <div className={classes.posDetailLabel}>Country</div>
            <div className={classes.posDetailData}>{country}</div>
          </Grid>
          {getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) === DELIVERY_TYPE.MINUTE_BASED && (
            <Grid item xs={3}>
              <div className={classes.posDetailLabel}>Express Type</div>
              <div className={classes.posDetailData}>{expressType}</div>
            </Grid>
          )}

          {getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) !== DELIVERY_TYPE.MINUTE_BASED && getServicePropositionDeliveryType(servicePropositionList, serviceTypeName) !== DELIVERY_TYPE.DAY_BASED && (
            <Grid item xs={3}>
              <div className={classes.posDetailLabel}>Next Day Slot Visibility</div>
              <div className={classes.posDetailData}>{"On"}</div>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(BasicServiceDetails);
