import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";

import { APP_ROUTES, USER_TYPES } from "../../config/constants";
import { _design_update_delivery_fee_state_object } from "./Utils/DeliveryFeeUtils";

import { fetchShipmentTypeRecordList, getDlvFeeConfigurationBasedOnPosAndZone, selectShipmentTypeDetailsRecord, selectSingleDlvFeeConfigurationRecord } from "./Redux/DeliveryFeeSlice";
import { selectUserDetails } from "../Login/redux/userSlice";
import { configurationStyle } from "./Styles/ConfigurationViewStyle";
import { serviceSelectPropositionRecord } from "../../config/redux/configurationsSlice";

import PrimaryButton from "../common/PrimaryButton/PrimaryButton";
import PosZoneMappingPreviewConfiguration from "./Configuration/common/PosZoneMappingPreviewConfiguration";
import ShipmentLevelPreviewConfiguration from "./Configuration/common/ShipmentLevelPreviewConfiguration";

const ConfigurationPreview = () => {
  const classes = configurationStyle();

  const location = useLocation<any>();
  const appDispatch = useDispatch();
  const history = useHistory();

  const dlvFeeConfigurationRecordService = useSelector(selectSingleDlvFeeConfigurationRecord);
  const shipmentTypeDetailsService = useSelector(selectShipmentTypeDetailsRecord);
  const userInfo: any = useSelector(selectUserDetails);
  const servicePropositionList = useSelector(serviceSelectPropositionRecord);

  const [previewData, setPreviewData] = useState<any>({ loading: false, multiShipmentLevel: {}, shipmentLevel: {}, selectedPosServiceTypeZone: [] });
  const [queryParam, setQueryParam] = useState<any>();

  useEffect(() => {
    if (
      queryParam &&
      queryParam.posNo &&
      dlvFeeConfigurationRecordService &&
      dlvFeeConfigurationRecordService.data &&
      Object.keys(dlvFeeConfigurationRecordService.data).length > 0 &&
      shipmentTypeDetailsService &&
      shipmentTypeDetailsService.data &&
      shipmentTypeDetailsService.data.length > 0
    ) {
      let data: any = {};
      let arr = dlvFeeConfigurationRecordService.data.selectedPosServiceTypeZone.filter((obj: any) => obj.posNo === queryParam.posNo);
      if (arr && arr.length > 0) {
        data = dlvFeeConfigurationRecordService.data;
      }
      if (data && Object.keys(data).length > 0) {
        let updatedNewDlvFeeConfigState = _design_update_delivery_fee_state_object(dlvFeeConfigurationRecordService.data, previewData, shipmentTypeDetailsService.data, servicePropositionList);
        setPreviewData(updatedNewDlvFeeConfigState);
      } else {
        history.push(APP_ROUTES.DELIVERY_FEE);
      }
    }
  }, [dlvFeeConfigurationRecordService, shipmentTypeDetailsService]);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    setQueryParam({ posNo: params.get("posNo"), zoneName: params.get("zoneName"), zoneId: params.get("zoneId") });
    appDispatch(getDlvFeeConfigurationBasedOnPosAndZone({ posNo: params.get("posNo"), zone: params.get("zoneName") }));
    appDispatch(fetchShipmentTypeRecordList());
  }, [servicePropositionList]);

  return (
    <div className={classes.container}>
      <Grid container justifyContent="space-between">
        <Typography className={classes.heading}>Preview</Typography>
      </Grid>
      <ShipmentLevelPreviewConfiguration classes={classes} state={previewData} />
      <PosZoneMappingPreviewConfiguration classes={classes} state={previewData} />
      <Grid className={classes.configButtonDiv}>
        <PrimaryButton
          className={classes.cancelButtonStyle}
          buttonLabel="Back To List"
          onClick={() => {
            history.goBack();
          }}
        />
        {userInfo && userInfo.userProfile && userInfo.userProfile.role !== undefined && userInfo.userProfile.role !== "" && userInfo?.userProfile?.role === USER_TYPES.MAF_ADMIN && (
          <PrimaryButton
            className={classes.editButtonStyle}
            buttonLabel="Edit"
            onClick={() => {
              history.push(`${APP_ROUTES.DELIVERY_FEE_CONFIGURATION}?posNo=${queryParam.posNo}&zoneName=${queryParam.zoneName}&zoneId=${queryParam.zoneId}`);
            }}
          />
        )}
      </Grid>
    </div>
  );
};

export default React.memo(ConfigurationPreview);
