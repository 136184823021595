import moment from "moment";
import CustomAlert from "../../../../components/common/CustomAlert/CustomAlert";
import { createTemplateSlots, updateDefaultTemplateSlotBySlotId, updateTemplateSlots } from "../../../../components/ServiceAndSlots/redux/templateSlotsSlice";
import { isEmptyOrNull } from "../../../../utils/DataUtils";
import { TEXT_CONSTANT, currentAllowedDeliveryTypes, slotsGeneratorFormsnterface } from "../DefaultInterface";
import { ALERT_TYPES, ALL_DAYS_VALUES } from "./../../../../config/constants";

export const DefaultServiceStartTime = moment().set({ hour: 8, minute: 30 });
export const DefaultServiceEndTime = "20:30";

export const isAddOtherDaysButtonDisabled = (configTabs: any, tabIndex: number, objectKey: any) => {
  let isButtonDisabled = false;
  if (
    configTabs &&
    configTabs[tabIndex] &&
    configTabs[tabIndex].slotsGeneratorForms &&
    configTabs[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface] &&
    Array.isArray(configTabs[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface])
  ) {
    let daysSelectedSoFar: any = configTabs[tabIndex].slotsGeneratorForms[objectKey as keyof slotsGeneratorFormsnterface].reduce((days: any, value: any) => [...days, ...value.days], []);
    let remainingDays = ALL_DAYS_VALUES.filter((day) => !daysSelectedSoFar.includes(day));
    if (remainingDays.length === 0) isButtonDisabled = true;
  }
  return isButtonDisabled;
};

export const executeScroll = (ref: any) => ref.current.scrollIntoView({ behavior: "smooth" });

export const validateDefaultZoneConfig = (configTabs: any, selectedConfigTab: any, isPOSMappingAreaBased: boolean, isUpdate?: boolean) => {
  let currentTab = configTabs[selectedConfigTab];
  let responseValue: any = {};
  if (currentTab.slotsGeneratorForms) {
    for (const key of Object.keys(currentTab.slotsGeneratorForms)) {
      if (isUpdate) {
        let newResponseValue: any = validateUpdateSlotDelivery(currentTab.slotsGeneratorForms[key as keyof slotsGeneratorFormsnterface], key);
        responseValue = { ...responseValue, ...newResponseValue };
      } else {
        let newResponseValue: any = validateSlotDelivery(currentTab.slotsGeneratorForms[key as keyof slotsGeneratorFormsnterface], key);
        responseValue = { ...responseValue, ...newResponseValue };
      }
    }
  }

  return responseValue;
};

// Use to send payload to backend in default services
export function createPayloadForSavedefaultConfig(details: any, posId: string, serviceTypeId: string, posDetails: any, allowedDeliveryTypes: any) {
  const payload: any = {
    foodTypeMapping: { serviceTypeId: serviceTypeId, foodTypes: ["FOOD_DRY", "FOOD_FROZEN", "FOOD_FRESH"], excluded: true },
    zoneConfig: {
      zoneName: details.zoneName,
      serviceTypeMappingId: posDetails?.serviceType?.filter((s: any) => s.serviceTypeId === serviceTypeId)[0].id,
      status: "",
    },
    templateSlots: [],
    selectedAreas: details.selectedAreas,
  };
  let results: any = {};
  if (details.slotsGeneratorForms) {
    if (allowedDeliveryTypes && Object.keys(allowedDeliveryTypes).length && Object.keys(allowedDeliveryTypes).length === 0) {
      for (const key of Object.keys(details.slotsGeneratorForms)) {
        const data = designDaysConfig(key, details.slotsGeneratorForms[key as keyof slotsGeneratorFormsnterface], posId);
        results[key] = [...data];
      }
    } else {
      for (const key of Object.keys(allowedDeliveryTypes)) {
        if (details.slotsGeneratorForms && details.slotsGeneratorForms[key]) {
          const data = designDaysConfig(key, details.slotsGeneratorForms[key as keyof slotsGeneratorFormsnterface], posId);
          results[key] = [...data];
        }
      }
    }
  }
  payload.defaultServiceTemplateSlots = results;
  return payload;
}

export const saveDefaultZoneConfigurations = async (posNo: string, serviceTypeName: string, selectedConfigTab: number, configurations: any, dispatch: any, servicePropositionList: any) => {
  const {
    payload: { zoneConfig, message },
  }: any = await dispatch(createTemplateSlots({ posNo, zoneConfigurations: configurations, service: serviceTypeName, zoneConfigIndex: selectedConfigTab, servicePropositionList }));
  if (message) {
    CustomAlert(ALERT_TYPES.ERROR, message);
  }
  if (zoneConfig && zoneConfig.id) {
    CustomAlert(ALERT_TYPES.SUCCESS, "Zone Configurations saved successfully");
  }
};

// Use to send payload to backend in default services
export function createPayloadForUpdatedefaultConfig(details: any, posId: string, serviceTypeId: string, allowedDeliveryTypes: any) {
  const payload: any = {
    foodTypeMapping: details.foodTypeMapping,
    zoneConfig: details.zoneConfig,
    templateSlots: [],
    selectedAreas: details.selectedAreas,
  };
  let results: any = {};
  if (details.slotsGeneratorForms) {
    if (allowedDeliveryTypes && Object.keys(allowedDeliveryTypes).length && Object.keys(allowedDeliveryTypes).length === 0) {
      for (const key of Object.keys(details.slotsGeneratorForms)) {
        if (Array.isArray(details.slotsGeneratorForms[key as keyof slotsGeneratorFormsnterface])) {
          const data = designDaysConfig(key, details.slotsGeneratorForms[key as keyof slotsGeneratorFormsnterface], posId);
          results[key] = [...data];
        } else {
          const data = designDaysConfigForUpdate(details.slotsGeneratorForms[key as keyof slotsGeneratorFormsnterface], posId);
          results[key] = [...data];
        }
      }
    } else {
      for (const key of Object.keys(allowedDeliveryTypes)) {
        if (details.slotsGeneratorForms && details.slotsGeneratorForms[key]) {
          if (Array.isArray(details.slotsGeneratorForms[key as keyof slotsGeneratorFormsnterface])) {
            const data = designDaysConfig(key, details.slotsGeneratorForms[key as keyof slotsGeneratorFormsnterface], posId);
            results[key] = [...data];
          } else {
            const data = designDaysConfigForUpdate(details.slotsGeneratorForms[key as keyof slotsGeneratorFormsnterface], posId);
            results[key] = [...data];
          }
        }
      }
    }
  }
  payload.defaultServiceTemplateSlots = results;
  return payload;
}

/**
 *
 * @param {object} configurations This object has all the template slots and zone configurations
 * Function to make a call to the backend API for updating conig or updating template slots
 */
export const updateZoneConfigurations = async (posNo: string, serviceTypeName: string, selectedConfigTab: number, configurations: any, dispatch: any, servicePropositionList: any) => {
  const {
    payload: { zoneConfig, message },
  } = await dispatch(
    updateTemplateSlots({
      posNo,
      zoneConfigurations: configurations,
      service: serviceTypeName,
      zoneConfigIndex: selectedConfigTab,
      servicePropositionList,
    })
  );

  if (message) {
    CustomAlert(ALERT_TYPES.ERROR, message);
  }

  if (zoneConfig && zoneConfig.id) {
    CustomAlert(ALERT_TYPES.SUCCESS, "Zone Configurations updated successfully");
  }
};

// Update single date range
export const updateTemplateSlotHandler = async (tabIndex: number, generatorFormIndex: number, index: number, slot: any, serviceTypeName: string, serviceTypeId: string, dispatch: any, objectKey: any) => {
  let requestBody = {
    id: slot?.id,
    posId: slot?.posId,
    zoneId: slot?.zoneId,
    minDay: slot?.minDay,
    maxDay: slot?.maxDay,
    day: slot?.day,
    slotCapacity: slot?.slotCapacity,
    bufferTime: slot?.bufferTime,
    serviceTypeId,
    isSupplier: objectKey === "supplier" ? true : false,
    status: slot?.status,
    cutOff: !isEmptyOrNull(slot?.cutOff) && moment(slot?.cutOff).format(TEXT_CONSTANT.SAVE_DATE_FORMAT),
  };
  const {
    payload: { message, data },
  } = await dispatch(updateDefaultTemplateSlotBySlotId({ serviceTypeName, slotData: requestBody, objectKey }));
  if (message) {
    CustomAlert(ALERT_TYPES.ERROR, message);
  }
  return data;
};

export function validateSlotDelivery(deliveryType: any, key: any) {
  let responseValue: any = {};
  for (let index = 0; index < deliveryType.length; index++) {
    const element = deliveryType[index];
    if (isEmptyOrNull(element?.minimumDays)) {
      let errorMsg = TEXT_CONSTANT.MINIMUM_DAYS_ERROR;
      responseValue[`${key}${index}minimumDays`] = errorMsg;
    }
    if (isEmptyOrNull(element?.maximumDays)) {
      let errorMsg = TEXT_CONSTANT.MAXIMUM_DAYS_ERROR;
      responseValue[`${key}${index}maximumDays`] = errorMsg;
    }
    if (isEmptyOrNull(element?.cutOff)) {
      let errorMsg = TEXT_CONSTANT.CUT_OFF_TIME_ERROR;
      responseValue[`${key}${index}cutOff`] = errorMsg;
    }
    if (Number(element?.minimumDays) >= Number(element?.maximumDays)) {
      let errorMsg = TEXT_CONSTANT.MINIMUM_DAYS_SAME_MAXIMUM_DAYS_ERROR;
      responseValue[`${key}${index}minMax`] = errorMsg;
    }
    if (element?.generatedRange?.length <= 0) {
      let errorMsg = "First generate range for all days before saving zone configuration";
      responseValue[`${key}${index}generatedRange`] = errorMsg;
    }
  }
  return responseValue;
}

export function designDaysConfig(key: any, deliveryType: any, posId: any) {
  let results: any = [];
  for (let index = 0; index < deliveryType.length; index++) {
    const element = deliveryType[index];
    let daysConfig = element.days.map((info: any) => ({
      day: info,
      posId: posId,
      minDay: element.minimumDays,
      maxDay: element.maximumDays,
      slotCapacity: element.generatedRange[0].capacity,
      bufferTime: element.generatedRange[0].bufferTime,
      supplier: key === "supplier" ? true : false,
      cutOff: !isEmptyOrNull(element?.cutOff) && moment(element?.cutOff).format(TEXT_CONSTANT.SAVE_DATE_FORMAT),
    }));
    results = [...results, ...daysConfig];
  }
  return [...results];
}

export function validateUpdateSlotDelivery(deliveryType: any, key: any) {
  let responseValue: any = {};
  for (const key of Object.keys(deliveryType)) {
    for (let index = 0; index < deliveryType[key].length; index++) {
      const element = deliveryType[key][index];
      if (isEmptyOrNull(element.minDay)) {
        let errorMsg = TEXT_CONSTANT.MINIMUM_DAYS_ERROR;
        responseValue[`${key}${index}minDay`] = errorMsg;
      }
      if (isEmptyOrNull(element.maxDay)) {
        let errorMsg = TEXT_CONSTANT.MAXIMUM_DAYS_ERROR;
        responseValue[`${key}${index}maxDay`] = errorMsg;
      }
      if (isEmptyOrNull(element.cutOff)) {
        let errorMsg = TEXT_CONSTANT.CUT_OFF_TIME_ERROR;
        responseValue[`${key}${index}cutOff`] = errorMsg;
      }
      if (Number(element.minDay) >= Number(element.maxDay)) {
        let errorMsg = TEXT_CONSTANT.MINIMUM_DAYS_SAME_MAXIMUM_DAYS_ERROR;
        responseValue[`${key}${index}minMax`] = errorMsg;
      }
    }
  }
  return responseValue;
}

export function designDaysConfigForUpdate(deliveryType: any, posId: any) {
  let results: any = [];
  for (const key of Object.keys(deliveryType)) {
    if (key !== "" && deliveryType[key] !== null && Array.isArray(deliveryType[key]) && deliveryType[key].length > 0) {
      for (let index = 0; index < deliveryType[key].length; index++) {
        const element = { ...deliveryType[key][index] };
        if (element?.cutOff) {
          element.cutOff = moment(element.cutOff).format(TEXT_CONSTANT.SAVE_DATE_FORMAT);
        }
        results.push(element);
      }
    }
  }
  return [...results];
}

export function validateDefaultZoneConfigBothEmpty(currentTab: any, isUpdate: any) {
  let responseValue: any = {};
  for (const key of Object.keys(currentTab)) {
    if (Array.isArray(currentTab[key as keyof slotsGeneratorFormsnterface])) {
      responseValue[key] = validateSlotDelivery(currentTab[key as keyof slotsGeneratorFormsnterface], key);
    } else {
      responseValue[key] = validateUpdateSlotDelivery(currentTab[key as keyof slotsGeneratorFormsnterface], key);
    }
  }
  return responseValue;
}

export function validateSaveDefaultConfigObject(configTabs: any, selectedZoneTab: any, isUpdate: any) {
  let currentTab = configTabs[selectedZoneTab];
  let errorMessage = "";
  let responseValue: any = { isValid: false, allowedDeliveryTypes: {} };

  if (currentTab.slotsGeneratorForms) {
    const validateValueResponse = validateDefaultZoneConfigBothEmpty(currentTab.slotsGeneratorForms, isUpdate);
    for (const key of Object.keys(validateValueResponse)) {
      const errorResponse = validateValueResponse[key];
      if (Object.keys(errorResponse).length === 0) {
        responseValue.isValid = false;
        responseValue.allowedDeliveryTypes[key] = true;
      } else {
        const errorResponseLength = Object.keys(errorResponse).length;
        const allowedLengthToSkip = Array.isArray(currentTab.slotsGeneratorForms[key]) ? 5 : 4;
        if (errorResponseLength === allowedLengthToSkip) {
          responseValue.isValid = false; // This section is empty by user
        } else {
          responseValue.isValid = true;
          errorMessage = errorResponse[Object.keys(errorResponse)[0]];
        }
      }
    }
  }

  if (errorMessage === "") {
    if (Object.keys(responseValue.allowedDeliveryTypes).length === 0) {
      CustomAlert(ALERT_TYPES.ERROR, "Minimum one delivery type configuration required");
      responseValue.isValid = true;
    }
  } else {
    CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    responseValue.isValid = true;
  }

  return responseValue;
}

export function getMissingDeliveryTypeFromObject(currentObject: any) {
  let listData = [] as any;
  currentAllowedDeliveryTypes.forEach((element: any) => {
    if (currentObject[element] === undefined) {
      listData.push(element);
    }
  });
  return listData;
}

export function validateUpdateSlotConfigObject(slotObj: any) {
  const errors: string[] = [];
  if (isEmptyOrNull(slotObj?.minDay)) {
    errors.push(TEXT_CONSTANT.MINIMUM_DAYS_ERROR);
  } else if (isEmptyOrNull(slotObj?.maxDay)) {
    errors.push(TEXT_CONSTANT.MAXIMUM_DAYS_ERROR);
  } else if (!slotObj?.minDay && !slotObj?.maxDay && Number(slotObj?.minDay) >= Number(slotObj?.maxDay)) {
    errors.push(TEXT_CONSTANT.MINIMUM_DAYS_SAME_MAXIMUM_DAYS_ERROR);
  } else if (isEmptyOrNull(slotObj?.cutOff)) {
    errors.push(TEXT_CONSTANT.CUT_OFF_TIME_ERROR);
  }

  if (errors.length > 0) {
    CustomAlert(ALERT_TYPES.ERROR, errors[0]);
    return true;
  }

  return false;
}
