import React from "react";

import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { capitalizeFirstLetter, isUndefined } from "../../../../utils/DataUtils";
import { AUTOMATED_ALLOWED_SERVICE_TYPES, PROMISE_TYPE_SERVICE_BASED } from "../../RuleEngineUtils";

interface RenderTypeOfPromiseCardInterface {
  classes: any;
  config: any;
  handlePromiseTypeCheckboxChange: (typeOfPromise: string) => void;
  serviceName?: string;
}

const RenderTypeOfPromiseCard = (props: RenderTypeOfPromiseCardInterface) => {
  const { classes, config, handlePromiseTypeCheckboxChange, serviceName } = props;

  const promiseType = !isUndefined(serviceName) && AUTOMATED_ALLOWED_SERVICE_TYPES.includes(serviceName || serviceName?.toUpperCase() || "") ? PROMISE_TYPE_SERVICE_BASED.EXPRESS : PROMISE_TYPE_SERVICE_BASED.DEFAULT;

  return (
    <Grid className={classes.typeOfPromiseContainer} container spacing={0}>
      <Grid item xs={2}>
        <Typography className={classes.typeOfPromiseText}>Type of Promise</Typography>
      </Grid>
      <Grid item xs={10} className={classes.dFlexEnd}>
        <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
          {!isUndefined(promiseType) &&
            Object.entries(promiseType).map(([key, value], index) => (
              <FormControlLabel
                key={key}
                control={<Checkbox checked={config.type === value} onChange={() => handlePromiseTypeCheckboxChange(value)} />}
                label={<Typography className={classes.typeFormControlLabelStyle}>{`${capitalizeFirstLetter(key)}`}</Typography>}
              />
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderTypeOfPromiseCard);
