import CustomAlert from "../common/CustomAlert/CustomAlert";
import moment, { Moment } from "moment";

import { arraysAreEqual, isArrayValid, isEmptyArray, isEmptyOrNull, isEmptyOrZero, isUndefined } from "../../utils/DataUtils";
import { convertSecondsIntoMinutes, generateDropdownItems } from "../../utils/helperFunctions";
import { ALERT_TYPES, APP_ROUTES, DAYS_DROPDOWN_OPTIONS, SCALABLE_OR_NPN_SCALABLE_ITEMS, SERVICE_TYPES } from "./../../config/constants";
import { convertWeightInGramsToKiloGrams } from "./CurrentConfiguration/CurrentConfigUtils";
import { HEADER_NAME_CONSTANT } from "./CurrentConfiguration/CurrentConfigConstant";
import { DateFormat, getMomentDateBasedOnFormat, getMomentDateObj, getTimeValueAsNumber, TimeValue } from "../../utils/DateUtils";

export const BREADCRUMB_LINKS = [{ name: "Delivery Promise Rule Engine", to: APP_ROUTES.RULE_ENGINE, isCurrentRoute: true }];
export const PROMISE_TYPE = { STATIC: "STATIC", DYNAMIC: "DYNAMIC", AUTOMATED: "AUTOMATED", PREDICTIVE: "PREDICTIVE" };

export const RULE_ENGINE_TEXT_CONSTANT = {
  MINIMUM_PROMISE_TIME: "Minimum Promise Time",
  MIN_TIME_AUTOMATED_PROMISE: "Minimum Time (Default Time)",
  MAX_TIME_AUTOMATED_PROMISE: "Maximum Time",
  LAST_MILE_TIME_AUTOMATED_PROMISE: "Last Mile Time",
  CHANGE_EXISTING_CONFIGURATION: "Change existing configuration",
  USE_EXISTING_CONFIGURATION: "Use existing configuration",
  EXPRESS: "Express",
  ASSIGNED_POS_ZONES: "Assigned POS Zones",
  TYPE_OF_PROMISE: "Type Of Promise",
  VEHICLE_TYPE_RULE: "Vehicle Type Rule",
  VIEW_CURRENT_CONFIGURATIONS: "VIEW CURRENT CONFIGURATIONS",
  CREATE_NEW_CONFIGURATION: "CREATE NEW CONFIGURATION",
  RULE_ENGINE_SET_UP_PREVIEW: "Rule Engine Set up Preview",
  DAYS: "Days",
  CONFIGURED_DAYS: "configuredDays",
  START_TIME: "Start Time",
  END_TIME: "End Time",
  MIN_TIME_HOUR: "Min Time Hour",
  MIN_TIME_MINUTES: "Min Time Minutes",
  FIELD_LABEL_START_TIME: "startTime",
  FIELD_LABEL_END_TIME: "endTime",
  FIELD_LABEL_MIN_TIME_HOUR: "minTimeHour",
  FIELD_LABEL_MIN_TIME_MINUTES: "minTimeMinutes",
  HOUR_END_VALUE: 23,
  MINUTES_END_VALUE: 59,
  ALLOWED_TOLERANCE: 1000,
  TIME_CONFIGS: "Time Configs",
  MIN_TIME: "Min Time",
};

export const AUTOMATED_PROMISE_CONFIG = {
  MIN_TIME_AUTOMATED_CONFIG: "minTimeAutomatedConfig",
  LAST_MILE_TIME_AUTOMATED_CONFIG: "lastmileTimeAutomatedConfig",
  MAX_TIME_AUTOMATED_CONFIG: "maxTimeAutomatedConfig",
};

export const PROMISE_TYPE_SERVICE_BASED = {
  DEFAULT: { STATIC: "STATIC", DYNAMIC: "DYNAMIC" },
  EXPRESS: { STATIC: "STATIC", DYNAMIC: "DYNAMIC", AUTOMATED: "AUTOMATED" },
  QCOMM: { STATIC: "STATIC", DYNAMIC: "DYNAMIC" },
};

export const AUTOMATED_ALLOWED_SERVICE_TYPES = ["EXPRESS"];

export const getDefaultOrFixedTimeMinutes = (defaultOrFixedTimeHours: string | number) => {
  let minutesItems = [];
  if (!defaultOrFixedTimeHours) {
    minutesItems = generateDropdownItems(15, 59, "minutes");
    return minutesItems;
  }
  minutesItems = generateDropdownItems(0, 59, "minutes");
  return minutesItems;
};

export interface PromiseApiConfigMaxTime {
  days: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[];
  time: string | number;
}

export interface PromiseConfigMaxTime {
  configuredDays: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[];
  maxTimeHour: string | number;
  maxTimeMinutes: string | number;
}

export type TimeConfigItemKeys = "startTime" | "endTime" | "minTimeHour" | "minTimeMinutes";
export interface TimeConfigItem {
  startTime: TimeValue;
  endTime: TimeValue;
  minTimeHour: string | any;
  minTimeMinutes: string | any;
}

export interface MinimumPromiseRuleValuesInterface {
  configuredDays: string[];
  timeConfig: TimeConfigItem[];
}

export interface PromiseConfigInterface {
  minActive: boolean;
  enableMinTimeEdit: boolean;
  minTimeSameAsEarlier: boolean;
  minimumPromiseRuleValues: MinimumPromiseRuleValuesInterface[];
  //
  maxActive: boolean;
  enableMaxTimeEdit: boolean;
  maxTimeSameAsEarlier: boolean;
  maximumPromiseRuleValues: PromiseConfigMaxTime[];
  //
  maxToDisplayActive: boolean;
  enableMaxTimeToDisplayEdit: boolean;
  maxTimeToDisplaySameAsEarlier: boolean;
  maxTimeHourToDisplay: string | number;
  maxTimeMinutesToDisplay: string | number;
}

export interface WeightRule {
  active: boolean;
  sameAsEarlier: boolean;
  incrementalTime: any;
  thresholdWeight: string;
}
export interface CountRuleInterface {
  active: boolean;
  sameAsEarlier: boolean;
  incrementalTime: string;
  thresholdCount: string;
}

export interface ScalableTypeRuleInterface {
  active: boolean;
  sameAsEarlier: boolean;
  itemList: Array<any>;
}

export interface OrderVelocityRuleInterface {
  sameAsEarlier: boolean;
  active: boolean;
  orderVelocityRuleValues: Array<any>;
}

export interface VehicleTypeRuleInterface {
  sameAsEarlier: boolean;
  active: boolean;
  bikeThresholdActive: any;
  carThresholdActive: any;
  otherThresholdActive: any;
  thresholdFrom: any;
  bikeThresholdTo: any;
  carThresholdTo: any;
  otherThresholdTo: any;
}

export interface lastmileTimeAutomatedConfigInterface {
  sameAsEarlier: boolean;
  active: boolean;
  lastMileTimeHour: string | number;
  lastMileTimeMin: string | number;
}

export interface DEFAULT_RULE_CONFIG {
  serviceType: string;
  type: string;
  status: string;
  isEditMode: boolean;
  posZoneIdentifierList: Array<any>;
  promiseConfig: PromiseConfigInterface; // Minimum Promise Time
  enableWeightRuleEdit: boolean; // Weight Rule
  weightRule: WeightRule;
  enableCountRuleEdit: boolean; // Count Rule
  countRule: CountRuleInterface;
  enableScaleRuleEdit: boolean; // Scalable Type Rule
  scalableTypeRule: ScalableTypeRuleInterface;
  enableOrderVelocityRuleEdit: boolean; // Order Velocity
  orderVelocityRule: OrderVelocityRuleInterface;
  enableVehicleTypeRuleEdit: boolean; // Vehicle Type
  vehicleTypeRule: VehicleTypeRuleInterface;
  enableLastMileTimeAutomatedConfigPromiseRuleEdit: boolean; // lastmile Time Automated
  lastmileTimeAutomatedConfig: lastmileTimeAutomatedConfigInterface;
  displayName?: string;
}

export const configRuleObject = Object.freeze({ active: false, sameAsEarlier: true });

export const maximumPromiseRuleList = [{ configuredDays: [], maxTimeHour: "", maxTimeMinutes: "" }];
export const minimumPromiseTimeConfig = { startTime: new Date().setHours(0, 0, 0), endTime: new Date().setHours(23, 59, 59), minTimeHour: "", minTimeMinutes: "" };
export const minimumPromiseTimeConfigList = [{ startTime: new Date().setHours(0, 0, 0), endTime: new Date().setHours(23, 59, 59), minTimeHour: "", minTimeMinutes: "" }];
export const minimumPromiseRuleList = [{ configuredDays: [], timeConfig: minimumPromiseTimeConfigList }];

export const promiseConfigObject = Object.freeze({
  minActive: false,
  enableMinTimeEdit: true,
  minTimeSameAsEarlier: true,
  minimumPromiseRuleValues: minimumPromiseRuleList,
  maxActive: false,
  enableMaxTimeEdit: true,
  maxTimeSameAsEarlier: true,
  maximumPromiseRuleValues: maximumPromiseRuleList,
  //
  maxToDisplayActive: false,
  enableMaxTimeToDisplayEdit: true,
  maxTimeToDisplaySameAsEarlier: true,
  maxTimeHourToDisplay: "",
  maxTimeMinutesToDisplay: "",
});

export const vehicleTypeRuleObject = Object.freeze({
  ...configRuleObject,
  bikeThresholdActive: false,
  carThresholdActive: false,
  otherThresholdActive: false,
  thresholdFrom: "",
  bikeThresholdTo: "",
  carThresholdTo: "",
  otherThresholdTo: "",
});

export const DEFAULT_RULE_CONFIG_STATE: DEFAULT_RULE_CONFIG = {
  serviceType: SERVICE_TYPES.STANDARD,
  type: PROMISE_TYPE.AUTOMATED,
  status: "ACTIVATED",
  isEditMode: false,
  posZoneIdentifierList: [],
  promiseConfig: { ...promiseConfigObject },
  enableWeightRuleEdit: true,
  weightRule: { ...configRuleObject, incrementalTime: "", thresholdWeight: "" },
  enableCountRuleEdit: true,
  countRule: { ...configRuleObject, incrementalTime: "", thresholdCount: "" },
  enableScaleRuleEdit: true,
  scalableTypeRule: { ...configRuleObject, itemList: [] },
  enableOrderVelocityRuleEdit: true,
  orderVelocityRule: { ...configRuleObject, orderVelocityRuleValues: [] },
  enableVehicleTypeRuleEdit: true,
  vehicleTypeRule: { ...vehicleTypeRuleObject },
  enableLastMileTimeAutomatedConfigPromiseRuleEdit: true,
  lastmileTimeAutomatedConfig: { ...configRuleObject, lastMileTimeHour: "", lastMileTimeMin: "" },
};

export const getRuleConfig = (isPromiseChnaged: boolean, dynamicPromise: boolean, currentState: DEFAULT_RULE_CONFIG) => {
  let engineRule: any = {};
  if (isPromiseChnaged) {
    if (dynamicPromise) {
      engineRule = { weightRule: currentState.weightRule, countRule: currentState.countRule, scalableTypeRule: currentState.scalableTypeRule };
    } else {
      engineRule = { weightRule: DEFAULT_RULE_CONFIG_STATE.weightRule, countRule: DEFAULT_RULE_CONFIG_STATE.countRule, scalableTypeRule: DEFAULT_RULE_CONFIG_STATE.scalableTypeRule };
    }
  } else {
    engineRule = { weightRule: currentState.weightRule, countRule: currentState.countRule, scalableTypeRule: currentState.scalableTypeRule };
  }
  return engineRule;
};

const getRuleType = (Info: any) => {
  if (Info?.type) {
    return Info?.type;
  } else if (!isUndefined(Info.dynamicPromise)) {
    return Info.dynamicPromise ? PROMISE_TYPE.DYNAMIC : PROMISE_TYPE.STATIC;
  }
};

export const getMinimumPromiseDetails = (minTime: payloadMinimumtimePromiseInterface[]): MinimumPromiseRuleValuesInterface[] => {
  if (!isArrayValid(minTime)) {
    return [...minimumPromiseRuleList];
  }

  const response: MinimumPromiseRuleValuesInterface[] = [];
  minTime.forEach((minTimeObj) => {
    if (isArrayValid(minTimeObj.days)) {
      let daysIndex: number = response.findIndex((obj) => arraysAreEqual(obj.configuredDays, minTimeObj.days));

      if (daysIndex === -1) {
        response.push({ configuredDays: minTimeObj.days, timeConfig: [] });
        daysIndex = response.length - 1; // Get the index of the newly added item
      }

      if (isArrayValid(minTimeObj.timeConfigs)) {
        const timeConfigs = minTimeObj.timeConfigs.map((timeObj) => {
          const { startTime, endTime, time } = timeObj;

          const minutes = !isUndefined(time) ? Math.floor(time / 60) : 0;
          const hour = Math.floor(minutes / 60);
          const mins = minutes % 60;

          return {
            startTime: getMomentDateObj(startTime, DateFormat.HH_MM_SS),
            endTime: getMomentDateObj(endTime, DateFormat.HH_MM_SS),
            minTimeHour: `${hour}`,
            minTimeMinutes: `${mins}`,
          };
        });

        response[daysIndex].timeConfig.push(...timeConfigs);

        // Sort the timeConfig array based on startTime
        response[daysIndex].timeConfig.sort((a, b) => {
          const aStartTimeValue = getTimeValueAsNumber(a.startTime);
          const bStartTimeValue = getTimeValueAsNumber(b.startTime);
          return aStartTimeValue - bStartTimeValue;
        });
      }
    }
  });
  return response;
};

export const getConfiguredData = (Info: any): DEFAULT_RULE_CONFIG => {
  let return_object: DEFAULT_RULE_CONFIG = {
    serviceType: Info?.serviceType,
    type: "",
    status: "ACTIVATED",
    isEditMode: true,
    posZoneIdentifierList: [{ posNo: Info?.posNo, posName: Info?.posName, zoneId: Info?.zoneId, zoneName: Info?.zoneName, status: Info?.status || "" }],
    promiseConfig: { ...promiseConfigObject, minTimeSameAsEarlier: false, maxTimeSameAsEarlier: false, maxTimeToDisplaySameAsEarlier: false },
    enableWeightRuleEdit: false,
    weightRule: { ...DEFAULT_RULE_CONFIG_STATE.weightRule, sameAsEarlier: false },
    enableCountRuleEdit: false,
    countRule: { ...DEFAULT_RULE_CONFIG_STATE.countRule, sameAsEarlier: false },
    enableScaleRuleEdit: false,
    scalableTypeRule: { ...DEFAULT_RULE_CONFIG_STATE.scalableTypeRule, sameAsEarlier: false },
    enableOrderVelocityRuleEdit: false,
    orderVelocityRule: { ...DEFAULT_RULE_CONFIG_STATE.orderVelocityRule, sameAsEarlier: false },
    enableVehicleTypeRuleEdit: false,
    vehicleTypeRule: { ...DEFAULT_RULE_CONFIG_STATE.vehicleTypeRule, sameAsEarlier: false },
    enableLastMileTimeAutomatedConfigPromiseRuleEdit: false,
    lastmileTimeAutomatedConfig: { ...DEFAULT_RULE_CONFIG_STATE.lastmileTimeAutomatedConfig, sameAsEarlier: false },
  };
  return_object.type = getRuleType(Info);

  return_object.promiseConfig = Object.assign({}, return_object.promiseConfig, {
    enableMinTimeEdit: false,
    minActive: true,
    minimumPromiseRuleValues: getMinimumPromiseDetails(Info?.promiseConfig?.minTime),
    enableMaxTimeEdit: false,
    maxActive: Info?.promiseConfig?.maxTime?.length > 0 ? true : false,
    maximumPromiseRuleValues: get_maximum_promise_list_based_on_rule_data(Info.promiseConfig?.maxTime),
    enableMaxTimeToDisplayEdit: false,
    maxToDisplayActive: Info?.promiseConfig?.maxTimeToDisplay && Number(Info?.promiseConfig?.maxTimeToDisplay) > 0 ? true : false,
    maxTimeHourToDisplay: Info?.promiseConfig?.maxTimeToDisplay ? Math.floor(Math.floor(Info?.promiseConfig?.maxTimeToDisplay / 60) / 60) : "",
    maxTimeMinutesToDisplay: Info?.promiseConfig?.maxTimeToDisplay ? Math.floor(Info?.promiseConfig?.maxTimeToDisplay / 60) % 60 : "",
  });

  if (Info?.rulesData?.weightRule?.active) {
    return_object.weightRule = Object.assign({}, return_object.weightRule, {
      active: Info?.rulesData?.weightRule?.active,
      incrementalTime: convertSecondsIntoMinutes(Info?.rulesData?.weightRule?.incrementalTime),
      thresholdWeight: convertWeightInGramsToKiloGrams(Info?.rulesData?.weightRule?.thresholdWeight),
    });
  }

  if (Info?.rulesData?.countRule?.active) {
    return_object.countRule = Object.assign({}, return_object.countRule, {
      active: Info?.rulesData?.countRule?.active,
      incrementalTime: Info?.rulesData?.countRule?.incrementalTime,
      thresholdCount: Info?.rulesData?.countRule?.thresholdCount,
    });
  }

  if (Info?.rulesData?.scalableTypeRule?.active) {
    return_object.scalableTypeRule = Object.assign({}, return_object.scalableTypeRule, {
      active: Info?.rulesData?.scalableTypeRule?.active,
      itemList: (Info?.rulesData?.scalableTypeRule?.itemList && Info?.rulesData?.scalableTypeRule?.itemList?.map((element: any) => ({ ...element, incrementalTime: convertSecondsIntoMinutes(element.incrementalTime) }))) || [],
    });
  }

  if (Info?.rulesData?.orderVelocityRule?.active) {
    return_object.orderVelocityRule = Object.assign({}, return_object.orderVelocityRule, {
      active: Info?.rulesData?.orderVelocityRule?.active || false,
      orderVelocityRuleValues:
        (Info?.rulesData?.orderVelocityRule?.orderVelocityRuleValues &&
          Info?.rulesData?.orderVelocityRule?.orderVelocityRuleValues.map((element: any) => ({
            ...element,
            timeRange: convertSecondsIntoMinutes(element.timeRange),
            extraPromiseTime: convertSecondsIntoMinutes(element.extraPromiseTime),
          }))) ||
        [],
    });
  }

  // if (Info?.rulesData?.vehicleTypeRule?.active) {
  //   return_object.vehicleTypeRule = Object.assign({}, return_object.vehicleTypeRule, {
  //     active: Info?.rulesData?.vehicleTypeRule?.active || false,
  //     bikeThresholdActive: get_bike_active_status_based_on_rule_data(Info?.rulesData?.vehicleTypeRule?.vehicleTypeWeightList, Info?.rulesData?.vehicleTypeRule?.heaviestVehicle),
  //     carThresholdActive: get_car_active_status_based_on_rule_data(Info?.rulesData?.vehicleTypeRule?.vehicleTypeWeightList, Info?.rulesData?.vehicleTypeRule?.heaviestVehicle),
  //     otherThresholdActive: false,
  //     thresholdFrom: 0,
  //     bikeThresholdTo: get_bike_value_status_based_on_rule_data(Info?.rulesData?.vehicleTypeRule?.vehicleTypeWeightList, Info?.rulesData?.vehicleTypeRule?.heaviestVehicle),
  //     carThresholdTo: get_car_value_status_based_on_rule_data(Info?.rulesData?.vehicleTypeRule?.vehicleTypeWeightList, Info?.rulesData?.vehicleTypeRule?.heaviestVehicle),
  //     otherThresholdTo: "",
  //   });
  // }

  if (!isEmptyOrZero(Info?.automatedConfig?.lastMileTime)) {
    return_object.lastmileTimeAutomatedConfig = Object.assign({}, return_object.lastmileTimeAutomatedConfig, {
      active: true,
      lastMileTimeHour: Info?.automatedConfig?.lastMileTime ? Math.floor(Math.floor(Info?.automatedConfig?.lastMileTime / 60) / 60) : "",
      lastMileTimeMin: Info?.automatedConfig?.lastMileTime ? Math.floor(Info?.automatedConfig?.lastMileTime / 60) % 60 : "",
    });
  }

  return return_object;
};

export const convertHoursOrMinutesToSeconds = (time: any, unit: string) => {
  let timeInSeconds = 0;
  switch (unit) {
    case "hours":
      timeInSeconds = time * 60 * 60;
      break;
    case "minutes":
      timeInSeconds = time * 60;
      break;

    default:
      break;
  }
  return timeInSeconds;
};

export const convertWeightInKiloGramsToGrams = (weightInGrams: any = 0) => {
  return weightInGrams * 1000;
};

export const display_empty_promise_error = (valueName: string) => {
  CustomAlert(ALERT_TYPES.ERROR, `${valueName}.`);
};

export const checkTime = (timeHour: string | number | null, timeMin: string | number | null, message: string): boolean => {
  if (isEmptyOrZero(timeHour) && isEmptyOrZero(timeMin)) {
    display_empty_promise_error(message);
    return false;
  } else if (!isEmptyOrZero(timeHour) && timeHour == "0" && isEmptyOrZero(timeMin)) {
    display_empty_promise_error(message);
    return false;
  }
  return true;
};

export const isMinimumPromiseTimeValid = (expressRuleConfig: DEFAULT_RULE_CONFIG) => {
  const minimumPromiseRuleValues = expressRuleConfig?.promiseConfig?.minimumPromiseRuleValues;

  if (!isArrayValid(minimumPromiseRuleValues)) {
    display_empty_promise_error(HEADER_NAME_CONSTANT.ENTER_MINIMUM_PROMISE_TIME);
    return false;
  }

  let totalDaysLength: number = 0;
  for (const minimumPromiseRule of minimumPromiseRuleValues) {
    if (!minimumPromiseRule) {
      display_empty_promise_error(HEADER_NAME_CONSTANT.ENTER_MINIMUM_PROMISE_TIME);
      return false;
    }

    const { configuredDays, timeConfig } = minimumPromiseRule;
    if (!isArrayValid(configuredDays)) {
      display_empty_promise_error(HEADER_NAME_CONSTANT.ENTER_MINIMUM_PROMISE_DAYS_TIME);
      return false;
    }

    if (minimumPromiseRuleValues.length === 1 && configuredDays.length !== DAYS_DROPDOWN_OPTIONS.length) {
      display_empty_promise_error(HEADER_NAME_CONSTANT.ENTER_MINIMUM_PROMISE_DAYS_LENGTH);
      return false;
    }

    totalDaysLength += configuredDays.length;

    if (isArrayValid(timeConfig)) {
      for (const timeObj of timeConfig) {
        const { startTime, endTime, minTimeHour, minTimeMinutes } = timeObj;

        if (!startTime || !endTime || !moment(startTime).isValid() || !moment(endTime).isValid()) {
          display_empty_promise_error(HEADER_NAME_CONSTANT.PROVIDE_VALID_START_END_TIME);
          return false;
        }

        if (!checkTime(minTimeHour, minTimeMinutes, HEADER_NAME_CONSTANT.ENTER_MINIMUM_PROMISE_TIME)) {
          return false;
        }
      }
    }
  }

  if (minimumPromiseRuleValues.length !== 1 && totalDaysLength !== DAYS_DROPDOWN_OPTIONS.length) {
    display_empty_promise_error(HEADER_NAME_CONSTANT.ENTER_MINIMUM_PROMISE_DAYS_LENGTH);
    return false;
  }
  return true;
};

export const is_automated_promise_valid = (expressRuleConfig: DEFAULT_RULE_CONFIG) => {
  return checkTime(expressRuleConfig.lastmileTimeAutomatedConfig.lastMileTimeHour, expressRuleConfig.lastmileTimeAutomatedConfig.lastMileTimeMin, HEADER_NAME_CONSTANT.ENTER_AUTOMATED_LAST_MILE_TIME);
};

const handleError = (message: string): boolean => {
  CustomAlert(ALERT_TYPES.ERROR, message);
  return false;
};

const isPosZoneMappingValid = (expressRuleConfig: DEFAULT_RULE_CONFIG) => {
  if (!expressRuleConfig.posZoneIdentifierList || !isArrayValid(expressRuleConfig.posZoneIdentifierList)) {
    handleError(HEADER_NAME_CONSTANT.PLEASE_ASSIGN_POS_ZONE);
    return false;
  }
  return true;
};

function isValidElement(element: PromiseConfigMaxTime): boolean {
  if (!isArrayValid(element.configuredDays)) {
    CustomAlert(ALERT_TYPES.ERROR, HEADER_NAME_CONSTANT.PLEASE_ENTER_DAYS);
    return false;
  } else if (isEmptyOrNull(element.maxTimeHour)) {
    CustomAlert(ALERT_TYPES.ERROR, HEADER_NAME_CONSTANT.PLEASE_ENTER_MAXIMUM_PROMISE_HOUR);
    return false;
  } else if (isEmptyOrNull(element.maxTimeMinutes)) {
    CustomAlert(ALERT_TYPES.ERROR, HEADER_NAME_CONSTANT.PLEASE_ENTER_MAXIMUM_PROMISE_MINUTES);
    return false;
  } else if (element.maxTimeHour === 0 && element.maxTimeMinutes === 0) {
    CustomAlert(ALERT_TYPES.ERROR, HEADER_NAME_CONSTANT.PLEASE_ENTER_VALID_PROMISE_HOUR_AND_MINUTES);
    return false;
  }
  return true;
}

const isMaximumPromiseTimeValid = (expressRuleConfig: DEFAULT_RULE_CONFIG) => {
  if (expressRuleConfig?.promiseConfig?.maximumPromiseRuleValues?.length > 0) {
    const itemLength = expressRuleConfig.promiseConfig.maximumPromiseRuleValues.length;
    let days = [] as any;
    for (let index = 0; index < itemLength; index++) {
      const element = expressRuleConfig.promiseConfig.maximumPromiseRuleValues[index];
      if (!isValidElement(element)) {
        return;
      }
      days = [...days, ...element.configuredDays];
    }
    const remainingDays = ALL_DAYS_VALUES.filter((day) => !days.includes(day));
    if (remainingDays.length !== 0) {
      return handleError(HEADER_NAME_CONSTANT.PLEASE_DEFINE_MAXIMUM_PROMISE_FOR_ALL_DAYS);
    }
  } else {
    return handleError(HEADER_NAME_CONSTANT.PLEASE_DEFINE_MAXIMUM_PROMISE_FOR_ALL_DAYS);
  }

  return true;
};

export const validateRuleEngineData = (expressRuleConfig: DEFAULT_RULE_CONFIG) => {
  let configurationPresent: number = 0;

  /** Type */
  if (isEmptyOrNull(expressRuleConfig.type)) {
    return handleError(HEADER_NAME_CONSTANT.PLEASE_SELECT_PROMISE_TYPE);
  } else {
    configurationPresent++;
  }

  /** Pos Zone Mapping */
  if (!isPosZoneMappingValid(expressRuleConfig)) {
    return false;
  }

  /** Maximum Promise Rule */
  if (expressRuleConfig.type !== PROMISE_TYPE.STATIC && expressRuleConfig.promiseConfig.maxActive) {
    configurationPresent++;
    if (!isMaximumPromiseTimeValid(expressRuleConfig)) {
      return false;
    }
  }

  /** Maximum Promise Time For Visibility */
  if (expressRuleConfig.type !== PROMISE_TYPE.STATIC && expressRuleConfig.promiseConfig.maxToDisplayActive) {
    configurationPresent++;
    if (isEmptyOrNull(expressRuleConfig.promiseConfig.maxTimeHourToDisplay)) {
      return handleError(HEADER_NAME_CONSTANT.PLEASE_ENTER_MAXIMUM_FOR_VISIBILITY_PROMISE_HOUR);
    } else if (isEmptyOrNull(expressRuleConfig.promiseConfig.maxTimeMinutesToDisplay)) {
      return handleError(HEADER_NAME_CONSTANT.PLEASE_ENTER_MAXIMUM_FOR_VISIBILITY_PROMISE_MINUTES);
    }
  }

  /** Min Active */
  if (expressRuleConfig.promiseConfig.minActive) {
    configurationPresent++;
    if (!isMinimumPromiseTimeValid(expressRuleConfig)) {
      return false;
    }
  }

  /** Lastmile Time Automated Config */
  if (expressRuleConfig.type === PROMISE_TYPE.AUTOMATED) {
    if (!is_automated_promise_valid(expressRuleConfig)) {
      return false;
    }
    configurationPresent++;
    return true;
  }

  /** Vehicle Type Rule */
  // if (expressRuleConfig.type === PROMISE_TYPE.DYNAMIC || expressRuleConfig.type === PROMISE_TYPE.STATIC) {
  //   if (expressRuleConfig.vehicleTypeRule !== undefined && expressRuleConfig.vehicleTypeRule.active !== undefined && expressRuleConfig.vehicleTypeRule.active) {
  //     configurationPresent++;
  //     if (expressRuleConfig.vehicleTypeRule.bikeThresholdActive === false && expressRuleConfig.vehicleTypeRule.carThresholdActive === false) {
  //       CustomAlert(ALERT_TYPES.ERROR, HEADER_NAME_CONSTANT.PLEASE_PROVIDE_VEHICLE_TYPE_RULE);
  //       return false;
  //     } else {
  //       if (expressRuleConfig.vehicleTypeRule.bikeThresholdActive === true && expressRuleConfig.vehicleTypeRule.carThresholdActive === true) {
  //         if (isEmptyOrNull(expressRuleConfig.vehicleTypeRule.bikeThresholdTo)) {
  //           CustomAlert(ALERT_TYPES.ERROR, HEADER_NAME_CONSTANT.PLEASE_PROVIDE_VALID_THRESHOLD_VALUE);
  //           return false;
  //         }
  //       }
  //     }
  //   }
  // }

  if (expressRuleConfig.type === PROMISE_TYPE.DYNAMIC) {
    /** Scalable Type Rule */
    if (expressRuleConfig.scalableTypeRule && expressRuleConfig.scalableTypeRule.active) {
      configurationPresent++;
      const itemLength = expressRuleConfig?.scalableTypeRule?.itemList?.length;
      let scalableItemError = expressRuleConfig?.scalableTypeRule?.itemList?.some((item: any) => isEmptyOrNull(item.section) || isEmptyOrNull(item.incrementalTime)) || itemLength === 0;
      if (scalableItemError) {
        if (itemLength === 0) {
          CustomAlert(ALERT_TYPES.ERROR, HEADER_NAME_CONSTANT.ADD_PRODUCTS_IN_TYPE);
          return;
        } else {
          for (let index = 0; index < itemLength; index++) {
            const element = expressRuleConfig.scalableTypeRule.itemList[index];
            if (isEmptyOrNull(element.section)) {
              CustomAlert(ALERT_TYPES.ERROR, HEADER_NAME_CONSTANT.SELECT_PRODUCTS_FROM_CATALOG);
              return;
            } else if (isEmptyOrNull(element.incrementalTime)) {
              CustomAlert(ALERT_TYPES.ERROR, `${HEADER_NAME_CONSTANT.ENTER_INCREMENTAL_TIME} ${element.section}`);
              return;
            }
          }
        }
      }
    }

    /** Count Rule */
    if (expressRuleConfig.countRule && expressRuleConfig.countRule.active) {
      configurationPresent++;
      if (isEmptyOrNull(expressRuleConfig.countRule.thresholdCount)) {
        return handleError(HEADER_NAME_CONSTANT.ITEM_COUNT_THRESHOLD_LIMIT_ERROR);
      } else if (isEmptyOrNull(expressRuleConfig.countRule.incrementalTime)) {
        return handleError(HEADER_NAME_CONSTANT.INCREMENTAL_TIME_ABOVE_THRESHOLD_ERROR);
      }
    }

    /** Weight Rule */
    if (expressRuleConfig.weightRule && expressRuleConfig.weightRule.active) {
      configurationPresent++;
      if (isEmptyOrNull(expressRuleConfig.weightRule.thresholdWeight)) {
        return handleError(HEADER_NAME_CONSTANT.ITEM_COUNT_THRESHOLD_WEIGHT_LIMIT_ERROR);
      } else if (isEmptyOrNull(expressRuleConfig.weightRule.incrementalTime)) {
        return handleError(HEADER_NAME_CONSTANT.INCREMENTAL_TIME_ABOVE_WEIGHT_THRESHOLD_ERROR);
      }
    }

    /** Order Velocity Rule */
    if (expressRuleConfig.orderVelocityRule !== undefined && expressRuleConfig.orderVelocityRule.active) {
      configurationPresent++;
      if (expressRuleConfig?.orderVelocityRule?.orderVelocityRuleValues?.length > 0) {
        const itemLength = expressRuleConfig.orderVelocityRule.orderVelocityRuleValues.length;
        for (let index = 0; index < itemLength; index++) {
          const element = expressRuleConfig.orderVelocityRule.orderVelocityRuleValues[index];
          if (isEmptyArray(element.configuredDays)) {
            return handleError(HEADER_NAME_CONSTANT.ORDER_VELOCITY_DAYS_ERROR);
          } else if (isEmptyOrNull(element.timeRange)) {
            return handleError(HEADER_NAME_CONSTANT.ORDER_VELOCITY_ITEM_RANGE_ERROR);
          } else if (isEmptyOrNull(element.orderThreshold)) {
            return handleError(HEADER_NAME_CONSTANT.ORDER_VELOCITY_ORDER_THRESHOLD_ERROR);
          } else if (isEmptyOrNull(element.extraPromiseTime)) {
            return handleError(HEADER_NAME_CONSTANT.ORDER_VELOCITY_EXTRA_PROMISE_ERROR);
          }
        }
      } else {
        return handleError(HEADER_NAME_CONSTANT.ORDER_VELOCITY_CONFIGURATION_ERROR);
      }
    }
  }

  /** No promise configuration change found to save/update */
  if (configurationPresent === 0) {
    CustomAlert(ALERT_TYPES.ERROR, HEADER_NAME_CONSTANT.NO_CONFIGURATION_PRESENT);
    return;
  }

  return true;
};

export function getUniqueList(selectedProduct: Array<any>, currentItem: any) {
  let selectedItem = selectedProduct && selectedProduct.map((element: any) => element.section);
  let uniqueItem = SCALABLE_OR_NPN_SCALABLE_ITEMS.filter((item: any) => !selectedItem.includes(item.id));
  if (currentItem && currentItem.section) {
    uniqueItem.push({
      id: currentItem.section,
      name: currentItem.section,
    });
  }
  return uniqueItem;
}

export const get_first_vehicle_type = (rule: any) => {
  if (rule !== undefined) {
    if (rule.bikeThresholdActive === true && rule.carThresholdActive === true) {
      return "CAR";
    } else if (rule.bikeThresholdActive === true) {
      return "BIKE";
    } else if (rule.carThresholdActive === true) {
      return "CAR";
    }
  }
  return "";
};

export const get_remaining_weight_list = (rule: any) => {
  if (rule !== undefined) {
    let return_list_data = [] as any;
    if (rule.bikeThresholdActive === true && rule.carThresholdActive === true) {
      let val = {
        vehicleType: "BIKE",
        thresholdWeight: convertWeightInKiloGramsToGrams(rule.bikeThresholdTo),
      };
      return_list_data.push(Object.assign({}, val));
    }
    return return_list_data;
  }
  return [];
};

export const get_bike_active_status_based_on_rule_data = (list_data: any, heaviestVehicle: any) => {
  if (heaviestVehicle === "BIKE") {
    return true;
  } else if (list_data !== undefined && Array.isArray(list_data) && list_data.length > 0) {
    let bike_found = false;
    list_data.forEach((val: any) => {
      if (val.vehicleType === "BIKE") {
        bike_found = true;
      }
    });
    return bike_found;
  }
  return false;
};

export const get_car_active_status_based_on_rule_data = (list_data: any, heaviestVehicle: any) => {
  if (heaviestVehicle === "CAR") {
    return true;
  } else if (list_data !== undefined && Array.isArray(list_data) && list_data.length > 0) {
    let bike_found = false;
    list_data.forEach((val: any) => {
      if (val.vehicleType === "CAR") {
        bike_found = true;
      }
    });
    return bike_found;
  }
  return false;
};

export const get_bike_value_status_based_on_rule_data = (list_data: any, heaviestVehicle: any) => {
  let thresholdValue: number = 0;
  if (heaviestVehicle === "BIKE") {
    return 0;
  } else if (list_data !== undefined && Array.isArray(list_data) && list_data.length > 0) {
    list_data.forEach((val: any) => {
      if (val.vehicleType === "BIKE") {
        thresholdValue = convertWeightInGramsToKiloGrams(Number(val?.thresholdWeight));
      }
    });
  }
  return thresholdValue;
};

export const get_car_value_status_based_on_rule_data = (list_data: any, heaviestVehicle: any) => {
  let thresholdValue: number = 0;
  if (heaviestVehicle === "CAR") {
    return 0;
  } else if (list_data !== undefined && Array.isArray(list_data) && list_data.length > 0) {
    list_data.forEach((val: any) => {
      if (val.vehicleType === "CAR") {
        thresholdValue = convertWeightInGramsToKiloGrams(Number(val?.thresholdWeight));
      }
    });
  }
  return thresholdValue;
};

export const ALL_DAYS_VALUES = DAYS_DROPDOWN_OPTIONS.reduce((acc: any, day: any) => [...acc, day.id], []);

export const calculateAvailableDaysForSelection = (currentIndex: any, object_data: any) => {
  let remainingDays = [...DAYS_DROPDOWN_OPTIONS];
  if (currentIndex !== 0) {
    let daysSelectedSoFar = [] as any;
    for (let i = 0; i < currentIndex; i++) {
      daysSelectedSoFar = [...daysSelectedSoFar, ...object_data[i].configuredDays];
    }
    remainingDays = DAYS_DROPDOWN_OPTIONS.filter((day) => !daysSelectedSoFar.includes(day.id));
  }
  return remainingDays;
};

export const get_maximum_promise_list_based_on_rule_data = (maxTime: PromiseApiConfigMaxTime[]) => {
  let values = [{ maxTimeHour: "", maxTimeMinutes: "", configuredDays: [...ALL_DAYS_VALUES] }] as PromiseConfigMaxTime[];
  if (maxTime?.length > 0) {
    values = [];
    maxTime.forEach((promise: PromiseApiConfigMaxTime) => {
      let obj = {
        configuredDays: [...promise.days],
        maxTimeHour: promise.time ? Math.floor(Math.floor(Number(promise.time) / 60) / 60) : "",
        maxTimeMinutes: promise.time ? Math.floor(Number(promise.time) / 60) % 60 : "",
      };
      values.push(obj);
    });
  }
  return values;
};

export const getRequestPayloadObject = (params: any) => {
  let requestPayload = {} as any;
  requestPayload = {
    countryId: params.get("countryId"),
    serviceType: params.get("serviceType"),
    posId: params.get("posNo"),
    zoneId: params.get("zoneId"),
  };
  return requestPayload;
};

interface PosZoneIdentifier {
  posNo: string;
  posName: string;
  zoneId: number;
  zoneName: string;
  status: string;
}

interface returnTypeMinPromiseInterface {
  days: string[];
  timeConfigs: {
    startTime: string | Moment;
    endTime: string | Moment;
    time: string | number;
  }[];
}

interface TimeConfig {
  time: string | number;
  startTime: string | Moment;
  endTime: string | Moment;
}

interface MinTimeConfig {
  days: string[];
  timeConfigs: TimeConfig[];
}

interface ApiPromiseConfig {
  maxTimeSameAsEarlier: boolean;
  maxTime: PromiseApiConfigMaxTime[];
  minTimeSameAsEarlier: boolean;
  minTime: returnTypeMinPromiseInterface[];
  maxTimeToDisplay: string | number | null;
  maxTimeToDisplaySameAsEarlier: boolean;
}

interface WeightPromiseRule {
  active: boolean;
  sameAsEarlier: boolean;
  incrementalTime: string | number;
  thresholdWeight: string | number;
}

interface CountRule {
  active: boolean;
  sameAsEarlier: boolean;
  incrementalTime: string | number;
  thresholdCount: string | number;
}

interface ScalableTypeRuleItem {
  active: boolean;
  sameAsEarlier: boolean;
  incrementalTime: string | number;
  section: string | string;
}

interface ScalableTypeRule {
  active: boolean;
  sameAsEarlier: boolean;
  itemList: ScalableTypeRuleItem[];
}

interface OrderVelocityRuleValue {
  timeRange: string | number;
  extraPromiseTime: string | number;
  orderThreshold?: string | number;
  configuredDays?: string[];
}

interface OrderVelocityRule {
  active: boolean;
  sameAsEarlier: boolean;
  orderVelocityRuleValues: OrderVelocityRuleValue[];
}

interface VehicleTypeRule {
  active: boolean;
  sameAsEarlier: boolean;
  bikeThresholdActive: boolean;
  carThresholdActive: boolean;
  otherThresholdActive: boolean;
  thresholdFrom: number;
  bikeThresholdTo: number;
  carThresholdTo: number;
  otherThresholdTo: string;
  heaviestVehicle: string;
  vehicleTypeWeightList: any[];
}
interface returnObjectInterface {
  countryId: string | number;
  serviceType: string;
  type: string;
  status: string;
  dynamicPromise: boolean;
  posZones: PosZoneIdentifier[];
  promiseConfig: ApiPromiseConfig;
  automatedConfig: { lastMileTime: number | null };
  weightRule?: WeightPromiseRule;
  countRule?: CountRule;
  scalableTypeRule?: ScalableTypeRule;
  orderVelocityRule?: OrderVelocityRule;
  vehicleTypeRule?: VehicleTypeRule;
}

export const createSaveUpdateRequestPayload = (expressRuleConfig: DEFAULT_RULE_CONFIG, currentCountry: any) => {
  const returnObject: returnObjectInterface = {
    countryId: currentCountry?.countryId,
    serviceType: expressRuleConfig.serviceType,
    type: expressRuleConfig.type,
    status: expressRuleConfig.status,
    dynamicPromise: expressRuleConfig.type === PROMISE_TYPE.DYNAMIC ? true : false,
    posZones: expressRuleConfig.posZoneIdentifierList,
    promiseConfig: { maxTimeSameAsEarlier: true, maxTime: [], minTimeSameAsEarlier: true, minTime: [], maxTimeToDisplay: null, maxTimeToDisplaySameAsEarlier: true },
    automatedConfig: { lastMileTime: null },
  };

  if (expressRuleConfig.promiseConfig.minActive) {
    returnObject.promiseConfig.minTimeSameAsEarlier = false;
    const minimumPromiseRuleValues = expressRuleConfig.promiseConfig.minimumPromiseRuleValues;
    minimumPromiseRuleValues.forEach((minimumPromise: MinimumPromiseRuleValuesInterface) => {
      const { configuredDays, timeConfig } = minimumPromise;
      const minimumObj: returnTypeMinPromiseInterface = { days: configuredDays, timeConfigs: [] };
      timeConfig.forEach((timeObj: TimeConfigItem) => {
        const { startTime, endTime, minTimeHour, minTimeMinutes } = timeObj;
        minimumObj.timeConfigs.push({
          startTime: getMomentDateBasedOnFormat(startTime, DateFormat.HH_MM_SS),
          endTime: getMomentDateBasedOnFormat(endTime, DateFormat.HH_MM_SS),
          time: convertHoursOrMinutesToSeconds(minTimeHour, "hours") + convertHoursOrMinutesToSeconds(minTimeMinutes, "minutes"),
        });
      });
      returnObject.promiseConfig.minTime.push(minimumObj);
    });
  }

  if (expressRuleConfig?.promiseConfig?.maxActive && expressRuleConfig?.promiseConfig?.maximumPromiseRuleValues?.length > 0) {
    returnObject.promiseConfig.maxTimeSameAsEarlier = false;
    returnObject.promiseConfig.maxTime = expressRuleConfig.promiseConfig.maximumPromiseRuleValues.map((item: PromiseConfigMaxTime) => ({
      days: [...item?.configuredDays],
      time: convertHoursOrMinutesToSeconds(item?.maxTimeHour, "hours") + convertHoursOrMinutesToSeconds(item.maxTimeMinutes, "minutes"),
    }));
  }

  if (expressRuleConfig.promiseConfig.maxToDisplayActive) {
    returnObject.promiseConfig.maxTimeToDisplaySameAsEarlier = false;
    returnObject.promiseConfig.maxTimeToDisplay = convertHoursOrMinutesToSeconds(expressRuleConfig.promiseConfig.maxTimeHourToDisplay, "hours") + convertHoursOrMinutesToSeconds(expressRuleConfig.promiseConfig.maxTimeMinutesToDisplay, "minutes");
  }

  if (expressRuleConfig.type === PROMISE_TYPE.AUTOMATED) {
    const { lastMileTimeHour, lastMileTimeMin } = expressRuleConfig.lastmileTimeAutomatedConfig;
    returnObject.automatedConfig.lastMileTime = convertHoursOrMinutesToSeconds(lastMileTimeHour, "hours") + convertHoursOrMinutesToSeconds(lastMileTimeMin, "minutes");
  }

  // if (expressRuleConfig.type === PROMISE_TYPE.DYNAMIC || expressRuleConfig.type === PROMISE_TYPE.STATIC) {
  //   returnObject.vehicleTypeRule = {
  //     ...expressRuleConfig.vehicleTypeRule,
  //     heaviestVehicle: get_first_vehicle_type(expressRuleConfig?.vehicleTypeRule),
  //     vehicleTypeWeightList: get_remaining_weight_list(expressRuleConfig?.vehicleTypeRule),
  //   };
  // }

  if (expressRuleConfig.type === PROMISE_TYPE.DYNAMIC) {
    returnObject.weightRule = {
      ...expressRuleConfig.weightRule,
      thresholdWeight: convertWeightInKiloGramsToGrams(expressRuleConfig.weightRule.thresholdWeight),
      incrementalTime: convertHoursOrMinutesToSeconds(expressRuleConfig.weightRule.incrementalTime, "minutes"),
    };
    returnObject.countRule = {
      ...expressRuleConfig.countRule,
      thresholdCount: expressRuleConfig.countRule.thresholdCount || 0,
      incrementalTime: expressRuleConfig.countRule.incrementalTime || 0,
    };
    returnObject.scalableTypeRule = {
      ...expressRuleConfig.scalableTypeRule,
      itemList: expressRuleConfig.scalableTypeRule.itemList.map((item: any) => ({
        ...item,
        incrementalTime: convertHoursOrMinutesToSeconds(item.incrementalTime, "minutes"),
      })),
    };
    returnObject.orderVelocityRule = {
      ...expressRuleConfig.orderVelocityRule,
      orderVelocityRuleValues: expressRuleConfig.orderVelocityRule.orderVelocityRuleValues.map((item: any) => ({
        ...item,
        timeRange: convertHoursOrMinutesToSeconds(item.timeRange, "minutes"),
        extraPromiseTime: convertHoursOrMinutesToSeconds(item.extraPromiseTime, "minutes"),
      })),
    };
  }

  return returnObject;
};

export interface TimeConfigInterface {
  time: number;
  startTime: string;
  endTime: string;
}

export interface payloadMinimumtimePromiseInterface {
  days: string[];
  timeConfigs: TimeConfigInterface[];
}