import { generateDropdownWithMentionedIntervalItems } from "../utils/helperFunctions";

export const APP_ROUTES = {
  HOME: "/login",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  CREATE_POS: "/create-pos",
  SERVICE_AND_SLOTS: "/service-and-slots",
  BASIC_DETAILS: "/basic",
  STANDARD: "/standard",
  EXPRESS: "/express",
  CLICK_N_COLLECT: "/click_n_collect",
  DEFAULT: "/DEFAULT",
  COMPLETE_SETUP: "/complete-setup",
  SETUP_POS: "/setup-pos",
  POS_LIST: "/pos-list",
  COUNTRY_SETTINGS: "/country-settings",
  USER_MANAGEMENT: "/user-management",
  RULE_ENGINE: "/rule-engine",
  RULE_ENGINE_CURRENT_CONFIGURATION: "/rule-engine/configuration",
  DELIVERY_FEE: "/delivery-fee",
  DELIVERY_FEE_CONFIGURATION: "/delivery-fee/configuration",
  DELIVERY_FEE_PREVIEW_CONFIGURATION: "/delivery-fee/preview-configuration",
  SINGLE_USE_PLASTIC: "/single-use-plastic",
  SINGLE_USE_PLASTIC_CURRENT_CONFIGURATION: "/single-use-plastic/configuration",
  EDIT_SINGLE_USE_PLASTIC_CURRENT_CONFIGURATION: "/single-use-plastic/edit-configuration",
  APPROVAL_REQUESTS: "/approval-flow-request",
  NEW_APPROVAL_REQUESTS: "/new-approval-request-for-pos",
  APPROVAL_REQUESTS_DETAILS: "/approval-flow-request-details",
  POLYGON_SERVICES: "/polygon-services",
  POLYGON_SERVICES_MAP: "/polygon-services-map-view",
  POLYGON_SERVICES_CONFIGURATION: "/polygon-services/configuration",
};

export const SERVICE_TYPES = { STANDARD: "STANDARD", EXPRESS: "EXPRESS", CLICK_N_COLLECT: "CLICK_N_COLLECT", DEFAULT: "DEFAULT", FBC: "FBC" } as const;
export const API_RESPONSE_STATUS = { IDLE: "idle", LOADING: "loading", SUCCEEDED: "succeeded", FAILED: "failed" };
export const PRODUCT_TYPES = { FOOD: "food", NON_FOOD: "nonfood" };
export const FOOD_SUB_TYPE_KEY_VALUE_PAIR = { FOOD_DRY: "Dry", FOOD_FRESH: "Fresh", FOOD_FROZEN: "Frozen" };
export const ALERT_TYPES = { SUCCESS: "success", ERROR: "error", WARN: "warn" };
export const QUOTA_VIEW_OPTIONS = { VIEW: "view", MODIFY: "modify" };
export const API_ERROR_CODES = { SERVICE_NOT_CONFIGURED: "Stores_R01" };
export const DATE_FORMAT_CONSTANT = { DATE_FORMAT_API: "YYYY-MM-DD", DATE_FORMAT: "DD/MM/YYYY", DATE_FORMAT_UI: "YYYY-MM-DD" };
export const STORE_STATE = { BUSY: "BUSY", AVAILABLE: "AVAILABLE" };
export const SELECT_ALL = { id: "All", name: "Select All" };
export const AREA_POS_MAPPING_TYPE = { AREA_BASED: "AREA_BASED", POLYGON_BASED: "POLYGON_BASED" };
export const SLOT_STATUSES = { ACTIVATED: "ACTIVATED", PENDING_ACTIVATION: "PENDING_ACTIVATION", DEACTIVATED: "DEACTIVATED", DELETED: "DELETED" };
export const ADDITIONAL_SERVICES = { DELIVERY_PROMISE_RULE_ENGINE: 1, COUNTRY_SETTINGS: 2, DELIVERY_FEE: 3, POLYGON_SERVICE: 4 };
export const ZONE_CHANGE_STATUS = { ACTIVATED: "ACTIVATED", DEACTIVATED: "DEACTIVATED", DELETED: "DELETED" };
export const APPROVAL_FLOW_ACTIONS = { APPROVED: "APPROVED", REJECTED: "REJECTED", DELETED: "DELETED", PENDING: "PENDING" };
export const STATUS = { ACTIVE: "Active", IN_ACTIVE: "In-active", ENABLED: "Enabled", DISABLED: "Disabled" };
export const SWITCH_STATUS = { ON: "On", OFF: "Off" };
export const TEXT_CONSTANT = { ALL: "All", BULK_UPDATE_NO_DAYS_SELECTED: "Please provide days to apply changes." };
export const SELECT_ALL_VALUE = { name: "Select All", value: "all" };
export const SELECT_NONE = { name: "Please Select", value: "n/a" };
export const DELIVERY_TYPE = { SLOT_BASED: "SLOT_BASED", DAY_BASED: "DAY_BASED", CLICK_N_COLLECT: "CLICK_N_COLLECT", MINUTE_BASED: "MINUTE_BASED", FULFILLED_BY_CARREFOUR: "Fulfilled By Carrefour" };
export const OBJECT_SERVICE_TYPE = { EXPRESS: "express", CLICK_N_COLLECT: "click_n_collect", DEFAULT: "default", STANDARD: "standard" };

export const DELIVERY_TYPES = { SLOT_BASED: "SLOT_BASED", DAY_BASED: "DAY_BASED", MINUTE_BASED: "MINUTE_BASED" } as const;
export type SERVICE_TYPES_KEYS = keyof typeof SERVICE_TYPES;
export interface CoordinatesInterface {
  lat: number;
  lng: number;
}

export const EXPORT_STANDARD_AND_EXPRESS_ENABLE_FLAG = false;

export const API_HOST_URL = `${process.env.REACT_APP_BASE_URL || "https://slotservice-api-develop-hc.mafrservicesstg.com/api/v1"}`;
export const API_HOST_URL_V2 = `${process.env.REACT_APP_BASE_URL_V2 || "https://slotservice-api-develop-hc.mafrservicesstg.com/api/v2"}`;
export const REACT_APP_DOS_RULE_ENGINE_API_URL = `${process.env.REACT_APP_DOS_RULE_ENGINE_API_URL}`;
export const REACT_APP_DOS_RULE_ENGINE_API_URL_V2 = `${process.env.REACT_APP_DOS_RULE_ENGINE_API_URL_V2}`;
export const APP_USER_MGMT_URL = `${process.env.REACT_APP_USER_MGMT_URL}`;
export const APP_DLV_PROMISE_URL = `${process.env.REACT_APP_DOS_DELIVERY_PROMISE_API_URL || "https://dos-delivery-promise-test.mafrservicesstg.com/api/v1"}`;
export const API_HOST_DUMMY_URL = `${process.env.REACT_APP_BASE_DUMMY_URL || "https://slotservice-api-test.mafrservicesstg.com"}`;
export const API_POLYGON_SERVICE_URL = `${process.env.REACT_APP_POLYGON_SERVICE || "https://str-loc-poly-svc.mafrservices.com/api/v1"}`;
export const googleApiCode = `${process.env.REACT_APP_GOOGLE_API_ID || "AIzaSyAxZDBDwnMWAkflCX5Gv_41iXxy2uYkfds"}`;
export const APP_DLV_PROMISE_MAIN_URL = `${process.env.REACT_APP_DOS_DELIVERY_PROMISE || "https://dos-delivery-promise.mafrservices.com"}`;
export const API_POLYGON_SERVICE_BASIC_URL = `${process.env.REACT_APP_BASIC_POLYGON_SERVICE || "https://str-loc-poly-svc.mafrservices.com"}`;

export const DELIVERY_PROMISE_VEHICLE_TYPE_LIST = ["BIKE", "CAR"]; // ["BIKE", "CAR", "OTHER"];

export const FOOD_SUB_TYPES = [
  { label: "Dry", value: "FOOD_DRY" },
  { label: "Fresh", value: "FOOD_FRESH" },
  { label: "Frozen", value: "FOOD_FROZEN" },
];

export const VALIDATE_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const VALIDATE_POS_NUMBER = /^[A-Za-z\d\s]+$/;
export const VALIDATE_CUST_CONTACT = /^[+]?\d+$/;
export const VALIDATE_COORDINATES = /^\d+(\.\d+)?$/;
export const NUMBER_REGEX = /^[0-9\s]*$/;

export const ALL_DAYS = "All Days";
export const HH_MM_SS = "HH:mm:ss";
export const HH_MM = "HH:mm";
export const CURRENT_COUNTRY = "currentCountry";
export const USER_INFO = "user";
export const USER_INFO_DOS = "user_dos";
export const STATUS_OK = "OK";
export const ITEMS_PER_PAGE = 10;
export const PHARMACY = "Pharmacy";
export const MAF_USER = "MAF";
export const SUPER_ADMIN = "SUPER";
export const READONLY_USER = "READONLY";
export const APPROVER = "APPROVER";
export const NEW_PATH_SELECTED = "newPathSelectedOnSideMenu";
export const DATE_FORMAT_API = "YYYY-MM-DD";
export const DATE_FORMAT = "DD/MM/YYYY";
export const REQUESTER_COMMENT = "Requester Comment";
export const APPROVER_COMMENT = "Approver Comment";

export const DAYS_DROPDOWN_OPTIONS = [
  { id: "MONDAY", name: "Monday" },
  { id: "TUESDAY", name: "Tuesday" },
  { id: "WEDNESDAY", name: "Wednesday" },
  { id: "THURSDAY", name: "Thursday" },
  { id: "FRIDAY", name: "Friday" },
  { id: "SATURDAY", name: "Saturday" },
  { id: "SUNDAY", name: "Sunday" },
];

export const DISPLAY_WEEKLY_HOLIDAYS = [
  { value: false, name: "Monday" },
  { value: false, name: "Tuesday" },
  { value: false, name: "Wednesday" },
  { value: false, name: "Thursday" },
  { value: false, name: "Friday" },
  { value: false, name: "Saturday" },
  { value: false, name: "Sunday" },
];

export const COLLECTION_POINT_TYPES = [
  { id: "PARKING", name: "PARKING" },
  { id: "STORE", name: "STORE" },
  { id: "CLEVERON", name: "CLEVERON" },
  { id: "LOCKER", name: "LOCKER" },
];

export const EXPRESS_TYPES = {
  ON_DEMAND: { value: "ON_DEMAND", label: "On Demand" },
  SLOT_BASED: { value: "SLOT_BASED", label: "Slot Based" },
};

export const REPORT_TYPES = {
  POS_BASIC: "POS_BASIC",
  CNC_CONFIG: "CNC_CONFIG",
  STANDARD_CONFIG: "STANDARD_CONFIG",
  EXPRESS_CONFIG: "EXPRESS_CONFIG",
};

export const SCALABLE_OR_NPN_SCALABLE_ITEMS = [
  { id: "050 - Delicatessen Counter", name: "050 - Delicatessen Counter" },
  { id: "051 - Dairy Counter", name: "051 - Dairy Counter" },
  { id: "052 - Butchery", name: "052 - Butchery" },
  { id: "053 - Fishery", name: "053 - Fishery" },
  { id: "054 - Bakery/Pastry", name: "054 - Bakery/Pastry" },
  { id: "055 - Pastry", name: "055 - Pastry" },
  { id: "056 - Fruits & Vegetables", name: "056 - Fruits & Vegetables" },
];

export const TYPES_OF_RULE_ENGINES = [
  { value: "Delivery Promise", label: "Delivery Promise", isDisabled: false },
  { value: "Delivery Fees", label: "Delivery Fees", isDisabled: true },
];

export const USER_TYPES = {
  MAF_ADMIN: "MAF_ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  READONLY_USER: "READONLY_USER",
  APPROVER: "APPROVER",
} as const;
export type USER_TYPES_KEYS = keyof typeof USER_TYPES;

export const SIDE_MENU_LABEL = {
  DASHBOARD: "Slot Utilization Dashboard",
  POS: "POS",
  RULE_ENGINE_SET_UP: "Rule Engines",
  POLYGON_SERVICES: "Polygon Services",
  APPROVAL_REQUESTS: "Slot Change Requests",
  USER_MANAGEMENT: "User Management",
  DELIVERY_PROMISE: "Delivery Promise",
  DELIVERY_FEE: "Delivery Fees",
  SINGLE_USE_PLASTIC: "Single Use Bag",
};

export const DELIVERY_PROMISE_SEARCH_TYPES = [
  { name: "POS No", value: "posNo" },
  { name: "POS Name", value: "posName" },
];

export const DELIVERY_FEE_SEARCH_TYPES = [
  { name: "POS No", value: "posNo" },
  { name: "POS Name", value: "storeName" },
];

export const POLYGON_SERVICE_SEARCH_TYPES = [
  { name: "Polygon ID", value: "polygonId" },
  { name: "Polygon Name", value: "polygonName" },
];

export const APPROVAL_FLOW_ACTIONS_LIST = [
  { id: APPROVAL_FLOW_ACTIONS.APPROVED, value: APPROVAL_FLOW_ACTIONS.APPROVED, name: "Approved" },
  { id: APPROVAL_FLOW_ACTIONS.REJECTED, value: APPROVAL_FLOW_ACTIONS.REJECTED, name: "Rejected" },
  // { id: APPROVAL_FLOW_ACTIONS.DELETED, value: APPROVAL_FLOW_ACTIONS.DELETED, name: "Deleted" },
  { id: APPROVAL_FLOW_ACTIONS.PENDING, value: APPROVAL_FLOW_ACTIONS.PENDING, name: "Pending" },
];

export const CAPACITY_OPTIMIZATION = {
  CAPACITY_UTILIZED: generateDropdownWithMentionedIntervalItems(5, 100, 5, "%"),
  BUFFER_TIME_DECREASE: generateDropdownWithMentionedIntervalItems(15, 120, 15, "minutes"),
};

export const SAVE_CONFIG_ENUM = {
  DELIVERY_PROMISE: "delivery-promise",
  DELIVERY_FEES: "delivery-fees",
  DELIVERY_BAG_CHARGES: "bag-charges",
  SERVICE_FEES: "service-fees",
  SLOT_CAPACITY_FEES: "slot-capacity-fees",
  MOV: "mov",
  SLOT_PROXIMITY_FEES: "slot-proximity-fees",
};

export const ALL_DAYS_VALUES = DAYS_DROPDOWN_OPTIONS.reduce((acc: any, day: any) => [...acc, day.id], []);

export const BUTTON_TEXT_CONSTANT = {
  BACK_TO_POS_LIST: "Back to POS list",
  BACK_TO_POS: "Back to POS",
  CLOSE: "Close",
};

export const APPLICATION_TEXT_CONSTANT = {
  ADDITIONAL_ZONE_CONFIGURATION: "Zone Configuration",
  PENDING_CONFIGURATION_HEADER: "Following configuration(s) needs to be completed to activate a POS/Zone",
  ZONE_ACTIVATION_FAILED: "Zone activation failed",
  ERROR_OCCURRED: "Error Occurred",
};

export const ZONE_ACTIVATION_REASON_CONSTANT: { [key: string]: string } = {
  DELIVERY_PROMISE: "Delivery Promise",
  POLYGON_MAPPING: "Polygon Mapping",
  DELIVERY_FEES: "Delivery Fees",
  DELIVERY_BAG_CHARGES: "Delivery Bag Charges",
  MINIMUM_ORDER_VALUE_THRESHOLD: "Minimum Order Value Threshold",
};

export const ZONE_ACTIVATION_CONSTANT: { [key: keyof typeof ZONE_ACTIVATION_REASON_CONSTANT | string]: string } = {
  DELIVERY_PROMISE: "Define delivery promise configuration.",
  POLYGON_MAPPING: "Map to at least 1 polygon ID OR map this POS/Zone to another POS/Zone as part of dynamic routing.",
  DELIVERY_FEES: "Define delivery fees configuration.",
  MINIMUM_ORDER_VALUE_THRESHOLD: "Define MOV threshold.",
  DELIVERY_BAG_CHARGES: "Define single use bag charges configuration.",
  MOV_THRESHOLD: "Define single use bag charges configuration.",
};

export const ITEMS_PER_BAG_REG_EXP = new RegExp("^[0-9]+$");
export const FEE_REG_EXP_VALUE = new RegExp("^[0-9]+.?[0-9]{0,3}$");
export const PERCENTAGE_REG_EXP_VALUE = new RegExp("^[0-9]+.?[0-9]{0,2}$");
export const ONE_DAY_IN_MILLI_SECONDS = 24 * 60 * 60 * 1000;