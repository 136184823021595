import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_RESPONSE_STATUS } from "../../../config/constants";
import * as CountrySettingsService from "./../../../api/CountrySettingsService";

export const fetchActivePOSAndServiceTypesCount = createAsyncThunk("countrySettings/fetchCount", async ({ country }) => {
  try {
    const { response } = await CountrySettingsService.fetchPOSAndServiceTypesCount({ country });
    return response;
  } catch (error) {
    return error;
  }
});

export const createCountrySettings = createAsyncThunk("pos/createCountrySettings", async ({ countryId, countryData }, { getState, rejectWithValue }) => {
  try {
    const { response } = await CountrySettingsService.createCountrySettings(countryId, countryData);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchCountrySettings = createAsyncThunk("pos/fetchCountrySettings", async ({ countryId }, { getState, rejectWithValue }) => {
  try {
    const { response } = await CountrySettingsService.fetchCountrySettings(countryId);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateCountrySettings = createAsyncThunk("pos/updateCountrySettings", async ({ countryId, countryData }, { getState, rejectWithValue }) => {
  try {
    const { response } = await CountrySettingsService.updateCountrySettings(countryId, countryData);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const initialState = {
  fetchingPosCount: false,
  posCount: [],
  posCountError: "",
  fetchingCountrySettings: false,
  countryDetails: {},
  countrySettingsError: "",
  loading: false,
  isCreated: false,
  isUpdated: false,
  fetchStatus: API_RESPONSE_STATUS.IDLE,
};

const countrySettingsSlice = createSlice({
  name: "countrySettings",
  initialState,
  reducers: {
    resetCountrySettings: () => {
      return initialState;
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(fetchActivePOSAndServiceTypesCount.pending, (state) => {
        state.fetchingPosCount = true;
        state.posCountError = "";
      })
      .addCase(fetchActivePOSAndServiceTypesCount.fulfilled, (state, action) => {
        state.fetchingPosCount = false;
        state.posCount = action.payload;
      })
      .addCase(fetchActivePOSAndServiceTypesCount.rejected, (state, action) => {
        state.fetchingPosCount = false;
        state.posCount = [];
        state.error = action.payload.error;
      })
      .addCase(createCountrySettings.pending, (state) => {
        state.loading = true;
        state.countrySettingsError = "";
        state.isCreated = false;
      })
      .addCase(createCountrySettings.fulfilled, (state, action) => {
        state.loading = false;
        state.isCreated = true;
      })
      .addCase(createCountrySettings.rejected, (state, action) => {
        state.loading = false;
        state.isCreated = false;
        state.countrySettingsError = action.payload.message;
      })
      .addCase(fetchCountrySettings.pending, (state) => {
        state.fetchingCountrySettings = true;
        state.countrySettingsError = "";
        state.countryDetails = {};
        state.isUpdated = false;
        state.fetchStatus = API_RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchCountrySettings.fulfilled, (state, action) => {
        state.fetchingCountrySettings = false;
        state.countryDetails = action.payload;
        state.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(fetchCountrySettings.rejected, (state, action) => {
        state.fetchingCountrySettings = false;
        state.countryDetails = {};
        state.countrySettingsError = action.payload.message;
        state.fetchStatus = API_RESPONSE_STATUS.FAILED;
      })
      .addCase(updateCountrySettings.pending, (state) => {
        state.loading = true;
        state.countrySettingsError = "";
        state.isUpdated = false;
      })
      .addCase(updateCountrySettings.fulfilled, (state, action) => {
        state.loading = false;
        state.countryDetails = action.payload;
        state.isUpdated = true;
      })
      .addCase(updateCountrySettings.rejected, (state, action) => {
        state.loading = false;
        // state.countryDetails = {};
        state.countrySettingsError = action.payload.message;
        state.isUpdated = false;
      });
  },
});

export default countrySettingsSlice.reducer;

export const { resetCountrySettings } = countrySettingsSlice.actions;

export const selectCountrySettings = (state) => state.countrySettings;
