import React from "react";
import { Link } from "react-router-dom";

import produce from "immer";

import { Grid, Typography, Tooltip } from "@material-ui/core";

import settingsIcon from "../../assets/svg/Settings.svg";

import { APP_ROUTES, SLOT_STATUSES } from "../../../config/constants";
import { convertSecondsIntoMinutes } from "../../../utils/helperFunctions";
import { canChangeRuleEngineConfig } from "../RuleEngineAccessControl";
import { capitalizeFirstLetter, isArrayValid, isUndefined } from "../../../utils/DataUtils";
import { getMinimumPromiseDetails, MinimumPromiseRuleValuesInterface, PROMISE_TYPE, RULE_ENGINE_TEXT_CONSTANT, TimeConfigInterface, TimeConfigItem } from "../RuleEngineUtils";
import { HEADER_NAME_CONSTANT, LISTING_SCREEN_APP_STATE_INTERFACE, OrderVelocityRuleValue, PARAM_INTERFACE, PromiseConfigMaxTime, RULE_ENGINE_LISTING_TEXT_CONSTANT, ScalableItemType } from "./CurrentConfigConstant";
import { DateFormat, getMomentDateWithCurrentFormatBasedOnFormat, getTimeInHourAndMinutes } from "../../../utils/DateUtils";

export const convertWeightInGramsToKiloGrams = (weightInGrams: number) => (weightInGrams ? Math.floor(Number(weightInGrams) / 1000) : weightInGrams);
export const isStatusActivated = (row: PARAM_INTERFACE) => row?.status && row?.status === SLOT_STATUSES.ACTIVATED;

export const isDynamicPromiseFlag = (row: PARAM_INTERFACE) => (row?.dynamicPromise ? RULE_ENGINE_LISTING_TEXT_CONSTANT.DYNAMIC : RULE_ENGINE_LISTING_TEXT_CONSTANT.STATIC);
export const isPromiseStaticOrDynamic = (row: PARAM_INTERFACE) => row?.type === PROMISE_TYPE.STATIC || row?.type === PROMISE_TYPE.DYNAMIC;
export const isPromiseDynamicOrAutomated = (row: PARAM_INTERFACE) => row?.type === PROMISE_TYPE.AUTOMATED || row?.type === PROMISE_TYPE.DYNAMIC;
export const isPromiseDynamic = (row: PARAM_INTERFACE) => row?.type === PROMISE_TYPE.DYNAMIC;
export const isPromiseAutomated = (row: PARAM_INTERFACE) => row?.type === PROMISE_TYPE.AUTOMATED;
export const isVehicleTypeRuleActive = (row: PARAM_INTERFACE) => row?.rulesData?.vehicleTypeRule?.active;
export const isCountRuleActive = (row: PARAM_INTERFACE) => row?.rulesData?.countRule?.active;
export const isWeightRuleActive = (row: PARAM_INTERFACE) => row?.rulesData?.weightRule?.active;
export const isOrderVelocityRuleActive = (row: PARAM_INTERFACE) => row?.rulesData?.orderVelocityRule?.active;
export const isMaximumPromiseRuleActive = (row: PARAM_INTERFACE) => row?.promiseConfig?.maxTime?.length > 0;
export const isScalableTypeRuleActive = (row: PARAM_INTERFACE) => row?.rulesData?.scalableTypeRule?.itemList?.length > 0;
export const isMaximumRuleConfiguredForAllDaysSame = (row: PARAM_INTERFACE) => row?.promiseConfig?.maxTime?.length === 1 && row?.promiseConfig?.maxTime[0]?.days?.length === 7;
export const isMaximumPromiseForVisibilityRuleActive = (row: PARAM_INTERFACE) => row?.promiseConfig?.maxTimeToDisplay && Number(row.promiseConfig.maxTimeToDisplay) > 0;
export const isPromiseDynamicOrAutomatedOrPREDICTIVE = (row: PARAM_INTERFACE) => row?.type === PROMISE_TYPE.AUTOMATED || row?.type === PROMISE_TYPE.DYNAMIC || row?.type === PROMISE_TYPE.PREDICTIVE;

export const getPromiseTimeInHourAndMinutes = (minutes: number) => `${Math.floor(minutes / 60)} hour ${minutes % 60} mins`;

export const getMaximumPromiseTime = (row: PARAM_INTERFACE) => {
  let minutes: number = 0;
  if (!isUndefined(row?.promiseConfig?.maxTime?.length === 1 && row?.promiseConfig?.maxTime[0]?.days?.length === 7 && row?.promiseConfig?.maxTime[0]?.time)) {
    minutes = Math.floor(Number(row?.promiseConfig?.maxTime[0]?.time) / 60);
  }
  return getPromiseTimeInHourAndMinutes(minutes);
};

export const getPromiseTypeName = (row: PARAM_INTERFACE) => {
  if (row?.type) {
    return row?.type;
  }
  return isDynamicPromiseFlag(row);
};

/**
 *
 * View Utils
 */

export const renderColumnCell = (classes: any, value: string | number) => <Typography className={classes.recordSpanStyle}>{value}</Typography>;
export const renderToolTipColumn = (classes: any, value: string | number) => <Typography className={classes.recordToolTipSpanStyle}>{value}</Typography>;
export const renderToolTipColumnCell = (classes: any) => <Typography className={classes.ScalableItemsSpanStyle}>{RULE_ENGINE_LISTING_TEXT_CONSTANT.ACTIVE}</Typography>;
export const renderPosName = (classes: any, posName: string | undefined | null) => <Tooltip title={posName || ""}>{renderColumnCell(classes, posName || "")}</Tooltip>;
export const renderColumnCellWithTootlTip = (classes: any, toolTipValue: NonNullable<React.ReactNode>) => <Tooltip title={toolTipValue}>{renderToolTipColumnCell(classes)}</Tooltip>;

export const renderToolTipCell = (classes: any, xsValue: any, value: string | number) => {
  return (
    <Grid item xs={xsValue}>
      {renderToolTipColumn(classes, value)}
    </Grid>
  );
};

export const renderEmptyColumn = (classes: any) => {
  return (
    <Grid item xs={1}>
      {renderToolTipColumn(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
    </Grid>
  );
};

export const renderStatus = (classes: any, row: PARAM_INTERFACE) => {
  return (
    <div style={{ alignSelf: "center" }}>
      <Typography className={isStatusActivated(row) ? classes.successSpanStyle : classes.failureSpanStyle}>{isStatusActivated(row) ? RULE_ENGINE_LISTING_TEXT_CONSTANT.ACTIVE : RULE_ENGINE_LISTING_TEXT_CONSTANT.IN_ACTIVE}</Typography>
    </div>
  );
};

export const renderDynamicPromise = (classes: any, row: PARAM_INTERFACE) => renderColumnCell(classes, capitalizeFirstLetter(getPromiseTypeName(row)));

export const renderMinimumPromiseTimeToolTipNode = (classes: any, row: PARAM_INTERFACE) => {
  const minPromiseResponse: MinimumPromiseRuleValuesInterface[] = getMinimumPromiseDetails(row.promiseConfig.minTime);
  return (
    isArrayValid(minPromiseResponse) &&
    minPromiseResponse.map((minTimeObj: MinimumPromiseRuleValuesInterface, minTimeObjIndex: number) => (
      <Grid container spacing={1} key={`${minTimeObjIndex}-promiseConfig-min-time`} className={classes.toolTipLargeContainerStyle}>
        <Grid container spacing={1}>
          {renderToolTipCell(classes, 2, HEADER_NAME_CONSTANT.DAYS)}
          {renderEmptyColumn(classes)}
          <Grid item xs={9} style={{ wordBreak: "break-all" }}>
            {isArrayValid(minTimeObj?.configuredDays) ? minTimeObj?.configuredDays.join(", ") : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN}
          </Grid>
          <Grid container spacing={1} className={classes.timeConfigContainerStyle}>
            {renderToolTipCell(classes, 3, RULE_ENGINE_TEXT_CONSTANT.START_TIME)}
            {renderToolTipCell(classes, 3, RULE_ENGINE_TEXT_CONSTANT.END_TIME)}
            {renderToolTipCell(classes, 3, RULE_ENGINE_TEXT_CONSTANT.MIN_TIME_HOUR)}
            {renderToolTipCell(classes, 3, RULE_ENGINE_TEXT_CONSTANT.MIN_TIME_MINUTES)}
            {isArrayValid(minTimeObj?.timeConfig) &&
              minTimeObj.timeConfig.map((timeObj: TimeConfigItem, timeObjIndex: number) => (
                <Grid container spacing={1} key={`${timeObjIndex}-promiseConfig-min-time-timeObj`}>
                  {renderToolTipCell(classes, 3, getMomentDateWithCurrentFormatBasedOnFormat(timeObj.startTime, DateFormat.HH_MM_SS, DateFormat.HH_MM).toString())}
                  {renderToolTipCell(classes, 3, getMomentDateWithCurrentFormatBasedOnFormat(timeObj.endTime, DateFormat.HH_MM_SS, DateFormat.HH_MM).toString())}
                  {renderToolTipCell(classes, 3, `${timeObj.minTimeHour} Hour`)}
                  {renderToolTipCell(classes, 3, `${timeObj.minTimeMinutes} Minutes`)}
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    ))
  );
};
export const renderMinimumPromiseTime = (classes: any, row: PARAM_INTERFACE) => {
  if (isArrayValid(row?.promiseConfig?.minTime)) {
    if (row?.promiseConfig?.minTime?.length === 1 && !isUndefined(row?.promiseConfig?.minTime[0]?.timeConfigs) && isArrayValid(row?.promiseConfig?.minTime[0]?.timeConfigs) && row?.promiseConfig?.minTime[0]?.timeConfigs?.length === 1) {
      return renderColumnCell(classes, getTimeInHourAndMinutes(row?.promiseConfig?.minTime[0]?.timeConfigs[0]?.time || 0));
    } else {
      return renderColumnCellWithTootlTip(classes, renderMinimumPromiseTimeToolTipNode(classes, row));
    }
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
};

export const renderMaximumPromiseTimeToolTipNode = (classes: any, row: PARAM_INTERFACE) => {
  if (row?.promiseConfig?.maxTime?.length > 0) {
    return row?.promiseConfig?.maxTime?.map((config: PromiseConfigMaxTime, configInde: number) => (
      <Grid container spacing={1} key={`${configInde}-promiseConfig-maxTime`} className={classes.toolTipContainerStyel}>
        <Grid container spacing={1}>
          {renderToolTipCell(classes, 2, HEADER_NAME_CONSTANT.DAYS)}
          {renderEmptyColumn(classes)}
          <Grid item xs={9} style={{ wordBreak: "break-all" }}>
            {config?.days?.length > 0 ? config?.days.join(", ") : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME)}
          {renderEmptyColumn(classes)}
          {renderToolTipCell(classes, 4, config?.time ? getPromiseTimeInHourAndMinutes(Number(config.time)) : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
        </Grid>
      </Grid>
    ));
  }
  return renderToolTipCell(classes, 7, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA_AVAILABLE);
};

export const renderMaximumPromiseTime = (classes: any, row: PARAM_INTERFACE) => {
  if (isPromiseDynamicOrAutomatedOrPREDICTIVE(row)) {
    if (isMaximumPromiseRuleActive(row)) {
      if (isMaximumRuleConfiguredForAllDaysSame(row)) {
        return renderColumnCell(classes, getMaximumPromiseTime(row));
      }
      return renderColumnCellWithTootlTip(classes, renderMaximumPromiseTimeToolTipNode(classes, row));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderLastMileTime = (classes: any, row: PARAM_INTERFACE) => {
  let lastMileTime: string | number = RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA;
  if (isPromiseAutomated(row)) {
    if (!isUndefined(row?.automatedConfig?.lastMileTime) && Number(row?.automatedConfig?.lastMileTime) > 0) {
      lastMileTime = getPromiseTimeInHourAndMinutes(Math.floor(Number(row?.automatedConfig?.lastMileTime) / 60));
    }
  } else {
    lastMileTime = RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A;
  }
  return renderColumnCell(classes, lastMileTime);
};

export const convertVehicleTypeListToDesignView = (listData: any, classes: any) => {
  if (listData?.length > 0) {
    return (
      <>
        <Grid container spacing={0}>
          {renderToolTipCell(classes, 5, HEADER_NAME_CONSTANT.VEHICLE_TYPE)}
          {renderToolTipCell(classes, 5, HEADER_NAME_CONSTANT.THRESHOLD_WEIGHT)}
        </Grid>
        {listData.map((item: any, item_index: number) => (
          <Grid container spacing={0} key={`${item_index}-listData`}>
            {renderToolTipCell(classes, 5, item?.vehicleType)}
            {renderToolTipCell(classes, 7, convertWeightInGramsToKiloGrams(item?.thresholdWeight))}
          </Grid>
        ))}
      </>
    );
  }
  return RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA;
};

export const renderVehicleTypeToolTipNode = (classes: any, row: PARAM_INTERFACE) => {
  return (
    <Grid container spacing={1} className={classes.toolTipContainerStyel}>
      <Grid container spacing={0}>
        {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.HEAVIEST_VEHICLE)}
        {renderEmptyColumn(classes)}
        {renderToolTipCell(classes, 4, row?.rulesData?.vehicleTypeRule?.heaviestVehicle || RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
      </Grid>
      {row?.rulesData?.vehicleTypeRule?.vehicleTypeWeightList?.length > 0 && (
        <Grid container spacing={0}>
          {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.VEHICLE_TYPE_WEIGHT_LIST)}
          {renderEmptyColumn(classes)}
          {renderToolTipCell(classes, 5, "")}
          <Grid item xs={11}>
            {convertVehicleTypeListToDesignView(row.rulesData.vehicleTypeRule.vehicleTypeWeightList, classes)}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export const renderTypeOfVehicleRule = (classes: any, row: PARAM_INTERFACE) => {
  if (isPromiseStaticOrDynamic(row)) {
    if (isVehicleTypeRuleActive(row)) {
      return renderColumnCellWithTootlTip(classes, renderVehicleTypeToolTipNode(classes, row));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderItemCountRuleToolTipNode = (classes: any, row: PARAM_INTERFACE) => {
  const incrementalTime = row?.rulesData?.countRule?.incrementalTime ? `${row?.rulesData?.countRule?.incrementalTime} seconds` : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN;
  return (
    <Grid container spacing={1} className={classes.toolTipContainerStyel}>
      <Grid container spacing={2}>
        {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.COUNT_THRESHOLD)}
        {renderEmptyColumn(classes)}
        {renderToolTipCell(classes, 4, row?.rulesData?.countRule?.thresholdCount || RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
      </Grid>

      <Grid container spacing={2}>
        {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.COUNT_INCREMENTAL_TIME)}
        {renderEmptyColumn(classes)}
        {renderToolTipCell(classes, 4, incrementalTime)}
      </Grid>
    </Grid>
  );
};
export const renderItemCountRule = (classes: any, row: PARAM_INTERFACE) => {
  if (isPromiseDynamic(row)) {
    if (isCountRuleActive(row)) {
      return renderColumnCellWithTootlTip(classes, renderItemCountRuleToolTipNode(classes, row));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderWeightRuleToolTipNode = (classes: any, row: PARAM_INTERFACE) => {
  const weightThreshold = row?.rulesData?.weightRule?.thresholdWeight ? convertWeightInGramsToKiloGrams(row?.rulesData?.weightRule?.thresholdWeight) : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN;
  const incrementalTime = row?.rulesData?.weightRule?.incrementalTime ? `${convertSecondsIntoMinutes(row.rulesData.weightRule.incrementalTime)} mins` : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN;

  return (
    <Grid container spacing={1} className={classes.toolTipContainerStyel}>
      <Grid container spacing={2}>
        {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.WEIGHT_THRESHOLD)}
        {renderEmptyColumn(classes)}
        {renderToolTipCell(classes, 4, weightThreshold)}
      </Grid>

      <Grid container spacing={2}>
        {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.WEIGHT_INCREMENTAL_TIME)}
        {renderEmptyColumn(classes)}
        {renderToolTipCell(classes, 4, incrementalTime)}
      </Grid>
    </Grid>
  );
};

export const renderWeightRule = (classes: any, row: PARAM_INTERFACE) => {
  if (isPromiseDynamic(row)) {
    if (isWeightRuleActive(row)) {
      return renderColumnCellWithTootlTip(classes, renderWeightRuleToolTipNode(classes, row));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderCountIncrementalTimeToolTipNode = (classes: any, row: PARAM_INTERFACE) => {
  if (row?.rulesData?.orderVelocityRule?.orderVelocityRuleValues?.length > 0) {
    return row.rulesData.orderVelocityRule.orderVelocityRuleValues.map((element: OrderVelocityRuleValue, element_index: number) => (
      <Grid container spacing={1} key={`${element_index}-orderVelocityRule-orderVelocityRuleValues`} className={classes.toolTipContainerStyel}>
        <Grid container spacing={1}>
          {renderToolTipCell(classes, 2, HEADER_NAME_CONSTANT.DAYS)}
          {renderEmptyColumn(classes)}
          <Grid item xs={9} style={{ wordBreak: "break-all" }}>
            <Typography className={classes.recordToolTipSpanStyle}>{element?.configuredDays?.length > 0 ? element.configuredDays.join(", ") : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.TIME_RANGE)}
          {renderEmptyColumn(classes)}
          {renderToolTipCell(classes, 4, element?.timeRange ? `${convertSecondsIntoMinutes(element?.timeRange)} Minutes` : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
        </Grid>

        <Grid container spacing={1}>
          {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.ORDER_THRESHOLD)}
          {renderEmptyColumn(classes)}
          {renderToolTipCell(classes, 4, element?.orderThreshold || RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
        </Grid>

        <Grid container spacing={1}>
          {renderToolTipCell(classes, 7, HEADER_NAME_CONSTANT.EXTRA_PROMISE_TIME)}
          {renderEmptyColumn(classes)}
          {renderToolTipCell(classes, 4, element?.extraPromiseTime ? `${convertSecondsIntoMinutes(element?.extraPromiseTime)} Minutes` : RULE_ENGINE_LISTING_TEXT_CONSTANT.EMPTY_COLUMN)}
        </Grid>
      </Grid>
    ));
  }
  return renderToolTipCell(classes, 7, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA_AVAILABLE);
};

export const renderCountIncrementalTime = (classes: any, row: PARAM_INTERFACE) => {
  if (isPromiseDynamic(row)) {
    if (isOrderVelocityRuleActive(row)) {
      return renderColumnCellWithTootlTip(classes, renderCountIncrementalTimeToolTipNode(classes, row));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderScalableItemsToolTipNode = (classes: any, row: PARAM_INTERFACE) => {
  if (row?.rulesData?.scalableTypeRule?.itemList?.length > 0) {
    return (
      <Grid container className={classes.lineHeightStyle}>
        {row.rulesData.scalableTypeRule.itemList.map((item: ScalableItemType) => (
          <Grid item xs={12} key={item?.section}>
            <Typography className={classes.recordToolTipSpanStyle}>{`${item?.section} - ${convertSecondsIntoMinutes(item?.incrementalTime)} mins`}</Typography>
          </Grid>
        ))}
      </Grid>
    );
  }
  return renderToolTipCell(classes, 7, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA_AVAILABLE);
};

export const renderScalableItems = (classes: any, row: PARAM_INTERFACE) => {
  if (isPromiseDynamic(row)) {
    if (isScalableTypeRuleActive(row)) {
      return renderColumnCellWithTootlTip(classes, renderScalableItemsToolTipNode(classes, row));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

export const renderActionColumn = (classes: any, row: PARAM_INTERFACE) => {
  const { countryId, posNo, serviceType, zoneId } = row;
  const searchParam: string = `?countryId=${countryId}&serviceType=${serviceType}&posNo=${posNo}&zoneId=${zoneId}`;
  return (
    <Link to={{ pathname: `${APP_ROUTES.RULE_ENGINE_CURRENT_CONFIGURATION}`, search: searchParam }} className={classes.completeSetupSettingsButton}>
      <img src={settingsIcon} alt="settings" />
    </Link>
  );
};

export const getMaximumPromiseTimeForVisibility = (row: PARAM_INTERFACE) => {
  let minutes: number = 0;
  if (!isUndefined(row?.promiseConfig?.maxTimeToDisplay)) {
    minutes = Math.floor(Number(row?.promiseConfig?.maxTimeToDisplay) / 60);
  }
  return getPromiseTimeInHourAndMinutes(minutes);
};

export const renderMaximumPromiseTimeForVisibility = (classes: any, row: PARAM_INTERFACE) => {
  if (isPromiseDynamicOrAutomated(row)) {
    if (isMaximumPromiseForVisibilityRuleActive(row)) {
      return renderColumnCell(classes, getMaximumPromiseTimeForVisibility(row));
    }
    return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.NO_DATA);
  }
  return renderColumnCell(classes, RULE_ENGINE_LISTING_TEXT_CONSTANT.N_A);
};

// export const getCurrentConfigTableColumn = (classes: any, userInfo: any) => {
//   const defaultTableWidthSet = Object.freeze({ sortable: false, headerAlign: "left", headerClassName: classes.headerStyle });
//   const defaultTableSet = Object.freeze({ flex: 1, sortable: false, headerAlign: "left", headerClassName: classes.headerStyle });

//   let coulums: any = [
//     { ...defaultTableWidthSet, field: "posNo", headerName: HEADER_NAME_CONSTANT.POS_NO, width: 90, renderCell: (row: PARAM_INTERFACE) => renderColumnCell(classes, row?.posNo) },
//     { ...defaultTableSet, field: "posName", headerName: HEADER_NAME_CONSTANT.POS_NAME, renderCell: (row: PARAM_INTERFACE) => renderPosName(classes, row?.posName) },
//     { ...defaultTableSet, field: "zoneName", headerName: HEADER_NAME_CONSTANT.ZONE_NAME, renderCell: (row: PARAM_INTERFACE) => renderColumnCell(classes, row?.zoneName) },
//     { ...defaultTableSet, field: "status", headerName: HEADER_NAME_CONSTANT.STATUS, renderCell: (row: PARAM_INTERFACE) => renderStatus(classes, params) },
//     { ...defaultTableSet, field: "promiseType", headerName: HEADER_NAME_CONSTANT.PROMISE_TYPE, renderCell: (row: PARAM_INTERFACE) => renderDynamicPromise(classes, params) },
//     { ...defaultTableSet, field: "minimumPromiseTime", headerName: HEADER_NAME_CONSTANT.MINIMUM_PROMISE_TIME, renderCell: (row: PARAM_INTERFACE) => renderMinimumPromiseTime(classes, params) },
//     { ...defaultTableSet, field: "itemCountRule", headerName: HEADER_NAME_CONSTANT.ITEM_COUNT, renderCell: (row: PARAM_INTERFACE) => renderItemCountRule(classes, params) },
//     { ...defaultTableSet, field: "weightRuleOfConsignment", headerName: HEADER_NAME_CONSTANT.WEIGHT_OF_CONSIGNMENT, renderCell: (row: PARAM_INTERFACE) => renderWeightRule(classes, params) },
//     { ...defaultTableSet, field: "countIncrementalTime", headerName: HEADER_NAME_CONSTANT.ORDER_VELOCITY, renderCell: (row: PARAM_INTERFACE) => renderCountIncrementalTime(classes, params) },
//     { ...defaultTableSet, field: "scalableItems", headerName: HEADER_NAME_CONSTANT.INCREMENTAL_TIME_FOR_SCALABLE_ITEMS, renderCell: (row: PARAM_INTERFACE) => renderScalableItems(classes, params) },
//     { ...defaultTableSet, field: "lastMileTime", headerName: HEADER_NAME_CONSTANT.LAST_MILE_TIME, renderCell: (row: PARAM_INTERFACE) => renderLastMileTime(classes, params) },
//     { ...defaultTableSet, field: "maximumPromiseTime", headerName: HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME, renderCell: (row: PARAM_INTERFACE) => renderMaximumPromiseTime(classes, params) },
//     { ...defaultTableSet, field: "maximumPromiseTimeForVisibility", headerName: HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME_FOR_VISIBILITY, renderCell: (row: PARAM_INTERFACE) => renderMaximumPromiseTimeForVisibility(classes, params) },
//   ];

//   if (userInfo && canChangeRuleEngineConfig(userInfo) === true) {
//     coulums.push({ ...defaultTableWidthSet, field: "actions", headerName: HEADER_NAME_CONSTANT.ACTIONS, renderCell: (row: PARAM_INTERFACE) => renderActionColumn(classes, params) });
//   }
//   return coulums;
// };

export const getCurrentConfigTableColumnHeader = (userInfo: any) => {
  const columns: any = [
    { field: "posNo", headerName: HEADER_NAME_CONSTANT.POS_NO },
    { field: "posName", headerName: HEADER_NAME_CONSTANT.POS_NAME },
    { field: "zoneName", headerName: HEADER_NAME_CONSTANT.ZONE_NAME },
    { field: "status", headerName: HEADER_NAME_CONSTANT.STATUS },
    { field: "promiseType", headerName: HEADER_NAME_CONSTANT.PROMISE_TYPE },
    { field: "minimumPromiseTime", headerName: HEADER_NAME_CONSTANT.MINIMUM_PROMISE_TIME },
    { field: "itemCountRule", headerName: HEADER_NAME_CONSTANT.ITEM_COUNT },
    { field: "weightRuleOfConsignment", headerName: HEADER_NAME_CONSTANT.WEIGHT_OF_CONSIGNMENT },
    { field: "countIncrementalTime", headerName: HEADER_NAME_CONSTANT.ORDER_VELOCITY },
    { field: "scalableItems", headerName: HEADER_NAME_CONSTANT.INCREMENTAL_TIME_FOR_SCALABLE_ITEMS },
    { field: "lastMileTime", headerName: HEADER_NAME_CONSTANT.LAST_MILE_TIME },
    { field: "maximumPromiseTime", headerName: HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME },
    { field: "maximumPromiseTimeForVisibility", headerName: HEADER_NAME_CONSTANT.MAXIMUM_PROMISE_TIME_FOR_VISIBILITY },
  ];
  if (userInfo && canChangeRuleEngineConfig(userInfo) === true) {
    columns.push({ field: "actions", headerName: HEADER_NAME_CONSTANT.ACTIONS });
  }
  return columns;
};

export const designLoadingScreenObject = (appState: LISTING_SCREEN_APP_STATE_INTERFACE, name: string, pageNumber: number, query: string) => {
  const newAppState: LISTING_SCREEN_APP_STATE_INTERFACE = produce(appState, (draft: LISTING_SCREEN_APP_STATE_INTERFACE) => {
    draft.loading = true;
    draft.expandedAccordion = name;
    draft.apiParams.query = query;
    draft.apiParams.currentPage = pageNumber;
    draft.tableRows = [];
  });
  return newAppState;
};

export const designResetScreenObject = (appState: LISTING_SCREEN_APP_STATE_INTERFACE) => {
  const newAppState: LISTING_SCREEN_APP_STATE_INTERFACE = produce(appState, (draft: LISTING_SCREEN_APP_STATE_INTERFACE) => {
    draft.loading = true;
    draft.apiParams.query = "";
    draft.apiParams.currentPage = 0;
    draft.apiParams.maxRecordPresent = 0;
    draft.searchTypeValue = "posNo";
    draft.searchValue = "";
    draft.tableRows = [];
  });
  return newAppState;
};

export const designErrorScreenObject = (appState: LISTING_SCREEN_APP_STATE_INTERFACE) => {
  const newAppState: LISTING_SCREEN_APP_STATE_INTERFACE = produce(appState, (draft: LISTING_SCREEN_APP_STATE_INTERFACE) => {
    draft.loading = false;
    draft.apiParams.query = "";
    draft.apiParams.currentPage = 0;
    draft.apiParams.maxRecordPresent = 0;
    draft.searchTypeValue = "posNo";
    draft.searchValue = "";
    draft.tableRows = [];
  });
  return newAppState;
};

export const designSuccessScreenObject = (appState: LISTING_SCREEN_APP_STATE_INTERFACE, currentPage: number, totalElements: number, content: any[]) => {
  const newAppState: LISTING_SCREEN_APP_STATE_INTERFACE = produce(appState, (draft: LISTING_SCREEN_APP_STATE_INTERFACE) => {
    draft.loading = false;
    draft.apiParams.query = "";
    draft.apiParams.currentPage = currentPage || 0;
    draft.apiParams.maxRecordPresent = totalElements || 0;
    draft.tableRows = content;
  });
  return newAppState;
};
