import moment from "moment";

import { createListBetweenTwoNumbers } from "../../utils/helperFunctions";
import { EXPRESS_TYPES, SERVICE_TYPES, DELIVERY_TYPES } from "./../../config/constants";
import { isArrayValid, isUndefined } from "../../utils/DataUtils";

// Tab list for cuntry setting

export const ServiceTypeTabList: Array<any> = [
  { serviceTypeLabel: "Standard", serviceType: SERVICE_TYPES.STANDARD, countKey: "standard", countStyle: "standardServiceTypeCount" },
  { serviceTypeLabel: "Express", serviceType: SERVICE_TYPES.EXPRESS, countKey: "express", countStyle: "expressServiceTypeCount" },
  { serviceTypeLabel: "Click & Collect", serviceType: SERVICE_TYPES.CLICK_N_COLLECT, countKey: "cnc", countStyle: "cncServiceTypeCount", hideDivisonView: true },
  { serviceTypeLabel: "Default", serviceType: SERVICE_TYPES.DEFAULT, countKey: "default", countStyle: "defaultServiceTypeCount" },
  { serviceTypeLabel: "FBC", serviceType: SERVICE_TYPES.FBC, countKey: "fbc", countStyle: "fbcServiceTypeCount" },
];

interface Holiday {
  dateLabel: string;
  offDay: string;
  offDate: string;
  active: boolean;
}

const createHolidayObjects = (info: any) => {
  const holidaysList = getHolidaysListObject(info);
  return holidaysList.map((item: { objectDate: string }) => ({ offDay: "", offDate: item.objectDate, active: true }));
};

const getHolidaysArray = (data: any) => {
  let calendarHoliday: Holiday[] = [];
  let weeklyholiday: Holiday[] = [];

  if (!isUndefined(data.holidays) && isArrayValid(data.holidays)) {
    calendarHoliday = data.holidays.flatMap((info: any) => {
      if (info.dateLabel) {
        return createHolidayObjects(info);
      } else if (!info.hasOwnProperty("offDay")) {
        return { offDay: "", offDate: info.offDate, active: true };
      } else {
        return [];
      }
    });
  }

  if (!isUndefined(data.weeklyHolidays) && isArrayValid(data.weeklyHolidays)) {
    weeklyholiday = data.weeklyHolidays.map((info: any) => ({ offDay: info, offDate: "", active: true }));
  }

  return [...calendarHoliday, ...weeklyholiday];
};

export function countrySettingsDetailsPayloadNew(countryName: string, configs: any, areaPosMappingType: any) {
  const result = Object.keys(configs).map((key) => {
    const { serviceTypeID, advanceSlotDays, nextDaySlotVisibility, bagChargeEnabled, serviceActive, expressType, deliveryType } = configs[key];
    return {
      serviceTypeID,
      advanceSlotDays: !isUndefined(configs[key]?.editSlotDays) ? configs[key].editSlotDays : advanceSlotDays,
      nextDaySlotVisibility,
      bagChargeEnabled,
      serviceActive: serviceActive as any,
      ...(expressType && { expressType }),
      ...(deliveryType === DELIVERY_TYPES.DAY_BASED && { holidays: getHolidaysArray(configs[key]) }),
    };
  });
  const body = { countryName, serviceTypes: result, areaPosMappingType: areaPosMappingType };
  return body;
}

export const StandardInitConfig = { serviceTypeID: 1, nextDaySlotVisibility: true, showAdvanceSlotDaysInput: false, advanceSlotDays: 7, editSlotDays: 7, bagChargeEnabled: false, serviceActive: true };
export const ExpressInitConfig = { serviceTypeID: 3, nextDaySlotVisibility: true, expressType: EXPRESS_TYPES.ON_DEMAND.value, showAdvanceSlotDaysInput: false, advanceSlotDays: 2, editSlotDays: 2, bagChargeEnabled: false, serviceActive: true };
export const ClickCollectInitConfig = { serviceTypeID: 4, nextDaySlotVisibility: true, showAdvanceSlotDaysInput: false, advanceSlotDays: 7, editSlotDays: 7, bagChargeEnabled: false, serviceActive: true };
export const DefaultInitConfig = { serviceTypeID: 2, holidays: [], bagChargeEnabled: false, serviceActive: true };
export const FBCInitialConfig = { serviceTypeID: 124124, serviceActive: true };
export const WeeklyHolidayConfig = { holidays: [] };
export const ADVANCE_DAYS_DDN_OPTIONS = createListBetweenTwoNumbers(2, 28);

export function getDateFromPicker(dateData: any) {
  return moment(dateData).format("YYYY-MM-DD");
}

function getDates(startDate: Date, stopDate: Date) {
  var dateArray: any = [];

  var currDate = moment(startDate).startOf("day");
  var lastDate = moment(stopDate).startOf("day");

  if (currDate) {
    const objectDate = moment(currDate.toDate()).format("YYYY-MM-DD");
    dateArray.push({ objectDate });
  }
  while (currDate.add(1, "days").diff(lastDate) <= 0) {
    const objectDate = moment(currDate.toDate()).format("YYYY-MM-DD");
    dateArray.push({ objectDate });
  }
  return dateArray;
}

export function getHolidaysListObject(dateObject: any) {
  if (dateObject && dateObject.dateFrom && dateObject.dateTo) {
    const dateDataArray = getDates(dateObject.dateFrom, dateObject.dateTo);
    return dateDataArray;
  }
  return [];
}

export const DEFAULT_DAY_BASED_OBJ = Object.freeze({
  advanceSlotDays: 0,
  nextDaySlotVisibility: false,
  holidays: [],
  bagChargeEnabled: false,
  serviceActive: true,
  defaultPos: {},
  deliveryType: DELIVERY_TYPES.DAY_BASED,
});

export const DEFAULT_MINUTE_BASED_OBJ = Object.freeze({
  advanceSlotDays: 2,
  expressType: "ON_DEMAND",
  nextDaySlotVisibility: false,
  bagChargeEnabled: false,
  serviceActive: true,
  defaultPos: {},
  deliveryType: DELIVERY_TYPES.MINUTE_BASED,
});

export const DEFAULT_SLOT_BASED_OBJ = Object.freeze({
  advanceSlotDays: 2,
  nextDaySlotVisibility: false,
  bagChargeEnabled: false,
  serviceActive: true,
  defaultPos: {},
  deliveryType: DELIVERY_TYPES.MINUTE_BASED,
});
