import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { userStyles } from "./Styles/PlolygonServicesPageStyles";
import { ALERT_TYPES, API_RESPONSE_STATUS, CoordinatesInterface, SLOT_STATUSES } from "../../config/constants";
import { _design_Pos_Zone_Service_List_Record } from "../DeliveryFee/Utils/DeliveryFeeUtils";

import * as PlolygonServicesInterface from "./Utils/PlolygonServicesInterface";
import * as PlolygonServicesSlice from "./Redux/PlolygonServicesSlice";
import * as PlolygonServicesUtils from "./Utils/PlolygonServicesUtils";
import * as PlolygonServicesConstants from "./Utils/PlolygonServicesConstants";

import Loader from "../common/Loader/Loader";
import PlolygonServicesHeader from "./Common/PlolygonServicesHeader";
import PlolygonServicesCountHeader from "./Common/PlolygonServicesCountHeader";
import PlolygonServicesCardSection from "./Common/PlolygonServicesCardSection";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import PolygonServiceFilterSection from "./Common/PolygonServiceFilterSection";
import PolygonDetailsDrawer from "./Common/Drawer/PolygonDetailsDrawer";
import PolygonMapService from "./PolygonMapService/PolygonMapService";
import OverlayLoader from "../common/Loader/OverlayLoader";

import { selectUserDetails } from "../Login/redux/userSlice";
import { downloadXLSXFile } from "utils/helperFunctions";
import { isArrayValid, isEmptyArray, isEmptyOrNull, isNull, isUndefined } from "../../utils/DataUtils";
import { PolygonInfoWindowViewUtils, PolygonPosZoneInfoWindowViewUtils } from "../common/App/AppViewUtils";
import { serviceSelectPropositionRecord } from "../../config/redux/configurationsSlice";

const PlolygonServicesPage = () => {
  const classes = userStyles();
  const appDispatch = useDispatch();

  const { currentCountry } = useSelector((state: any) => state.appConfig);
  const { countryDetails } = useSelector((state: any) => state.countrySettings);
  const { polygonServiceMapRecord } = useSelector((state: any) => state.plolygonService);
  const polygonServiceConfigurationRecord = useSelector(PlolygonServicesSlice.selectPolygonServiceConfigurationRecord);
  const polygonseServiceCountData = useSelector(PlolygonServicesSlice.selectPolygonServiceCountData);
  const userInfo: any = useSelector(selectUserDetails);
  const servicePropositionList = useSelector(serviceSelectPropositionRecord);

  const [appCountStateObj, setAppCountStateObj] = useState<PlolygonServicesInterface.PlolygonServicesInitialCountStateInteface>({ ...PlolygonServicesInterface.PlolygonServicesInitialCountState });
  const [appStateObj, setAppStateObj] = useState<PlolygonServicesInterface.PlolygonServicesInitialStateInteface>({ ...PlolygonServicesInterface.PlolygonServicesInitialState });
  const [appFilterPopUpObj, setAppFilterPopUpObj] = useState<PlolygonServicesInterface.PolygonFilterPopUpStateInterface>({ ...PlolygonServicesInterface.PolygonFilterPopUpInitialState });
  const [polygonDetailsSideDrawerObj, setPolygonDetailsSideDrawerObj] = useState<PlolygonServicesInterface.PolygonDetailsSideDrawerInterface>({ ...PlolygonServicesInterface.PolygonDetailsSideDrawerInitialState });

  // Fetch Record
  //
  const fetchPolygonRecord = (countryName: any) => {
    let serviceTypeFilterList = PlolygonServicesUtils.generateServiceNameForPolygon(servicePropositionList);
    if (appCountStateObj && appCountStateObj.serviceTypeFilter && !isEmptyOrNull(appCountStateObj.serviceTypeFilter)) {
      serviceTypeFilterList = [appCountStateObj.serviceTypeFilter];
    }
    fetchPolygonServiceRecordnBasedOnCountryId(countryName, serviceTypeFilterList, appCountStateObj.selectedTileFilter, appStateObj.page, appFilterPopUpObj, false);
    fetchPolygonServiceCountBasedOnQuery(countryName, serviceTypeFilterList);
  };

  const fetchPolygonServiceRecordnBasedOnCountryId = (countryName: any, serviceTypeProp: any, selectedTileFilter: any, page: any, popUpObj: any, searchBy: any) => {
    let serviceTypeFilter = serviceTypeProp;
    if (isEmptyArray(serviceTypeProp)) {
      serviceTypeFilter = [...PlolygonServicesConstants.ALL_SERVICE_TYPE];
    }
    const request_payload = {
      countryName: countryName,
      data: { serviceTypes: [...serviceTypeFilter], selectedTile: PlolygonServicesUtils.getSelectedTileNameByIndex(selectedTileFilter), pageNo: page, pageSize: appStateObj.size, sort: "polygonId" },
    } as any;
    if (searchBy !== undefined && searchBy === true) {
      request_payload.data.searchBy = appStateObj.searchTypeValue;
      request_payload.data.searchTerm = appStateObj.searchValue;
    }
    let serviceTypeFilterPayload: any = PlolygonServicesUtils.design_service_type_filter_from_header(popUpObj);
    if (!isUndefined(serviceTypeFilterPayload) !== undefined && isArrayValid(serviceTypeFilterPayload)) {
      request_payload.data.serviceTypeFilterV2 = [...serviceTypeFilterPayload];
    }
    appDispatch(PlolygonServicesSlice.getPolygonServiceRecordnBasedOnCountryId(request_payload));
  };

  const fetchPolygonServiceCountBasedOnQuery = (countryName: any, serviceTypeProp?: any) => {
    let serviceTypeFilter = serviceTypeProp;
    if (isUndefined(serviceTypeProp) || isEmptyArray(serviceTypeProp)) {
      serviceTypeFilter = [...PlolygonServicesConstants.ALL_SERVICE_TYPE];
    }
    const request_payload = { countryName: countryName, data: { service_types: serviceTypeFilter.toString() } };
    appDispatch(PlolygonServicesSlice.getPolygonServiceCountBasedOnQuery(request_payload));
  };

  // Error
  //
  const configurePolygonServiceError = () => {
    if (polygonServiceConfigurationRecord !== undefined && polygonServiceConfigurationRecord.error !== undefined && polygonServiceConfigurationRecord.error !== "") {
      let newAppStateObj = PlolygonServicesUtils.handle_reset_pos_zone_mappind_details_utils(appStateObj, false, "", "", "");
      setAppStateObj(newAppStateObj);
      if (polygonServiceConfigurationRecord.error !== "" && typeof polygonServiceConfigurationRecord.error === "string" && !polygonServiceConfigurationRecord.error.includes(PlolygonServicesConstants.NO_POLYGON_EXIST_FOR_COUNTRY)) {
        CustomAlert(ALERT_TYPES.ERROR, polygonServiceConfigurationRecord.error);
      }
    }
  };

  const configurePolygonServiceCountError = () => {
    if (polygonseServiceCountData !== undefined && polygonseServiceCountData.error !== undefined && polygonseServiceCountData.error !== "") {
      let newAppCountStateObj = PlolygonServicesUtils.handle_reset_polygon_service_count_utils(appCountStateObj, false);
      setAppCountStateObj(newAppCountStateObj);
      CustomAlert(ALERT_TYPES.ERROR, polygonseServiceCountData.error);
    }
  };

  // Data
  //
  const configurePolygonServiceRecordListData = () => {
    if (
      polygonServiceConfigurationRecord !== undefined &&
      polygonServiceConfigurationRecord.fetchStatus !== undefined &&
      polygonServiceConfigurationRecord.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED &&
      polygonServiceConfigurationRecord.data !== undefined &&
      typeof polygonServiceConfigurationRecord.data === "object"
    ) {
      let newAppStateObj = PlolygonServicesUtils.design_polygon_service_list_record(appStateObj, polygonServiceConfigurationRecord.data, servicePropositionList);
      setAppStateObj(newAppStateObj);
    }
  };

  const configurePolygonServiceCountData = () => {
    if (
      polygonseServiceCountData !== undefined &&
      polygonseServiceCountData.fetchStatus !== undefined &&
      polygonseServiceCountData.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED &&
      polygonseServiceCountData.data !== undefined &&
      typeof polygonseServiceCountData.data === "object"
    ) {
      let newAppCountStateObj = PlolygonServicesUtils.design_polygon_service_count_record_utils(appCountStateObj, polygonseServiceCountData.data, servicePropositionList);
      setAppCountStateObj(newAppCountStateObj);
    }
  };

  const updatePolygonServiceRecord = async (payloadObject: any) => {
    if (payloadObject !== undefined && typeof payloadObject === "object" && Object.keys(payloadObject).length > 0) {
      const { payload }: any = await appDispatch(PlolygonServicesSlice.postPolygonServiceRecordnByPayload(payloadObject));
      if (payload !== undefined && payload.success !== undefined && payload.success === true) {
        CustomAlert(ALERT_TYPES.SUCCESS, PlolygonServicesConstants.CONFIGURATIONS_UPDATED_SUCCESSFULLY);
        fetchPolygonRecord(currentCountry.name);
      } else {
        if (payload !== undefined && typeof payload === "object" && payload.message !== undefined && typeof payload.message === "string" && payload.message !== "") {
          CustomAlert(ALERT_TYPES.ERROR, payload.message);
        } else {
          CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.ERROR_OCCURRED_WHILE_SAVING);
        }
        setAppStateObj({ ...appStateObj, loading: false });
      }
    } else {
      setAppStateObj({ ...appStateObj, loading: false });
    }
  };

  const designPolygonSubmitObject = () => {
    const polygon_record = appStateObj.polygonServiceListData[appStateObj.editableRowIndex];
    if (appStateObj !== undefined && appStateObj.isNonServiceable !== undefined && appStateObj.isNonServiceable) {
      let payloadObject = PlolygonServicesUtils.design_polygon_submit_pauload_object_utils(appStateObj, polygon_record, appStateObj.serviceTypeName, appStateObj.isNonServiceable, {}, servicePropositionList);
      updatePolygonServiceRecord(payloadObject);
    } else {
      if (
        appStateObj !== undefined &&
        appStateObj.selectedPosZoneIndex !== undefined &&
        appStateObj.selectedPosZoneIndex !== "" &&
        appStateObj.selectedPosZoneObj !== undefined &&
        typeof appStateObj.selectedPosZoneObj === "object" &&
        Object.keys(appStateObj.selectedPosZoneObj).length > 0
      ) {
        let payloadObject = PlolygonServicesUtils.design_polygon_submit_pauload_object_utils(appStateObj, polygon_record, appStateObj.serviceTypeName, appStateObj.isNonServiceable, appStateObj.selectedPosZoneObj, servicePropositionList);
        updatePolygonServiceRecord(payloadObject);
      } else {
        CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.NO_POS_ZONE_SELECTED_ERROR);
        setAppStateObj({ ...appStateObj, loading: false });
      }
    }
  };

  const handlePOSZoneMappingSubmit = () => {
    setAppStateObj({ ...appStateObj, loading: true });
    if (
      appStateObj !== undefined &&
      appStateObj.editableRowIndex !== undefined &&
      appStateObj.editableRowIndex >= 0 &&
      appStateObj.polygonServiceListData !== undefined &&
      Array.isArray(appStateObj.polygonServiceListData) &&
      appStateObj.polygonServiceListData.length > 0 &&
      appStateObj.polygonServiceListData[appStateObj.editableRowIndex] !== undefined &&
      appStateObj.polygonServiceListData[appStateObj.editableRowIndex].polygonId !== undefined &&
      appStateObj.polygonServiceListData[appStateObj.editableRowIndex].polygonId !== ""
    ) {
      designPolygonSubmitObject();
    } else {
      CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.NO_POLYGON_LIST_FOUND_FOR_SUBMIT);
      setAppStateObj({ ...appStateObj, loading: false });
    }
  };

  const handleServiceTypeChange = (event: any) => {
    if (event !== undefined && Array.isArray(event)) {
      if (event.length > 0) {
        setAppCountStateObj({ ...appCountStateObj, selectedServiceTypeFilter: event, loading: true });
        let newAppFilterPopUpObj = PlolygonServicesUtils.handle_clear_all_pos_zone_filter_pop_up_utils(appFilterPopUpObj);
        setAppFilterPopUpObj(newAppFilterPopUpObj);
        fetchPolygonServiceRecordnBasedOnCountryId(currentCountry.name, event, appCountStateObj.selectedTileFilter, appStateObj.page, newAppFilterPopUpObj, false);
        fetchPolygonServiceCountBasedOnQuery(currentCountry.name, event);
      } else {
        setAppCountStateObj({ ...appCountStateObj, selectedServiceTypeFilter: event });
      }
    }
  };

  const handleTileClick = (countObjIndex: any) => {
    if (appStateObj.polygonView === PlolygonServicesConstants.POLYGON_VIEW_TYPE.LISTING && countObjIndex !== undefined && countObjIndex >= 0) {
      localStorage.removeItem(PlolygonServicesConstants.POLYGON_ID_LOCAL_STORAGE);
      setAppCountStateObj({ ...appCountStateObj, selectedTileFilter: countObjIndex });
      setAppStateObj({ ...appStateObj, loading: true, page: 1, searchValue: "", searchTypeValue: "polygonId" });
      let newAppFilterPopUpObj = PlolygonServicesUtils.handle_clear_all_pos_zone_filter_pop_up_utils(appFilterPopUpObj);
      setAppFilterPopUpObj(newAppFilterPopUpObj);
      let serviceTypeFilter = PlolygonServicesUtils.generateServiceNameForPolygon(servicePropositionList);
      if (!isEmptyOrNull(appCountStateObj.serviceTypeFilter)) {
        serviceTypeFilter = [appCountStateObj.serviceTypeFilter];
      }
      fetchPolygonServiceRecordnBasedOnCountryId(currentCountry.name, serviceTypeFilter, countObjIndex, 1, newAppFilterPopUpObj, false);
    }
  };

  const fetchNextRecord = (event: any, page: any) => {
    let is_search_based = false;
    if (!isUndefined(appStateObj) && !isEmptyOrNull(appStateObj.searchTypeValue) && !isEmptyOrNull(appStateObj.searchValue)) {
      is_search_based = true;
    }
    const pageNumber = Number(page) + 1;
    setAppStateObj({ ...appStateObj, loading: true, page: pageNumber });
    let serviceTypeFilter = PlolygonServicesUtils.generateServiceNameForPolygon(servicePropositionList);
    if (!isEmptyOrNull(appCountStateObj.serviceTypeFilter)) {
      serviceTypeFilter = [appCountStateObj.serviceTypeFilter];
    }
    fetchPolygonServiceRecordnBasedOnCountryId(currentCountry.name, serviceTypeFilter, appCountStateObj.selectedTileFilter, pageNumber, appFilterPopUpObj, is_search_based);
  };

  const design_mapped_pos_zone_record = (listData: any, appFilterPopUpObjCopy: any) => {
    if (listData !== undefined && Array.isArray(listData)) {
      let newAppFilterPopUpObj = PlolygonServicesUtils._handle_mapped_pos_zone_list_by_service_utils(appFilterPopUpObjCopy, listData);
      setAppFilterPopUpObj(newAppFilterPopUpObj);
    }
  };

  const fetchMappedPosZoneListBasedOnServiceTypeAndCountry = async (payloadObject: any, newAppFilterPopUpObj: any) => {
    const { payload }: any = await appDispatch(PlolygonServicesSlice.getMappedPosZoneListBasedOnServiceTypeAndCountry(payloadObject));
    if (payload !== undefined && payload.success !== undefined && payload.success === true && payload.data !== undefined) {
      design_mapped_pos_zone_record(payload.data, newAppFilterPopUpObj);
    } else {
      if (payload !== undefined && typeof payload === "object" && payload.message !== undefined && typeof payload.message === "string" && payload.message !== "") {
        CustomAlert(ALERT_TYPES.ERROR, payload.message);
      } else {
        CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.ERROR_OCCURRED_WHILE_SAVING);
      }
      let newAppFilterPopUpObj = PlolygonServicesUtils.handle_open_filter_pop_up_utils(undefined, "", appFilterPopUpObj, false);
      setAppFilterPopUpObj(newAppFilterPopUpObj);
    }
  };

  const handleFilterPopupOpen = (event: any, serviceType: any) => {
    if (serviceType !== undefined && serviceType !== "") {
      if (currentCountry !== undefined && currentCountry !== "" && currentCountry.name !== undefined && currentCountry.name !== "" && serviceType !== undefined && serviceType !== "") {
        let payloadObject = { countryName: currentCountry.name, serviceType: serviceType.toUpperCase() } as any;
        let newAppFilterPopUpObj = PlolygonServicesUtils.handle_open_filter_pop_up_utils(event, serviceType, appFilterPopUpObj, true);
        setAppFilterPopUpObj(newAppFilterPopUpObj);
        fetchMappedPosZoneListBasedOnServiceTypeAndCountry(payloadObject, newAppFilterPopUpObj);
      }
    }
  };

  const handleFilterPopupClose = (serviceType: any) => {
    let newAppFilterPopUpObj = PlolygonServicesUtils.handle_open_filter_pop_up_utils(undefined, "", appFilterPopUpObj, false);
    setAppFilterPopUpObj(newAppFilterPopUpObj);
  };

  const headerRowCheckboxOnChange = (event: any, listIndex: any, serviceTypeName: any) => {
    if (listIndex !== undefined && listIndex >= 0) {
      if (event !== undefined && event.target !== undefined && event.target.checked !== undefined) {
        const { checked } = event.target;
        if (
          appFilterPopUpObj !== undefined &&
          appFilterPopUpObj.posZoneServiceFilteredRecord !== undefined &&
          Array.isArray(appFilterPopUpObj.posZoneServiceFilteredRecord) &&
          appFilterPopUpObj.posZoneServiceFilteredRecord.length > 0 &&
          appFilterPopUpObj.posZoneServiceFilteredRecord[listIndex] !== undefined
        ) {
          let newAppFilterPopUpObj = PlolygonServicesUtils.handle_check_box_click_utils(appFilterPopUpObj, checked, listIndex);
          setAppFilterPopUpObj(newAppFilterPopUpObj);
        }
      }
    }
  };

  const handlePosZoneSearchTextChange = (event: any) => {
    if (event !== undefined && event.target !== undefined) {
      const { value } = event.target;
      let { listData } = PlolygonServicesUtils.design_pos_zone_based_on_search(appFilterPopUpObj.posZoneServiceRecord, value, appFilterPopUpObj.posZoneServiceFilteredRecord);
      setAppFilterPopUpObj({ ...appFilterPopUpObj, posZoneServiceFilteredRecord: listData, posZoneSearchText: value });
    }
  };

  const handlePosZoneSearch = () => {};

  const handleResetPopUpOnClick = (serviceType: any) => {
    let newAppFilterPopUpObj = PlolygonServicesUtils.handle_reset_pos_zone_filter_pop_up_utils(appFilterPopUpObj, serviceType);
    setAppFilterPopUpObj(newAppFilterPopUpObj);
  };

  const resetServiceTypeFilterAndTileAndFetchPolygonRecord = () => {
    setAppCountStateObj({ ...appCountStateObj, selectedTileFilter: 0, serviceTypeFilter: "" });
    setAppStateObj({ ...appStateObj, loading: true });
    setAppFilterPopUpObj({ ...appFilterPopUpObj, loading: false, openPopUp: false, anchorEl: null, posZoneSearchText: "", posZoneServiceRecord: [], posZoneServiceFilteredRecord: [] });
    fetchPolygonServiceRecordnBasedOnCountryId(currentCountry.name, PlolygonServicesUtils.generateServiceNameForPolygon(servicePropositionList), 0, 1, appFilterPopUpObj, false);
  };

  const handleApplyPopUpOnClick = (serviceType: any) => {
    if (!isUndefined(serviceType) && !isEmptyOrNull(serviceType)) {
      resetServiceTypeFilterAndTileAndFetchPolygonRecord();
    }
  };

  const clearAllFilter = () => {
    setAppCountStateObj({ ...appCountStateObj, selectedTileFilter: 0, serviceTypeFilter: "" });
    setAppStateObj({ ...appStateObj, loading: true, searchValue: "", searchTypeValue: "polygonId", latSearchValue: "", lngSearchValue: "" });
    let newAppFilterPopUpObj = PlolygonServicesUtils.handle_clear_all_pos_zone_filter_pop_up_utils(appFilterPopUpObj);
    setAppFilterPopUpObj(newAppFilterPopUpObj);
    fetchPolygonServiceRecordnBasedOnCountryId(currentCountry.name, PlolygonServicesUtils.generateServiceNameForPolygon(servicePropositionList), 0, 1, newAppFilterPopUpObj, false);
    fetchPolygonServiceCountBasedOnQuery(currentCountry.name, PlolygonServicesUtils.generateServiceNameForPolygon(servicePropositionList));
  };

  const handleSearchTypeChange = (selected: any) => {
    if (selected !== undefined && selected !== "") {
      setAppStateObj({ ...appStateObj, searchTypeValue: selected, latSearchValue: "", lngSearchValue: "" });
    }
  };

  const handleSearchBoxTextChange = (event: any) => {
    if (event?.target) {
      const { value, name } = event.target;
      if (name === "latSearchValue" || name === "lngSearchValue") {
        if (value === "") {
          setAppStateObj({ ...appStateObj, [name]: value });
        } else if (name === "latSearchValue" && value > -89 && value < 91) {
          setAppStateObj({ ...appStateObj, [name]: String(value) });
        } else if (name === "lngSearchValue" && value > -179 && value < 181) {
          setAppStateObj({ ...appStateObj, [name]: String(value) });
        }
      } else {
        setAppStateObj({ ...appStateObj, searchValue: value });
      }
    }
  };

  const handleSearchIconClick = () => {
    if (appStateObj !== undefined && appStateObj.searchTypeValue !== undefined && appStateObj.searchTypeValue !== "") {
      if (appStateObj !== undefined && appStateObj.searchValue !== undefined && appStateObj.searchValue !== "") {
        setAppStateObj({ ...appStateObj, loading: true });
        if (appStateObj.polygonView === PlolygonServicesConstants.POLYGON_VIEW_TYPE.MAP) {
          showSearchedPolygonOnMap();
        } else {
          setAppCountStateObj({ ...appCountStateObj, selectedTileFilter: 0, serviceTypeFilter: "" });
          let newAppFilterPopUpObj = PlolygonServicesUtils.handle_clear_all_pos_zone_filter_pop_up_utils(appFilterPopUpObj);
          setAppFilterPopUpObj(newAppFilterPopUpObj);
          fetchPolygonServiceRecordnBasedOnCountryId(currentCountry.name, PlolygonServicesUtils.generateServiceNameForPolygon(servicePropositionList), 0, 1, newAppFilterPopUpObj, true);
        }
      } else {
        CustomAlert(ALERT_TYPES.ERROR, "Please provide search value");
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, "Please provide search type");
    }
  };

  const handleSearchBoxKeyDownChange = (event: any) => {
    if ((event.key !== undefined && event.key === "Enter") || event.keyCode === 13) {
      handleSearchIconClick();
    }
  };

  const fetch_polygon_details_based_on_id_service_type_and_country = async (polygonId: any, polygonName: any, countryName: any, serviceType: any) => {
    if (polygonId !== undefined && polygonId !== "" && countryName !== undefined && countryName !== "" && serviceType !== undefined && serviceType !== "") {
      let newPolygonDetailsSideDrawerObj = PlolygonServicesUtils.reset_polygon_details_history_drawer_obj(polygonDetailsSideDrawerObj);
      setPolygonDetailsSideDrawerObj(newPolygonDetailsSideDrawerObj);
      //
      let payloadObject = { polygonId: polygonId, serviceType: serviceType, country: countryName } as any;
      const { payload }: any = await appDispatch(PlolygonServicesSlice.getPolygonDetailsHistoryBasedOnIdServiceTypeAndCountry(payloadObject));
      if (payload !== undefined && payload.success !== undefined && payload.success === true && payload.data !== undefined) {
        let updatedPolygonDetailsSideDrawerObj = PlolygonServicesUtils.design_polygon_history_record_utils(polygonId, polygonName, serviceType, payload.data, newPolygonDetailsSideDrawerObj);
        setPolygonDetailsSideDrawerObj(updatedPolygonDetailsSideDrawerObj);
      } else {
        if (payload !== undefined && typeof payload === "object" && payload.message !== undefined && typeof payload.message === "string" && payload.message !== "") {
          CustomAlert(ALERT_TYPES.ERROR, payload.message);
        } else {
          CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.ERROR_OCCURRED_WHILE_SAVING);
        }
        hanlePolygonDetailsDrawerClose();
      }
    }
  };

  const hanlePolygonDetailsDrawerOpen = (polygonId: any, polygonName: any) => {
    if (!isUndefined(polygonId) && !isUndefined(currentCountry?.name)) {
      fetch_polygon_details_based_on_id_service_type_and_country(polygonId, polygonName, currentCountry.name, PlolygonServicesUtils.generateServiceNameForPolygon(servicePropositionList)[0]);
    }
  };

  const hanlePolygonDetailsDrawerClose = () => {
    let newPolygonDetailsSideDrawerObj = PlolygonServicesUtils.close_polygon_details_history_drawer_obj(polygonDetailsSideDrawerObj);
    setPolygonDetailsSideDrawerObj(newPolygonDetailsSideDrawerObj);
    setPolygonDetailsSideDrawerObj({ ...polygonDetailsSideDrawerObj, openDrawer: false, loading: false, polygonId: "", polygonName: "", polygonDetailsHistory: [] });
  };

  const handlePolygonDetailsServiceTypeTabChange = (event: any, newValue: string) => {
    if (newValue !== undefined && newValue !== "") {
      if (polygonDetailsSideDrawerObj !== undefined) {
        if (polygonDetailsSideDrawerObj.polygonId !== undefined && polygonDetailsSideDrawerObj.polygonId !== "") {
          fetch_polygon_details_based_on_id_service_type_and_country(polygonDetailsSideDrawerObj.polygonId, polygonDetailsSideDrawerObj.polygonName, currentCountry?.name, newValue);
        }
      }
    }
  };

  const handleExportCSVClick = async () => {
    if (currentCountry !== undefined && currentCountry !== "" && currentCountry.name !== undefined && currentCountry.name !== "") {
      setAppStateObj({ ...appStateObj, isExporting: true });
      let payload_request = {
        countryName: currentCountry.name,
      };
      const { payload }: any = await appDispatch(PlolygonServicesSlice.exportToExcel(payload_request));
      setAppStateObj({ ...appStateObj, isExporting: false });
      if (payload !== undefined && payload.fileName !== undefined) {
        downloadXLSXFile(payload.fileName, payload?.response);
      } else {
        let error_message = "Something went wrong, Please try again later";
        if (payload !== undefined && payload.message !== undefined) {
          error_message = payload.message;
        }
        CustomAlert(ALERT_TYPES.ERROR, error_message);
      }
    }
  };

  const handlePolygonClick = (event: any, polygon: PlolygonServicesInterface.MapPolygonInterface) => {
    if (event && event.latLng) {
      setAppStateObj({ ...appStateObj, openMapInfoWindow: true, mapInfoWindowRecord: polygon, event: event });
    }
  };

  const handlePolygonInfoWindowClose = (event: any) => {
    event.stopPropagation();
    setAppStateObj({ ...appStateObj, openMapInfoWindow: false, mapInfoWindowRecord: null, event: null });
  };

  const showSearchedPolygonOnMap = () => {
    let searchedIndex = PlolygonServicesUtils.get_searched_polygon__index_utils(appStateObj);
    if (searchedIndex !== -1) {
      setAppStateObj({
        ...appStateObj,
        loading: false,
        openMapInfoWindow: true,
        mapInfoWindowRecord: appStateObj.mapPolygonsRecord[searchedIndex],
        event: { lat: appStateObj.mapPolygonsRecord[searchedIndex].path[0].lat, lng: appStateObj.mapPolygonsRecord[searchedIndex].path[0].lng },
      });
    } else {
      setAppStateObj({ ...appStateObj, loading: false });
    }
  };

  const clearAllMapFilter = () => {
    setAppCountStateObj({ ...appCountStateObj, serviceTypeFilter: "" });
    setAppStateObj({ ...appStateObj, overlayLoader: true, mapPolygonsRecord: [], mapMarkersRecord: [], searchValue: "", searchTypeValue: "polygonId" });
    const polygonRecord = PlolygonServicesUtils.design_polygon_map_view_record_utils(polygonServiceMapRecord.data, appStateObj.serviceBasedColorRecord);
    const mapMarkersRecord = PlolygonServicesUtils.re_enter_map_marker_data(appStateObj);
    let center = { lat: appStateObj.mapDefaultCenter.lat, lng: appStateObj.mapDefaultCenter.lng };
    if (!isUndefined(appStateObj) && !isUndefined(appStateObj?.latSearchValue) && !isUndefined(appStateObj?.lngSearchValue)) {
      if (countryDetails?.data?.serviceTypes && isArrayValid(countryDetails.data.serviceTypes)) {
        let obj = countryDetails.data.serviceTypes.find((service: any) => service.defaultPos && service.defaultPos.latitude);
        if (obj?.defaultPos?.longitude) {
          center.lat = Number(obj.defaultPos.latitude);
          center.lng = Number(obj.defaultPos.longitude);
        }
      }
    }
    setAppStateObj({
      ...appStateObj,
      overlayLoader: false,
      mapPolygonsRecord: polygonRecord,
      mapMarkersRecord: mapMarkersRecord,
      mapDefaultCenter: center,
      mapCenter: center,
      searchValue: "",
      searchTypeValue: "polygonId",
      openMapInfoWindow: false,
      mapInfoWindowRecord: null,
      isPosLevelFilteredApplied: false,
      event: null,
      posLevelFilterData: null,
      latSearchValue: "",
      lngSearchValue: "",
      currentTime: new Date().setHours(new Date().getHours()),
    });
  };

  const handleSingleServiceTypeChange = (event: any) => {
    if (event && event.target) {
      const { value } = event.target;
      const serviceTypeFilter = [value];
      if (appStateObj.polygonView === PlolygonServicesConstants.POLYGON_VIEW_TYPE.MAP) {
        setAppCountStateObj({ ...appCountStateObj, serviceTypeFilter: value });
        setAppStateObj({ ...appStateObj, overlayLoader: true });
        let updatedAppStateObj = PlolygonServicesUtils.handle_single_service_type_change_utils(appStateObj, value);
        setAppStateObj(updatedAppStateObj);
      } else {
        setAppCountStateObj({ ...appCountStateObj, serviceTypeFilter: value, loading: true });
        let newAppFilterPopUpObj = PlolygonServicesUtils.handle_clear_all_pos_zone_filter_pop_up_utils(appFilterPopUpObj);
        setAppFilterPopUpObj(newAppFilterPopUpObj);
        fetchPolygonServiceRecordnBasedOnCountryId(currentCountry.name, serviceTypeFilter, appCountStateObj.selectedTileFilter, appStateObj.page, newAppFilterPopUpObj, false);
        fetchPolygonServiceCountBasedOnQuery(currentCountry.name, serviceTypeFilter);
      }
    }
  };

  const openPolygonPosZoneInfoWindow = (markerIndex: number) => {
    if (appStateObj?.mapMarkersRecord && appStateObj?.mapMarkersRecord[markerIndex] && appStateObj?.mapMarkersRecord[markerIndex]?.name && !isEmptyOrNull(appStateObj?.mapMarkersRecord[markerIndex]?.name)) {
      const newAppStateObj = PlolygonServicesUtils.turn_on_overlay_loader(appStateObj);
      setAppStateObj(newAppStateObj);
      let updatedAppStateObj = PlolygonServicesUtils.open_polygon_pos_zone_info_window_utils(markerIndex, newAppStateObj, appCountStateObj);
      setAppStateObj(updatedAppStateObj);
    }
  };

  const closePolygonPosZoneInfoWindow = (event: any) => {
    event.stopPropagation();
    let newAppStateObj = PlolygonServicesUtils.close_polygon_pos_zone_info_window_utils(appStateObj);
    setAppStateObj(newAppStateObj);
  };

  const selectServiceTypeClick = (marker: PlolygonServicesInterface.MAP_MARKER_INTERFACE, serviceIndex: number) => {
    setAppStateObj({ ...appStateObj, overlayLoader: true });
    let newAppStateObj = PlolygonServicesUtils.select_service_polygon_pos_zone_info_window_utils(marker, serviceIndex, polygonServiceMapRecord.data, appStateObj);
    setAppStateObj(newAppStateObj);
  };

  const selectServiceZoneTypeClick = (marker: any, zone: any, zoneIndex: number) => {
    setAppStateObj({ ...appStateObj, overlayLoader: true, mapPolygonsRecord: [] });
    let newAppStateObj = PlolygonServicesUtils.select_service_zone_polygon_pos_zone_info_window_utils(marker, zone, zoneIndex, polygonServiceMapRecord.data, appStateObj);
    setAppStateObj(newAppStateObj);
  };

  const clearPosLevelFilter = () => {
    const polygonRecord = PlolygonServicesUtils.design_polygon_map_view_record_utils(polygonServiceMapRecord.data, appStateObj.serviceBasedColorRecord, appCountStateObj.serviceTypeFilter);
    const newMapMarkersRecord = PlolygonServicesUtils.reset_map_marker_data(appStateObj);
    setAppStateObj({
      ...appStateObj,
      overlayLoader: false,
      mapPolygonsRecord: polygonRecord,
      mapMarkersRecord: newMapMarkersRecord,
      mapInfoWindowRecord: null,
      isPosLevelFilteredApplied: false,
      posLevelFilterData: null,
    });
  };

  const designSuccessPolygonServiceMapRecord = () => {
    if (polygonServiceMapRecord && polygonServiceMapRecord.data) {
      const polygonRecord = PlolygonServicesUtils.design_polygon_map_view_record_utils(polygonServiceMapRecord.data, appStateObj.serviceBasedColorRecord, appCountStateObj.serviceTypeFilter, appStateObj);
      const newMapMarkersRecord = PlolygonServicesUtils.refresh_map_marker_data(appStateObj);
      setAppStateObj({ ...appStateObj, overlayLoader: false, mapPolygonsRecord: polygonRecord, mapMarkersRecord: newMapMarkersRecord });
    } else {
      setAppStateObj({ ...appStateObj, overlayLoader: false });
    }
  };

  const designPolygonServiceMapRecord = () => {
    if (polygonServiceMapRecord && polygonServiceMapRecord.error && !isNull(polygonServiceMapRecord.error)) {
      setAppStateObj({ ...appStateObj, overlayLoader: false });
    } else {
      designSuccessPolygonServiceMapRecord();
    }
  };

  /** Once map is loaded on the screen --> onIdle is called with boundries */
  const fetchPolygonServiceMapRecordnBasedOnBoundaries = (northEast: CoordinatesInterface, southWest: CoordinatesInterface) => {
    const currentTime: any = new Date().setHours(new Date().getHours());
    let filterTime: any = moment(currentTime).format("HH:mm:ss");
    if (moment(appStateObj.currentTime).isValid()) {
      filterTime = moment(appStateObj.currentTime).format("HH:mm:ss");
    }
    const request_payload = {
      boundaries: { northEast: `${northEast.lat},${northEast.lng}`, southWest: `${southWest.lat},${southWest.lng}` },
      filterTime: filterTime,
    };
    setAppStateObj({ ...appStateObj, overlayLoader: true, mapPolygonsRecord: [], boundaries: request_payload.boundaries });
    appDispatch(PlolygonServicesSlice.getPolygonServiceMapRecordnBasedOnBoundaries(request_payload));
  };

  /** If pos zone data present then create marker and obtain center. And Show map.
   *  Once map loaded on to the screen onIdle is called by default
   *  */
  const showMapAndMoveCenterBasedOnCountry = (markersRecord: any) => {
    let center = { lat: 0, lng: 0 };
    if (countryDetails && countryDetails.data && countryDetails.data.serviceTypes && isArrayValid(countryDetails.data.serviceTypes)) {
      let obj = countryDetails.data.serviceTypes.find((service: any) => service.defaultPos && service.defaultPos.latitude);
      if (obj && obj.defaultPos && obj.defaultPos.latitude && obj.defaultPos.longitude) {
        center.lat = Number(obj.defaultPos.latitude);
        center.lng = Number(obj.defaultPos.longitude);
      }
    }
    setAppStateObj({
      ...appStateObj,
      loading: false,
      polygonView: PlolygonServicesConstants.POLYGON_VIEW_TYPE.MAP,
      overlayLoader: true,
      mapDefaultCenter: center,
      mapCenter: center,
      mapMarkersRecord: markersRecord,
      mapPolygonsRecord: [],
      currentTime: new Date().setHours(new Date().getHours()),
      boundaries: null,
      polygonServiceListData: [],
      page: 1,
      size: 50,
      maxRecordPresent: 0,
      polygonServiceListHeader: {},
      serviceBasedColorRecord: PlolygonServicesUtils.getServiceTypeColorList(servicePropositionList),
    });
  };

  /** To switch to map view first fetch pos of the country to create markers and get default pos lat lng to add map center */
  const fetchPosZoneRecordPositionDetails = async (serviceTypeRecord: Array<string>) => {
    setAppStateObj({ ...appStateObj, loading: true, searchValue: "", searchTypeValue: "polygonId", currentTime: new Date().setHours(new Date().getHours()), boundaries: null });
    setAppCountStateObj({ ...appCountStateObj, selectedTileFilter: 0 });
    const request_payload = { serviceTypes: serviceTypeRecord, status: [SLOT_STATUSES.ACTIVATED] };
    const { payload }: any = await appDispatch(PlolygonServicesSlice.getPosZoneRecordPositionDetails(request_payload));
    if (payload && isArrayValid(payload)) {
      const markersRecord = PlolygonServicesUtils.design_polygon_map_marker_record_utils(appStateObj, payload, servicePropositionList, appCountStateObj.serviceTypeFilter);
      showMapAndMoveCenterBasedOnCountry(markersRecord);
    } else {
      setAppStateObj({ ...appStateObj, loading: false });
      if (payload !== undefined && typeof payload === "object" && payload.message !== undefined && typeof payload.message === "string" && payload.message !== "") {
        CustomAlert(ALERT_TYPES.ERROR, payload.message);
      } else {
        CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.ERROR_OCCURRED_WHILE_SAVING);
      }
    }
  };

  const switchPolygonView = () => {
    switch (appStateObj.polygonView) {
      case PlolygonServicesConstants.POLYGON_VIEW_TYPE.LISTING:
        fetchPosZoneRecordPositionDetails(PlolygonServicesUtils.generateServiceNameForPolygon(servicePropositionList));
        return;
      case PlolygonServicesConstants.POLYGON_VIEW_TYPE.MAP:
        setAppStateObj({
          ...appStateObj,
          polygonView: PlolygonServicesConstants.POLYGON_VIEW_TYPE.LISTING,
          loading: true,
          mapMarkersRecord: [],
          mapPolygonsRecord: [],
          searchValue: "",
          searchTypeValue: "polygonId",
          openMapInfoWindow: false,
          mapInfoWindowRecord: null,
          event: null,
          isPosLevelFilteredApplied: false,
          posLevelFilterData: null,
          latSearchValue: "",
          lngSearchValue: "",
          currentTime: new Date().setHours(new Date().getHours()),
          boundaries: null,
          serviceBasedColorRecord: [],
        });
        fetchPolygonRecord(currentCountry.name);
        return;
    }
  };

  // View Utils
  const viewPolygonInfoWindow = () => {
    return appStateObj.openMapInfoWindow ? (
      <PolygonInfoWindowViewUtils
        classes={classes}
        polygonRecord={appStateObj.mapInfoWindowRecord}
        handlePolygonInfoWindowClose={handlePolygonInfoWindowClose}
        serviceList={PlolygonServicesUtils.generateServiceNameValueForPolygon(servicePropositionList)}
      />
    ) : null;
  };

  const posZoneMarkerInfoWindow = (marker: any) => {
    return <PolygonPosZoneInfoWindowViewUtils classes={classes} marker={marker} selectServiceTypeClick={selectServiceTypeClick} closePolygonPosZoneInfoWindow={closePolygonPosZoneInfoWindow} selectServiceZoneTypeClick={selectServiceZoneTypeClick} />;
  };

  const moveMapToCordinates = () => {
    if (!isEmptyOrNull(appStateObj.latSearchValue) && !isEmptyOrNull(appStateObj.lngSearchValue)) {
      const isLatitude = PlolygonServicesUtils.isLatitudeValid(appStateObj.latSearchValue);
      const isLongitude = PlolygonServicesUtils.isLongitudeValid(appStateObj.lngSearchValue);
      if (isLatitude && isLongitude) {
        const newAppStateObj = PlolygonServicesUtils.move_map_to_cordinates_on_search_utils(appStateObj);
        setAppStateObj(newAppStateObj);
      } else {
        CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.LATITUDE_LONGITUDE_ERROR);
      }
    } else {
      CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.LATITUDE_LONGITUDE_EMPTY_ERROR);
    }
  };

  const handleServiceTimeChange = (name: string, dateData: any) => {
    if (moment(dateData).isValid() && !isEmptyOrNull(appStateObj.boundaries)) {
      const request_payload = { boundaries: appStateObj.boundaries, filterTime: moment(dateData).format("HH:mm:ss") };
      setAppStateObj({ ...appStateObj, [name]: dateData, overlayLoader: true, mapPolygonsRecord: [], boundaries: request_payload.boundaries });
      appDispatch(PlolygonServicesSlice.getPolygonServiceMapRecordnBasedOnBoundaries(request_payload));
    } else {
      setAppStateObj({ ...appStateObj, [name]: dateData });
    }
  };

  useEffect(() => {
    polygonServiceMapRecord && polygonServiceMapRecord.fetchStatus && polygonServiceMapRecord.fetchStatus !== API_RESPONSE_STATUS.IDLE && polygonServiceMapRecord.fetchStatus !== API_RESPONSE_STATUS.LOADING && designPolygonServiceMapRecord();
  }, [polygonServiceMapRecord]);

  useEffect(() => {
    polygonServiceConfigurationRecord && polygonServiceConfigurationRecord.data && configurePolygonServiceRecordListData();
  }, [polygonServiceConfigurationRecord, polygonServiceConfigurationRecord.data]);

  useEffect(() => {
    polygonServiceConfigurationRecord && polygonServiceConfigurationRecord.error && configurePolygonServiceError();
  }, [polygonServiceConfigurationRecord, polygonServiceConfigurationRecord.error]);

  useEffect(() => {
    polygonseServiceCountData && polygonseServiceCountData.data && configurePolygonServiceCountData();
  }, [polygonseServiceCountData, polygonseServiceCountData.data]);

  useEffect(() => {
    polygonseServiceCountData && polygonseServiceCountData.error && configurePolygonServiceCountError();
  }, [polygonseServiceCountData, polygonseServiceCountData.error]);

  useEffect(() => {
    if (!isUndefined(servicePropositionList) && !isUndefined(currentCountry.name) && !isEmptyOrNull(currentCountry.name)) {
      setAppStateObj({ ...appStateObj, loading: true });
      fetchPolygonRecord(currentCountry.name);
    }
  }, [servicePropositionList, currentCountry]);

  useEffect(() => {
    return () => {
      appDispatch(PlolygonServicesSlice.resetPolygonServiceConfigurationServiceState());
      appDispatch(PlolygonServicesSlice.resetPolygonServiceCountServiceState());
      localStorage.removeItem(PlolygonServicesConstants.POLYGON_ID_LOCAL_STORAGE);
    };
  }, []);

  return (
    <div className={classes.container}>
      <OverlayLoader loading={appStateObj.overlayLoader} />
      <PlolygonServicesHeader classes={classes} appStateObj={appStateObj} isExporting={appStateObj.isExporting} handleExportCSVClick={handleExportCSVClick} switchPolygonView={switchPolygonView} userInfo={userInfo} />
      {appStateObj.loading || appCountStateObj.loading ? (
        <div className={classes.loaderDiv}>
          <Loader />
        </div>
      ) : (
        <>
          <PlolygonServicesCountHeader
            classes={classes}
            appStateObj={appStateObj}
            appCountStateObj={appCountStateObj}
            handleServiceTypeChange={handleServiceTypeChange}
            handleTileClick={handleTileClick}
            handleSingleServiceTypeChange={handleSingleServiceTypeChange}
          />
          <PolygonServiceFilterSection
            classes={classes}
            appStateObj={appStateObj}
            handleSearchTypeChange={handleSearchTypeChange}
            handleSearchBoxTextChange={handleSearchBoxTextChange}
            handleSearchBoxKeyDownChange={handleSearchBoxKeyDownChange}
            handleSearchIconClick={handleSearchIconClick}
            clearAllFilter={appStateObj.polygonView === PlolygonServicesConstants.POLYGON_VIEW_TYPE.LISTING ? clearAllFilter : clearAllMapFilter}
            clearPosLevelFilter={clearPosLevelFilter}
            moveMapToCordinates={moveMapToCordinates}
            handleServiceTimeChange={handleServiceTimeChange}
          />
          {appStateObj.polygonView === PlolygonServicesConstants.POLYGON_VIEW_TYPE.LISTING ? (
            <PlolygonServicesCardSection
              classes={classes}
              rowsData={appStateObj.polygonServiceListData}
              currentCountry={currentCountry}
              userInfo={userInfo}
              appStateObj={appStateObj}
              fetchNextRecord={fetchNextRecord}
              appCountStateObj={appCountStateObj}
              //
              handleFilterPopupOpen={handleFilterPopupOpen}
              handleFilterPopupClose={handleFilterPopupClose}
              appFilterPopUpObj={appFilterPopUpObj}
              headerRowCheckboxOnChange={headerRowCheckboxOnChange}
              handlePosZoneSearch={handlePosZoneSearch}
              handlePosZoneSearchTextChange={handlePosZoneSearchTextChange}
              handleResetPopUpOnClick={handleResetPopUpOnClick}
              handleApplyPopUpOnClick={handleApplyPopUpOnClick}
              //
              hanlePolygonDetailsDrawerOpen={hanlePolygonDetailsDrawerOpen}
            />
          ) : appStateObj.polygonView === PlolygonServicesConstants.POLYGON_VIEW_TYPE.MAP ? (
            <PolygonMapService
              classes={classes}
              appStateObj={appStateObj}
              fetchPolygonServiceMapRecordnBasedOnBoundaries={fetchPolygonServiceMapRecordnBasedOnBoundaries}
              openPolygonPosZoneInfoWindow={openPolygonPosZoneInfoWindow}
              handlePolygonClick={handlePolygonClick}
              viewPolygonInfoWindow={viewPolygonInfoWindow}
              posZoneMarkerInfoWindow={posZoneMarkerInfoWindow}
            />
          ) : (
            <></>
          )}
        </>
      )}
      {polygonDetailsSideDrawerObj !== undefined && polygonDetailsSideDrawerObj.openDrawer !== undefined && polygonDetailsSideDrawerObj.openDrawer === true && (
        <PolygonDetailsDrawer
          polygonDetailsSideDrawerObj={polygonDetailsSideDrawerObj}
          handleClose={hanlePolygonDetailsDrawerClose}
          handlePolygonDetailsServiceTypeTabChange={handlePolygonDetailsServiceTypeTabChange}
          serviceListRecord={PlolygonServicesUtils.generateServiceNameValueForPolygon(servicePropositionList)}
        />
      )}
    </div>
  );
};

export default React.memo(PlolygonServicesPage);
