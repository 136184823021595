import React, { useEffect, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

import { userStyles } from "./Styles/PlolygonServicesPageStyles";
import { POLYGON_CONSTANT_TEXT } from "./Utils/PlolygonServicesConstants";
import { createCamelCase } from "../../utils/helperFunctions";
import { ChangeConfigOtion } from "../common/App/AppViewUtils";
import { ALERT_TYPES, API_RESPONSE_STATUS, APP_ROUTES } from "../../config/constants";
import { _design_Pos_Zone_Service_List_Record } from "../DeliveryFee/Utils/DeliveryFeeUtils";
import { countObjectProperties, isArrayValid, isEmptyOrNull, isUndefined } from "utils/DataUtils";
import { AppStateObj, AppStateObjInterface, MappingListItem, ServiceTypeRecord } from "./Utils/PlolygonServicesInterface";
import { serviceSelectPropositionRecord } from "../../config/redux/configurationsSlice";

import * as PlolygonServicesSlice from "./Redux/PlolygonServicesSlice";
import * as PlolygonServicesUtils from "./Utils/PlolygonServicesUtils";
import * as PlolygonServicesConstants from "./Utils/PlolygonServicesConstants";

import TimePicker from "../common/TimePicker/TimePicker";
import PosZoneMappingDialog from "./PosZoneMappingDialog/PosZoneMappingDialog";
import CustomAlert from "../common/CustomAlert/CustomAlert";
import OverlayLoader from "../common/Loader/OverlayLoader";
import RenderPolygonIdsMapping from "./Common/RenderPolygonIdsMapping";
import PrimaryButton from "components/common/PrimaryButton/PrimaryButton";
import RenderPolygonDetailsPopUp from "./Common/RenderPolygonDetailsPopUp";

interface PlolygonServicesConfigurationPageInterface {}

const PlolygonServicesConfigurationPage = (props: PlolygonServicesConfigurationPageInterface) => {
  const {} = props;

  const classes = userStyles();
  let location = useLocation<any>();
  const history = useHistory();
  const appDispatch = useDispatch();

  const { currentCountry } = useSelector((state: any) => state.appConfig);
  const posZoneMapListService: any = useSelector(PlolygonServicesSlice.selectServiceTypePosZoneMapRecord);
  const allPolygonListIdsRecord: any = useSelector(PlolygonServicesSlice.selectAllIdsPolygonRecord);
  const servicePropositionList = useSelector(serviceSelectPropositionRecord);

  const [appStateObj, setAppStateObj] = useState<AppStateObjInterface>(AppStateObj);

  const existingConfigChangeHandler = (event: any, recordListIndex: number, name: "sameAsEarlier") => {
    const serviceTypeRecordList = [...appStateObj.serviceTypeRecordList];
    const updatedServiceTypeRecord = { ...serviceTypeRecordList[recordListIndex] };
    updatedServiceTypeRecord[name] = !updatedServiceTypeRecord[name];
    updatedServiceTypeRecord.active = updatedServiceTypeRecord[name];
    updatedServiceTypeRecord.mappingList = [{ startTime: new Date().setHours(0, 0, 0), endTime: new Date().setHours(23, 59, 59), isNonServiceable: null }];
    serviceTypeRecordList[recordListIndex] = updatedServiceTypeRecord;
    setAppStateObj({ ...appStateObj, serviceTypeRecordList: [...serviceTypeRecordList] });
  };

  const configChangeExistingHandler = (event: any, recordListIndex: number, name: "enableConfigEdit") => {
    const serviceTypeRecordList = [...appStateObj.serviceTypeRecordList];
    const updatedServiceTypeRecord = { ...serviceTypeRecordList[recordListIndex] };
    updatedServiceTypeRecord[name] = !updatedServiceTypeRecord[name];
    serviceTypeRecordList[recordListIndex] = updatedServiceTypeRecord;
    setAppStateObj({ ...appStateObj, serviceTypeRecordList: [...serviceTypeRecordList] });
  };

  const handleServiceTypeAccordionOpenState = (recordListIndex: number) => {
    const serviceTypeRecordList = [...appStateObj.serviceTypeRecordList];
    const updatedServiceTypeRecord = {
      ...serviceTypeRecordList[recordListIndex],
      openAccordian: !serviceTypeRecordList[recordListIndex].openAccordian,
    };
    serviceTypeRecordList[recordListIndex] = updatedServiceTypeRecord;
    setAppStateObj({ ...appStateObj, serviceTypeRecordList: [...serviceTypeRecordList] });
  };

  const handleAddMappingButton = (recordListIndex: number) => {
    const serviceTypeRecordList = [...appStateObj.serviceTypeRecordList];
    const updatedServiceTypeRecord = { ...serviceTypeRecordList[recordListIndex] };

    let issueFound: boolean = false;
    let endFound: boolean = false;
    // Check if existing time ranges exceed 24 hours
    const existingTimeDifference = updatedServiceTypeRecord.mappingList.reduce((acc, mapping) => {
      if (mapping.startTime && mapping.endTime && moment(mapping.startTime).isValid() && moment(mapping.endTime).isValid()) {
        if (moment(mapping.endTime).hours() === 23 && moment(mapping.endTime).minutes() === 59) {
          endFound = true;
        }
        return acc + (Number(mapping.endTime) - Number(mapping.startTime));
      } else {
        issueFound = true;
        CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.POLYGON_CONSTANT_TEXT.PROVIDE_VALID_START_END_TIME);
        return acc; // Return accumulated time even if some mappings have invalid times
      }
    }, 0);

    // Allow a small buffer to account for potential rounding errors
    const allowedTolerance = 1000; // 1 second in milliseconds
    if (endFound || existingTimeDifference + allowedTolerance >= PlolygonServicesConstants.ONE_DAY_IN_MILLI_SECONDS) {
      CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.POLYGON_CONSTANT_TEXT.TIME_RANGE_ISSUE_ADD_MAPPING);
      return;
    }

    if (!issueFound) {
      const lastEndTime = updatedServiceTypeRecord.mappingList.length > 0 ? updatedServiceTypeRecord.mappingList[updatedServiceTypeRecord.mappingList.length - 1].endTime : undefined;
      const newMapping = { startTime: lastEndTime || new Date().setHours(0, 0, 0), endTime: new Date().setHours(23, 59, 59), isNonServiceable: null };
      updatedServiceTypeRecord.mappingList = [...updatedServiceTypeRecord.mappingList, newMapping];
      serviceTypeRecordList[recordListIndex] = updatedServiceTypeRecord;
      setAppStateObj({ ...appStateObj, serviceTypeRecordList: [...serviceTypeRecordList] });
    }
  };

  const handleRemoveMappingButton = (recordListIndex: number, mappingListIndex: number) => {
    const serviceTypeRecordList = [...appStateObj.serviceTypeRecordList];
    const updatedServiceTypeRecord = { ...serviceTypeRecordList[recordListIndex] };
    updatedServiceTypeRecord.mappingList = [...updatedServiceTypeRecord.mappingList.slice(0, mappingListIndex), ...updatedServiceTypeRecord.mappingList.slice(mappingListIndex + 1)];
    serviceTypeRecordList[recordListIndex] = updatedServiceTypeRecord;
    setAppStateObj({ ...appStateObj, serviceTypeRecordList: [...serviceTypeRecordList] });
  };

  const handleServiceTimeChange = (mappingObjIndex: number, serviceObjIndex: number, name: "startTime" | "endTime", date: any) => {
    let dateCopy: any = date;
    const serviceTypeRecordList = [...appStateObj.serviceTypeRecordList];
    const updatedServiceTypeRecord = { ...serviceTypeRecordList[serviceObjIndex] };
    const updatedMappingList = [...updatedServiceTypeRecord.mappingList];
    if (name === "endTime" && moment(date).isValid() && date?.getHours() === 23 && date?.getMinutes() === 59) {
      dateCopy.setSeconds(59);
    }
    updatedMappingList[mappingObjIndex] = { ...updatedMappingList[mappingObjIndex], [name]: dateCopy };
    updatedServiceTypeRecord.mappingList = updatedMappingList;
    serviceTypeRecordList[serviceObjIndex] = updatedServiceTypeRecord;
    setAppStateObj({ ...appStateObj, serviceTypeRecordList: [...serviceTypeRecordList] });
  };

  const handleOpenPosZoneMappingDialog = (serviceName: string, serviceRowIndex: number, mappingListRowIndex: number) => {
    if (serviceName !== undefined && serviceName !== "") {
      let serviceTypeList = [];
      serviceTypeList.push(serviceName.toUpperCase());
      let newAppStateObj = PlolygonServicesUtils.handle_reset_pos_zone_mappind_details_utils(appStateObj, true, serviceName, serviceRowIndex, mappingListRowIndex);
      setAppStateObj(newAppStateObj);
      appDispatch(PlolygonServicesSlice.fetchPosZoneServiceTypeListByCountryIdAndServiceType({ countryId: currentCountry.countryId, pageSize: 1000, pageNumber: 0, serviceType: [...serviceTypeList] }));
    }
  };

  const handleClosePosZoneMappingDialog = () => {
    appDispatch(PlolygonServicesSlice.resetPosZoneListDetailsServiceState());
    let newAppStateObj = PlolygonServicesUtils.handle_reset_pos_zone_mappind_details_utils(appStateObj, false, "", "", "");
    setAppStateObj(newAppStateObj);
  };

  const fetchRecordsByPosCode = () => {
    if (appStateObj !== undefined && appStateObj.posZoneSearchText !== undefined) {
      let newAppStateObj = PlolygonServicesUtils.design_custom_pos_zone_mapping_filtered_list_based_on_search(appStateObj, appStateObj.posZoneSearchText);
      setAppStateObj(newAppStateObj);
    }
  };

  const handleKeyUp = (e: any) => {
    if (e !== undefined) {
      if (e.key !== undefined && e.key === "Enter") {
        fetchRecordsByPosCode();
      } else if (e.keyCode !== undefined && e.keyCode === 13) {
        fetchRecordsByPosCode();
      }
    }
  };

  const configurePosZoneDataError = () => {
    if (posZoneMapListService !== undefined && posZoneMapListService.error !== "") {
      let newAppStateObj = PlolygonServicesUtils._handle_empty_pos_zone_mapping_data_and_loading_off(appStateObj);
      setAppStateObj(newAppStateObj);
    }
  };

  const configurePosZoneData = () => {
    if (
      posZoneMapListService.data !== undefined &&
      Array.isArray(posZoneMapListService.data) &&
      posZoneMapListService.fetchStatus !== undefined &&
      posZoneMapListService.fetchStatus !== "" &&
      posZoneMapListService.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED
    ) {
      const { listData }: any = _design_Pos_Zone_Service_List_Record(posZoneMapListService.data);
      let newAppStateObj = PlolygonServicesUtils._handle_Pos_Zone_Service_List_Record(appStateObj, listData);
      setAppStateObj(newAppStateObj);
    }
  };

  const handlePosZoneSearchChange = (event: any) => {
    if (event && event.target) {
      let { value } = event.target;
      let newAppStateObj = PlolygonServicesUtils.design_custom_pos_zone_mapping_filtered_list_based_on_search(appStateObj, value);
      setAppStateObj(newAppStateObj);
    }
  };

  const updatePosNumberOpenIndex = (pos_list_id: any, pos_list_index: any) => {
    if (pos_list_id != undefined && pos_list_id !== "") {
      let newAppStateObj = PlolygonServicesUtils._handle_action_pos_zone_mapping_open_index_utils(appStateObj, pos_list_id, pos_list_index);
      setAppStateObj(newAppStateObj);
    }
  };

  const handleChangeIsNonServiceable = () => {
    setAppStateObj({ ...appStateObj, isNonServiceable: !appStateObj.isNonServiceable, selectedPosZoneIndex: "", selectedPosZoneObj: {} });
  };

  const selectPosZoneForMapping = (pos_list_id: any, pos_list_index: any, pos_zone_id: any, pos_zone_Index: any) => {
    if (pos_list_id != undefined && pos_list_id !== "" && pos_zone_id !== undefined && pos_zone_id !== "") {
      let newAppStateObj = PlolygonServicesUtils._handle_action_pos_zone_selected_mapping_obj_utils(appStateObj, pos_list_id, pos_list_index, pos_zone_id, pos_zone_Index);
      setAppStateObj(newAppStateObj);
    }
  };

  const designPolygonSubmitObject = () => {
    const { serviceTypeName, serviceRowIndex, mappingListRowIndex, serviceTypeRecordList, selectedPosZoneObj, isNonServiceable } = appStateObj;

    if (!serviceTypeName || serviceRowIndex === undefined || mappingListRowIndex === undefined) {
      CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.NO_POS_ZONE_SELECTED_ERROR);
      setAppStateObj({ ...appStateObj, loading: false });
      return;
    }

    const targetMapping = serviceTypeRecordList[Number(serviceRowIndex)]?.mappingList?.[Number(mappingListRowIndex)];

    if (!targetMapping || !isArrayValid(serviceTypeRecordList[Number(serviceRowIndex)]?.mappingList)) {
      CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.NO_POS_ZONE_SELECTED_ERROR);
      setAppStateObj({ ...appStateObj, loading: false });
      return;
    }

    const updatedMappingList = [
      ...serviceTypeRecordList[Number(serviceRowIndex)].mappingList.slice(0, Number(mappingListRowIndex)),
      { ...targetMapping, ...(isNonServiceable ? { isNonServiceable } : { ...selectedPosZoneObj, isNonServiceable: false }) },
      ...serviceTypeRecordList[Number(serviceRowIndex)].mappingList.slice(Number(mappingListRowIndex) + 1),
    ];
    let newAppStateObj = PlolygonServicesUtils.hanlde_update_service_type_record(appStateObj, false, appStateObj.serviceRowIndex, updatedMappingList);
    setAppStateObj(newAppStateObj);
  };

  const handlePOSZoneMappingSubmit = () => {
    setAppStateObj({ ...appStateObj, loading: true });
    if (appStateObj.isNonServiceable || countObjectProperties(appStateObj?.selectedPosZoneObj) > 0) {
      designPolygonSubmitObject();
    } else {
      CustomAlert(ALERT_TYPES.ERROR, PlolygonServicesConstants.NO_POS_ZONE_SELECTED_ERROR);
      setAppStateObj({ ...appStateObj, loading: false });
    }
  };

  const getAllPolygonRecords = () => {
    setAppStateObj({
      ...appStateObj,
      loading: true,
      isEditMode: false,
      polygonId: null,
      polygonIdsSearchTextValue: "",
      allPolygonListIdsRecord: [],
      mappedPolygonIdsSearchTextValue: "",
      mappedPolygonIdsRecord: [],
    });
    appDispatch(PlolygonServicesSlice.getAllPolygonIdsDetails());
  };

  const configurePolygonIdsRecordError = () => {
    CustomAlert(ALERT_TYPES.ERROR, allPolygonListIdsRecord.error);
    setAppStateObj({ ...appStateObj, loading: false, serviceTypeRecordList: PlolygonServicesUtils.getServiceTypeRecordList(servicePropositionList) });
  };

  const configurePolygonIdsRecord = () => {
    setAppStateObj({
      ...appStateObj,
      loading: false,
      polygonIdsSearchTextValue: "",
      allPolygonListIdsRecord: allPolygonListIdsRecord.data,
      mappedPolygonIdsSearchTextValue: "",
      mappedPolygonIdsRecord: [],
      serviceTypeRecordList: PlolygonServicesUtils.getServiceTypeRecordList(servicePropositionList),
    });
  };

  const handleClosePolygonDetailsPopUp = () => {
    setAppStateObj({ ...appStateObj, openPolygonDetailsPopUp: false, polygonDetailsPopUpData: {} });
  };

  const fetchPolygonIdDetails = async (polygonId: string) => {
    const { payload }: any = await appDispatch(PlolygonServicesSlice.fetchPolygonIdDetails(polygonId));
    if (payload?.polygonId !== undefined) {
      setAppStateObj({ ...appStateObj, loading: false, openPolygonDetailsPopUp: true, polygonDetailsPopUpData: payload || {} });
    } else {
      setAppStateObj({ ...appStateObj, loading: false, openPolygonDetailsPopUp: false, polygonDetailsPopUpData: {} });
      let errorMessage = POLYGON_CONSTANT_TEXT.ERROR_FETCHING_CONFIG;
      if (payload.message && payload.message !== POLYGON_CONSTANT_TEXT.NO_MESSAGE_AVAILABLE) {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    }
  };

  const getPolygonDetails = (polygonId: string) => {
    if (polygonId !== undefined && polygonId !== "") {
      setAppStateObj({ ...appStateObj, loading: false });
      fetchPolygonIdDetails(polygonId);
    } else {
      CustomAlert(ALERT_TYPES.ERROR, POLYGON_CONSTANT_TEXT.NO_POLYGON_ID_FOUND);
    }
  };

  const headerPolygonIdsRowCheckboxOnchange = (event: any) => {
    if (event && event.target) {
      const { checked } = event.target;
      let newAppStateObj = PlolygonServicesUtils.headerPolygonIdsRowCheckboxOnchangeUtils(appStateObj, checked);
      setAppStateObj(newAppStateObj);
    }
  };

  const tableRowCellCheckboxOnchange = (event: any, polygonId: string) => {
    if (event && event.target) {
      const { checked } = event.target;
      let newAppStateObj = PlolygonServicesUtils.tableRowCellCheckboxOnchangeUtils(appStateObj, checked, polygonId);
      setAppStateObj(newAppStateObj);
    }
  };

  const deleteSelectedMappedPolygonId = (event: any, polygonId: string) => {
    if (polygonId !== undefined && polygonId !== "") {
      let newAppStateObj = PlolygonServicesUtils.deleteSelectedMappedPolygonIdUtils(appStateObj, polygonId);
      setAppStateObj(newAppStateObj);
    }
  };

  const handlePolygonIdSearchTextChange = (event: any) => {
    if (event && event.target) {
      let { name, value } = event.target;
      if (name === "polygonIdsSearchTextValue") {
        let newAppStateObj = PlolygonServicesUtils.handlePolygonIdSearchTextChangeUtils(appStateObj, allPolygonListIdsRecord?.data || [], value);
        setAppStateObj(newAppStateObj);
      }
    }
  };

  const handleMappedPolygonIdSearchTextChange = (event: any) => {
    if (event && event.target) {
      let { name, value } = event.target;
      if (name === "mappedPolygonIdsSearchTextValue") {
        let newAppStateObj = PlolygonServicesUtils.handleMappedPolygonIdSearchTextChangeUtils(appStateObj, value);
        setAppStateObj(newAppStateObj);
      }
    }
  };

  const getPolygonIdsRecordList = () => {
    let assigned_list_data = PlolygonServicesUtils.getPolygonIdsRecordListUtils(appStateObj, allPolygonListIdsRecord?.data || []);
    return [...new Set(assigned_list_data)];
  };

  const getMappedPolygonIdsRecordList = () => {
    let assigned_list_data = PlolygonServicesUtils.getMappedPolygonIdsRecordListUtils(appStateObj);
    return [...new Set(assigned_list_data)];
  };

  // handlePolygonConfigurationSave
  const saveNewPolygonIdConfig = async (payloadOject: any) => {
    const { payload }: any = await appDispatch(PlolygonServicesSlice.createNewPolygonIdConfig(payloadOject));
    if (payload?.message !== undefined) {
      setAppStateObj({ ...appStateObj, loading: false });
      let errorMessage = POLYGON_CONSTANT_TEXT.ERROR_CREATE_CONFIG;
      if (payload.message && payload.message !== POLYGON_CONSTANT_TEXT.NO_MESSAGE_AVAILABLE) {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    } else if (payload) {
      CustomAlert(ALERT_TYPES.SUCCESS, `Polygon mappings updated successfully`);
      history.push(APP_ROUTES.POLYGON_SERVICES);
    }
  };

  const updateNewPolygonIdConfig = async (payloadOject: any) => {
    const { payload }: any = await appDispatch(PlolygonServicesSlice.updatePolygonIdConfig(payloadOject));
    if (payload?.message !== undefined) {
      setAppStateObj({ ...appStateObj, loading: false });
      let errorMessage = POLYGON_CONSTANT_TEXT.ERROR_CREATE_CONFIG;
      if (payload.message && payload.message !== POLYGON_CONSTANT_TEXT.NO_MESSAGE_AVAILABLE) {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    } else if (payload) {
      CustomAlert(ALERT_TYPES.SUCCESS, `Polygon mapping updated successfully`);
      history.push(APP_ROUTES.POLYGON_SERVICES);
    }
  };

  const handlePolygonConfigurationSave = () => {
    const payloadOject = PlolygonServicesUtils.validatePolygonIdConfigurationSaveObject(appStateObj, POLYGON_CONSTANT_TEXT.SAVE, servicePropositionList);
    if (payloadOject && Object.keys(payloadOject).length !== 0) {
      setAppStateObj({ ...appStateObj, loading: false });
      saveNewPolygonIdConfig(payloadOject);
    }
  };

  const handlePolygonConfigurationUpdate = () => {
    const payloadOject = PlolygonServicesUtils.validatePolygonIdConfigurationSaveObject(appStateObj, POLYGON_CONSTANT_TEXT.UPDATE, servicePropositionList);
    if (payloadOject && Object.keys(payloadOject).length !== 0) {
      setAppStateObj({ ...appStateObj, loading: false });
      updateNewPolygonIdConfig(payloadOject);
    }
  };

  const getPolygonIdDetails = async (polygonId: string) => {
    setAppStateObj({ ...appStateObj, loading: true, isEditMode: true });
    const { payload }: any = await appDispatch(PlolygonServicesSlice.fetchPolygonIdDetails(polygonId));
    if (payload?.polygonId !== undefined) {
      setAppStateObj({ ...appStateObj, loading: false, isEditMode: true, polygonId: payload.polygonId, serviceTypeRecordList: PlolygonServicesUtils.designServiceTypeRecordListUtils(payload, servicePropositionList) });
    } else {
      setAppStateObj({ ...appStateObj, loading: false });
      let errorMessage = POLYGON_CONSTANT_TEXT.ERROR_FETCHING_CONFIG;
      if (payload.message && payload.message !== POLYGON_CONSTANT_TEXT.NO_MESSAGE_AVAILABLE) {
        errorMessage = payload.message;
      }
      CustomAlert(ALERT_TYPES.ERROR, errorMessage);
    }
  };

  const isDisabled = (serviceObj: ServiceTypeRecord) => {
    return appStateObj.isEditMode ? serviceObj.enableConfigEdit : false;
  };

  useEffect(() => {
    posZoneMapListService && posZoneMapListService.error && configurePosZoneDataError();
  }, [posZoneMapListService, posZoneMapListService.error]);

  useEffect(() => {
    posZoneMapListService && posZoneMapListService.data && configurePosZoneData();
  }, [posZoneMapListService, posZoneMapListService.data]);

  useEffect(() => {
    allPolygonListIdsRecord && allPolygonListIdsRecord.error && configurePolygonIdsRecordError();
  }, [allPolygonListIdsRecord, allPolygonListIdsRecord.error]);

  useEffect(() => {
    allPolygonListIdsRecord && allPolygonListIdsRecord.data && configurePolygonIdsRecord();
  }, [allPolygonListIdsRecord, allPolygonListIdsRecord.data]);

  useEffect(() => {
    const query: any = new URLSearchParams(location.search);
    let polygonId = query.get(POLYGON_CONSTANT_TEXT.POLYGON_ID_PARAM);
    if (!isUndefined(polygonId) && !isEmptyOrNull(polygonId)) {
      getPolygonIdDetails(polygonId);
    } else {
      getAllPolygonRecords();
    }
  }, [location.search]);

  useEffect(() => {
    return () => {
      appDispatch(PlolygonServicesSlice.resetAllPolygonIdsRecordServiceState());
      appDispatch(PlolygonServicesSlice.resetSpecificPolygonIdDetailsState());
    };
  }, []);

  const renderServiceAccordian = (serviceObj: ServiceTypeRecord, serviceObjIndex: number) => {
    return (
      <Accordion key={`${serviceObjIndex}-${serviceObj?.name}-serviceObj-service-type-record-list`} className={classes.accordionStyle} expanded={serviceObj.openAccordian} onChange={() => handleServiceTypeAccordionOpenState(serviceObjIndex)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryStyle}>
          <Grid container justifyContent="space-between" spacing={0}>
            <Grid item xs={12} className={classes.marginAuto}>
              <Typography className={classes.accordionTitle}>{`${serviceObj?.displayName ? serviceObj.displayName : serviceObj?.name ? createCamelCase(serviceObj?.name) : POLYGON_CONSTANT_TEXT.SERVICE_TYPE}`}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsStyle}>
          <Grid container spacing={1}>
            <Grid item xs={2} className={classes.marginAuto}>
              <Typography className={classes.configurationHeaderTextStyle}>{`${serviceObj.active ? POLYGON_CONSTANT_TEXT.START_TIME : ""}`}</Typography>
            </Grid>
            <Grid item xs={2} className={classes.marginAuto}>
              <Typography className={classes.configurationHeaderTextStyle}>{`${serviceObj.active ? POLYGON_CONSTANT_TEXT.END_TIME : ""}`}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.marginAuto}>
              <Typography className={classes.configurationHeaderTextStyle}>{`${serviceObj.active ? POLYGON_CONSTANT_TEXT.POS_ZONE : ""}`}</Typography>
            </Grid>
            <Grid item xs={2} className={classes.marginAuto}>
              {serviceObj.active ? <AddCircleIcon className={isDisabled(serviceObj) ? classes.addCircleDisabledStyle : classes.addCircleStyle} onClick={() => (isDisabled(serviceObj) ? undefined : handleAddMappingButton(serviceObjIndex))} /> : ""}
            </Grid>
            <Grid item xs={3} className={classes.marginAuto}>
              <Grid container justifyContent="flex-end">
                <ChangeConfigOtion
                  is_edit_mode={appStateObj.isEditMode}
                  classes={classes}
                  use_existing_checked={serviceObj.sameAsEarlier}
                  use_existing_config_change_handler={(e: any) => existingConfigChangeHandler(e, serviceObjIndex, "sameAsEarlier")}
                  change_existing_checked={serviceObj.enableConfigEdit}
                  use_change_existing_config_change_handler={(e: any) => configChangeExistingHandler(e, serviceObjIndex, "enableConfigEdit")}
                />
              </Grid>
            </Grid>
          </Grid>
          {serviceObj.active && (
            <div className={classes.configurationDivStyle}>
              {serviceObj?.mappingList?.length > 0 &&
                serviceObj.mappingList.map((mappingObj: any, mappingObjIndex: number) => (
                  <Grid container spacing={1} key={`${mappingObjIndex}-${mappingObj?.name}-service`} className={classes.configurationValueDivStyle}>
                    <Grid item xs={2} className={classes.marginAuto}>
                      <TimePicker
                        value={mappingObj.startTime}
                        handleChange={(date: any) => handleServiceTimeChange(mappingObjIndex, serviceObjIndex, "startTime", date)}
                        fieldLabel={undefined}
                        className={undefined}
                        timerProps={undefined}
                        disabled={appStateObj.isEditMode ? serviceObj.enableConfigEdit : false}
                        ampm={false}
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.marginAuto}>
                      <TimePicker
                        value={mappingObj.endTime}
                        handleChange={(date: any) => handleServiceTimeChange(mappingObjIndex, serviceObjIndex, "endTime", date)}
                        fieldLabel={undefined}
                        className={undefined}
                        timerProps={undefined}
                        disabled={appStateObj.isEditMode ? serviceObj.enableConfigEdit : false}
                        ampm={false}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={isDisabled(serviceObj) ? classes.disabledSectionContainer : classes.sectionContainer}
                      onClick={() => (isDisabled(serviceObj) ? undefined : handleOpenPosZoneMappingDialog(serviceObj?.name, serviceObjIndex, mappingObjIndex))}
                    >
                      {mappingObj.isNonServiceable ? (
                        <Typography className={classes.nonMappedTextStyle}>{`${"Non Serviceable Area"}`}</Typography>
                      ) : mappingObj.posNo ? (
                        <Typography className={classes.mappedTextStyle}>{`${mappingObj?.posNo || ""} - ${mappingObj?.posName || ""} - ${mappingObj?.zoneName || ""}`}</Typography>
                      ) : (
                        <Typography className={classes.addMappingTextStyle}>{`${POLYGON_CONSTANT_TEXT.ADD_MAPPING}`}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={2} className={isDisabled(serviceObj) ? classes.disabledSectionContainer : classes.sectionContainer}>
                      {mappingObjIndex !== 0 && (
                        <DeleteIcon className={isDisabled(serviceObj) ? classes.deleteIconDisabledStyle : classes.deleteIconStyle} onClick={() => (isDisabled(serviceObj) ? undefined : handleRemoveMappingButton(serviceObjIndex, mappingObjIndex))} />
                      )}
                    </Grid>
                    <Grid item xs={3} className={classes.marginAuto}>
                      {""}
                    </Grid>
                  </Grid>
                ))}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <OverlayLoader loading={appStateObj.loading} />
      <div className={classes.container}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <Typography className={classes.heading}>{`${appStateObj.isEditMode ? POLYGON_CONSTANT_TEXT.UPDATE_CONFIGURATION : POLYGON_CONSTANT_TEXT.CREATE_NEW_CONFIGURATION_BUTTON} 
            ${!isEmptyOrNull(appStateObj.polygonId) ? "Polygon ID : " + appStateObj.polygonId : ""}`}</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.configDetailsDiv}>
          <>
            {appStateObj.serviceTypeRecordList.map((serviceObj: ServiceTypeRecord, serviceObjIndex: number) =>
              !appStateObj.isEditMode ? renderServiceAccordian(serviceObj, serviceObjIndex) : serviceObj.active ? renderServiceAccordian(serviceObj, serviceObjIndex) : <></>
            )}
            {!appStateObj.isEditMode && (
              <RenderPolygonIdsMapping
                classes={classes}
                allPolygonListIdsRecord={appStateObj.allPolygonListIdsRecord || []}
                titleCount={appStateObj?.allPolygonListIdsRecord?.length || 0}
                totalPolygonIds={allPolygonListIdsRecord?.data?.length || 0}
                mappedPolygonIdsRecord={appStateObj.mappedPolygonIdsRecord}
                polygonIdsSearchTextValue={appStateObj.polygonIdsSearchTextValue}
                mappedPolygonIdsSearchTextValue={appStateObj.mappedPolygonIdsSearchTextValue}
                getPolygonDetails={getPolygonDetails}
                headerPolygonIdsRowCheckboxOnchange={headerPolygonIdsRowCheckboxOnchange}
                tableRowCellCheckboxOnchange={tableRowCellCheckboxOnchange}
                handlePolygonIdSearchTextChange={handlePolygonIdSearchTextChange}
                handleMappedPolygonIdSearchTextChange={handleMappedPolygonIdSearchTextChange}
                deleteSelectedMappedPolygonId={deleteSelectedMappedPolygonId}
                getPolygonIdsRecordList={getPolygonIdsRecordList}
                getMappedPolygonIdsRecordList={getMappedPolygonIdsRecordList}
              />
            )}
            <Grid className={classes.configButtonDiv}>
              <Link to={{ pathname: `${APP_ROUTES.POLYGON_SERVICES}` }} style={{ textDecoration: "none" }}>
                <PrimaryButton className={classes.cancelConfigStyle} buttonLabel={`${POLYGON_CONSTANT_TEXT.BACK_TO_LIST}`} disableFocusRipple={true} disableRipple={true} />
              </Link>
              {appStateObj.isEditMode ? (
                <PrimaryButton className={classes.previewAndUpdateConfigStyle} buttonLabel={`Update`} disableFocusRipple={true} disableRipple={true} onClick={() => handlePolygonConfigurationUpdate()} />
              ) : (
                <PrimaryButton className={classes.previewAndUpdateConfigStyle} buttonLabel="Save" disableFocusRipple={true} disableRipple={true} onClick={() => handlePolygonConfigurationSave()} />
              )}
            </Grid>
          </>
        </Grid>
        {appStateObj.showPosZoneMappingPopUp && (
          <PosZoneMappingDialog
            open={appStateObj.showPosZoneMappingPopUp}
            handleClose={handleClosePosZoneMappingDialog}
            stateObj={appStateObj}
            fetchRecordsByPosCode={fetchRecordsByPosCode}
            handleKeyUp={handleKeyUp}
            handlePosZoneSearchChange={handlePosZoneSearchChange}
            updatePosNumberOpenIndex={updatePosNumberOpenIndex}
            handleChangeIsNonServiceable={handleChangeIsNonServiceable}
            selectPosZoneForMapping={selectPosZoneForMapping}
            handlePOSZoneMappingSubmit={handlePOSZoneMappingSubmit}
            currentCountry={currentCountry}
            isLoading={appStateObj.loading}
          />
        )}
        {appStateObj.openPolygonDetailsPopUp && (
          <RenderPolygonDetailsPopUp
            open={appStateObj.openPolygonDetailsPopUp}
            handleClose={handleClosePolygonDetailsPopUp}
            polygonDetailsPopUpData={appStateObj.polygonDetailsPopUpData}
            polygonServiceRecord={PlolygonServicesUtils.generateServiceNameValueForPolygon(servicePropositionList)}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(PlolygonServicesConfigurationPage);
