import { Card, CardActions, CardContent, Grid, Modal, Typography } from "@material-ui/core";
import { AREA_POS_MAPPING_TYPE, DELIVERY_TYPES, EXPRESS_TYPES, STATUS, SWITCH_STATUS } from "../../../config/constants";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import { ServiceTypeBasicDetails } from "../CountrySettingView";
import { getHolidaysListObject } from "../CountrySettingsUtils";
import { isArrayValid, isNonEmptyObject, isUndefined } from "../../../utils/DataUtils";

interface CountryChangesConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  classes: any;
  areaPosMappingType: string;
  loading: boolean;
  handleSubmit: () => void;
  config: any;
}

export const CountryChangesConfirmationModal = (props: CountryChangesConfirmationModalProps) => {
  const { open, onClose, classes, areaPosMappingType, loading, handleSubmit, config } = props;

  const getHolidayList = (holidays: any) => {
    if (!isUndefined(holidays) && isArrayValid(holidays)) {
      let allHolidays: string[] = [];
      for (const holiday of holidays) {
        if (holiday.dateLabel) {
          const list = getHolidaysListObject(holiday);
          allHolidays = [...allHolidays, ...list.map((item: { objectDate: any }) => item.objectDate)];
        } else if (!isUndefined(holiday.offDate)) {
          allHolidays.push(holiday.offDate);
        }
      }
      return allHolidays.join(", ");
    }
    return "";
  };

  const renderServiceTypeDetails = (serviceTypeName: string, details: any, deliveryType: any) => {
    return (
      <Grid item xs={6} className={classes.serviceTypeGridItem}>
        <Grid container spacing={0} justifyContent="flex-start" className={classes.serviceTypeGridContainer}>
          <ServiceTypeBasicDetails
            classes={classes}
            serviceTypeName={serviceTypeName}
            serviceActiveStatus={details.serviceActive ? STATUS.ACTIVE : STATUS.IN_ACTIVE}
            nextDaySlotVisibilityStatus={details.nextDaySlotVisibility ? SWITCH_STATUS.ON : SWITCH_STATUS.OFF}
            editSlotDaysStatus={details.editSlotDays}
            advanceSlotDays={details.advanceSlotDays}
            plasticBagChargeStatus={details.bagChargeEnabled ? STATUS.ENABLED : STATUS.DISABLED}
            expressTypeStatus={details.expressType ? (details.expressType === EXPRESS_TYPES.SLOT_BASED.value ? EXPRESS_TYPES.SLOT_BASED.label : EXPRESS_TYPES.ON_DEMAND.label) : undefined}
          />
          {(deliveryType === DELIVERY_TYPES.DAY_BASED || !isUndefined(details?.holidays)) && (
            <>
              <Grid item xs={12} className={classes.serviceTypeDetailsGridItem}>
                <Typography className={classes.applyModalBodyContent}>{`Calendar Holidays : `}</Typography>
                <Typography className={classes.selectedValuesData}>{getHolidayList(details.holidays)}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.serviceTypeDetailsGridItem}>
                <Typography className={classes.applyModalBodyContent}>{`Weekly Holidays : `}</Typography>
                <Typography className={classes.selectedValuesData}>{`${details?.weeklyHolidays?.join(", ")}`}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card className={classes.applyChangesModalBody}>
        <CardContent>
          <Grid item xs={12} className={classes.modalTitleGridItem}>
            <Typography className={classes.modalTitle}>Are you sure you want to apply the following changes? </Typography>
          </Grid>
          <Grid container spacing={0} className={classes.modalBody}>
            {Object.keys(config).map((serviceTypeName) => renderServiceTypeDetails(serviceTypeName, config[serviceTypeName], config[serviceTypeName].deliveryType))}
            <Grid item xs={12} className={classes.serviceTypeGridContainer}>
              <Typography className={classes.applyModalBodyTitle}>Area Assignment</Typography>
              <Typography className={classes.applyModalBodyContent}>{areaPosMappingType === AREA_POS_MAPPING_TYPE.AREA_BASED ? "Area Based Assignment" : "Polygon Based Assignment"}</Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.serviceTypeFooterGridItem}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <PrimaryButton className={classes.buttonStyles} buttonLabel="CANCEL" buttonVariant="outlined" onClick={onClose} />
            </Grid>
            <Grid item xs={4}>
              <PrimaryButton className={classes.buttonStyles} buttonLabel={loading ? "loading..." : "PROCEED"} onClick={handleSubmit} disabled={loading} />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Modal>
  );
};
