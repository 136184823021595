import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { AREA_POS_MAPPING_TYPE, DELIVERY_TYPE, EXPRESS_TYPES } from "../../config/constants";
import { serviceSelectPropositionRecord } from "../../config/redux/configurationsSlice";
import { getNestedValue } from "../../utils/helperFunctions";
import { isArrayValid, isNull, isUndefined } from "../../utils/DataUtils";

import Express from "./Express/Express";
import StandardService from "./Standard/Standard";
import DefaultService from "../ServiceAndSlots/DefaultService/DefaultService";
import ClickAndCollect from "./ClickAndCollect/ClickAndCollect";

const ServiceAndSlots = () => {
  const { path } = useRouteMatch();
  const servicePropositionList = useSelector(serviceSelectPropositionRecord);

  /**
   * STANDARD is SLOT_BASED
   * Water is SLOT_BASED
   *
   * DEFAULT is DAY_BASED
   *
   * CLICK_N_COLLECT is Click And Collect
   *
   * FBC is Fulfilled By Carrefour
   *
   * Express is MINUTE_BASED
   * Quick Commerce is MINUTE_BASED
   * Bulk is MINUTE_BASED
   */

  const getRenderedComponent = (serviceObj) => {
    let deliveryType = getNestedValue(serviceObj, "deliveryType");
    if (isNull(deliveryType)) {
      deliveryType = getNestedValue(serviceObj, "name");
    }

    switch (deliveryType) {
      case DELIVERY_TYPE.SLOT_BASED:
        return <StandardService serviceTypeId={serviceObj.id} serviceTypeName={serviceObj.name.toLowerCase()} displayName={serviceObj?.displayName || ""} />;
      case DELIVERY_TYPE.MINUTE_BASED:
        return <Express serviceTypeId={serviceObj.id} serviceTypeName={serviceObj.name.toLowerCase()} expressType={EXPRESS_TYPES.ON_DEMAND.value} displayName={serviceObj?.displayName || ""} />;
      case DELIVERY_TYPE.CLICK_N_COLLECT:
        return <ClickAndCollect serviceTypeId={serviceObj.id} serviceTypeName={serviceObj.name.toLowerCase()} displayName={serviceObj?.displayName || ""} />;
      case DELIVERY_TYPE.DAY_BASED:
        return <DefaultService serviceTypeId={serviceObj.id} serviceTypeName={serviceObj.name.toLowerCase()} isPOSMappingAreaBased={false} displayName={serviceObj?.displayName || ""} />;
      default:
        return null;
    }
  };

  return (
    <Switch>
      {!isUndefined(servicePropositionList) && isArrayValid(servicePropositionList) ? (
        servicePropositionList.map((serviceObj) => {
          const serviceNameLower = getNestedValue(serviceObj, "name", "").toLowerCase();
          const routePath = `${path}/${serviceNameLower}/:posNo?`;
          return (
            <Route key={serviceObj.id} path={routePath}>
              {getRenderedComponent(serviceObj)}
            </Route>
          );
        })
      ) : (
        <Route path="*">
          <div>No services available.</div>
        </Route>
      )}
    </Switch>
  );
};

export default React.memo(ServiceAndSlots);
